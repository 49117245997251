import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Memorial, Page, ProdutoUnidade } from 'eddydata-lib';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MemorialService extends BaseResourceService<Memorial> {

  constructor(
    protected injector: Injector
  ) {
    super(`memoriais`, injector);
  }

  /**
 * @deprecated Método deverá ser substituído por `obterPorLicitacao`
 */
  public obterPorLicitacaoTransparencia(id: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?licitacao_id=${id}&relations=produto_unidade.produto&orderBy=ordem,id`
      , this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacao(id: number, incluirPropostas?: boolean, pregao?: boolean): Observable<Page> {
    let uri = `relations=licitacao,produto_unidade.produto.material,produto_unidade.unidade,rpl_itens,rpl_itens.rpl.exercicio,itens.produto,itens.produto_unidade.unidade,itens.produto_unidade.produto,itens.rpl_itens.rpl.exercicio${incluirPropostas ? ',propostas.proponente.favorecido,propostas.memorial' : ''}&licitacao_id=${id}&orderBy=${pregao ? 'ordem_julgamento,' : ''}ordem,id,itens.produto.nome,propostas.proponente.ordem`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`
      , this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorLicitacaoPregao(id: number): Observable<Page> {
    //rpl_itens,rpl_itens.rpl
    let uri = `relations=licitacao,produto_unidade.produto.material,produto_unidade.unidade&licitacao_id=${id}&orderBy=ordem_julgamento,ordem,id,itens.produto.nome,propostas.proponente.ordem`;
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/filtrar?${uri}`
      , this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


  public atualizarProdutoUnidade(produto_unidade: ProdutoUnidade, produto: { produto: string, unidade: string, licitacao_id: number }): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade`, { produto_unidade, produto }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarProdutoUnidadeId(produto_unidade: ProdutoUnidade, memorial_id: number): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade/${memorial_id}`, { produto_unidade }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarProdutoUnidadeConversao(memoriais: Memorial[]): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade-conversao`, { memoriais }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
