
import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContratoService, LicitacaoService, MemorialService, ProponenteService, PropostaService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, Comissao, DateFormatPipe, EddyAutoComplete, FormatoLicitacao, FuncaoService, GlobalService, Licitacao,
  LicitacaoComissaoMembro, LicitacaoFiscal, LicitacaoStorage, LoginContabil, Memorial, MemorialItem, Modalidade, OrgaoAudesp, OrgaoAudespService,
  Pagina, Setor, SetorService, SituacaoLicitacao, TipoContratacao, TipoObjetoLicitacao, Prazo, AmparoLegalService, RplStorage, RplStorageService,
  PNCPService, ListaVerificacao, Proposta, Rpl, Rcms, ParametroRequisicaoService, Orgao, TipoIntegracaoLicitacao, OrgaoService, ProgressoService
} from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ComissaoService } from '../../comissao/service/comissao.service';
import { PrazoService } from "../../prazo/service/prazo.service";
import { LicitacaoOcorrenciaService } from '../../licitacao-ocorrencia/service/licitacao-ocorrencia.service';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';
import { LicitacaoComissaoMembroService } from '../service/licitacao-comissao-membro.service';
import { LicitacaoStorageService } from '../service/licitacao-storage.service';
import { LicitacaoFiscalService, LicitacaoTestemunhaService, PregaoHistoricoService } from 'compra-lib';
import { ReservaDotacaoItemService } from 'contabil-lib';

declare var $: any;

@Component({
  selector: 'app-licitacao-form',
  templateUrl: './licitacao-form.component.html'
})
export class LicitacaoFormComponent extends BaseResourceFormComponent<Licitacao, LoginContabil> implements OnInit {
  /**
   * Declaração de variáveis
   */

  // cadastro
  @ViewChild('numero_') inputField: ElementRef;
  @Output() callbackRecarregarEntidade: EventEmitter<boolean> = new EventEmitter();
  @Output() callbackOrdem: EventEmitter<boolean> = new EventEmitter();
  @Output() callbackIncluido: EventEmitter<boolean> = new EventEmitter();

  public intregacaoHomologacao = false;

  public listaSituacoes: { id: SituacaoLicitacao, nome: string }[];
  public listaModalidades: Modalidade[];
  public listaTipoContratacoes: TipoContratacao[];
  public setorAutoComplete: EddyAutoComplete<Setor>;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public orgaoAudespAutoComplete: EddyAutoComplete<OrgaoAudesp>;
  public prazoEntregaAutoComplete: EddyAutoComplete<Prazo>;
  public prazoPagamentoAutoComplete: EddyAutoComplete<Prazo>;
  public listaComissoes: Comissao[];
  public formatosLicitacao: { id: FormatoLicitacao, nome: string }[];
  public listaTipoObjeto: { id: TipoObjetoLicitacao, nome: string }[];
  public listaObjetoLicitacao: { id: number, nome: string }[];
  public listaNatureza: { id: number, nome: string }[];
  public listaTipoLicitacao: { id: number, nome: string }[];
  public listaLei1232006: { id: number, nome: string }[];
  public opcoesSimNao: { id: 'S' | 'N', nome: string }[];
  public outroOrgao: 'S' | 'N' = 'N'
  public listaArquivos: LicitacaoStorage[];
  public listaArquivosRpl: RplStorage[];
  public servicoDlg: String = "servicoDlg";
  public buscaProdutoDlg: String = 'dialogProdutoUnBuscaAjusteLicitacao';
  public cadastroProdutoDlg: String = 'dialogProdutoUnCadastroAjusteLicitacao';
  public tipoContratacao: 'REGISTRO_PRECO' | 'CONTRATO' = 'REGISTRO_PRECO';
  public amparoLegalList: Array<{ codigo: number, lei: string }> = new Array<{ codigo: number, lei: string }>();
  public deixarSalvar: boolean = false;
  public rplsImportadas: Rpl[] = [];
  public rcmsImportadas: Rcms[] = [];
  public paramentroReservaDotacaoRpl: boolean = false;
  public chamarPendencia: boolean = false;
  public verificarBotoesProp: boolean = false;
  public verificarBotoesProponente: boolean = false;

  // julgamento
  public edicaoProposta = false;
  public julgarPropostasSubmit = false;

  // etapas do processo
  public etapaProcesso: boolean;
  public etapaMemorial: boolean;
  public etapaCredenciamento: boolean;
  public etapaProposta: boolean;
  public etapaJulgamento: boolean;
  public etapaContrato: boolean;
  public etapaFase4: boolean;

  // ajuste de conversão de serviços
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public contratosPendentes: any[];
  public reservasDisponiveis: any[];
  public visualizarExclusao: boolean = false;
  public credenciamento = false;
  public salvarListaVerificacao: boolean = false;
  public ordemAlf: boolean = false;
  public ordemOriginalItens: Memorial[] = [];
  public codConvocatorio = 0;
  public integracaoLicitacao: Orgao;
  public tipoIntegracaoLicitacao: TipoIntegracaoLicitacao = 'NENHUMA';

  // julgamento
  public edicaoContratoExcluido = false;
  public contratoIncluido = false;

  // importar processo
  public licitacaoImportOpt: {
    licitacao: Licitacao,
    processo: boolean,
    datas: boolean,
    justificativa: boolean,
    objeto: boolean,
    fiscais: boolean,
    memorial: boolean,
  };

  public tiposLei: { id: boolean, nome: string }[] = [
    { id: false, nome: 'Lei 8666/1993' },
    { id: true, nome: 'Lei 14.133/2021' },
  ];

  public opcoesSimNaoBoolean: { id: boolean, nome: string }[] = [
    { id: true, nome: 'Sim' },
    { id: false, nome: 'Não' }
  ];
  public opcoesSimNaoGarantia: { id: boolean, nome: string }[] = [
    { id: true, nome: 'Sim' },
    { id: false, nome: 'Não' }
  ];

  public opcoesSimNaoBeneficio: { id: boolean, nome: string }[] = [
    { id: true, nome: 'Sim' },
    { id: false, nome: 'Não' }
  ];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected modalidadeService: ModalidadeService,
    protected setorService: SetorService,
    protected comissaoService: ComissaoService,
    protected memorialService: MemorialService,
    protected proponenteService: ProponenteService,
    protected propostaService: PropostaService,
    protected contratoService: ContratoService,
    protected comissaoMembrosService: LicitacaoComissaoMembroService,
    protected ocorrenciaService: LicitacaoOcorrenciaService,
    protected storageService: LicitacaoStorageService,
    protected rplStorageService: RplStorageService,
    protected orgaoAudespService: OrgaoAudespService,
    protected progressoService: ProgressoService,
    protected reservaDotacaoItemServive: ReservaDotacaoItemService,
    private prazoService: PrazoService,
    protected licitacaoService: LicitacaoService,
    private amparoService: AmparoLegalService,
    protected fiscalService: LicitacaoFiscalService,
    protected parametroRequisicaoService: ParametroRequisicaoService,
    protected testemunhaService: LicitacaoTestemunhaService,
    private pncpService: PNCPService,
    private orgaoService: OrgaoService,
    protected historioPregaoService: PregaoHistoricoService) {
    super(new Licitacao(), injector, Licitacao.converteJson, licitacaoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      formato_contratacao: [null, [Validators.required]],
      justificativa: ['', [Validators.required]],
      data_inicio: [null],
      data_termino: [null],
      prazo_pagamento: [null],
      prazo_entrega: [null],
      numero: [null, [Validators.required]],
      sequencia: [1],
      data_abertura: [null, [Validators.required]],
      data_ata: [null],
      data_edital: [null],
      data_julgamento: [null],
      data_parecer: [null],
      data_publicacao: [null],
      data_homologacao: [null],
      data_cancelamento: [null],
      data_assinatura: [null],
      data_ratificacao: [null],
      data_abertura_env: [null],
      data_entrega_env: [null],
      data_impugnacao: [null],
      data_esclarecimento: [null],
      deixar_salvar: [null],
      situacao: ['ATIVA', [Validators.required]],
      processo: [null, [Validators.required]],
      objeto: [null, [Validators.required]],
      valor_estimado: [0],
      valor_licitado: [0],
      enviar_transparencia: [false, [Validators.required]],
      chamamento_publico: [false, [Validators.required]],
      criterio_julgamento: ['MENOR_PRECO', [Validators.required]],
      situacao_pregao: [null],
      tipo_pregao: [null],
      criterio_desempate: [null],
      fracassar_auto: [true, [Validators.required]],
      modelo_ata: [null],
      natureza: [null],
      tipo_licitacao: [null, [Validators.required]],
      tabela_desconto: [null],
      objeto_licitacao: [null],
      lei_1232006: [null],
      credenciamento: [null],
      tipo_objeto: [null],
      modalidade: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      setor: [null],
      exercicio: [this.login.exercicio, [Validators.required]],
      comissao: [],
      tipo_contratacao: [null],
      solicitante: [null],
      processo_cc: [null],
      orgao_audesp: [null],
      ordem_alfabetica_memorial: [false],
      data_inicio_proposta: [null],
      data_fim_proposta: [null],
      data_inicio_pregao: [null],
      data_transparencia: [null],
      prazo_proposta: [null],
      forma_participacao: [null],
      tipo_disputa: [null],
      itens: [],
      proponentes: [],
      fiscais: [],
      testemunhas: [],
      ocorrencias: [],
      comissao_membros: [],
      modo_disputa: [0],
      amparo_legal: [0],
      informacao_complementar: [''],
      justificativa_presencial: [''],
      link_origem_lance: [''],
      edital: [null],
      cadastrado_pncp: [false],
      lei_14133_2021: [true],
      procedimento_auxiliar: [null],
      permite_subcontratacao: [false],
      clausulas_penais: [null],
      clausulas_financeiras: [null],
      lista_verificacao: [],
      ignorar_pncp: [false],
      sequencial_pncp: [null],
      categoria_processo: [null],
      enviado_integracao: [null],
      recebido_integracao: [null],
      tipo_integracao: [null],
      exige_garantia: [false],
      exige_beneficio: [false],
    });
  }

  protected campoFoco(): ElementRef {
    if (this.inputField) this.inputField.nativeElement.focus();
    return null;
  }

  protected async afterLoad() {
    this.tipoContratacao = this.entidade?.formato_contratacao === 'REGISTRO_PRECO' ? 'REGISTRO_PRECO' : 'CONTRATO';
    if (!this.entidadeForm.get('formato_contratacao').value) {
      this.entidadeForm.get('formato_contratacao').setValue(this.entidade?.formato_contratacao);
    }
    this.entidade.data_inicio = new DateFormatPipe().transform(this.entidade.data_inicio, []);
    this.entidade.data_termino = new DateFormatPipe().transform(this.entidade.data_termino, []);
    this.entidade.data_abertura = new DateFormatPipe().transform(this.entidade.data_abertura, ['datetime']);
    this.entidade.data_ata = new DateFormatPipe().transform(this.entidade.data_ata, []);
    this.entidade.data_edital = new DateFormatPipe().transform(this.entidade.data_edital, []);
    this.entidade.data_julgamento = new DateFormatPipe().transform(this.entidade.data_julgamento, []);
    this.entidade.data_parecer = new DateFormatPipe().transform(this.entidade.data_parecer, []);
    this.entidade.data_publicacao = new DateFormatPipe().transform(this.entidade.data_publicacao, []);
    this.entidade.data_homologacao = new DateFormatPipe().transform(this.entidade.data_homologacao, []);
    this.entidade.data_cancelamento = new DateFormatPipe().transform(this.entidade.data_cancelamento, []);
    this.entidade.data_assinatura = new DateFormatPipe().transform(this.entidade.data_assinatura, []);
    this.entidade.data_ratificacao = new DateFormatPipe().transform(this.entidade.data_ratificacao, []);
    this.entidade.data_abertura_env = new DateFormatPipe().transform(this.entidade.data_abertura_env, ['datetime']);
    this.entidade.data_inicio_proposta = new DateFormatPipe().transform(this.entidade.data_inicio_proposta, ['datetime']);
    this.entidade.data_fim_proposta = new DateFormatPipe().transform(this.entidade.data_fim_proposta, ['datetime']);
    this.entidade.data_inicio_pregao = new DateFormatPipe().transform(this.entidade.data_inicio_pregao, ['datetime']);
    this.entidade.data_entrega_env = new DateFormatPipe().transform(this.entidade.data_entrega_env, ['datetime']);
    this.entidade.data_impugnacao = new DateFormatPipe().transform(this.entidade.data_impugnacao, ['datetime']);
    this.entidade.data_esclarecimento = new DateFormatPipe().transform(this.entidade.data_esclarecimento, ['datetime']);
    this.credenciamento = this.entidade?.credenciamento;
    if (this.entidade.id) {
      this.amparoLegalList = await this.amparoService.buscarAmparosPNCP(1, false);
    }
    this.outroOrgao = this.entidade.orgao_audesp ? 'S' : 'N';

    this.historioPregaoService.filtrar(1, -1, { 'licitacao_id': this.entidade.id, 'orderBy': 'data_cadastro,cronometro,lance.lance' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.entidade.historicos = res ? res.content : [];
      });

    new Promise((resolve) => {
      this.memorialService.obterPorLicitacao(this.entidade.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidade.itens = res ? res.content : [];
          this.buscarImportacaoItens();
          this.proponenteService.obterPorLicitacao(this.entidade.id, true).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              this.entidade.proponentes = res ? res.content : [];

              this.contratoService.obterPorLicitacao(this.entidade.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                  this.entidade.contratos = res ? res.content : [];
                  resolve(true);
                }, error => this.funcaoService.acaoErro(error));
            }, error => this.funcaoService.acaoErro(error));
        }, error => this.funcaoService.acaoErro(error));
    }).then(() => {
      this.comissaoMembrosService.filtrar(0, 0, { licitacao_id: this.entidade.id, 'comissao_membro.ativo': true }).pipe(takeUntil(this.unsubscribe))
        .subscribe(async (res) => {
          await this.preencherMembros();
          const participantes: LicitacaoComissaoMembro[] = res.content;
          this.entidade.comissao_membros.forEach(m => {
            m.marcado = Boolean(participantes.find(p => p.comissao_membro.id === m.comissao_membro.id)).valueOf();
          });
        });
      this.carregarObjetosLicitacao();
      this.carregarContratacoes();
      this.atribuirEtapasProcesso();


      this.ocorrenciaService.filtrar(1, -1, { 'licitacao.id': this.entidade.id, relations: 'usuario', orderBy: 'data_ocorrencia$DESC' })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidade.ocorrencias = res.content;
        });

      this.storageService.filtrar(1, -1, { 'licitacao.id': this.entidade.id, orderBy: 'data_cadastro$DESC' })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaArquivos = res.content;
        });

      const rplImportadas = [];
      this.entidade?.itens.forEach((item) => {
        if (item?.rpl_itens[0]?.rpl?.id)
          rplImportadas.push(item?.rpl_itens[0]?.rpl?.id);
      });

      if (rplImportadas.length > 0) {
        this.rplStorageService.filtrar(1, -1, { 'rpl.id$in': rplImportadas.join(','), relations: 'rpl,rpl.exercicio', orderBy: 'rpl.numero$DESC,data_cadastro$DESC' })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.listaArquivosRpl = res.content;
          });
      }

      this.reservaDotacaoItemServive.filtrar(1, -1, { 'reserva.licitacao.id': this.entidade.id, orderBy: 'data_reserva', relations: 'ficha.despesa' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.reservasDisponiveis = res.content
          for (let item of this.reservasDisponiveis) {
            this.reservaDotacaoItemServive.totalReservadoItem(item.id)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe((resposta) => {
                item['saldo_reserva'] = +resposta.reserva;
              });
          }
        });

      this.contratosPendentes = this.contratoService.obterContratosPendentesLicitacao(this.entidade);

      this.verificarItensConversao();
    });

    this.fiscalService.filtrar(1, -1, { 'licitacao_id': this.entidade.id, 'relations': 'pessoa,rpl' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((fiscais) => {
        this.entidade.fiscais = fiscais.content;
      });

    this.testemunhaService.filtrar(1, -1, { 'licitacao_id': this.entidade.id, 'relations': 'pessoa' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((testemunhas) => {
        this.entidade.testemunhas = testemunhas.content;
      });

    if (this.entidade.id) {
      this.calcularCodConvocatorio();
      this.verificarExclusivoMPE()
    }
  }

  protected async podeAlterar(item: Licitacao): Promise<boolean> {
    return this.login.sistema != 'controle-interno';
  }

  calcularCodConvocatorio() {
    let modalidade: Modalidade = this.entidadeForm.get('modalidade').value;
    let modoDisputa: number = +this.entidadeForm.get('modo_disputa').value;

    if (this.entidade.id && (modalidade == null || (modoDisputa == 0 || modoDisputa == null))) {
      modalidade = !modalidade ? this.entidade.modalidade : modalidade;
      modoDisputa = modoDisputa == 0 ? this.entidade.modo_disputa : modoDisputa;
    }

    if (modalidade == null || modoDisputa == 0) {
      this.codConvocatorio = 0;
      return;
    }

    const procedimento: boolean = this.entidadeForm.get('chamamento_publico').value;

    //Prioridade 1 - Se for dispensa com disputa
    if (modoDisputa == 4) {
      this.codConvocatorio = 2;
      return;
    }

    //Prioridade 2
    //Quando é Dispensa de licitação ou Inexigibilidade, entra como Ato que autoriza contratação direta, código PNCP 3
    if ((modalidade.codigo_pncp == 8 || modalidade.codigo_pncp == 9) && modoDisputa != 4) {
      this.codConvocatorio = 3;
      return;
    }

    //Prioridade 3
    //Quando é selecionado preenchimento auxiliar, vai entrar como chamamento público código pncp 4
    if (procedimento) {
      this.codConvocatorio = 4;
      return;
    }

    //Prioridade 4
    if ((modalidade.codigo_pncp >= 1 && modalidade.codigo_pncp <= 7) || modalidade.codigo_pncp == 13) {
      this.codConvocatorio = 1;
      return;
    }

    this.codConvocatorio = 0;
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'modalidade.tipos_contratacao.tipo_contratacao,orgao,setor,setor.unidade,exercicio,comissao,'
        + 'tipo_contratacao,fiscais.pessoa,testemunhas.pessoa,licitacao_audesp,orgao_audesp,prazo_pagamento,prazo_entrega,usuario_remocao,lista_verificacao',
      // orderBy: 'historicos.data_cadastro,historicos.cronometro,historicos.lance.lance'
    };
  }

  protected async afterInit(): Promise<void> {
    this.modalidadeService.filtrar(1, -1, {
      'cidade.id': this.login.cidade.id, ativo: true, relations: 'tipos_contratacao.tipo_contratacao', orderBy: 'codigo'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidades = res ? res.content : new Array<Modalidade>();
        if (this.entidade.id) {
          if (!this.listaModalidades.find((m) => m.id == this.entidade.modalidade.id)) {
            this.listaModalidades.push(this.entidade.modalidade);
          }
        }
      }, error => this.funcaoService.acaoErro(error));

    this.comissaoService.filtrar(1, -1,
      {
        'orgao.id': this.login.orgao.id,
        'membros.ativo': true,
        relations: 'membros.pessoa',
        orderBy: 'nome',
        ativo: true
      }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaComissoes = res ? res.content : new Array<Comissao>();
      }, error => this.funcaoService.acaoErro(error));

    this.setorAutoComplete = new EddyAutoComplete(this.entidadeForm.get('setor'), this.setorService,
      'id', ['codigo', 'nome'], { orgao_id: this.login.orgao.id, relations: 'unidade,orgao', orderBy: 'nome' }, { number: ['id'], text: ['nome', 'codigo'] });

    this.orgaoAudespAutoComplete = new EddyAutoComplete(this.entidadeForm.get('orgao_audesp'), this.orgaoAudespService,
      'id', ['nome_municipio', 'nome_entidade'],
      { orderBy: 'nome_municipio,nome_entidade' }, { number: ['codigo_municipio', 'codigo_entidade'], text: ['nome_municipio', 'nome_entidade'] });

    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('processo_cc'), this.licitacaoService,
      'numero', ['numero'], { orgao_id: this.login.orgao.id, orderBy: 'numero', relations: 'modalidade' }, { text: ['numero'] }, () => {
        let licitacaoCC: Licitacao = this.entidadeForm.get('processo_cc').value;
        if (licitacaoCC) {
          this.licitacaoService.obter({ id: licitacaoCC.id, relations: this.parametrosExtras()['relations'] }).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              licitacaoCC = res;
              this.memorialService.obterPorLicitacao(licitacaoCC.id).pipe(takeUntil(this.unsubscribe))
                .subscribe((res) => {
                  licitacaoCC.itens = res ? res.content : [];
                  this.licitacaoImportOpt = {
                    licitacao: licitacaoCC, datas: true, fiscais: true, justificativa: true, memorial: true, objeto: true, processo: true
                  }
                }, error => this.funcaoService.acaoErro(error));
            }, error => this.funcaoService.acaoErro(error));
        }
      });

    this.parametroRequisicaoService.obter({ orgao_id: this.login?.orgao?.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.paramentroReservaDotacaoRpl = res?.reserva_dotacao_rpls
      });



    this.prazoEntregaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("prazo_entrega"),
      this.prazoService,
      "id",
      ["nome"],
      { "orgao.id": this.login.orgao.id, entrega: true },
      { text: ["nome"] }
    );

    this.prazoPagamentoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("prazo_pagamento"),
      this.prazoService,
      "id",
      ["nome"],
      { "orgao.id": this.login.orgao.id, pagamento: true },
      { text: ["nome"] }
    );

    this.listaSituacoes = this.globalService.obterSituacoesLicitacao();
    this.formatosLicitacao = this.globalService.obterFormatosLicitacao();
    this.listaTipoObjeto = this.globalService.obterTiposObjetoLicitacao();
    this.listaNatureza = this.globalService.obterNaturezaLicitacao();
    this.listaTipoLicitacao = this.globalService.obterTipoLicitacao()
    this.listaLei1232006 = this.globalService.obterLei1232006Licitacao();
    this.entidadeForm.get('exige_garantia').setValue(false);
    this.carregarObjetosLicitacao();
    this.opcoesSimNao = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ];

    this.entidade.itens = [];

    if (this.currentActionRoute === 'novo') {
      this.amparoLegalList = await this.amparoService.buscarAmparosPNCP(1, true);
      this.orgaoService.obter({ id: this.login?.orgao?.id, 'ignoreCondObrig': true }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get('tipo_integracao').setValue(res.tipo_integracao);
        });
    }

    this.reservasDisponiveis = [];
    this.entidadeForm.get('lei_14133_2021').setValue(this.login.parametro['licitacao']?.lei_14133_2021);
  }

  protected async beforeSubmit() {
    try {
      this.desabilitaCheckBox();

      if (!this.entidadeForm.get('formato_contratacao').value) {
        throw new Error(`Selecione o formato de contratação: Registro de preço / Reserva de valor`);
      }

      if (this.login.parametro['licitacao']?.licitacoes_bb && this.entidadeForm.get('modalidade').value === '8') {
        if (!this.entidadeForm.get('data_inicio_proposta').value) {
          throw new Error('Necessário informar data de ínicio da Proposta!');
        }

        if (!this.entidadeForm.get('data_fim_proposta').value) {
          throw new Error('Necessário informar data de término da Proposta!');
        }

        if (!this.entidadeForm.get('data_inicio_pregao').value) {
          throw new Error('Necessário informar data do pregão!');
        }

        // if (!this.entidadeForm.get('forma_participacao').value) {
        //   throw new Error('Necessário informar forma de participação!');
        // }
      }

      if (!this.entidade.id && this.entidadeForm.get('modalidade')?.value?.codigo === 7) {
        this.entidadeForm.get('tipo_pregao').setValue('PROCEDIMENTO_COMUM');
        this.entidadeForm.get('criterio_desempate').setValue('CREDENCIAMENTO');
        this.entidadeForm.get('criterio_julgamento').setValue('MENOR_PRECO');
        this.entidadeForm.get('situacao_pregao').setValue('PENDENTE');
      }

      if (this.entidadeForm.get('natureza').value === 'null') this.entidadeForm.get('natureza').setValue(null);
      if (this.entidadeForm.get('tipo_objeto').value === 'null') {
        this.entidadeForm.get('tipo_objeto').setValue(null);
        this.entidadeForm.get('objeto_licitacao').setValue(null);
      } else {
        if (this.entidadeForm.get('objeto_licitacao').value === 'null') this.entidadeForm.get('objeto_licitacao').setValue(null);
      }

      this.entidade.tipo_contratacao = this.entidadeForm.get('tipo_contratacao').value

      if (this.entidadeForm.get('tipo_licitacao').value === 'null') this.entidadeForm.get('tipo_licitacao').setValue(null);
      if (this.entidadeForm.get('lei_1232006').value === 'null') this.entidadeForm.get('lei_1232006').setValue(null);

      if (this.ordemOriginalItens.length) {
        this.entidadeForm.get('itens').setValue(this.ordemOriginalItens);
      } else {
        this.entidadeForm.get('itens').setValue(this.entidade.itens);
      }

      this.entidadeForm.get('comissao').setValue(this.entidade.comissao)
      this.entidadeForm.get('fiscais').setValue(this.entidade.fiscais);
      this.entidadeForm.get('testemunhas').setValue(this.entidade.testemunhas);
      this.entidadeForm.get('ocorrencias').setValue(this.entidade.ocorrencias);
      this.entidadeForm.get('proponentes').setValue(this.entidade.proponentes);
      this.entidadeForm.get('comissao_membros').setValue(this.entidade.comissao_membros);
      this.entidadeForm.get('ordem_alfabetica_memorial').setValue(this.entidade.ordem_alfabetica_memorial)

      if (this.entidadeForm.get('situacao').value === 'REMOVIDO' && !this.entidade?.id) {
        throw new Error('Não é possivel criar pocessos licitatórios com a situação inicial REMOVIDO!')
      }

      const modalidade: Modalidade = await this.modalidadeService.obterId(this.entidadeForm.get('modalidade').value.id).toPromise();

      if (this.entidadeForm.get('lei_14133_2021').value) {

        if (this.listaArquivos?.length > 0) {
          if (this.entidadeForm.get('amparo_legal').value <= 0) {
            throw new Error('Necessário selecionar o amparo legal')
          }

          if (this.entidadeForm.get('modo_disputa').value <= 0) {
            throw new Error('Necessário selecionar o modo de disputa');
          }
        }

        // if (modalidade.codigo_pncp != 8 && modalidade.codigo_pncp != 9) {
        //   if (this.entidadeForm.get('data_inicio_proposta').value <= 0) {
        //     throw new Error('Necessário informar a data de inicio das propostas');
        //   }

        //   if (this.entidadeForm.get('data_fim_proposta').value <= 0) {
        //     throw new Error('Necessário informar a data de fim das propostas');
        //   }
        // }
      }

      let licitacoes: Licitacao[] = (await this.licitacaoService.filtrar(1, -1, { exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, 'situacao$in': 'HOMOLOGADA,ADJUDICADA', modalidade_id: this.entidadeForm.get('modalidade').value.id }).toPromise()).content

      if (+modalidade.valor_limite > 0) {
        if (licitacoes.reduce((acc, licitacao) => +licitacao.valor_licitado + acc, 0) > +modalidade.valor_limite && await this.confirm(`Atenção modalidade ${modalidade.nome} já atingiu o valor limite de ${modalidade.valor_limite}, verifique se o processo pode ser enquadrado em outra modalidade. Deseja prosseguir?`, 'Confirmação!')) {
          throw new Error('Revise a modalidade!')
        }
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  public async confirm(mensagem: string, titulo: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: mensagem,
        header: titulo,
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        key: "modalidade",
        accept: () => {
          resolve(false);
        },
        reject: () => {
          resolve(true);
        },
      });
    });
  }

  protected afterSubmit(ent: Licitacao) {
    if (this.julgarPropostasSubmit) {
      if (this.entidade.modalidade.codigo === 7) {
        this.router.navigate(['/pregoes', ent.id, 'editar']);
      } else {
        this.router.navigate(['/processos-licitatorios', 'julgamento', ent.id]);
      }
    } else {
      if (this.inclusao) this.router.navigate(['/processos-licitatorios', ent.id, 'editar']);
      else {
        this.entidadeForm.get('valor_licitado').setValue(ent.valor_licitado);
        this.afterLoad();
        this.entidade.data_alteracao = new Date();
      }
    }
    this.buscarImportacaoItens();
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public carregarContratacoes(atribuirContratacao?: boolean) {
    const modalidade: Modalidade = this.entidadeForm.get('modalidade').value;
    if (modalidade?.codigo !== 6 && modalidade?.codigo !== 4) {
      this.entidadeForm.get('credenciamento').setValue(false);
    }
    this.credenciamento = this.entidadeForm.get('credenciamento').value;
    if (modalidade?.tipos_contratacao) {
      this.listaTipoContratacoes = modalidade.tipos_contratacao.map(tc => tc.tipo_contratacao);
      if (this.listaTipoContratacoes.length > 0) {
        this.entidadeForm.get('tipo_contratacao').setValue(
          atribuirContratacao || !this.entidade.tipo_contratacao
            ? this.listaTipoContratacoes[0]
            : this.listaTipoContratacoes.find(t => t.id === this.entidade.tipo_contratacao.id)
        );
      }
    }
  }

  public carregarNumero() {
    const modalidade: Modalidade = this.entidadeForm.get('modalidade').value;
    this.licitacaoService.proximoNumero(this.login.exercicio.ano, modalidade.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.entidadeForm.get('numero').setValue(res);
      });
  }

  public async preencherMembros() {
    this.entidade.comissao_membros = [];
    this.entidade.comissao = this.entidadeForm.get('comissao').value === 'null' ? null : this.entidadeForm.get('comissao').value;
    if (!this.entidade.comissao) return;
    if (!this.entidade.comissao.membros) {
      this.entidade.comissao.membros = this.listaComissoes.find(c => c.id === this.entidadeForm.get('comissao').value.id)?.membros;
    }
    this.entidade.comissao.membros?.forEach(m => {
      this.entidade.comissao_membros.push({ comissao_membro: m, marcado: true })
    });
  }

  public julgarPropostas() {
    this.julgarPropostasSubmit = true;
    this.submitForm();
  }

  public async efetivarPropostas() {
    this.entidade.itens = (await this.memorialService.filtrar(1, -1,
      {
        'licitacao_id': this.entidade.id,
        relations: 'licitacao,produto_unidade.produto.material,produto_unidade.unidade,propostas.proponente.favorecido,propostas.memorial,rpl_itens.rpl'
      }
    ).toPromise()).content

    for (const memorial of this.entidade.itens) {
      for (const proposta of memorial.propostas) {
        proposta.situacao = 'VENCEDOR';
        proposta.quantidade_final = memorial.quantidade;
        proposta.valor_final = proposta.valor_unitario;
      }
    }

    await this.salvar();
    await this.loadResource();
  }

  public async recarregar(): Promise<void> {
    await this.loadResource();
  }

  public importarProcessoAnterior() {
    if (!this.licitacaoImportOpt?.licitacao) {
      toastr.error('Processo não selecionado para importação!');
      return;
    }

    try {
      if (this.licitacaoImportOpt.processo) {
        this.entidadeForm.get('modalidade').setValue(this.licitacaoImportOpt.licitacao.modalidade);
        this.carregarContratacoes();

        this.entidadeForm.get('tipo_objeto').setValue(this.licitacaoImportOpt.licitacao.tipo_objeto);
        this.carregarObjetosLicitacao();

        this.atribuirValorCC([
          'formato_contratacao', 'processo', 'valor_estimado', 'criterio_julgamento', 'fracassar_auto', 'modalidade', 'setor', 'comissao', 'tipo_contratacao',
          'natureza', 'tipo_objeto', 'objeto_licitacao', 'tipo_licitacao', 'lei_1232006'
        ]);
      }
      if (this.licitacaoImportOpt.objeto) {
        this.atribuirValorCC(['objeto']);
      }
      if (this.licitacaoImportOpt.justificativa) {
        this.atribuirValorCC(['justificativa']);
      }
      if (this.licitacaoImportOpt.fiscais && this.licitacaoImportOpt.licitacao.fiscais?.length > 0) {
        this.entidade.fiscais = []
        for (const fiscal of this.licitacaoImportOpt.licitacao.fiscais) {
          this.entidade.fiscais.push(new LicitacaoFiscal(undefined, fiscal.pessoa, undefined))
        }
      }
      if (this.licitacaoImportOpt.memorial) {
        const itens: Memorial[] = [];
        this.licitacaoImportOpt.licitacao.itens.forEach(item => {
          const m = new Memorial();

          m.descricao = item.descricao;
          m.observacao = item.observacao;
          m.ordem = item.ordem;
          m.ordem_julgamento = item.ordem_julgamento;
          m.produto_unidade = item.produto_unidade;
          m.quantidade = item.quantidade;
          m.unidade = item.unidade;
          m.valor_referencia = item.valor_referencia;
          m.reducao_criterio = item.reducao_criterio;
          m.reducao_lance = item.reducao_lance;
          m.cota = item.cota;

          const itensLote: MemorialItem[] = [];
          item.itens.forEach(itemLote => {
            itensLote.push(new MemorialItem(undefined, itemLote.quantidade, itemLote.descricao, m, itemLote.produto, itemLote.produto_unidade, false));
          });
          m.itens = itensLote;

          itens.push(m);
        });
        if (itens.length > 0) {
          this.entidade.itens = itens;
        }
      }

      toastr.success('Informações importadas com sucesso!');
      $('#dialogImportarProcessoAnterior').modal('hide');
    } catch (err) {
      toastr.error('Não foi possível importar informações de processo anterior!');
    }
  }

  private atribuirValorCC(campos: string[], data?: boolean) {
    if (campos && this.licitacaoImportOpt?.licitacao) {
      campos.forEach(campo => {
        let valorCC = this.licitacaoImportOpt.licitacao[campo];
        if (data) valorCC = new DateFormatPipe().transform(this.licitacaoImportOpt.licitacao[campo], [])
        if (valorCC) {
          this.entidadeForm.get(campo)?.setValue(valorCC);
        }
      });
    }
  }

  private atribuirEtapasProcesso() {
    this.etapaProcesso = !!this.entidade.id;
    this.etapaMemorial = !!(this.entidade.itens?.length > 0);
    this.etapaCredenciamento = !!(this.entidade.proponentes?.length > 0);
    this.etapaProposta = !!this.entidade.proponentes?.find(m => m?.propostas?.length > 0);

    const listaDeProponents = this.entidade.proponentes?.map(m => m.propostas).reduce((acc, curr) => acc.concat(curr), []);

    const propostaVencedora = listaDeProponents.find(prop => prop.situacao === 'VENCEDOR');

    this.etapaJulgamento = !!propostaVencedora;
    const contra = this.contratoService.obterContratosPendentesLicitacao(this.entidade)
    this.etapaContrato = !!(this.entidade.contratos?.length > 0 && contra?.length === 0);
    this.etapaFase4 = !!this.entidade.licitacao_audesp;

    if (!this.etapaContrato || this.entidadeForm.get('credenciamento').value) {
      if ((this.entidade.modalidade.codigo === 6 || this.entidade.modalidade.codigo === 4) && this.entidadeForm.get('credenciamento').value) {
        this.edicaoProposta = this.entidadeForm.get('situacao').value === 'ATIVA' || this.entidadeForm.get('credenciamento').value;
      } else if (this.entidade.contratos?.length === 0) {
        if (this.entidade.modalidade.codigo === 7) {
          this.edicaoProposta = !this.etapaJulgamento;
        } else {
          this.edicaoProposta = this.entidadeForm.get('situacao').value === 'ATIVA';
        }
      } else if (this.entidade?.contratos?.find(c => c.excluido)) {
        const listaPendentes = [];
        for (const proponente of this.entidade.proponentes) {
          let contratoExcluido = this.entidade?.contratos?.filter(c => c?.excluido && c?.favorecido?.id === proponente?.favorecido?.id && c.favorecido.id);

          contratoExcluido = contratoExcluido.filter(contrato =>
            contrato.favorecido.id !== (this.entidade.contratos.find(c => !c.excluido && c.favorecido.id === contrato.favorecido.id))?.favorecido?.id)

          if (contratoExcluido?.length > 0) {
            let prop = contratoExcluido.find(ce => ce.favorecido.id === proponente.favorecido.id)

            if (prop?.id) {
              const pendente = Object.assign({}, proponente);
              pendente.propostas = proponente.propostas.filter(p => p.memorial.produto_unidade = prop.itens.find(i => i?.memorial?.id === p?.memorial?.id)?.produto_unidade);
              pendente.propostas.forEach(p => p.memorial.produto_unidade = this.entidade?.itens?.find(m => m?.id === p?.memorial?.id)?.produto_unidade)
              pendente.licitacao = Object.assign({}, this.entidade);
              delete pendente.licitacao.proponentes;
              delete pendente.licitacao.itens;
              let propostas: Proposta[] = []

              for (let p = 0; p < pendente?.propostas?.length; p++) {
                if (+pendente.propostas[+p].quantidade_final !== 0) {
                  propostas.push(pendente.propostas[p]);
                }
              }
              pendente.propostas = propostas

              if (pendente.propostas?.length) {
                listaPendentes.push(pendente);
              }
            }
          }
        }
        this.edicaoContratoExcluido = !!(listaPendentes?.length > 0);
      }
    }
  }

  public remover() {
    if (this.entidade.id && !this.etapaMemorial && !this.entidade.enviar_transparencia) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover o processo?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.licitacaoService.remover(this.entidade.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.router.navigate([Pagina["Licitações"]]);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      toastr.warning('Processo não pode ser removido!');
    }
  }

  public carregarObjetosLicitacao() {
    this.listaObjetoLicitacao = this.globalService.obterObjetoLicitacao(this.entidadeForm.get('tipo_objeto').value);
  }

  public carregarPropostas() {
    this.entidade.data_alteracao = new Date()
  }

  public atualizarVlEstimado(valor: number) {
    this.entidade.valor_estimado = valor;
    this.entidadeForm.get('valor_estimado').setValue(valor);
    this.verificarExclusivoMPE()
  }

  public async aposAjustarProdutos(produtos: any[]) {
    if (!produtos)
      return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }
    for (let item of this.entidade.itens) {
      if (item.produto_unidade?.id) {
        for (const p of produtos) {
          if (p.id !== item.id && p.produto_unidade.id === item.produto_unidade?.id) {
            toastr.warning(`Ajuste repetido no serviço (${p.produto}).`);
            this.visulizarAjusteServico = true;
            return;
          }
        }
        continue;
      }
      let produto_unidade = produtos
        .find((p) => p.produto === item.produto_unidade.produto.nome
          && p.unidade === item.produto_unidade.unidade.nome);
      if (produto_unidade) {
        item.produto_unidade = produto_unidade.produto_unidade;
      }

      item.licitacao = new Licitacao();
      item.licitacao.id = this.entidade.id;
    }
    const itensParaAtualizar = this.entidade.itens.filter(item => item?.licitacao?.id)
    if (!this.ajusteSemSubmit) {
      this.memorialService.atualizarProdutoUnidadeConversao(itensParaAtualizar).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          window.location.reload();
        }, error => this.funcaoService.acaoErro(error))
    }

    this.ajusteSemSubmit = false;
    this.contratosPendentes = this.contratoService.obterContratosPendentesLicitacao(this.entidade);
  }

  public async verificaValidadeRespostas(lista) {
    let tamanho = Object.entries(lista).length;
    for (let i = 1; i <= +tamanho; i++) {
      const qst = lista[`qst${i}`];
      const justificativa = lista[`qst${i}Justificativa`];
      if (qst === 'N' && justificativa === '') {
        return true
      }
    }
    return false
  }

  public async chamarSalvar() {
    this.entidadeForm.get('deixar_salvar').setValue(this.deixarSalvar)
    if (this.salvarListaVerificacao) {
      let lista: ListaVerificacao = this.entidadeForm.get('lista_verificacao').value
      if (lista) {
        if (this.entidadeForm.get('modalidade').value.codigo === 5 || this.entidadeForm.get('modalidade').value.codigo === 6) {
          if (await this.verificaValidadeRespostas(lista.lista_verificacao1)) return toastr.error("Lista de Verificação 1: Responda indicação do local do processo em que foi atendida a exigência");
        } else {
          if (await this.verificaValidadeRespostas(lista.lista_verificacao_comum)) return toastr.error("Verificação Comum a Todos as Contratações: Responda indicação do local do processo em que foi atendida a exigência");
          if (await this.verificaValidadeRespostas(lista.lista_verificacao_pesquisa)) return toastr.error("Verificação Relativa à Pesquisa de Preços: Responda indicação do local do processo em que foi atendida a exigência");
          if (await this.verificaValidadeRespostas(lista.lista_verificacao_aquisicao)) return toastr.error("Verificação Específica para Aquisições: Responda indicação do local do processo em que foi atendida a exigência");
          if (await this.verificaValidadeRespostas(lista.lista_verificacao_servico)) return toastr.error("Verificação Específica para Serviços: Responda indicação do local do processo em que foi atendida a exigência");
        }

        if (this.entidadeForm.get('modalidade').value.codigo === 5) {
          if (await this.verificaValidadeRespostas(lista.lista_verificacao2B)) return toastr.error("Lista de Verificação 2B: Responda indicação do local do processo em que foi atendida a exigência");

          if (!lista.aquisicao && !lista.servico) {
            toastr.error(`Responda pelo menos um "SIM" para aquisição ou serviço.`);
            return;
          }

          if (lista.aquisicao) {
            if (await this.verificaValidadeRespostas(lista.lista_verificacao3A)) return toastr.error("Lista de Verificação 3A: Responda indicação do local do processo em que foi atendida a exigência");
          }

          if (lista.servico) {
            if (await this.verificaValidadeRespostas(lista.lista_verificacao3B)) return toastr.error("Lista de Verificação 3B: Responda indicação do local do processo em que foi atendida a exigência");
          }
        }

        if (this.entidadeForm.get('modalidade').value.codigo === 6) {
          if (await this.verificaValidadeRespostas(lista.lista_verificacao2A)) return toastr.error("Lista de Verificação 2A: Responda indicação do local do processo em que foi atendida a exigência");

          if (!lista.aquisicao && !lista.servico) {
            toastr.error(`Responda pelo menos um "SIM" para aquisição ou serviço.`);
            return;
          }

          if (lista.aquisicao) {
            if (await this.verificaValidadeRespostas(lista.lista_verificacao3A)) return toastr.error("Lista de Verificação 3A: Responda indicação do local do processo em que foi atendida a exigência");
          }

          if (lista.servico) {
            if (await this.verificaValidadeRespostas(lista.lista_verificacao3B)) return toastr.error("Lista de Verificação 3B: Responda indicação do local do processo em que foi atendida a exigência");
          }
        }
        this.salvarListaVerificacao = false;

      }
    } else {
      this.entidadeForm.get('lista_verificacao').setValue(null);
    }

    if (this.entidade.id && this.entidade.cadastrado_pncp) {
      if (this.validaRatificacaoPncp()) {
        this.confirmationService.confirm({
          message: '<pre>Deseja realmente atualizar o processo?\n'
            + 'Foram identificado dados a serem retificados no PNCP, ao continuar esse processo licitatorio será marcado como pendente de retificação e a situação como "Divulgada no PNCP".</pre>',
          header: 'Retificação PNCP',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Confirmar',
          rejectLabel: 'Cancelar',
          accept: () => {
            this.entidadeForm.get('cadastrado_pncp').setValue(false);
            this.salvar();
          }
        });
      } else {
        this.salvar();
      }
    } else {
      this.salvar();
    }
  }

  private async validarDataProposta() {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        header: "Dispensa fora do prazo",
        message: "A dispensa deve ser postada 3 dias antes do início das propostas. Deseja continuar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        key: "validaDataProposta",
        accept: () => {
          resolve(true)
        },
        reject: () => {
          resolve(false)
        }
      });
    });
  }

  private validaRatificacaoPncp(): boolean {
    //Falta adicionar mudança na situação da licitação
    const entidadeAtual: Licitacao = this.jsonDataToResourceFn(this.entidadeForm.getRawValue());
    if ((this.entidade.modalidade.codigo != entidadeAtual.modalidade.codigo)
      || (this.entidade.numero != entidadeAtual.numero)
      || (this.entidade.processo != entidadeAtual.processo)
      || (this.entidade.objeto != entidadeAtual.objeto)
      || (this.entidade.informacao_complementar != entidadeAtual.informacao_complementar)
      || (this.entidade.formato_contratacao != entidadeAtual.formato_contratacao)
      || (this.funcaoService.converteDataHoraBR(this.entidade.data_inicio_proposta) != this.funcaoService.converteDataHoraBR(entidadeAtual.data_inicio_proposta))
      || (this.funcaoService.converteDataHoraBR(this.entidade.data_fim_proposta) != this.funcaoService.converteDataHoraBR(entidadeAtual.data_fim_proposta))
      || (this.funcaoService.converteDataBR(this.entidade.data_fim_proposta) != this.funcaoService.converteDataBR(entidadeAtual.data_fim_proposta))
      || (this.entidade.link_origem_lance != entidadeAtual.link_origem_lance)
      || (this.entidade.justificativa_presencial != entidadeAtual.justificativa_presencial)) {
      return true;
    }
    return false;
  }

  public async salvar() {
    const texto: string = await this.validaConformidadePncp();
    if (texto != null) {
      toastr.error(texto);
      return;
    }

    this.entidadeForm.get('setor').setValue(this.entidade.setor);

    if (this.ordemOriginalItens.length) {
      this.entidadeForm.get('itens').setValue(this.ordemOriginalItens);
    } else {
      this.entidadeForm.get('itens').setValue(this.entidade.itens);
    }

    if (this.entidadeForm.get('situacao').value === 'REMOVIDO' && this.entidade?.id) {
      this.visualizarExclusao = true;
    } else {
      if (this.produtosAjuste.length === 0) {
        let ordem = 0;
        this.ordemOriginalItens.forEach((m) => {
          m.ordem = ++ordem;
          m.ordem_julgamento = m.ordem;
        });
        this.entidade.itens;
        await this.submitForm()
      } else {
        this.confirmationService.confirm({
          message: 'Existem itens pendentes de vinculo, não é possível salvar sem antes realizar a regularização do cadastro.',
          header: 'Aviso!',
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "OK",
          key: "modalidade",
          rejectVisible: false,
          accept: () => {
            return;
          },
        });
      }
    }
  }

  nomeConvocacao(cod: number): string {
    switch (cod) {
      case 1: return 'EDITAL';
      case 2: return 'AVISO DE CONTRATAÇÃO DIRETA';
      case 3: return 'ATO QUE AUTORIZA A CONTRATAÇÃO DIRETA';
      case 4: return 'EDITAL DE CHAMAMENTO PÚBLICO';
      default: return 'CONVOCAÇÃO INVÁLIDA PARA O PNCP';
    }
  }

  nomeDisputa(cod: number): string {
    switch (cod) {
      case 1: return 'ABERTO';
      case 2: return 'FECHADO';
      case 3: return 'ABERTO -> FECHADO';
      case 4: return 'DISPENSA COM DISPUTA';
      case 5: return 'NÃO SE APLICA';
      case 6: return 'FECHADO -> ABERTO';
      default: return 'INVÁLIDO';
    }
  }


  //Colocar aqui qualquer validação desejada para barrar no PNCP
  private async validaConformidadePncp(): Promise<string> {
    const lic: Licitacao = this.jsonDataToResourceFn(this.entidadeForm.getRawValue());
    if (!lic.lei_14133_2021) {
      return null;
    }
    this.calcularCodConvocatorio()
    const modalidade: number = lic.modalidade.codigo_pncp;
    let tipo_licitacao: number;
    let tipo = this.entidade.tipo_licitacao ?? this.entidadeForm.get('tipo_licitacao').value
    switch (tipo) {
      case 1: tipo_licitacao = 1;
        break;
      case 2: tipo_licitacao = 4;
        break;
      case 5: tipo_licitacao = 2;
        break;
      case 7: tipo_licitacao = 6;
        break;
      case 13: tipo_licitacao = 5;
        break;
      case 14: tipo_licitacao = 7;
        break;
      case 15: tipo_licitacao = 8;
        break;
      case 16: tipo_licitacao = 9;
        break;
      default: tipo_licitacao = -1;
    }

    try {
      await this.pncpService.consultarConformidadeConvatorioDisputa(this.codConvocatorio, +lic.modo_disputa)
    } catch (e) {
      return `O tipo de convocação ${this.nomeConvocacao(this.codConvocatorio)}, não é compativel com o Tipo de disputa ${this.nomeDisputa(+lic.modo_disputa)}`
    }
    
    try {
      await this.pncpService.consultarConformidadeModalidadeJulgamento(modalidade, tipo_licitacao);
    } catch (e) {
      return `A modalidade ${lic.modalidade.nome}, não é compativel com o tipo de julgamento ${this.globalService.obterTipoLicitacao()[tipo]['nome']}`
    }

    try {
      await this.pncpService.consultarConformidadeConvatorioModalidadeAmparo(lic.amparo_legal, modalidade, this.codConvocatorio);
    } catch (e) {
      return `O Amparo legal, a modalidade da licitação ${lic.modalidade.nome}, e o tipo de convocação ${this.nomeConvocacao(this.codConvocatorio)}, não estão em conformidade`
    }

    // //Validação 5.17
    // if ((lic.amparo_legal == 4 || lic.amparo_legal == 121) && (this.codConvocatorio != 1 || (modalidade != 1 && modalidade != 13))) {
    //   return 'Os amparos legais 4 e 121 somente podem ser usados no modo de convocação Edital, em modalidades do tipo Leilão';
    // }

    // if ((lic.amparo_legal == 5 || lic.amparo_legal == 124) && (this.codConvocatorio != 1 || modalidade != 2)) {
    //   return 'Os amparos legais 5 e 124 somente podem ser usados no modo de convocação Edital, em modalidade Diálogo Competitivo';
    // }

    // if (lic.amparo_legal == 3 && (this.codConvocatorio != 1 || modalidade != 3)) {
    //   return 'O amparo legal 3 somente pode ser usados no modo de convocação Edital, em modalidade Concurso';
    // }

    // if (lic.amparo_legal == 2 && (this.codConvocatorio != 1 || (modalidade != 4 && modalidade != 5))) {
    //   return 'O amparo legal 2 somente pode ser utilizado no modo de convocação Edital, em modalidade Concorrência';
    // }

    // if ((lic.amparo_legal == 114 || lic.amparo_legal == 115) && (this.codConvocatorio != 1 || modalidade != 4)) {
    //   return 'Os amparos legais 114 e 115 somente podem ser utilizados no modo de convocação Edital, em modalidade Concorrência eletrônica';
    // }

    // if ((lic.amparo_legal >= 116 && lic.amparo_legal <= 120) && (this.codConvocatorio != 1 || (modalidade != 3 && modalidade != 4))) {
    //   return 'Os amparos legais de 116 a 120 somente podem ser utilizados no modo de convocação Edital, em modalidade Concurso ou Concorrência eletrônica';
    // }

    // if (lic.amparo_legal == 1 && (this.codConvocatorio != 1 || (modalidade != 6 && modalidade != 7))) {
    //   return 'O amparo legal 1 somente pode ser utilizado no modo convocação Edital, em modalidade Pregão';
    // }

    // if (lic.amparo_legal == 113 && (this.codConvocatorio != 1 || modalidade != 6)) {
    //   return 'O amparo legal 113 somente pode ser utilizado no modo convocação Edital, em modalidade Pregão Eletrônico';
    // }

    // if (lic.amparo_legal == 123 && (this.codConvocatorio != 1 || (modalidade != 4 && modalidade != 6))) {
    //   return 'O amparo legal 123 somente pode ser utilizado no modo convocação Edital, em modalidade Concorrência Eletrônica ou Pregão Eletrônico';
    // }

    // if (((lic.amparo_legal >= 18 && lic.amparo_legal <= 46) || (lic.amparo_legal >= 60 && lic.amparo_legal <= 79) || (lic.amparo_legal >= 84 && lic.amparo_legal <= 101))
    //   && ((this.codConvocatorio != 2 && this.codConvocatorio != 3) || modalidade != 8)) {
    //   return 'Os amparos legais de 18 a 46, de 60 a 79, e de 84 a 101, somente podem ser utilizado no modos de Aviso ou Ato que autoriza a contratação direta, na modalidade Dispensa de Licitação.';
    // }

    // if (((lic.amparo_legal >= 51 && lic.amparo_legal <= 59) || (lic.amparo_legal >= 126 && lic.amparo_legal <= 137)) && (this.codConvocatorio != 3 || modalidade != 8)) {
    //   return 'Os amparos legais de 51 a 59, de 126 a 137, somente podem ser utilizados no modo Ato que autoriza a contratação direta, em modalidade Dispensa de Licitação';
    // }

    // if ((lic.amparo_legal == 138 || lic.amparo_legal == 139) && (modalidade != 8 || (this.codConvocatorio != 3 && this.codConvocatorio != 4))) {
    //   return 'Os amparos legais 138 e 139, somente podem ser utilizados nos modos de Ato que autoriza a contratação direta ou Edital de Chamamento público na modalidade DIspensa de Licitação';
    // }

    // if (((lic.amparo_legal >= 6 && lic.amparo_legal <= 17) || lic.amparo_legal == 50 || lic.amparo_legal == 102) && (modalidade != 9 || this.codConvocatorio != 3)) {
    //   return 'Os amparos legais de 6 a 17, 50 e 102, somente podem ser utilizados no modo Ato que autoriza a contratação direta, na modalidade Inexigibilidade';
    // }

    // if (lic.amparo_legal == 49 && ((this.codConvocatorio != 1 && this.codConvocatorio != 4) || modalidade != 10)) {
    //   return 'O amparo legal 49 somente pode ser utilizados no modo Edital ou Chamamento Público, na modalidade Manifestação de Interesse';
    // }

    // if (lic.amparo_legal == 112 && (modalidade != 10 || (this.codConvocatorio != 2 && this.codConvocatorio != 3))) {
    //   return 'O amparo legal 112 somente pode ser utilizado no modo de Aviso ou Ato que autoriza contratação direta na modalidade Manifestação de Interesse';
    // }

    // if (lic.amparo_legal == 48 && (modalidade != 11 || (this.codConvocatorio != 1 && this.codConvocatorio != 4))) {
    //   return 'O amparo legal 48 somente pode ser utilizado no modo Edital ou Chamamento Público na modalidade Pré-qualificação';
    // }

    // if (lic.amparo_legal == 125 && (modalidade != 12 || this.codConvocatorio != 1)) {
    //   return 'O amparo legal 125 somente pode ser utilizado no modo Edital com modaliade Credenciamento';
    // }

    // if (lic.amparo_legal == 103 && (this.codConvocatorio != 3 || modalidade != 12)) {
    //   return 'O amparo legal 103 somente pode ser utilizado no modo Ato que autoriza Contratação direta na modalidade Credenciamento';
    // }

    // if (lic.amparo_legal >= 140 && lic.amparo_legal <= 142 && (this.codConvocatorio != 4 || modalidade != 12)) {
    //   return 'Os amparos legais de 140 a 142 somente podem ser utilizado no modo Edital de chamamento público na modalidade Credenciamento';
    // }

    // if (lic.amparo_legal == 80 && (modalidade == 14 || this.codConvocatorio == 4)) {
    //   return 'O amparo legal 80 somente pode ser utilizado nos modos difernetes de Chamamento Público, em todas modalidades exceto Inaplicabilidade da Licitação';
    // }

    // if (lic.amparo_legal >= 81 && lic.amparo_legal <= 83 && (modalidade != 14 || this.codConvocatorio == 4)) {
    //   return 'Os amparos legais de 81 a 83 somente pode ser utilizado nos modos difernetes de Chamamento Público, em modalidade Inaplicabilidade da Licitação';
    // }

    // if (lic.amparo_legal >= 104 && lic.amparo_legal <= 111 && (modalidade != 14 || (this.codConvocatorio != 2 && this.codConvocatorio != 3))) {
    //   return 'Os amparos legais de 104 a 111 somente podem ser usados nos modos Aviso ou Ato que autoriza contratação direta, na modalidade Inaplicabilidade da Licitação';
    // }

    // if (lic.amparo_legal == 149 && (modalidade != 8 || this.codConvocatorio != 3)) {
    //   return 'O amparo legal 149 só pode ser usado no modo "Ato que Autoriza Contratação Direta" e na modalidade Dispensa de Licitação'
    // }

    // if (lic.amparo_legal == 150 && ((modalidade != 8 && modalidade != 9) || this.codConvocatorio != 3)) {
    //   return 'O amparo legal 150 só pode ser usado no modo "Ato que Autoriza Contratação Direta" e na modalidade "Dispensa de Licitação" ou "Inexigibilidade"'
    // }

    // //Validações 5.18
    // if (this.codConvocatorio == 1 && (lic.modo_disputa == 4 || lic.modo_disputa == 5)) {
    //   return 'O modo de convocação EDITAL não pode ter modo de disputa Dispensa ou Não se aplica';
    // }

    // if (this.codConvocatorio == 2 && lic.modo_disputa != 4) {
    //   return 'O modo de convocação AVISO DE CONTRATAÇÃO DIRETA não pode ter um modo de disputa diferente de "Dispensa com Disputa"';
    // }

    // if (this.codConvocatorio >= 3 && lic.modo_disputa != 5) {
    //   return 'O modo de convocação ATO QUE PERMITE A CONTRATAÇÃO DIRETA ou EDITAL DE CHAMAMENTO PÚBLICO devem ter o modo de disputa como Não se Aplica.';
    // }
  }

  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.entidade.itens = [];
    }
    this.visulizarAjusteServico = false;
  }

  public desbilitarCampo(): Boolean {
    if (this.etapaContrato === true) {
      return true
    } else {
      return false
    }
  }

  public desabilitaCheckBox() {
    if (this.entidadeForm.get('tipo_licitacao').value !== 5 && this.entidadeForm.get('natureza').value !== 3) {
      this.entidadeForm.get('tabela_desconto').setValue(false)
    }
  }

  public ordemOriginal(itens: Memorial[]) {
    //this.ordemOriginalItens = itens; - para salvar do jeito inicial
  }

  public handleTipoContratacaoChange(): void {
    if (this.entidadeForm.get('formato_contratacao').value === 'RESERVA_VALOR') {
      this.tipoContratacao = 'CONTRATO'
    } else {
      this.tipoContratacao = 'REGISTRO_PRECO';
    }
  }

  public obterVerificacao(lista: ListaVerificacao) {
    this.salvarListaVerificacao = true;
    if (this.entidade.lista_verificacao?.id) {
      lista.id = this.entidade.lista_verificacao?.id;
      this.entidadeForm.get('lista_verificacao').setValue(lista);
    } else {
      this.entidadeForm.get('lista_verificacao').setValue(lista);
    }
  }

  private verificarItensConversao() {
    this.produtosAjuste = []
    if (this.entidade?.itens?.filter((i) => !i.produto_unidade?.id && !i.lote).length > 0) {
      toastr.warning(`Foram identificados itens com serviços não cadastrados`);
      this.produtosAjuste = this.entidade?.itens?.filter((i) => !i.produto_unidade?.id)
        .map((i) => {
          return { produto: i.produto_unidade.produto.nome, unidade: i.produto_unidade.unidade.nome };
        });
      this.visulizarAjusteServico = true;
    }
  }

  public camposQuePodemAlterar() {
    if (!this.deixarSalvar) {
      this.deixarSalvar = true;
    }
  }

  public async registraDataTransparencia() {
    this.entidadeForm.get('data_transparencia').setValue(new Date())

    if (this.entidadeForm.get('enviar_transparencia').value) {
      const dataInicio = this.entidadeForm.get('data_inicio_proposta').value ? (this.funcaoService.formartaData(this.entidadeForm.get('data_inicio_proposta').value).split(' ')[0]) : undefined
      const dataFim = this.entidadeForm.get('data_fim_proposta').value
      const dataPublicacaoTrans = this.funcaoService.formartaData(this.entidadeForm.get('data_transparencia').value).split(' ')[0]

      if (!dataInicio || !dataFim) {
        toastr.warning(`Na modalidade ${this.entidadeForm.get('modalidade').value.nome} para publicação no portal transparência é obrigatório informar as datas de início e término da proposta!`)
        this.entidadeForm.get('enviar_transparencia').setValue(false)

      } else if (this.entidadeForm.get('modalidade').value.codigo === 5 && this.funcaoService.diferencaEmDias(new Date(dataInicio), new Date(dataPublicacaoTrans)) < 2 && !(await this.validarDataProposta())) {
        this.entidadeForm.get('enviar_transparencia').setValue(false)
      }
    }
  }

  public abrirPncp() {
    const ano = this.entidadeForm.get('exercicio').value ? this.entidadeForm.get('exercicio').value.ano : this.entidade?.exercicio.ano
    const sequencial_pncp = this.entidadeForm.get('sequencial_pncp').value ? this.entidadeForm.get('sequencial_pncp').value : this.entidade?.sequencial_pncp

    window.open(this.pncpService.abrirPortalPNCP(this.entidade.orgao.cnpj, ano, sequencial_pncp, 'E'), '_blank');
  }

  public buscarImportacaoItens() {
    this.rplsImportadas = [];
    for (const item of this.entidade?.itens as Memorial[]) {
      if (item?.rpl_itens && item.rpl_itens?.length > 0 && !item.lote) {
        for (const rplItem of item.rpl_itens) {
          if (!this.rplsImportadas.find(l => l.id === (rplItem).rpl.id)) {
            this.rplsImportadas.push(rplItem.rpl);
          }
        }
      }
      if (item?.itens && item.itens?.length > 0 && item?.itens?.find(ml => ml?.rpl_itens?.length > 0) && item.lote) {
        for (const itemLote of item.itens) {
          for (const rplItem of itemLote.rpl_itens) {
            if (!this.rplsImportadas.find(l => l.id === (rplItem).rpl.id)) {
              this.rplsImportadas.push(rplItem.rpl);
            }
          }
        }
      }
    }
    this.rcmsImportadas = [];
    if (this.entidade.id) {
      this.memorialService.filtrar(1, -1, { 'licitacao.id': this.entidade.id, 'relations': 'rcms_itens.rcms.exercicio,itens.rcms_itens.rcms.exercicio' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res.content.find(m => m?.rcms_itens?.length > 0)) {
            for (const item of res.content as Memorial[]) {
              if (item?.rcms_itens && item.rcms_itens?.length > 0 && !item.lote) {
                for (const rcmsItem of item.rcms_itens) {
                  if (!this.rcmsImportadas.find(l => l.id === (rcmsItem).rcms.id)) {
                    this.rcmsImportadas.push(rcmsItem.rcms);
                  }
                }
              }
              if (item?.itens && item.itens?.length > 0 && item.itens.find(ml => ml?.rcms_itens?.length > 0) && item.lote) {
                for (const itemLote of item.itens) {
                  for (const rcmsItem of itemLote.rcms_itens) {
                    if (!this.rcmsImportadas.find(l => l.id === (rcmsItem).rcms.id)) {
                      this.rcmsImportadas.push(rcmsItem.rcms);
                    }
                  }
                }
              }
            }
          }
        });
    }
  }

  public async carregarPendencia() {
    if (this.chamarPendencia) {
      this.chamarPendencia = false;
    } else {
      this.chamarPendencia = true;
    }
  }

  public verificarBotoesProponentes() {
    if (!this.verificarBotoesProponente) {
      this.verificarBotoesProponente = true;
    } else {
      this.verificarBotoesProponente = false;
    }
  }

  public verificarBotoesProposta() {
    if (!this.verificarBotoesProp) {
      this.verificarBotoesProp = true;
    } else {
      this.verificarBotoesProp = false;
    }
  }

  public envioPCP() {
    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Os dados do processo serão exportados ao Portal de Compras Públicas, deseja continuar?</pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        this.licitacaoService.envioIntegracaoPCP(this.entidade.id, this.entidade.orgao.id, this.login.usuario.id, this.intregacaoHomologacao).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, (retorno) => {
            this.entidade.ocorrencias.push(retorno);
            this.entidade.enviado_integracao = true;
            toastr.success("Processo cadastrado com sucesso. Favor fazer a validação do mesmo na página de importação de processos da área logada do portal.")
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  public retornoPCP() {
    this.confirmationService.confirm({
      header: 'Confirmar Retorno',
      message: `<pre>Os dados do processo serão importados do Portal de Compras Públicas, deseja continuar?</pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        this.licitacaoService.retornoIntegracaoPCP(this.entidade.id, this.entidade.orgao.id, this.login.usuario.id, this.intregacaoHomologacao).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, (retorno) => {
            toastr.info(retorno['mensagem'])
            this.entidade.ocorrencias.push(retorno);
            if (retorno['recibido']) {
              this.entidade.recebido_integracao = true;
              this.loadResource();
            }
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  public envioBLL() {
    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Os dados do processo serão exportados ao ${this.entidade.tipo_integracao === 'BNC' ? 'BNC' : 'BLL'}, deseja continuar?</pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        this.licitacaoService.envioIntegracaoBLL(this.entidade.id, this.entidade.orgao.id, this.login.usuario.id, this.intregacaoHomologacao, this.entidade.tipo_integracao === 'BNC' ? true : false).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, (retorno) => {
            this.entidade.ocorrencias.push(retorno);
            this.entidade.enviado_integracao = true;
            toastr.success("Identificador do Processo de integração registrado. \nFavor fazer a validação do mesmo na página de importação de processos da área logada do portal.")
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  public retornoBLL() {
    this.confirmationService.confirm({
      header: 'Confirmar Retorno',
      message: `<pre>Os dados do processo serão importados da ${this.entidade.tipo_integracao === 'BNC' ? 'BNC' : 'BLL'}, deseja continuar? </pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        this.licitacaoService.retornoIntegracaoBLL(this.entidade.id, this.entidade.orgao.id, this.login.usuario.id, this.intregacaoHomologacao, this.entidade.tipo_integracao === 'BNC' ? true : false).subscribe((idTransacao) => {
          this.progressoService.show(idTransacao, (retorno) => {
            toastr.info(retorno['mensagem'])
            this.entidade.ocorrencias.push(retorno);
            if (retorno['recibido']) {
              this.entidade.recebido_integracao = true;
              this.loadResource();
            }
          });
        }, e => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  public verificarExclusivoMPE() {
    if (!this.entidade?.id) {
      this.entidade.valor_estimado = this.entidadeForm.get('valor_estimado').value;
    };

    if ((+this.entidadeForm.get('valor_estimado').value > 0 && +this.entidadeForm.get('valor_estimado').value < 80000 && this.entidadeForm.get('tipo_integracao').value === 'PCP') || (this.entidade.tipo_integracao === 'PCP' && +this.entidade.valor_estimado > 0 && +this.entidade.valor_estimado < 80000)) {
      this.entidadeForm.get('lei_1232006').setValue(1);
      this.entidadeForm.get('lei_1232006').disable();
      this.entidade.lei_1232006 = 1;
    } else {
      // this.entidadeForm.get('lei_1232006').setValue(1);
      this.entidadeForm.get('lei_1232006').enable();
    }
  }
}