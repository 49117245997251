import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'eddydata-lib';
import { CompraFormComponent } from './compra-form/compra-form.component';
import { CompraListComponent } from './compra-list/compra-list.component';
import { CompraViewComponent } from './compra-view/compra-view.component';

const routes: Routes = [
  { path: '', component: CompraListComponent, canActivate: [AuthGuard] },
  { path: 'novo', component: CompraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/editar', component: CompraFormComponent, canActivate: [AuthGuard] },
  { path: ':id/visualizar', component: CompraViewComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompraRoutingModule { }
