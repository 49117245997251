import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MemorialService } from 'administrativo-lib';
import { ProdutoService } from 'almoxarifado-lib';
import { CodigoMercadoriaBB, CriterioReducaoMemorial, EddyAutoComplete, FuncaoService, Licitacao, LicitacaoPipe, Login, Memorial, ParametroLicitacao, ParametroLicitacaoService, PNCPService, Produto, ProdutoUnidade, RcmsCotacao, RcmsItem, RplCotacao, RplItem } from 'eddydata-lib';
import { Alignment, Workbook } from 'exceljs';
import { ConfirmationService } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { LicitacaoFiscalService } from '../licitacao-fiscal/service/licitacao-fiscal.service';
import { CodigoMercadoriaService } from '../produto/service/codigo-mercadoria.service';
import { RcmsItemService } from '../rcms/service/rcms-item.service';
import { RplItemService } from '../rpl/service/rpl-item.service';

declare var $: any;

@Component({
  selector: 'app-memorial',
  templateUrl: './memorial.component.html'
})
export class MemorialComponent implements OnInit, OnDestroy {
  @Input() edicao: boolean = true; // variavel para definir se opções de edições estarão disponiveis
  @Input() pregao: boolean = false; // variavel para definir se a chamada veio da tela de pregao
  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Output() callbackRPL: EventEmitter<number> = new EventEmitter();
  @Output() callbackItens: EventEmitter<Memorial[]> = new EventEmitter();

  public imaskQtd = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskConfig = {
    mask: Number,
    scale: 6,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskPncp = {
    mask: Number,
    min: 0,
    max: 99.9999,
    scale: 4,
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public imaskTempoDisputa = {
    mask: Number,
    scale: 0,
    signed: true,
  };

  public ordemAlf: boolean
  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public itemAnterior: Memorial;
  public itemAtual: Memorial;
  public itemExtras: Memorial = new Memorial();
  public produtoAtual: Produto;
  public criteriosReducao: { id: CriterioReducaoMemorial, nome: string }[];
  public reducaoCriterioGlobal: CriterioReducaoMemorial = 'V';
  public reducaoLanceGlobal: number = 0;
  public marcado: boolean = true;
  public abrirCota: boolean = false;
  public ordemOriginal: any[] = [];
  public mostrarProduto = false;
  public codigoMercadoria: CodigoMercadoriaBB;
  public codigoMercadoriaComplete: EddyAutoComplete<CodigoMercadoriaBB>;
  public justificativa: string;
  public justificativaFn: () => any;
  public mostrarDescricao: boolean = false

  public normal: 'S' | 'N' = 'N';
  public adicional: 'S' | 'N' = 'N';
  public opcoesSimNaoNormal: { id: 'S' | 'N', nome: string }[];
  public opcoesSimNaoAdicional: { id: 'S' | 'N', nome: string }[];
  public percentualNormal: any;
  public percentualAdicional: any;

  //Ajuste de serviços de conversão
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public parametrosLicitacao: ParametroLicitacao;
  protected unsubscribe: Subject<void> = new Subject();

  @ViewChild('produto_item_') campoItem: AutoComplete;
  @ViewChild('nome_lote_') campoLote: ElementRef;
  @ViewChild('quantidade_') campoQtde: ElementRef;
  @ViewChild('descricao_') campoDescricao: ElementRef;
  @ViewChild('reducao_') campoReducao: ElementRef;
  @ViewChild('btnAdicionar') public btnAdicionar: ElementRef;
  @ViewChild('btnAdicionarLote') public btnAdicionarLote: ElementRef;
  @ViewChild('justificativa_') campoJustificativa: ElementRef;

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected memorialService: MemorialService,
    protected itemRplService: RplItemService,
    protected itemRcmsService: RcmsItemService,
    protected produtoService: ProdutoService,
    private pncpService: PNCPService,
    private codigoMercadoriaService: CodigoMercadoriaService,
    private parametroLicitacaoService: ParametroLicitacaoService,
    protected fiscalService: LicitacaoFiscalService
  ) { }

  ngOnInit(): void {
    this.produtoAutoComplete = ProdutoService.autoCompleteCodigoCompleto(null, this.produtoService,
      'id', ['codigo', 'nome'], { orderBy: 'codigo,nome', orgao_id: this.login.orgao.id, relations: 'unidades.unidade,material', 'desabilita_compra': false }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
    this.criteriosReducao = [{ id: 'V', nome: '$' }, { id: 'P', nome: '%' },];
    this.codigoMercadoriaComplete = new EddyAutoComplete(null, this.codigoMercadoriaService,
      'codigo', ['codigo'], null, { text: ['codigo', 'nome'] }
    );

    this.parametroLicitacaoService.filtrar(1, -1, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.parametrosLicitacao = res.content[0];
      });

    this.opcoesSimNaoNormal = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ];

    this.opcoesSimNaoAdicional = [
      { id: 'S', nome: 'Sim' },
      { id: 'N', nome: 'Não' },
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entidade) {
      this.ordemAlf = this.entidade.ordem_alfabetica_memorial
    }
  }

  @HostListener('window:keydown.control.i', ['$event'])
  public adicionarItem(event: KeyboardEvent) {
    event.preventDefault();
    this.adicionar(false);
  }

  @HostListener('window:keydown.control.l', ['$event'])
  public adicionarLote(event: KeyboardEvent) {
    event.preventDefault();
    this.adicionar(true);
  }

  @HostListener('window:keydown.control.p', ['$event'])
  public abrirConsultaProduto(event: KeyboardEvent) {
    event.preventDefault();
    $('#dialogProdutoUnBusca').modal('show');
  }

  private adicionar(lote: boolean) {
    if (!this.edicao) return;

    if (this.entidade.itens && this.entidade.itens.find((itm) => { return itm.editavel === true })) {
      toastr.info('Finalize a edição atual antes de incluir outro item');
      return;
    }

    this.mostrarProduto = true;
    this.codigoMercadoria = new CodigoMercadoriaBB;
    this.itemAnterior = undefined;
    this.itemAtual = new Memorial();
    this.itemAtual.editavel = true;
    this.itemAtual.lote = lote;
    this.itemAtual.reducao_criterio = 'V';
    this.itemAtual.reducao_lance = 0;
    this.itemAtual.cota = 'NAO_DEFINIDO';
    this.produtoAtual = undefined;

    if (lote) {
      this.itemAtual.quantidade = 0;
    }

    if (!this.entidade.itens || this.entidade.itens.length === 0) {
      this.entidade.itens = [];
      this.itemAtual.ordem = 1;
    } else {
      this.itemAtual.ordem = (this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem })[this.entidade.itens.length - 1].ordem) + 1;
    }

    this.itemAtual.ordem_julgamento = this.itemAtual.ordem;

    this.entidade.itens.unshift(this.itemAtual);
    this.btnAdicionar.nativeElement.disabled = true;
    this.btnAdicionarLote.nativeElement.disabled = true;
    this.focarItem();
  }

  public editar(item: Memorial) {
    if (!this.edicao) return;

    if (this.entidade.itens && this.entidade.itens.find((itm) => { return itm.editavel === true || itm.editavelDescricao === true || itm.editavelReducao === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    this.codigoMercadoria = new CodigoMercadoriaBB;
    this.mostrarProduto = true;
    this.itemAnterior = Object.assign({}, item);
    this.itemAtual = item;
    this.itemAtual.editavel = true;

    if (!item.lote && item.produto_unidade?.produto?.id) {
      this.itemAtual.produto_unidade = item.produto_unidade;
      this.produtoService.obter({ orgao_id: this.login.orgao.id, id: this.itemAtual.produto_unidade.produto.id, relations: 'unidades.unidade' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.produtoAtual = res;
          this.selecionarUnidade();
          this.focarItem();
        });
    }

    if (item.produto_unidade?.produto?.cod_mercadoria) {
      this.codigoMercadoria.codigo = item.produto_unidade?.produto?.cod_mercadoria;
    } else {
      this.codigoMercadoria.codigo = undefined;
    }

    this.btnAdicionar.nativeElement.disabled = true;
    this.btnAdicionarLote.nativeElement.disabled = true;
    this.produtoAtual = null;
  }

  public async salvar(item: Memorial) {
    if (!this.edicao) return;

    if (item.editavelDescricao) {
      this.salvarDescricao(item);
      return;
    }

    try {
      if (item.lote) {
        if (!item.descricao) {
          throw new Error('Informe a descrição do lote!');
        }
        if (!item.unidade) {
          throw new Error('Informe a unidade do lote!');
        }
        if (this.entidade.itens.find(m => m.descricao === item.descricao && m !== item)) {
          this.itemAtual.descricao = undefined;
          this.itemAtual.unidade = undefined;
          throw new Error('Lote já incluído neste processo!');
        }

        item.quantidade = 1;
        item.valor_referencia = item.valor_referencia ? +item.valor_referencia : 0;
      } else {
        if (!item.produto_unidade?.id) {
          if (item.produto_unidade?.produto?.nome)
            toastr.warning(`Serviço vínculado é proveniente de conversão, necessario cadastrar o serviço para alterar o dados.`)
          else
            toastr.warning(`Informe produto/serviço para o item`)
          return false;
        }
        if (!this.produtoAtual || !item.produto_unidade) {
          throw new Error('Informe o produto/serviço e a unidade!');
        }
        if (!item.quantidade || +item.quantidade <= 0) {
          throw new Error('Informe a quantidade!');
        }
        if (this.entidade.itens.find(m => m.produto_unidade && item.produto_unidade && m.produto_unidade.id === item.produto_unidade.id && m !== item)) {
          this.itemAtual.produto_unidade = undefined;
          this.itemAtual.quantidade = undefined;
          throw new Error('Item já incluído neste processo!');
        }

        if (this.login.parametro['licitacao'].licitacoes_bb && this.codigoMercadoria.codigo === undefined) {
          throw new Error('Informe o código de mercadoria!');
        }

        delete this.produtoAtual.unidades;
        item.produto_unidade.produto = this.produtoAtual;
        if (!item.descricao)
          item.descricao = this.produtoAtual.descricao;
        item.unidade = item.produto_unidade.unidade.nome;
        if (this.codigoMercadoria.id !== undefined && this.login.parametro['licitacao'].licitacoes_bb) {
          item.produto_unidade.produto.cod_mercadoria = this.codigoMercadoria.codigo;
          const produto = await this.produtoService.obter({ 'id': item.produto_unidade.produto.id, orgao_id: this.login.orgao.id, relations: 'unidades.unidade,material,orgao' }).toPromise();
          produto.cod_mercadoria = this.codigoMercadoria.codigo;
          this.produtoService.atualizar(produto).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.success('Atualizado o código da mercadoria com sucesso!')
            }, (err) => toastr.error(err.error.payload));
        }
      }

      this.itemAtual.editavel = false;
      this.itemAtual = undefined;
      this.itemAnterior = undefined;
      this.btnAdicionar.nativeElement.disabled = false;
      this.btnAdicionarLote.nativeElement.disabled = false;
      this.ordenarItens();

      if (!this.entidade.id) {
        this.adicionar(item.lote)
      }
    } catch (e) {
      this.funcaoService.acaoErro(e)
      throw e;
    }
  }

  public cancelar(item: Memorial, index: number) {
    if (!this.edicao) return;
    this.itemAtual = Object.assign({}, this.itemAnterior);

    if (!this.itemAtual) {
      this.entidade.itens.splice(index, 1);
    } else if (!item.id && item.lote && !this.itemAtual.descricao && !this.itemAtual.unidade) {
      this.entidade.itens.splice(index, 1);
    } else if (!item.id && !item.lote && !this.itemAtual.quantidade && !this.itemAtual.produto_unidade) {
      this.entidade.itens.splice(index, 1);
    } else {
      if (!item.lote && this.produtoAtual) {
        delete this.produtoAtual.unidades;
        item.produto_unidade.produto = this.produtoAtual;
      }
      item.descricao = this.itemAtual.descricao;

      item.editavel = false;
      item.editavelDescricao = false;
    }
    this.itemAtual = undefined;
    this.itemAnterior = undefined;
    this.btnAdicionar.nativeElement.disabled = false;
    this.btnAdicionarLote.nativeElement.disabled = false;
  }

  public async remover(item: Memorial, index: number) {
    if (!this.edicao) return;
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover o item?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.memorialService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe(async (res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.itens.splice(index, 1);
              this.atualizarOrdem();
              this.btnAdicionar.nativeElement.disabled = false;
              this.btnAdicionarLote.nativeElement.disabled = false;
              for (const pe of this.entidade.proponentes) {
                const pa = pe.propostas.find(p => p.memorial.id === item.id)
                if (pa) pe.propostas.splice(pe.propostas.indexOf(pa), 1)
              }
              if (item?.rpl_itens?.[0]?.rpl?.id)
                await this.validaRemoverFiscalRpl(item?.rpl_itens?.[0]?.rpl?.id)
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.itens.splice(index, 1);
      this.atualizarOrdem();
      this.btnAdicionar.nativeElement.disabled = false;
      this.btnAdicionarLote.nativeElement.disabled = false;
      for (const pe of this.entidade.proponentes) {
        const pa = pe.propostas.find(p => p.memorial.id === item.id)
        if (pa) pe.propostas.splice(pe.propostas.indexOf(pa), 1)
      }

      if (item?.rpl_itens?.[0]?.rpl?.id)
        await this.validaRemoverFiscalRpl(item?.rpl_itens?.[0]?.rpl?.id)
    }
  }

  public alterarDescricao(item: Memorial) {
    if (!this.edicao && !this.pregao) return;
    if (this.entidade.itens && this.entidade.itens.find((itm) => { return itm.editavel === true || itm.editavelDescricao === true || itm.editavelReducao === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    if (!item.produto_unidade) {
      this.editar(item)
    } else {
      this.itemAnterior = Object.assign({}, item);
      item.editavelDescricao = true;
      setTimeout(() => this.campoDescricao ? this.campoDescricao.nativeElement.focus() : null, 500);
    }
  }

  public salvarDescricao(item: Memorial) {
    if (!this.edicao && !this.pregao) return;
    item.descricao = item.descricao.trim();
    item.editavelDescricao = false;
  }

  public alterarReducao(item: Memorial) {
    if (!this.edicao && !this.pregao) return;
    if (this.entidade.itens && this.entidade.itens.find((itm) => { return itm.editavel === true || itm.editavelDescricao === true || itm.editavelReducao === true })) {
      toastr.info('Finalize a edição atual antes de editar outro item');
      return;
    }

    item.editavelReducao = true;
    setTimeout(() => this.campoReducao ? this.campoReducao.nativeElement.select() : null, 500);
  }

  public salvarReducao(item: Memorial) {
    if (!this.edicao && !this.pregao) return;
    item.editavelReducao = false;
  }

  public salvarReducaoGlobal() {
    if (!this.edicao && !this.pregao) return;
    this.entidade.itens.forEach(item => {
      item.reducao_lance = this.reducaoLanceGlobal;
      item.reducao_criterio = this.reducaoCriterioGlobal;
    });
  }

  public ordenarItens(force?: boolean) {
    if (!this.edicao && !force) return;
    this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem });
  }

  public atualizarOrdem(force?: boolean) {
    if (!this.edicao && !force) return;
    this.ordenarItens(force);
    let ordem = 0;
    this.entidade.itens?.forEach((m) => {
      m.ordem = ++ordem;
      m.ordem_julgamento = m.ordem;
    });
  }

  private focarItem() {
    if (!this.edicao) return;
    if (this.itemAtual) {
      if (this.itemAtual.lote) {
        setTimeout(() => this.campoLote ? this.campoLote.nativeElement.focus() : null, 500);
      } else {
        setTimeout(() => this.campoItem ? this.campoItem.focusInput() : null, 500);
      }
    }
  }

  public selecionarUnidade() {
    if (!this.edicao) return;
    if (this.itemAtual && this.produtoAtual?.unidades) {
      if (this.itemAtual.unidade) {
        this.itemAtual.produto_unidade = this.produtoAtual.unidades.find((un) => un.unidade.nome === this.itemAtual.unidade);
      } else {
        this.itemAtual.produto_unidade = this.produtoAtual.unidades[0];
      }
    }
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (!this.edicao && !this.pregao) return;
    moveItemInArray(this.entidade.itens, event.previousIndex, event.currentIndex);
    let ordem = 0;
    this.entidade.itens.forEach((m) => {
      if (this.pregao) {
        m.ordem_julgamento = ++ordem;
      } else {
        m.ordem = ++ordem;
      }
    });
  }

  public abrirItens(item: Memorial) {
    this.itemAtual = item;
    $('#dialogMemorialItem').modal('show');
  }

  public callbackProdutoUn(prodUn: ProdutoUnidade) {
    if (!this.edicao) return;
    if (this.itemAtual && !this.itemAtual.lote) {
      this.itemAtual.produto_unidade = prodUn
      this.produtoService.obter({ orgao_id: this.login.orgao.id, id: this.itemAtual.produto_unidade.produto.id, relations: 'unidades.unidade' }).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.produtoAtual = res;
          setTimeout(() => this.campoQtde ? this.campoQtde.nativeElement.focus() : null, 500);
        });
    }
  }

  public async exportarMapaPreco() {
    if (!this.entidade.id) return;
    const algCenter: Partial<Alignment> = { vertical: 'middle', horizontal: 'center' };
    const algRight: Partial<Alignment> = { vertical: 'middle', horizontal: 'right' };

    const wb = new Workbook();
    wb.creator = this.login.usuario.nome;
    wb.lastModifiedBy = this.login.usuario.nome;
    wb.created = new Date();
    wb.modified = new Date();
    wb.calcProperties.fullCalcOnLoad = true;

    const ws = wb.addWorksheet('Memorial', { views: [{ state: 'frozen', xSplit: 0, ySplit: 4, activeCell: 'E5' }] });

    ws.columns = [
      { key: 'item', width: 5, alignment: algCenter, protection: { locked: true } },
      { key: 'unidade', width: 10, alignment: algCenter, protection: { locked: true } },
      { key: 'quantidade', width: 7, alignment: algRight, protection: { locked: true } },
      { key: 'descricao', width: 80, alignment: { vertical: 'middle', horizontal: 'left' }, protection: { locked: true } },
      { key: 'marca', width: 30, alignment: { vertical: 'middle', horizontal: 'left' } },
      { key: 'valor', width: 12, alignment: algRight },
      { key: 'total', width: 12, alignment: algRight, protection: { locked: true } }
    ];

    ws.getCell('A1').value = 'Código:';
    ws.getCell('A1').alignment = algCenter;
    ws.mergeCells('A1:B1');

    ws.getCell('C1').value = this.entidade.id;
    ws.getCell('C1').alignment = algCenter;

    ws.getCell('D1').value = 'ARQUIVO DE MAPA DE PREÇOS PARA PREENCHIMENTO DE PROPOSTA ESCRITA';
    ws.getCell('D1').alignment = algCenter;
    ws.getCell('D1').font = { bold: true, size: 16 };
    ws.mergeCells('D1:G1');

    ws.getCell('A2').value = 'Documento';
    ws.getCell('A2').alignment = algCenter;
    ws.getCell('A2').font = { bold: true, size: 12, color: { argb: 'FFFF0000' } };
    ws.mergeCells('A2:C2');

    ws.getCell('D2').value = this.login.orgao.nome + ' - ' + this.login.cidade.nome;
    ws.getCell('D2').alignment = algCenter;
    ws.getCell('D2').font = { bold: true, size: 12 };
    ws.mergeCells('D2:G2');

    ws.getCell('A3').value = 'Informe o Documento';
    ws.getCell('A3').alignment = algCenter;
    ws.getCell('A3').protection = { locked: false };
    ws.getCell(`A3`).numFmt = '[<=9999]0000;[>=999999999999]00"."000"."000"/"0000-00;000"."000"."000-00';
    ws.getCell('A3').font = { size: 12, color: { argb: 'FFFF0000' } };
    ws.getCell('A3').dataValidation = {
      type: 'textLength',
      operator: 'greaterThanOrEqual',
      allowBlank: false,
      showInputMessage: true,
      formulae: [12],
      promptTitle: 'Valor Inválido!',
      prompt: 'Campo de preenchimento obrigatório'
    };
    ws.mergeCells('A3:C3');

    ws.getCell('D3').value = `Divisão de Licitações e Compras - PROCESSO ADMINISTRATIVO Nº: ${this.entidade.processo} - ${this.entidade.modalidade.nome} Nº: ${new LicitacaoPipe().transform(this.entidade.numero)}`;
    ws.getCell('D3').alignment = algCenter;
    ws.getCell('D3').font = { bold: true, size: 12 };
    ws.mergeCells('D3:G3');

    ws.getRow(4).values = ['Item', 'Und.', 'Qtde', 'Descrição', 'Marca', 'Valor Unit.', 'Total'];
    ws.getRow(4).alignment = algCenter;
    ws.getRow(4).font = { bold: true };

    let linha = 5;
    this.entidade.itens.filter(i => !i.suspenso).forEach((item) => {
      ws.getRow(linha);

      ws.getCell(`A${linha}`).value = item.ordem;
      ws.getCell(`A${linha}`).alignment = algCenter;

      ws.getCell(`B${linha}`).value = item.unidade;
      ws.getCell(`B${linha}`).alignment = algCenter;

      ws.getCell(`C${linha}`).value = item.quantidade;
      ws.getCell(`C${linha}`).alignment = algCenter;

      ws.getCell(`D${linha}`).value = item.descricao;
      ws.getCell(`D${linha}`).alignment = { wrapText: true }

      ws.getCell(`E${linha}`).alignment = algCenter;
      ws.getCell(`E${linha}`).protection = { locked: false };

      ws.getCell(`F${linha}`).alignment = algRight;
      ws.getCell(`F${linha}`).numFmt = '#,##0.0000;[Red]\-#,##0.0000';
      ws.getCell(`F${linha}`).protection = { locked: false };
      ws.getCell(`F${linha}`).dataValidation = {
        type: 'decimal',
        operator: 'lessThanOrEqual',
        allowBlank: true,
        showInputMessage: true,
        formulae: [0],
        promptTitle: 'Valor Inválido!',
        prompt: 'O valor informado deve ser positivo'
      };

      ws.getCell(`G${linha}`).value = { formula: `C${linha} * F${linha}`, date1904: true };
      ws.getCell(`G${linha}`).alignment = algRight;
      ws.getCell(`G${linha}`).numFmt = '#,##0.0000;[Red]\-#,##0.0000';

      linha++;
    });

    await ws.protect('842d51933523ffbcdd1836e7d4a9323ff8b9cdd183', {});

    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `mapa-precos_${this.entidade.numero}.xlsx`;
      link.target = '_blank';
      link.click();
      window.URL.revokeObjectURL(downloadURL);
    });
  }

  public toggleSelecionado() {
    // marcado === false marcado
    // marcado === true desmarcado
    this.entidade.itens.forEach(m => m.selecionado = !this.marcado)
  }

  public suspenderItem(item: Memorial, anular?: boolean) {
    if (!item) return;
    this.abrirJustificativa(() => {
      item.suspenso = !anular;
      item.ordem = this.entidade.itens.length + 1;
      item.observacao = anular ? '' : `Justificativa suspensão: ${this.justificativa}`;
      this.atualizarOrdem(true);
    });
  }

  private abrirJustificativa(fn: () => any) {
    this.justificativaFn = fn;
    this.justificativa = '';
    setTimeout(() => this.campoJustificativa ? this.campoJustificativa.nativeElement.select() : null, 500);
    $('#dialogJustificativaSuspensao').modal('show');
  }

  public confirmarJustificativa() {
    if (!this.justificativa || !this.justificativa.trim()) {
      toastr.warning('Informe a Justificativa!');
      return;
    }

    this.justificativaFn();

    $('#dialogJustificativaSuspensao').modal('hide');
  }

  public abrirCotaReservada() {
    this.abrirCota = true;
    $('#dialogMemorialCota').modal('show');
  }

  public callbackCotaReservada(itensCota: Memorial[]) {
    itensCota.forEach(m => {
      m.ordem = (this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem })[this.entidade.itens.length - 1].ordem) + 1;
      m.ordem_julgamento = m.ordem;

      this.entidade.itens.push(m);
    });
    this.ordenarItens();
    this.abrirCota = false;
  }

  public desfazerCota(item: Memorial) {
    if (!item || item.cota === 'NAO_DEFINIDO') return;
    let cotaPrincipal: Memorial;
    let cotaReservada: Memorial;

    if (item.cota === 'PRINCIPAL') {
      cotaPrincipal = item;
      cotaReservada = this.entidade.itens.find(m => m.cota === 'RESERVADO' && m.produto_unidade.id === item.produto_unidade.id)
    } else {
      cotaPrincipal = this.entidade.itens.find(m => m.cota === 'PRINCIPAL' && m.produto_unidade.id === item.produto_unidade.id)
      cotaReservada = item;
    }
    if (!cotaPrincipal || !cotaReservada) {
      this.confirmationService.confirm({
        message: 'Não foi localizado o par de cotas para este item, Deseja realmente remover o item?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.memorialService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.itens.splice(this.entidade.itens.indexOf(item), 1);
              this.atualizarOrdem();
              this.btnAdicionar.nativeElement.disabled = false;
              this.btnAdicionarLote.nativeElement.disabled = false;
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      cotaPrincipal.quantidade = +cotaPrincipal.quantidade + +cotaReservada.quantidade;
      cotaPrincipal.cota = 'NAO_DEFINIDO';

      if (cotaReservada.id) {
        this.memorialService.remover(cotaReservada.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.entidade.itens.splice(this.entidade.itens.indexOf(cotaReservada), 1);

            cotaPrincipal.licitacao = Object.assign({}, this.entidade);
            delete cotaPrincipal.licitacao.itens;
            this.memorialService.atualizar(cotaPrincipal).pipe(takeUntil(this.unsubscribe))
              .subscribe((res) => {
                this.atualizarOrdem();
                this.btnAdicionar.nativeElement.disabled = false;
                this.btnAdicionarLote.nativeElement.disabled = false;

                toastr.success('Cota desfeita com sucesso!');
              }, (err) => toastr.error(err.error.payload));
          }, (err) => toastr.error(err.error.payload));
      } else {
        this.entidade.itens.splice(this.entidade.itens.indexOf(cotaReservada), 1);
        this.atualizarOrdem();
        this.btnAdicionar.nativeElement.disabled = false;
        this.btnAdicionarLote.nativeElement.disabled = false;

        toastr.success('Cota desfeita com sucesso!');
      }
    }
  }

  public async callbackImportacaoRPL(itensRPL: RplItem[]) {
    for (const rplItem of itensRPL) {
      // verifica se item do rpl já foi importado no memorial (não salvos)
      if (this.entidade.itens.find(m => m.rpl_itens.find(ri => ri.id === rplItem.id))) {
        toastr.info(`Item ${rplItem.produto_unidade.produto.nome} já importado ao memorial`);
        continue;
      }
      const item: RplItem = Object.assign({}, rplItem);
      let memorial: Memorial | 'N' = rplItem.memorial;
      let cotacoes: RplCotacao[] = [];
      if (memorial && memorial != 'N') { // soma quantidade do rpl ao item do memorial
        memorial.quantidade = +memorial.quantidade + +item.quantidade;

        cotacoes = cotacoes.concat(item.cotacoes); // inclui cotação do item do rpl atual
        for (const itemRpl of memorial.rpl_itens) { // inclui cotações ainda não salvas de outros rplsImportadas
          if (itemRpl.cotacoes && itemRpl.cotacoes.length > 0)
            cotacoes = cotacoes.concat(itemRpl.cotacoes);
        }
        if (memorial?.id) {
          for (const itemRpl of await (
            await this.itemRplService.filtrar(0, 0, { memorial_id: memorial.id, relations: 'cotacoes' }).toPromise()).content as RplItem[]
          ) { // inclui cotações já salvas de outros rpls
            if (itemRpl.cotacoes && itemRpl.cotacoes.length > 0)
              cotacoes = cotacoes.concat(itemRpl.cotacoes);
          }
        }

        if (cotacoes.length > 0) {
          memorial.valor_referencia = +this.calculoValorReferencia(cotacoes, memorial);
        }
      } else { // inclui item do RPL ao memorial
        memorial = new Memorial();
        memorial.reducao_criterio = 'V';
        memorial.reducao_lance = 0;

        memorial.cota = 'NAO_DEFINIDO';
        if (!this.entidade.itens || this.entidade.itens.length === 0) {
          this.entidade.itens = [];
          memorial.ordem = 1;
        } else {
          memorial.ordem = (this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem })[this.entidade.itens.length - 1].ordem) + 1;
        }
        memorial.ordem_julgamento = memorial.ordem;

        // informações do item do RPL
        memorial.produto_unidade = item.produto_unidade;
        memorial.descricao = this.mostrarDescricao
          ? item.produto_unidade.produto.descricao
          : item.produto_unidade.produto.nome;
        memorial.unidade = item.produto_unidade.unidade.nome;
        memorial.quantidade = +item.quantidade;
        memorial.valor_referencia = 0.0;
        if (item.cotacoes && item.cotacoes.length > 0) {
          // item.cotacoes.forEach(c => (memorial as Memorial).valor_referencia += +c.valor_unitario);
          memorial.valor_referencia = this.calculoValorReferencia(item.cotacoes, memorial)//+(memorial.valor_referencia / item.cotacoes.length).toFixed(4);
        }

        this.entidade.itens.push(memorial);
      }

      // vincula item do RPL ao memorial para ser salvo na api
      delete item.memorial;
      if (!memorial.rpl_itens) memorial.rpl_itens = [];
      memorial.rpl_itens.push(item);
    }
    this.ordemOriginal.push(...this.entidade.itens);
    this.ordenarItens();
    this.callbackItens.emit(this.ordemOriginal);

    this.confirmationService.confirm({
      message: 'Deseja atualizar o valor estimado do processo?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        let total = 0;
        for (const item of this.entidade.itens) {
          total += +item.quantidade * +item.valor_referencia;
        }
        this.callbackRPL.emit(total);
      }
    });
  }

  public async callbackImportacaoRCMS(itensRCMS: RcmsItem[]) {
    for (const rcmsItem of itensRCMS) {
      // verifica se item do rpl já foi importado no memorial (não salvos)
      if (this.entidade.itens.find(m => m.rcms_itens?.find(ri => ri.id === rcmsItem.id))) {
        toastr.info(`Item ${rcmsItem.produto_unidade.produto.nome} já importado ao memorial`);
        continue;
      }
      const item: RcmsItem = Object.assign({}, rcmsItem);
      let memorial: Memorial | 'N' = rcmsItem.memorial;
      let cotacoes: RcmsCotacao[] = [];
      if (memorial && memorial != 'N') { // soma quantidade do rpl ao item do memorial
        memorial.quantidade = +memorial.quantidade + +item.quantidade;


        cotacoes = cotacoes.concat(item.cotacoes); // inclui cotação do item do rpl a
        if (!memorial.rcms_itens?.length) {
          memorial.rcms_itens = []
        }
        for (const itemRcms of memorial?.rcms_itens as RcmsItem[]) { // inclui cotações ainda não salvas de outros rplsImportadas
          if (itemRcms.cotacoes && itemRcms.cotacoes.length > 0)
            cotacoes = cotacoes.concat(itemRcms.cotacoes);
        }
        if (memorial?.id) {
          for (const itemRcms of await (
            await this.itemRcmsService.filtrar(0, 0, { memorial_id: memorial.id, relations: 'cotacoes' }).toPromise()).content as RcmsItem[]
          ) { // inclui cotações já salvas de outros rpls
            if (itemRcms.cotacoes && itemRcms.cotacoes.length > 0)
              cotacoes = cotacoes.concat(itemRcms.cotacoes);
          }
        }

        if (cotacoes.length > 0) {
          let valorCotacao = 0;
          cotacoes.forEach(c => valorCotacao += +c.valor_unitario);
          valorCotacao = +(+valorCotacao / +cotacoes.length).toFixed(4);

          memorial.valor_referencia = valorCotacao;
        }
      } else { // inclui item do RCMS ao memorial
        memorial = new Memorial();
        memorial.reducao_criterio = 'V';
        memorial.reducao_lance = 0;

        memorial.cota = 'NAO_DEFINIDO';
        if (!this.entidade.itens || this.entidade.itens.length === 0) {
          this.entidade.itens = [];
          memorial.ordem = 1;
        } else {
          memorial.ordem = (this.entidade.itens.sort((a, b) => { return a.ordem - b.ordem })[this.entidade.itens.length - 1].ordem) + 1;
        }
        memorial.ordem_julgamento = memorial.ordem;

        // informações do item da RCMS
        memorial.produto_unidade = item.produto_unidade;
        memorial.descricao = this.mostrarDescricao
          ? item?.produto_unidade?.produto?.nome
          : item?.produto_unidade?.produto?.descricao;
        memorial.unidade = item.produto_unidade.unidade.nome;
        memorial.quantidade = +item.quantidade;
        memorial.valor_referencia = 0.0;
        if (item.cotacoes && item.cotacoes.length > 0) {
          item.cotacoes.forEach(c => (memorial as Memorial).valor_referencia += +c.valor_unitario);
          memorial.valor_referencia = +(memorial.valor_referencia / item.cotacoes.length).toFixed(4);
        }

        this.entidade.itens.push(memorial);
      }

      // vincula item do RPL ao memorial para ser salvo na api
      delete item.memorial;
      if (!memorial.rcms_itens) memorial.rcms_itens = [];
      memorial.rcms_itens.push(item);
    }
    this.ordemOriginal.push(...this.entidade.itens);
    this.ordenarItens();
    this.callbackItens.emit(this.ordemOriginal);

    this.confirmationService.confirm({
      message: 'Deseja atualizar o valor estimado do processo?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        let total = 0;
        for (const item of this.entidade.itens) {
          total += +item.quantidade * +item.valor_referencia;
        }
        this.callbackRPL.emit(total);
      }
    });
  }

  public calculoValorReferencia(cotacoes: any[], memorial: Memorial): number {
    let qntFornecedores = 0;
    let somaValorUnitario = 0
    let listaFavorecidos = cotacoes.filter((v, i, a) => +a.findIndex(t => (+t.rcmsFavorecido?.favorecido?.id === +v.rcmsFavorecido?.favorecido?.id || +t.favorecido?.favorecido?.id === +v.favorecido?.favorecido?.id)) === i)

    listaFavorecidos = listaFavorecidos.map(cot => {
      let cotacao = cotacoes.filter(gf => +gf.rcmsFavorecido?.favorecido?.id === +cot.rcmsFavorecido?.favorecido?.id || +gf.favorecido?.favorecido?.id === +cot.favorecido?.favorecido?.id)
      let contador = 0;
      let valor_unitario = 0;
      cotacao.reduce((acc, interavel) => {
        if (interavel.valor_unitario > 0) {
          contador++
          return valor_unitario += +interavel.valor_unitario
        } else {
          return valor_unitario
        }
      }, 0)
      cot['valor'] = +valor_unitario / +contador;

      if (!cot.valor_unitario) cot['valor'] = 0;

      return cot
    });
    for (const [idx, cotacao] of listaFavorecidos.entries()) {
      somaValorUnitario += +cotacao['valor'];
      if (+cotacao['valor'] > 0)
        qntFornecedores++;
    }
    return +(+somaValorUnitario / +qntFornecedores).toFixed(4);
  }

  public removerVarios() {
    for (const item of this.entidade.itens.filter(m => m.selecionado)) {
      if (item.id) {
        this.memorialService.remover(item.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => { }, (err) => toastr.error(err.error.payload));
      }
    }

    for (const item of this.entidade.itens.filter(m => m.selecionado)) {
      this.entidade.itens.splice(this.entidade.itens.indexOf(item), 1);
      if (item?.rpl_itens[0]?.rpl?.id)
        this.validaRemoverFiscalRpl(item?.rpl_itens[0]?.rpl?.id)
    }

    this.ordemFalse();
    this.atualizarOrdem();
    toastr.info('Itens removidos com sucesso!', 'Exclusão');
    $('#dialogRemoverVarios').modal('hide');
  }

  public ordemAlfabetica() {
    this.entidade.ordem_alfabetica_memorial = this.ordemAlf
    let ordem = 0;
    if (this.ordemAlf) {

      this.ordemOriginal = [...this.entidade.itens];
      this.entidade.itens.sort((a, b) => {
        if (a.descricao > b.descricao) {
          return 1
        }
        if (a.descricao < b.descricao) {
          return -1
        }
        return 0;
      });
      this.entidade.itens.forEach((m) => {
        m.ordem = ++ordem;
        m.ordem_julgamento = m.ordem;

      });
    } else if (!this.entidade.processo) {
      this.entidade.itens = [...this.ordemOriginal];
      this.ordemOriginal.forEach((m) => {
        m.ordem = ++ordem;
        m.ordem_julgamento = m.ordem;
      });
    } else {
      this.entidade.itens = [...this.ordemOriginal];
      this.ordemOriginal.forEach((m) => {
        m.ordem = ++ordem;
        m.ordem_julgamento = m.ordem;
      });
    }
  }

  public ocultarBotao() {
    if (this.entidade.itens?.length > 0) {
      return this.entidade.itens.find(i => i.id) ? true : false
    } else if (this.edicao) {
      return false;
    } else if (this.entidade.id) {
      return true;
    }
  }

  public ordemFalse() {
    this.entidade.ordem_alfabetica_memorial = false;
    this.ordemAlf = false;
  }

  public importarRPLPorDescricao(event: boolean) {
    this.mostrarDescricao = event;
  }

  camposExtras(item: Memorial) {
    this.itemExtras = item;

    if (item.opcao_normal) {
      this.normal = 'S';
      this.percentualNormal = item.percentual_normal;
    }

    if (item.opcao_adicional) {
      this.adicional = 'S';
      this.percentualAdicional = item.percentual_adicional;
    }

    if (!this.itemExtras.tipo_beneficio) {
      this.itemExtras.tipo_beneficio = (this.itemExtras.cota == 'RESERVADO' ? 3 : 4);
    }

    if (!this.itemExtras.criterio) {
      switch (this.entidade.tipo_licitacao) {
        case 1:
          this.itemExtras.criterio = 1;
          break;
        case 2:
          this.itemExtras.criterio = 4
          break;
        case 4:
          this.itemExtras.criterio = 5;
        case 5:
          this.itemExtras.criterio = 2;
          break;
        case 7:
          this.itemExtras.criterio = 6;
          break;
        default:
          this.itemExtras.criterio = null;
      }
    }
  }

  verificaSalvarExtras() {
    if (this.itemExtras.cadastrado_pncp) {
      this.confirmationService.confirm({
        message: '<pre>Deseja realmente retificar o item? \n'
          + 'Foi identificado que o item já foi enviado ao PNCP e por isso, será necessário ratifica-lo.\n'
          + 'Após a retificação, ele será marcado como "Em andamento" independente da situação atual dele no PNCP.</pre>',
        header: 'Retificação',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          if (this.normal == 'S') {
            this.itemExtras.opcao_normal = true;
            if (+this.percentualNormal >= 0 && +this.percentualNormal < 100) {
              this.itemExtras.percentual_normal = +this.percentualNormal;
            } else {
              this.percentualNormal = '0';
              toastr.error('Valor do percentual normal inválido!')
              return;
            }
          } else {
            this.itemExtras.opcao_normal = false;
          }

          if (this.adicional == 'S') {
            this.itemExtras.opcao_adicional = true;
            if (+this.percentualAdicional >= 0 && +this.percentualAdicional < 100) {
              this.itemExtras.percentual_adicional = +this.percentualAdicional;
            } else {
              this.percentualAdicional = '0';
              toastr.error('Valor do percentual adicional inválido!')
              return;
            }
          } else {
            this.itemExtras.opcao_adicional = false;
          }

          this.pncpService.retificarItemContratacao(this.itemExtras, this.entidade).subscribe((res) => {
            toastr.success('Ratificado');
            this.salvarExtras();
          }, (e) => {
            toastr.error(e.error.message);
          });
        }
      });
    } else {
      this.salvarExtras();
    }
  }

  async salvarExtras() {
    if (this.normal == 'S') {
      this.itemExtras.opcao_normal = true;
      if (+this.percentualNormal >= 0 && +this.percentualNormal < 100) {
        this.itemExtras.percentual_normal = +this.percentualNormal;
      } else {
        this.percentualNormal = '0';
        toastr.error('Valor do percentual normal inválido!')
        return;
      }
    } else {
      this.itemExtras.opcao_normal = false;
    }

    if (this.adicional == 'S') {
      this.itemExtras.opcao_adicional = true;
      if (+this.percentualAdicional >= 0 && +this.percentualAdicional < 100) {
        this.itemExtras.percentual_adicional = +this.percentualAdicional;
      } else {
        this.percentualAdicional = '0';
        toastr.error('Valor do percentual adicional inválido!')
        return;
      }
    } else {
      this.itemExtras.opcao_adicional = false;
    }

    //Substitui o item na listagem
    this.entidade.itens[this.itemExtras.ordem - 1] = this.itemExtras;

    if (this.itemExtras.id) {
      //Faz uma copia da licitação, para salvar os campos extras sem afetar a liquidação atual.
      const aux = Object.assign({}, this.entidade);
      aux.itens = [];//Necessário para não dar loop de informações.

      this.itemExtras.licitacao = aux;
      await this.memorialService.atualizar(this.itemExtras).toPromise();
    }
    this.itemExtras = new Memorial();
  }

  cancelarExtras() {
    this.itemExtras = new Memorial();
  }

  public carregarMercadoria(produto: Produto) {
    if (produto.cod_mercadoria)
      this.codigoMercadoria.codigo = produto.cod_mercadoria;
    else
      this.codigoMercadoria.codigo = undefined;
  }

  public async validaRemoverFiscalRpl(rplId: number) {
    if (rplId) {
      const rplAIndaImportada = []

      this?.entidade?.itens?.forEach((memorial) => {
        memorial?.rpl_itens?.filter((rplItem) => {
          if (rplItem?.rpl?.id === rplId) {
            rplAIndaImportada.push(rplItem);
          }
        });
      });

      if (rplAIndaImportada?.length === 0) {
        if (this?.entidade?.id) {
          await this.fiscalService.removerPorRpl(this?.entidade?.id, rplId).toPromise();
        }

        const fiscais = this?.entidade?.fiscais.filter((fiscal) => fiscal?.rpl?.id === rplId);
        fiscais.forEach(fiscal => this.entidade.fiscais.splice(this.entidade.fiscais.indexOf(fiscal), 1));
      }
    }
  }

  public aplicarJustificativa() {
    if (!this.itemExtras.justificativa) {
      toastr.warning('Informe o justificativa antes de aplicar a outros itens');
      return;
    } else {
      this.entidade.itens.forEach(l => l.justificativa = this.itemExtras.justificativa)
      toastr.info('Necessário salvar o processo para salvar a alteração!', 'Informação');
      return
    }
  }
}
