import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EmpenhoService } from 'administrativo-lib';
import { EddyAutoComplete, Favorecido, FavorecidoService, GlobalService, Login, OrgaoAssinaturaService } from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotaEmpenho } from '../../relatorio/contabil/nota-empenho';

@Component({
  selector: 'lib-nota-empenho-dlg',
  templateUrl: './nota-empenho-dlg.component.html'
})
export class NotaEmpenhoDlgComponent implements OnInit, OnDestroy {


  protected datepipe: DatePipe;
  public ptBR: any;
  public selectedOrdem: string;
  public selectedOpt: string;
  public selectNaoImpresso: boolean;
  public selectUsuario: boolean;
  public selectFornecedor: boolean;
  public selectLicitacao: boolean;
  public selectAnulado: boolean;
  public marcarComoImpresso: boolean;
  public dataInicio: Date;
  public dataFinal: Date;
  public numeroInicio: number;
  public numeroFinal: number;
  public favorecido: Favorecido;
  protected unsubscribe: Subject<void> = new Subject();

  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;

  public imaskNumero = {
    mask: Number,
    scale: 0,
    signed: false
  };

  @Input() login: Login;
  @Input() empenhosSelecionados: any[];

  constructor(
    protected confirmationService: ConfirmationService,
    protected messageService: MessageService,
    private globalService: GlobalService,
    private empenhoService: EmpenhoService,
    private assinaturaService: OrgaoAssinaturaService,
    private favorecidoService: FavorecidoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');
    this.selectedOrdem = 'ord1';
    this.selectedOpt = 'opt1';
    this.selectNaoImpresso = false;
    this.selectUsuario = false;
    this.dataInicio = new Date();
    this.dataFinal = new Date();

    this.favorecidoAutoComplete = new EddyAutoComplete(null, this.favorecidoService,
      'id', ['nome'], { 'cidade.id': this.login.cidade.id, relations: 'tipo,contas.banco', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  imprimir() {
    if (this.selectNaoImpresso) {
      this.confirmationService.confirm({
        message: 'Deseja marcar os empenhos como impressos ?',
        acceptLabel: "Sim",
        rejectLabel: "Não",
        header: 'Confirmação',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.gerarImpressaoEmpenhos();
          this.marcarComoImpresso = true;
        },
        reject: () => {
          this.marcarComoImpresso = false;
          this.gerarImpressaoEmpenhos();
        }
      });
    } else {
      this.marcarComoImpresso = false;
      this.gerarImpressaoEmpenhos();
    }
  }

  gerarImpressaoEmpenhos() {
    let relations = '';
    const parametros = {};
    if (this.selectedOpt === 'opt1') {
      if (+this.dataFinal < +this.dataInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Data final do empenho está maior que a inicial' });
        return;
      }
      if (!this.dataInicio || !this.dataFinal || +this.dataFinal === 0 || +this.dataInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe a data inicial e final para imprimir' });
        return;
      }
    } else if (this.selectedOpt === 'opt2') {
      if (+this.numeroFinal < +this.numeroInicio) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Número final do empenho está maior que o inicial' });
        return;
      }
      if (!this.numeroInicio || !this.numeroFinal || +this.numeroFinal === 0 || +this.numeroInicio === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Informe o número inicial e final para imprimir' });
        return;
      }
    } else {
      if (this.empenhosSelecionados.length === 0 || !this.empenhosSelecionados) {
        this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Selecione ao menos um empenho para imprimir' });
        return;
      }
    }

    relations += 'ficha.executora.unidade,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.despesa,ficha.aplicacao_variavel,'; // ficha.despesa
    relations += 'licitacao,modalidade,subelemento,favorecido.contas.banco,favorecido.tipo,contrato,convenio,liquidacoes,compra.rcms.requerente,compra.rcms.prazo,ficha.aplicacao_variavel';

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['orderBy'] = this.selectedOrdem === 'ord1' ? 'numero$ASC' : 'favorecido.nome$ASC';

    if (this.selectedOpt === 'opt1') {
      parametros['data_empenho$ge'] = this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd');
      parametros['data_empenho$le'] = this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd');
    } else if (this.selectedOpt === 'opt2') {
      parametros['numero$ge'] = +this.numeroInicio;
      parametros['numero$le'] = +this.numeroFinal;
    } else {
      parametros['numero$in'] = this.empenhosSelecionados;
    }
    if (this.selectNaoImpresso) {
      parametros['impresso'] = !this.selectNaoImpresso;
    }
    if (this.selectUsuario) {
      parametros['usuario_cadastro_id'] = this.login.usuario.id;
    }
    if (this.selectLicitacao) {
      parametros['licitacao_id$not_null'] = '';
    }
    if (this.selectFornecedor) {
      parametros['favorecido.nome$like'] = this.favorecido.nome;
    }
    if (this.selectAnulado) {
      parametros['especie'] = 'EOA';
    } else {
      parametros['especie'] = 'EMO';
    }

    this.empenhoService
      .extendido(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        // let parametros_despesa = {};
        // for (let itemLista of lista.content) {
        //   console.log(itemLista)
        //   parametros_despesa['ficha.despesa.codigo'] = itemLista.subelemento.codigo;

        // }
        // this.empenhoService.filtrar(1, -1, parametros_despesa)
        // .pipe(takeUntil(this.unsubscribe))
        // .subscribe((res) => {
        //   res.content
        // });
        new NotaEmpenho(this.assinaturaService).imprimir(lista.content, this.login);
        if (this.marcarComoImpresso) {
          this.empenhoService.marcarComoImpresso(parametros).subscribe(() => {
          });
        }
      },
        (error) => this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      );
  }

}
