// tslint:disable: variable-name
import { RcmsItem } from 'eddydata-lib';
import { CriterioReducaoMemorial } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { ProdutoUnidade } from '../almoxarifado/produto-unidade.model';
import { Licitacao } from './licitacao.model';
import { MemorialItem } from './memorial-item.model';
import { Proposta } from './proposta.model';
import { RplCotacao } from './rpl-cotacao.model';
import { RplItem } from './rpl-item.model';

export class Memorial extends BaseResourceModel {
  constructor(
    public id?: number,
    public quantidade?: number,
    public valor_referencia?: number,
    public reducao_lance?: number,
    public reducao_criterio?: CriterioReducaoMemorial,
    public ordem_julgamento?: number,
    public unidade?: string,
    public ordem?: number,
    public descricao?: string,
    public observacao?: string,
    public suspenso?: boolean,
    public lote?: boolean,
    public aux?: number,
    public cota?: 'NAO_DEFINIDO' | 'PRINCIPAL' | 'RESERVADO',
    public licitacao?: Licitacao,
    public produto_unidade?: ProdutoUnidade,
    public rpl_itens?: RplItem[],
    public rcms_itens?: RcmsItem[],
    public itens?: MemorialItem[],
    public propostas?: Proposta[],
    public editavel?: boolean,
    public editavelDescricao?: boolean,
    public editavelReducao?: boolean,
    public selecionado?: boolean,
    public selecionadoCota?: boolean,
    public cotacoes?: RplCotacao[],
    public categoria: 1 | 2 | 3 = 3,
    public ppb: boolean = false,
    public sigiloso: boolean = false,
    public tipo_beneficio?: 1 | 2 | 3 | 4 | 5,
    public criterio?: 1 | 2 | 4 | 5 | 6 | 7 | 8 | 9,
    public patrimonio?: string,
    public codigo_imobiliario?: string,
    public cadastrado_pncp?: string,
    public situacao_pncp?: number,
    public tipo_disputa?: string,
    public tempo_disputa?: string,
    public justificativa?: string,
    public opcao_normal?: boolean,
    public percentual_normal?: number,
    public opcao_adicional?: boolean,
    public percentual_adicional?: number,
  ) {
    super();
  }
}
