import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ContratoService, PropostaService } from 'administrativo-lib';
import { Contrato, FuncaoService, Licitacao, Login, Proponente } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';

declare var $: any;

@Component({
  selector: 'app-contrato-licitacao',
  templateUrl: './contrato-licitacao.component.html'
})
export class ContratoLicitacaoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() entidade: Licitacao;
  @Input() login: Login;
  @Input() tipoDeContratoPai?: 'CONTRATO' | 'REGISTRO_PRECO' = 'CONTRATO';
  @Input() public listaPendentes: Proponente[];

  public pendenteAtual: Proponente;
  public abrirContrato = false;
  public credenciamento = false;
  public pregaoPendente = false;
  public servicoDlg: String = "servicoDlgContrato";
  public buscaProdutoDlg: String = 'dialogProdutoUnBuscaAjusteLicitacaoContrato';
  public cadastroProdutoDlg: String = 'dialogProdutoUnCadastroAjusteLicitacaoContrato';
  public marcas: string[];
  public marcasFiltradas: string[];

  //ajuste de conversão de serviços
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected confirmationService: ConfirmationService,
    public funcaoService: FuncaoService,
    protected propostaService: PropostaService,
    public contratoService: ContratoService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entidade) {
      this.listaPendentes = this.contratoService.obterContratosPendentesLicitacao(this.entidade);

      if (this.entidade.modalidade.codigo === 7) {
        this.pregaoPendente = this.entidade.situacao_pregao !== 'CONCLUIDO COM RECURSO' && this.entidade.situacao_pregao !== 'CONCLUIDO SEM RECURSO'
      } else {
        this.pregaoPendente = false;
      }

      this.credenciamento = this.entidade.credenciamento;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public totalizarPendente(proponente: Proponente): number {
    let total = 0;
    proponente.propostas.forEach(p => total += this.funcaoService.arredondar(+p.quantidade_final * +p.valor_final, 2));
    return this.funcaoService.arredondar(total, 2);
  }

  public incluirContrato(proponente: Proponente) {
    this.abrirContrato = true;
    this.pendenteAtual = Object.assign({}, proponente);
    $('#dialogContratoLicitacao').modal('show');
  }

  public callbackContrato(contrato: Contrato) {
    this.contratoService.obterPorLicitacao(this.entidade.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.entidade.contratos = res ? res.content : [];
        this.listaPendentes = this.contratoService.obterContratosPendentesLicitacao(this.entidade);
      }, error => toastr.error(error.message ? error.message : error));
  }

  public salvar(proponente: Proponente) {
    this.produtosAjuste = []
    if (this.entidade.itens.filter((i) => !i.produto_unidade?.id && !i.lote).length > 0) {
      toastr.warning(`Foram identificados itens com serviços não cadastrados`);
      this.produtosAjuste = this.entidade.itens.filter((i) => !i.produto_unidade?.id)
        .map((i) => {
          return { produto: i.produto_unidade.produto.nome, unidade: i.produto_unidade.unidade.nome };
        });
      this.visulizarAjusteServico = true;

      throw new Error();
    }
    if (this.produtosAjuste.length === 0) {
      this.incluirContrato(proponente);
    }
  }

  //Ajuste conversão
  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.entidade.itens = [];
    }
    this.visulizarAjusteServico = false;
  }

  public aposAjustarProdutos(produtos: any[]) {
    if (!produtos) return;

    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }

    for (let item of this.entidade.itens) {
      if (item.produto_unidade?.id)
        continue;
      let produto_unidade = produtos
        .find((p) => p.produto === item.produto_unidade.produto.nome
          && p.unidade === item.produto_unidade.unidade.nome);
      if (produto_unidade) {
        item.produto_unidade = produto_unidade.produto_unidade;
      }
      item.descricao = produto_unidade.produto_unidade.produto.descricao;
      item.unidade = produto_unidade.produto_unidade.unidade.nome
    }

    if (!this.ajusteSemSubmit) this.ajusteSemSubmit = false;
    // this.submitForm(this.limparTela);

    this.listaPendentes = this.contratoService.obterContratosPendentesLicitacao(this.entidade);
  }

  getContratosAtivos(): any[] {
    if (!this.entidade?.contratos) return [];
    return this.entidade.contratos.filter(contrato => !contrato.excluido);
  }

  getContratosExcluidos(): any[] {
    if (!this.entidade?.contratos) return [];
    return this.entidade.contratos.filter(contrato => contrato.excluido);
  }

  public atualizarMarcas() {
    this.marcas = [];
    if (this.entidade?.proponentes) {
      for (let i = 0; i < this.entidade.proponentes.length; i++) {
        const proponente = this.entidade.proponentes[i];
        if (proponente.propostas) {
          this.marcas = this.marcas.concat(proponente.propostas.filter(p => p.marca !== undefined).map((p) => String(p.marca).trim()));
        }
      }
      // remove duplicados
      this.marcas = [...new Set(this.marcas)].filter((marca) => marca !== undefined && marca !== null);
      this.marcasFiltradas = this.marcas;
    }
  }

  public alterarProposta(proposta) {
    if (proposta) {
      proposta.editavel = true;
      // proposta['responsavel_temporario'] = proposta.responsavel;
    }
  }

  public filtrarMarcas(event: any) {
    let query = event.query;

    if (!this.marcas) {
      this.atualizarMarcas()
    }

    this.marcasFiltradas = this.marcas.filter((marca) => marca.toLowerCase().startsWith(query.toLowerCase()));
  }

  public async salvarMarcasPropostas(proposta) {
    this.marcas = [];
    if (proposta) {
      for (const i of this.entidade?.itens) {
        for (const prop of i?.propostas) {
          if (prop.id == proposta.id) {
            prop.marca = proposta.marca
          }
        }
      }

      await this.propostaService.atualizar(proposta).toPromise();

      proposta.editavel = false;
      // remove duplicados
      this.marcas = [...new Set(this.marcas)].filter((marca) => marca !== undefined && marca !== null);
      this.marcasFiltradas = this.marcas;
    }
  }
}
