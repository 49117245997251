import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Contrato, ContratoOcorrencia, DateFormatPipe, FuncaoService, GlobalService, Login, TipoOcorrenciaContrato } from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ContratoOcorrenciaService } from '../service/contrato-ocorrencia.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-ocorrencia',
  templateUrl: './contrato-ocorrencia.component.html'
})
export class ContratoOcorrenciaComponent implements OnInit, OnDestroy {

  @Input() entidade: Contrato;
  @Input() login: Login;
  @Input() visualizar: Boolean;

  public itemAnterior: ContratoOcorrencia;
  public itemAtual: ContratoOcorrencia;
  public listaTipo: { id: TipoOcorrenciaContrato, nome: string }[];

  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected confirmationService: ConfirmationService,
    protected ocorrenciaService: ContratoOcorrenciaService,
  ) { }

  ngOnInit(): void {
    this.listaTipo = this.globalService.obterTiposOcorrenciaContrato();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public adicionar() {
    this.itemAtual = new ContratoOcorrencia();
    this.itemAtual.data_ocorrencia = new Date();
    this.itemAtual.tipo = 'OUTRO';
    this.itemAtual.usuario = this.login.usuario;

    $('#dialogContratoOcorrencia').modal('show');
  }

  public editar(item: ContratoOcorrencia) {
    if (!item) return;
    this.itemAtual = item;
    this.itemAtual.data_ocorrencia = new DateFormatPipe().transform(this.itemAtual.data_ocorrencia, []);

    $('#dialogContratoOcorrencia').modal('show');
  }

  public salvar() {
    if (this.itemAtual) {
      if (!this.entidade.ocorrencias) {
        this.entidade.ocorrencias = [];
      }

      const contrato: Contrato = Object.assign({}, this.entidade);
      delete contrato.ocorrencias;

      const itm = this.entidade.ocorrencias.find((i) => i.tipo === this.itemAtual.tipo && i.data_ocorrencia === this.itemAtual.data_ocorrencia && i.ocorrencia === this.itemAtual.ocorrencia)
      if (itm) {
        itm.data_ocorrencia = this.itemAtual.data_ocorrencia;
        itm.ocorrencia = this.itemAtual.ocorrencia;
        itm.tipo = this.itemAtual.tipo;
        itm.contrato = contrato;

        this.ocorrenciaService.atualizar(itm).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
          toastr.success('Registro salvo com sucesso!');
        }, error => {
          toastr.error('Não foi possível salvar o registro!')
        });
      } else {
        this.itemAtual.contrato = contrato;
        this.ocorrenciaService.inserir(this.itemAtual).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
          res.data_cadastro = new Date();
          this.entidade.ocorrencias.push(res);
          toastr.success('Registro salvo com sucesso!');
          this.entidade.ocorrencias.sort((a, b) => { return new Date(b.data_ocorrencia).getTime() - new Date(a.data_ocorrencia).getTime() });
        }, error => {
          toastr.error('Não foi possível salvar o registro!')
        });
      }
      this.entidade.ocorrencias.sort((a, b) => { return new Date(b.data_ocorrencia).getTime() - new Date(a.data_ocorrencia).getTime() });
      this.itemAtual = undefined

      $('#dialogContratoOcorrencia').modal('hide');
    }
  }

  public remover(item: ContratoOcorrencia, index: number) {
    if (item.id) {
      this.confirmationService.confirm({
        message: 'Deseja realmente remover a ocorrência?',
        header: 'Exclusão',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        accept: () => {
          this.ocorrenciaService.remover(item.id).pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              toastr.info('Registro removido com sucesso!', 'Exclusão');
              this.entidade.ocorrencias.splice(index, 1);
            }, (err) => toastr.error(err.error.payload));
        }
      });
    } else {
      this.entidade.ocorrencias.splice(index, 1);
    }
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }

}
