import { DatePipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService } from '../../../models/services/base-resource.service';
import { Page } from '../../../util/page';

@Injectable({
  providedIn: 'root'
})
export class SaldoEstoqueService extends BaseResourceService<any> {

  constructor(
    protected injector: Injector
  ) {
    super(`saldos-estoque`, injector);
  }

  public obterProdutosComSaldo(setorAlmoxarifado: number, parametros?: { codigo?: string, nome?: string, codigobarra?: string, data?: string, saldoLote?: boolean }): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/produtos-com-saldo/${setorAlmoxarifado}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public obterProdutosComSaldoEstoque(estoque_id: number, parametros?: { codigo?: string, nome?: string, codigobarra?: string, data?: string }): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/produtos-com-saldo-estoque/${estoque_id}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public obterProdutosComSaldoESemMovimento(estoque_id: number, exercicio_id: number, parametros?: { codigo?: string, nome?: string, codigobarra?: string, data?: string }): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/produtos-com-saldo-estoque-e-sem-movimento/${estoque_id}/${exercicio_id}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public obterProdutosComSaldoEstoquePaginado(pagina: number, limite: number, estoque_id: number, parametros?: { codigo?: string, nome?: string, codigobarra?: string, data?: string }): Observable<Page> {
    let params = this.converterParametrosStr(parametros)

    return this.http.get<Page>(`${this.login.cidade.id}/${this.api}/produtos-com-saldo-estoque/${estoque_id}/${pagina}/${limite}${params}`, this.httpOptions()).pipe(map(res => res), catchError(error => this.handleError(error)))
  }


  public obterProdutosComSaida(estoque_id: number, parametros?: { codigo?: string, nome?: string, codigobarra?: string }): Observable<any[]> {
    let paramsWhere = '';
    if (parametros?.codigo) {
      paramsWhere += `?codigo=${parametros.codigo}`
    }
    if (parametros?.nome) {
      if (paramsWhere.length > 0)
        paramsWhere = '&';
      else
        paramsWhere = '?';
      paramsWhere += `nome=${parametros.nome}`
    }
    if (parametros?.codigobarra) {
      if (paramsWhere.length > 0)
        paramsWhere = '&';
      else
        paramsWhere = '?';
      paramsWhere += `codigo_barra=${parametros.codigobarra}`
    }
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/produtos-com-saida/${estoque_id}${paramsWhere}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public obterSaldo(exercicio: number, orgao: number, setorAlmoxarifado: number, produtoUnidade: number, lote: string): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo/${exercicio}/${orgao}/${setorAlmoxarifado}/${produtoUnidade}/${lote}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public obterCustoMedio(orgao: number, produtoUnidade: number, dataConversao?: Date, dataAtual?: Date): Observable<number> {

    let params = '';
    if (this.parametro()?.customedio_ponderado)
      params = `?customedio_ponderado=S`;

    if (dataConversao && this.parametro()?.customedio_ponderado) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }

    if (dataAtual) {
      let data = new DatePipe('pt').transform(dataAtual, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataAtual=${data}`;
    }

    console.log(params)
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/custo-medio/${orgao}/${produtoUnidade}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }


  public saldoProduto(produto: number, estoque: number, movimento_item?: number): Observable<any> {
    let parametros = '';
    if (movimento_item)
      parametros = `?movimento_item=${movimento_item}`;
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/saldo-produto/${estoque}/${produto}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoProdutoPorAlmoxarifado(produto: number, orgao: number): Observable<{ estoque: string, sum_entrada: number, sum_saida: number, saldo_atual: number }[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saldo-produto-almoxarifado/${orgao}/${produto}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoProdutoPorEstoque(produto: number, orgao: number, comSaldo: boolean): Observable<{ estoque: string, sum_entrada: number, sum_saida: number, saldo_atual: number }[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saldo-produto-estoque/${orgao}/${produto}/${comSaldo}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public movimentoMesesAlmoxarifado(estoque: number, orgao: number, exercicio: number): Observable<{ sum_entrada: number, sum_saida: number, mes: number }[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/entrada-almoxarifado-mes/${orgao}/${estoque}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public movimentoAlmoxarifadoPorGrupo(estoque: number, orgao: number, exercicio: number): Observable<{ sum_entrada: number, nome_grupo: String }[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/movimento-entrada-grupo/${orgao}/${estoque}/${exercicio}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoProdutoPorSetor(produto: number, orgao: number, estoqueid?: number): Observable<{ setor: string, sum_entrada: number, sum_saida: number, saldo_atual: number }[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saldo-produto-setor/${orgao}/${produto}${estoqueid ? `?estoque_id=${estoqueid}` : ''}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoProdutoPorSetorAlmoxarifado(produto: number, orgao: number, estoqueid: number, comSaldo: boolean): Observable<{ setor: string, sum_entrada: number, sum_saida: number, saldo_atual: number }[]> {
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saldo-produto-setor-almoxarifado/${orgao}/${produto}/${estoqueid}/${comSaldo}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoPorLote(produto_unidade: number, setor_almoxarifado: number, orgao: number, saida?: boolean): Observable<{
    lote: string, saldo: number, entrada: number, saida: number
  }[]> {
    let parametros = '';
    if (saida)
      parametros = `?saida=${saida}`;
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saldo-lotes/${orgao}/${setor_almoxarifado}/${produto_unidade}${parametros}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoPorProduto(estoque: number, orgao: number, parametros?: {
    setor_id?: number, produto_unidade_id?: number,
    com_saldo?: boolean
  }): Observable<{
    produto: string, unidade: string,
    codigo: string, sum_entrada: number,
    sum_saida: number, saldo: number,
    lote: string, vencimento: string
  }[]> {
    let param = this.getParametros(parametros);
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saldo-produtos/${orgao}/${estoque}?${param}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public posicaoEstoque(estoque: number, data: Date, tipo: 'com_saldo' | 'mov_estoque' | 'todos' = 'mov_estoque', ordernacao: 'codigo' | 'produto' | 'subgrupo', parametros?: { grupo?: number, exibir_lote?: boolean }, setorAlmoxarifado?: number, dataConversao?: Date, orgao?: number): Observable<any> {
    let data_ = new DatePipe('pt').transform(data, 'yyyy-MM-dd');

    let params = this.converterParametrosStr(parametros);

    if (this.parametro()?.customedio_ponderado)
      params += `${params && params.length > 0 ? '&' : '?'}customedio_ponderado=S`;

    if (setorAlmoxarifado)
      params += `${params && params.length > 0 ? '&' : '?'}setorAlmoxarifado=${setorAlmoxarifado}`;

    if (dataConversao !== null && this.parametro()?.customedio_ponderado) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }
    if (orgao)
      params += `${params && params.length > 0 ? '&' : '?'}orgao=${orgao}`;
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/posicao-estoque/${estoque}/${data_}/${tipo}/N/${ordernacao}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public fichaEstoque(estoque: number, produto_unidade_id, data_inicial: Date, data_final: Date, lote?: string, setorAlmoxarifado?: number): Observable<any> {
    let data_inicial_ = new DatePipe('pt').transform(data_inicial, 'yyyy-MM-dd');
    let data_final_ = new DatePipe('pt').transform(data_final, 'yyyy-MM-dd');
    let params = "";
    if (lote)
      params += `${params && params.length > 0 ? '&' : '?'}lote=${lote}`;

    if (setorAlmoxarifado)
      params += `${params && params.length > 0 ? '&' : '?'}setorAlmoxarifado=${setorAlmoxarifado}`;

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/ficha-estoque/${estoque}/${produto_unidade_id}/${data_inicial_}/${data_final_}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public balancoMensal(orgao: number, exercicio: number, mes: number, ordenacao: 'codigo', setorAlmoxarifado?: number, dataConversao?: Date, estoque?: number): Observable<any> {
    let params = '';
    if (this.parametro()?.customedio_ponderado)
      params = `?customedio_ponderado=S`;
    if (setorAlmoxarifado)
      params += `${params && params.length > 0 ? '&' : '?'}setorAlmoxarifado=${setorAlmoxarifado}`;
    if (dataConversao !== null && this.parametro()?.customedio_ponderado) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }
    if (estoque) {
      params += `${params && params.length > 0 ? '&' : '?'}estoque=${estoque}`;
    }
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/balanco-mensal/${orgao}/${exercicio}/${mes}/${ordenacao}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public consumoMedio(
    orgao: number, data_ini: Date, data_fim: Date, tipo_consumo: String, ordenacao: String,
    considerar_ajuste: boolean, parametros?: {
      setor_almox?: number, especie?: String, id_grupo?: number,
      materiais?: String, setores_almox?: number[]
    }, unificarUnidade?: boolean
  ): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    let considerar = considerar_ajuste ? 'S' : 'N';
    let unificarUnidades = unificarUnidade ? 'S' : 'N';
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/consumo-medio/${orgao}/${data_ini}/${data_fim}/${tipo_consumo}/${ordenacao}/${considerar}/${unificarUnidades}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public balancoPorGrupo(orgao: number, ano: number, mes: number,
    parametros?: { estoque?: number, setorAlmoxarifado?: number, emitirPorVpd?: boolean, conciliacaoContabil?: boolean, grupoSubGrupo?: boolean }, dataConversao?: Date): Observable<any> {
    let params = this.converterParametrosStr(parametros);

    if (this.parametro()?.customedio_ponderado)
      params += `${params && params.length > 0 ? '&' : '?'}customedio_ponderado=S`;

    if (dataConversao !== null) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/balanco-por-grupo/${orgao}/${ano}/${mes}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public balancoPorGrupoTodosEstoques(orgao: number, ano: number, mes: number,
    parametros?: { estoque?: number, setorAlmoxarifado?: number, emitirPorVpd?: boolean, conciliacaoContabil?: boolean, grupoSubGrupo?: boolean },
    dataConversao?: Date, grupoSubGrupo?: boolean): Observable<any> {
    let params = this.converterParametrosStr(parametros);

    if (this.parametro()?.customedio_ponderado)
      params += `${params && params.length > 0 ? '&' : '?'}customedio_ponderado=S`;

    if (dataConversao !== null) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/balanco-por-grupo-todos-estoques/${orgao}/${ano}/${mes}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public balancoPorSubGrupo(orgao: number, ano: number, mes: number,
    parametros?: { estoque?: number, setorAlmoxarifado?: number, emitirPorVpd?: boolean, conciliacaoContabil?: boolean }, dataConversao?: Date): Observable<any> {
    let params = this.converterParametrosStr(parametros);

    if (this.parametro()?.customedio_ponderado)
      params += `${params && params.length > 0 ? '&' : '?'}customedio_ponderado=S`;

    if (dataConversao !== null && this.parametro()?.customedio_ponderado) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/balanco-por-sub-grupo/${orgao}/${ano}/${mes}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public balancoPorSubGrupoTodosEstoques(orgao: number, ano: number, mes: number,
    parametros?: { estoque?: number, setorAlmoxarifado?: number, emitirPorVpd?: boolean, conciliacaoContabil?: boolean }, dataConversao?: Date): Observable<any> {
    let params = this.converterParametrosStr(parametros);

    if (this.parametro()?.customedio_ponderado)
      params += `${params && params.length > 0 ? '&' : '?'}customedio_ponderado=S`;

    if (dataConversao !== null && this.parametro()?.customedio_ponderado) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/balanco-por-sub-grupo-todos-estoques/${orgao}/${ano}/${mes}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }


  public resumoGeral(orgao: number, ano: number, parametros?: { estoque?: number, mes?: number, setorAlmoxarifado?: number }, dataConversao?: Date): Observable<any> {
    let params = this.converterParametrosStr(parametros);

    if (this.parametro()?.customedio_ponderado)
      params += `${params && params.length > 0 ? '&' : '?'}customedio_ponderado=S`;

    if (dataConversao !== null && this.parametro()?.customedio_ponderado) {
      let dataC_ = new DatePipe('pt').transform(dataConversao, 'yyyy-MM-dd');
      params += `${params && params.length > 0 ? '&' : '?'}dataConversao=${dataC_}`;
    }

    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/resumo-geral/${orgao}/${ano}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }

  public saldoProdutoUnidadeLoteVencimento(produto_unidade_id: number, setorAlmoxarifado_id: number,
    parametros?: { desc_item?: number, nao_obg_saldo?: boolean, saida_obrigatoria?: boolean, data_limite?: string, destino?: boolean }): Observable<any[]> {
    let query = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/saldo-produtos-lote-vencimento/${produto_unidade_id}/${setorAlmoxarifado_id}${query}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) }))
  }

  public saldoProdutoLoteVencimentoEstoque(produto: number, estoque_id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/saldo-produtos-lote-vencimento-estoque/${produto}/${estoque_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) }))
  }

  public reposicao(orgao: number, geral: boolean, ordenacao: string, parametros?: { subgrupo?: number, estoque_id?: number }): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/reposicao-estoque/${orgao}/${geral ? 'S' : 'N'}/${ordenacao}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) }))
  }


  public reposicaoDestino(orgao: number, parametros?: { estoque_id?: number }): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/reposicao-destino/${orgao}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) }))
  }

  public movimentacaoConsolidada(estoque: number, data1: string, data2: string, entrada: boolean, subgrupo?: number): Observable<any[]> {
    let params = '';
    if (this.parametro()?.customedio_ponderado)
      params = `?customedio_ponderado=S`;
    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/movimentacao-consolidada/${estoque}/${data1}/${data2}/${entrada ? 'S' : 'N'}${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) }))
  }

  private parametro() {
    if (this.login?.parametro?.['almoxarifado'])
      return this.login.parametro['almoxarifado'];
    return null;
  }


  public saidaConsolicada(orgao: number, data_ini: Date, data_fim: Date, ordenacao: String, estoque: number, exercicio: number, parametros?: { destino?: any[], subgrupo?: number }): Observable<any[]> {
    let params = this.converterParametrosStr(parametros);
    return this.http.get<any[]>(
      `${this.login.cidade.id}/${this.api}/saida-consolidada/${orgao}/${data_ini}/${data_fim}/${ordenacao}/${estoque}/${exercicio}/${params}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => { return this.handleError(err) })
      );
  }
}
