import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Credito, CreditoItem, FormatoExportacao, Lei, OrgaoAssinatura, OrgaoAssinaturaService, PpaLei, PpaService } from "eddydata-lib";
import * as extenso from "extenso";
import { takeUntil } from "rxjs/operators";
import * as toastr from "toastr";
import { CreditoService } from "../service/credito.service";
import { LeiService } from "projects/contabil/src/app/planejamento/lei/service/lei.service";
import { PpaLeiService } from "administrativo-lib";

@Component({
  selector: 'app-minuta-rpt',
  templateUrl: './minuta-rpt.component.html'
})
export class MinutaRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  private creditos: Credito[] = [];
  public dadosLei: Lei[] = [];
  public decretoSelected: string;
  public contador: number = 0;
  public contadorPage: number = 0;
  public lei: string;
  public nomePrefeito: string;
  public dataLei: string;
  public cargo: string;
  public totalCredito: number = 0;
  public decretoList: { key: string, value: string }[] = [];
  public listFormato: FormatoExportacao[] = ['pdf', 'xlsx', 'xml', 'JSON', 'csv', 'docx', 'txt']
  public formato: FormatoExportacao;
  private assinaturaVigente: OrgaoAssinatura;
  public maskDecreto = {
    mask: [
      { mask: '0000/0000' },
      { mask: '00000/0000' }
    ]
  };
  private leis: string[] = [];
  public suggestionsLeis: string[];
  public leiSelected: string;
  public ppaLei: PpaLei;
  public mesmaLei: boolean = false;

  constructor(
    private creditoService: CreditoService,
    protected assinaturaService: AssinaturaService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
    protected ppaService: PpaService,
    protected ppaLeiService: PpaLeiService,
    protected leiService: LeiService
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.removerCabecalho = true;
    this.removerRodape = true;
  }

  protected afterInit(): void {
    this.ppaLeiService.filtrar(1, -1, {
      'tipo': 'loa',
      'ano': this.login?.exercicio?.ano,
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.ppaLei = res.content[0];
      })
    this.creditoService.filtrar(1, -1, {
      'relations': 'itens.ficha.executora,itens.ficha.programa,itens.ficha.acao,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.subfuncao,itens.ficha.recurso,itens.ficha.aplicacao,itens.ficha.aplicacao_variavel,orgao',
      'exercicio.id': this.login?.exercicio?.id,
      'ativo': true,
      'orderBy': 'itens.ficha.acao.codigo,itens.ficha.aplicacao_variavel.codigo,itens.ficha.despesa.codigo'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.creditos = res.content;

        res.content.forEach((val: Credito) => {
          if (!this.decretoList.find(x => x.value === val.decreto.replace('/', ''))) this.decretoList.push({ key: val.decreto, value: val.decreto.replace('/', '') });
        });
        this.decretoList.sort((d1, d2) => +d1.value - +d2.value);

        res.content.map((l) => l.lei).sort()
          .forEach((l) => {
            if (this.leis.filter((lei) => lei === l).length === 0)
              this.leis.push(l);
          });
      });

    this.leiService.filtrar(1, -1, {
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.dadosLei = res.content;
      })

    this.orgaoAssinaturaService.filtrar(1, -1, {
      'orgao.id': this.login?.orgao?.id,
      'data_limite$ge': this.funcaoService.converteDataSQL(new Date()),
      'orderBy': 'data_limite'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if (res.content && res.content.length > 0) {
          this.assinaturaVigente = res.content[0];
          this.nomePrefeito = res.content[0].prefeito;
          this.cargo = res.content[0].cargo_prefeito;
        }
      });

    this.orientacao = 'portrait';
    this.formato = 'pdf';
  }

  public filtarLei(event: any) {
    let filtro: string = String(event.query).trim();
    if (filtro)
      this.suggestionsLeis = this.leis.filter((l) => l.replace('/', '').startsWith(filtro.replace('/', '')));
    else
      this.suggestionsLeis = Object.assign([], this.leis);
  }

  protected modalRelatorio(): string {
    return null;
  }

  protected tituloRelatorio(): string {
    return '';
  }

  public imprimir(): void {
    if (!this.validar()) return;
    super.imprimir();
    this.contador = 0;
    this.contadorPage = 0;
  }

  private validar(): boolean {
    if (!this.decretoSelected && !this.leiSelected) {
      toastr.warning(`Informe ao menos um número de decreto ou lei!`);
      return false;
    }

    if (!this.assinaturaVigente) {
      toastr.warning(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      return false;
    }
    return true;
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo: {}[] = [];

    let dados = this.creditos.filter((credito: Credito) => {
      if (this.decretoSelected && this.leiSelected) {
        return credito.decreto === this.decretoSelected && credito.lei === this.leiSelected;
      }

      if (this.decretoSelected) {
        return credito.decreto === this.decretoSelected;
      }

      return credito.lei === this.leiSelected;
    });

    if (!dados) {
      toastr.warning('Não foi localizado minutas com os filtros informados!');
      return;
    }

    const creditosAgrupados = this.funcaoService.agrupar(dados, ['decreto', 'data_publicacao', 'data_credito']); //, 'lei'
    conteudo.push(this.dadosCabecalho(creditosAgrupados[0]));
    for (const creditos of creditosAgrupados) {
      ++this.contadorPage;
      for (const credito of creditos.registros) {
        conteudo.push(this.dadosCreditos(credito, false));
        conteudo.push(this.dadosCreditos(credito, true));
      }
    }
    conteudo.push(this.dadosAssinatura(creditosAgrupados[0], creditosAgrupados.length));

    return conteudo;
  }

  protected dadosCabecalho(credito: any): {}[] {
    this.contador = 0
    let brasaoImage: {};
    let lei;

    if (this.login?.brasao) {
      brasaoImage = { image: this.login.brasao, width: 65, height: 55, alignment: 'center', margin: [0, 12, 0, 0] };
    } else {
      brasaoImage = { margin: [0, 30, 0, 0], text: '' };
    }
    brasaoImage['rowSpan'] = 1;
    brasaoImage['border'] = [false];

    if (this.creditos) {
      for (const item of this.creditos) {
        if (item.decreto === this.decretoSelected) {
          if (+item.exercicio.ano == this.login.exercicio.ano) {
            if (item.lei.length < 9) {
              this.lei = '0' + this.funcaoService.mascarar('####/####', item.lei.replace('/', ''));
            } else {
              this.lei = this.funcaoService.mascarar('#####/####', item.lei.replace('/', ''));
            }
          }
          lei = this.dadosLei.find((l) => this.lei === this.funcaoService.toString(l.codigo))
          if (item.data_vigencia) {
            this.dataLei = this.funcaoService.converteDataBR(item.data_vigencia);
          } else {
            this.dataLei = '00/00/0000';
          }
        }
      }
    }

    if (this.ppaLei.lei.length < 10) {
      this.ppaLei.lei = '0' + this.ppaLei.lei;
    }



    if (this.ppaLei.lei === this.lei) {
      this.mesmaLei = true;
    } else {
      this.mesmaLei = false;
    }

    if (credito?.registros[0].lei.length === 9) {
      this.lei = this.funcaoService.mascarar('#####/####', credito?.registros[0].lei.replace('/', ''));
      this.dataLei = this.funcaoService.converteDataBR(credito?.registros[0].data_vigencia);
    }

    return [
      {
        layout: 'linhas',
        unbreakable: true,
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [brasaoImage],
            [
              {
                text: ``,
                bold: true, alignment: 'center', fontSize: 12, border: [false], margin: [0, 13, 0, 0]
              }
            ], [
              {
                text: `DECRETO N.º ${credito?.grupo['decreto'].substring(0, credito?.grupo['decreto'].length - 4)} DE ${this.funcaoService.formatarDataExtenso(new Date(credito?.grupo['data_credito']))}`,
                bold: true, alignment: 'justify', fontSize: 12, border: [false], margin: [45, -4, 0, 0]
              }
            ]
          ]
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: `Dispõe sobre alterações no Orçamento Fiscal de ${credito.registros[0]['exercicio']['ano']}, 
                te dá outras providências.`, alignment: 'justify', fontSize: 10, border: [false], margin: [45, 0, 0, 25]
              },
            ],
          ]
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: `${this.mesmaLei ? `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t${this.nomePrefeito}, Prefeito Municipal de ${this.login?.cidade?.nome ? this.login?.cidade?.nome : ''}, Estado de ${this.login?.cidade?.estado?.nome ? this.login?.cidade?.estado?.nome : ''}, no exercício de suas atribuições legais, observadas as disposições da Lei Federal n.º 4.320/64, da Lei Municipal n.º ${!this.ppaLei ? '' : this.ppaLei.lei} (Lei Orçamentaria Anual).` : `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t${this.nomePrefeito}, Prefeito Municipal de ${this.login?.cidade?.nome ? this.login?.cidade?.nome : ''}, Estado de ${this.login?.cidade?.estado?.nome ? this.login?.cidade?.estado?.nome : ''}, no exercício de suas atribuições legais, observadas as disposições da Lei Federal n.º 4.320/64, da Lei Municipal n.º ${!this.ppaLei ? '' : this.ppaLei.lei} (Lei Orçamentaria Anual) e da Lei Municipal n.º ${!this.lei ? '' : this.lei}.`}`,
                alignment: 'left', fontSize: 10, border: [false], margin: [-40, 0, 0, 25]
              }
            ], [
              { text: 'D E C R E T A', alignment: 'center', fontSize: 12, border: [false], margin: [-120, 5, 0, 40] }
            ]
          ]
        }
      }
    ];
  }

  protected dadosCreditos(credito: Credito, anulacao: boolean): {}[] {
    let artigo: string;
    let restanteArtigo: string;
    var aux = credito.itens.filter((item: CreditoItem) => {
      if (anulacao) return item.especie === 'A';

      return item.especie === 'N';
    });

    var grupoExecutora = this.funcaoService.agrupar(aux, ['ficha.executora.codigo', 'ficha.executora.nome'], ['valor_credito']);
    let creditos: {}[] = [];
    let total: number = 0;
    let totalExecutora: number = 0;
    let paragrafo: string;
    let art1: string;
    let tipo: string;
    total = aux.reduce((acc, item) => +item.valor_credito + acc, 0)

    switch (credito.tipo) {
      case '1':
        tipo = grupoExecutora.length > 1 ? 'suplementares' : 'suplementar';
        break;
      case '2':
        tipo = grupoExecutora.length > 1 ? 'especiais' : 'especial';
        break;
      case '3':
        tipo = grupoExecutora.length > 1 ? 'extraordinários' : 'extraordinário';
        break;
    }

    for (const executora of grupoExecutora) {
      switch (credito.ocorrencia) {
        case '1':
          grupoExecutora.length > 1 ?
            art1 = `no valor total de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase().replace(/\b(DE|DA)\b/, '')}), nas seguintes classificações:` :
            art1 = `no valor total de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase().replace(/\b(DE|DA)\b/, '')}), na seguinte classificação:`;
          break;
        case '2':
          grupoExecutora.length > 1 ?
            art1 = `aprovado através da Lei n.º ${!this.lei ? '' : this.lei}, no valor total de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase().replace(/\b(DE|DA)\b/, '')}), na seguinte classificação:` :
            art1 = `aprovado através da Lei n.º ${!this.lei ? '' : this.lei}, no valor total de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase().replace(/\b(DE|DA)\b/, '')}), nas seguintes classificações:`;
          break;
        case '3':
          grupoExecutora.length > 1 ?
            art1 = `aprovado através da Lei n.º ${!this.lei ? '' : this.lei}, no valor total de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase().replace(/\b(DE|DA)\b/, '')}), nas seguintes classificações:` :
            art1 = `aprovado através da Lei n.º ${!this.lei ? '' : this.lei}, no valor total de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase().replace(/\b(DE|DA)\b/, '')}), na seguinte classificação:`;
          break;
      }

      if (credito.ocorrencia === '1' && !anulacao) {
        grupoExecutora.length > 1 ?
          paragrafo = 'Parágrafo Único - Os recursos para cobertura dos créditos adicionais abertos na forma deste artigo são de origem de anulações nas seguintes classificações:' :
          paragrafo = 'Parágrafo Único - O recurso para cobertura do crédito adicionai aberto na forma deste artigo é de origem de anulação na seguinte classificação:';
      } else if (credito.ocorrencia === '2') {
        grupoExecutora.length > 1 ?
          paragrafo = 'Parágrafo Único - Os recursos para a cobertura dos créditos adicionais abertos na forma deste artigo são de origem de excesso de arrecadação.' :
          paragrafo = 'Parágrafo Único - O recurso para a cobertura do crédito adicional aberto na forma deste artigo é de origem de excesso de arrecadação.';
      } else if (credito.ocorrencia === '3') {
        grupoExecutora.length > 1 ?
          paragrafo = 'Parágrafo Único - Os recursos para a cobertura dos créditos adicionais abertos na forma deste artigo são de origem de superávit financeiro verificado no Balanço do exercício anterior.' :
          paragrafo = 'Parágrafo Único - O recurso para a cobertura do crédito adicional aberto na forma deste artigo é de origem de superávit financeiro verificado no Balanço do exercício anterior.';
      }
      creditos.push([
        {
          text: `${executora.grupo['ficha.executora.codigo'] ? this.funcaoService.mascarar('##.##.##', executora.grupo['ficha.executora.codigo']) + ' ' : ''}${executora.grupo['ficha.executora.nome'] ? executora.grupo['ficha.executora.nome'] : ''}`,
          fontSize: 8, bold: true, border: [false], margin: [0, 5, 0, 0]
        },
        { text: '', border: [false] },
      ]);

      const grupoPrograma = this.funcaoService.agrupar(executora.registros, ['ficha.funcao.codigo', 'ficha.subfuncao.codigo', 'ficha.programa.codigo', 'ficha.programa.nome']);
      for (const programa of grupoPrograma) {
        creditos.push([
          {
            text: `${programa.grupo['ficha.funcao.codigo'] ? programa.grupo['ficha.funcao.codigo'] + '.' : ''}${programa.grupo['ficha.subfuncao.codigo'] ? programa.grupo['ficha.subfuncao.codigo'] + '.' : ''}${programa.grupo['ficha.programa.codigo'] ? programa.grupo['ficha.programa.codigo'] + ' ' : ''}${programa.grupo['ficha.programa.nome'] ? programa.grupo['ficha.programa.nome'] : ''}`,
            fontSize: 8, border: [false], margin: [0, 1, 0, 0]
          },
          { text: '', border: [false] },
        ]);
        const grupoAcao = this.funcaoService.agrupar(programa.registros, ['ficha.acao.codigo', 'ficha.acao.nome']);
        for (const acao of grupoAcao) {
          creditos.push([
            { text: `${acao.grupo['ficha.acao.codigo'] ? acao.grupo['ficha.acao.codigo'] + ' ' : ''}${acao.grupo['ficha.acao.nome'] ? acao.grupo['ficha.acao.nome'] : ''}`, fontSize: 8, border: [false] },
            { text: '', border: [false] },
          ]);
          const ficha = this.funcaoService.agrupar(acao.registros, ['ficha.despesa.codigo', 'ficha.despesa.nome', 'ficha.aplicacao_variavel.codigo', 'ficha.aplicacao_variavel.nome'], ['valor_credito'])
          for (const a of ficha) {
            creditos.push([
              {
                text: `${a.grupo['ficha.despesa.codigo'] ? a.grupo['ficha.despesa.codigo'] + ' ' + a.grupo['ficha.despesa.nome'] : ''}`,
                fontSize: 8, border: [false]
              }, {
                text: `${a.totalizadores['valor_credito'] > 0 ? this.funcaoService.convertToBrNumber(a.totalizadores['valor_credito']) : '0,00' ? this.funcaoService.convertToBrNumber(-a.totalizadores['valor_credito']) : '0,00'}`,
                fontSize: 8, alignment: 'right', border: [false]
              }
            ]);
            creditos.push([
              { text: `Fonte / Aplicação: ${a.grupo['ficha.aplicacao_variavel.codigo'] ? a?.grupo['ficha.aplicacao_variavel.codigo'] + ' ' + a?.grupo['ficha.aplicacao_variavel.nome'] : ''}`, fontSize: 8, border: [false], bold: true },
              {
                text: ``,
                fontSize: 8, bold: true, alignment: 'right', border: [false]
              },
            ]);
          };
        }
      }
      creditos.push([
        { text: 'TOTAL.....', fontSize: 8, bold: true, border: [false] },
        {
          text: `${executora.totalizadores['valor_credito'] > 0 ? this.funcaoService.convertToBrNumber(executora.totalizadores['valor_credito']) : '0,00' ? this.funcaoService.convertToBrNumber(-executora.totalizadores['valor_credito']) : '0,00'}`,
          fontSize: 8, bold: true, alignment: 'right', border: [false, true, false, false]
        },
      ]);
      totalExecutora = executora.totalizadores['valor_credito'];
    }
    creditos.push([
      { text: `${total > 0 ? 'TOTAL GERAL.....' : ''}`, fontSize: 9, bold: true, border: [false], margin: [0, 0, 0, 20] },
      { text: `${total > 0 ? this.funcaoService.convertToBrNumber(total) : ''}`, fontSize: 9, bold: true, alignment: 'right', border: [false] },
    ]);
    creditos.push([
      { text: paragrafo, alignment: 'left', colSpan: 2, fontSize: 10, border: [false, false, false, false], margin: [-40, 0, 0, 10] }, ''
    ]);

    this.totalCredito += total;
    ++this.contador;
    if (!anulacao) {
      let orgao: string;
      switch (credito.orgao.id) {
        case 1:
          orgao = 'através da Secretaria Municipal de Finanças';
          break;
        case 2:
          orgao = 'através da Câmara Municipal';
          break;
        case 3:
          orgao = 'através do Centro universitario de Franca';
          break;
        case 4:
          orgao = 'através da Faculdade de direito de Franca';
          break;
        case 5:
          orgao = 'através do Serviço de Assistencia e Seguro Social dos Municipiários de Franca';
          break;
        case 6:
          orgao = 'através da Fundação esporte, Arte e Cultura';
          break;
      }
      const lei = credito?.lei?.replace('/', '');
      if (grupoExecutora.length > 1) {
        artigo = `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\tArt. ${this.contador}º - `
        restanteArtigo = `Ficam abertos, ${orgao}, observada a Lei n.º ${lei ? this.funcaoService.mascarar(lei.length > 8 ? '#####/####' : '####/####', lei) : ''} de ${this.dataLei} créditos adicionais ${tipo} no Orçamento Fiscal de ${credito.exercicio.ano}, ${art1}`
      } else {
        artigo = `\u200B\t\t\t\t\t\t\t\t\t\t\t\t\tArt. ${this.contador}º - `
        restanteArtigo = `Fica aberto, ${orgao}, observada a Lei n.º ${lei ? this.funcaoService.mascarar(lei.length > 8 ? '#####/####' : '####/####', lei) : ''} de ${this.dataLei} crédito adicional ${tipo} no Orçamento Fiscal de ${credito.exercicio.ano}, ${art1}`
      }
    }

    if (!artigo) {
      --this.contador;
    }

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', '*'],
          body: [
            [
              { text: `${!artigo ? '' : artigo}`, alignment: 'justify', fontSize: 10, border: [false], margin: [-170, -40, 0, 10] },
              { text: `${!artigo ? '' : restanteArtigo}`, alignment: 'justify', fontSize: 10, border: [false], margin: [-210, -40, 0, 10] },
            ]
          ]
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', 100],
          body: creditos
        }
      }
    ];
  }

  protected dadosAssinatura(credito: any, length: number): {} {
    return {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*'],
        body: [
          [
            {
              text: `Art. ${this.contador + 1}º - Este Decreto entrará em vigor na data de sua publicação, surtindo seus efeitos a
              \u200B\t\t\t\tpartir de ${this.funcaoService.formatarDataExtenso(new Date(credito.grupo['data_publicacao']))}`,
              alignment: 'justify', fontSize: 10, border: [false], margin: [-40, 0, 0, 8]
            }
          ], [
            {
              text: `Art. ${this.contador + 2}º - Revogam-se as disposições em contrário.`,
              alignment: 'justify', fontSize: 10, border: [false], margin: [-40, 0, 0, 8]
            }
          ], [
            {
              text: `${this.funcaoService.formatarDataExtenso(new Date(credito.grupo['data_publicacao']), this.login?.cidade?.nome).toUpperCase()}`,
              alignment: 'center', fontSize: 8, border: [false], margin: [-40, 15, 0, 10]
            }
          ], [
            {
              text: '_______________________________________________',
              alignment: 'center', fontSize: 10, bold: true, border: [false], margin: [-40, 15, 0, 0]
            }
          ], [
            {
              text: `${this.nomePrefeito ? this.nomePrefeito.toUpperCase() : ''}`,
              alignment: 'center', fontSize: 8, border: [false], margin: [-40, 0, 0, 0]
            }
          ], [
            {
              text: this.cargo,
              alignment: 'center', fontSize: 8, border: [false], margin: [-40, 0, 0, 0], pageBreak: this.contadorPage == length ? '' : 'after'
            }
          ]
        ]
      }
    };
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function () {
          return 1;
        },
        vLineWidth: function () {
          return 0;
        },
        hLineColor: function () {
          return 'black';
        },
        paddingLeft: function () {
          return 3;
        },
        paddingRight: function () {
          return 3;
        },
        paddingTop: function () {
          return 8;
        },
        paddingBottom: function () {
          return 8;
        }
      }
    };
  }

  protected margemRelatorio(): number[] {
    return [120, 40, 60, 40]
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.decretoSelected = null;
    this.leiSelected = null;
    this.visualizarChange.emit(this.visualizar);
  }

}