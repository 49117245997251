import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseResourceService, DespesaPrevista, Page } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class DespesaPrevistaService extends BaseResourceService<DespesaPrevista> {

  constructor(
    protected injector: Injector
  ) {
    super(`despesas-prevista`, injector);
  }

  public gerarPrevisao(id: number) {
    return this.http.post<Array<any>>(
      `${this.login.cidade.id}/${this.api}/gerar-previsao`, { id }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public gerarPrevisaoAnual(exercicioId: number, orgaoId: number) {
    return this.http.post<Array<any>>(
      `${this.login.cidade.id}/${this.api}/gerar-previsao-anual`, { exercicio: exercicioId, orgao: orgaoId }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalPrevistoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-previsto-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalPrevistoArrecadadoAno(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-previsto-arrecadado-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  planilhaPrevisao(pagina: number, limite: number, ano: number, orgaos: number): Observable<Page> {
    return this.http.get<Page>(
      `${this.login.cidade.id}/${this.api}/planilha-previsao/${pagina}/${limite}?ano=${ano}&orgaos=${orgaos}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
