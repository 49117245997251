import { DatePipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Assinaturas, ContaBancariaService, EmpenhoService, FichaReceitaService, PagamentoService, RecebimentoService, RecursoService } from 'administrativo-lib';
import { Coluna, ContaBancaria, DateFormatPipe, EddyAutoComplete, EmpenhoExtra, Executora, ExecutoraService, Exercicio, ExercicioService, Favorecido, FavorecidoService, FormatoExportacao, FuncaoService, GlobalService, Liquidacao, LoginContabil, Pagamento, ParametroContabil, ParametroContabilService, ProgressoService, Recurso, Relatorio } from 'eddydata-lib';
import { Alignment, Workbook } from 'exceljs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { DiarioCaixaService } from '../service/diario-caixa.service';
import { OrdemPagamentoService } from '../service/ordem-pagamento.service';
import { BoletimPagamento } from './boletim-pagamento';
import { BoletimReceita } from './boletim-receita';
import { ContasPagarRelatorio } from './contas-pagar-relatorio';
import { DecendioRpt } from './decendio';
import { EmpenhoSemOPRpt } from './empenhos-sem-op';
import { OrdemCronologica } from './ordem-cronologica';

@Component({
  selector: 'app-relatorio-tesouraria',
  templateUrl: './relatorio-tesouraria.component.html'
})
export class RelatorioTesourariaComponent implements OnInit, OnDestroy {
  public filtroFavorecido: boolean;
  public filtroUnidadeExecutora: boolean;
  public filtroMesLiquidacao: boolean;
  public filtroEmissaoEmpenho: boolean;
  public filtroEmissaoLiquidacao: boolean;
  public filtroVencimentoEmpenho: boolean;
  public filtroVencimentoLiquidacao: boolean;
  public filtroPosicaoAte: boolean;
  public filtroRecurso: boolean;
  public favorecido: Favorecido;
  public executoraSelect: Executora;
  public liquidados: string = 'liquidado';
  public tipoEmpenho: string = 'orcamentario';
  public orderContaPagar: string = 'fornecedor';
  public dataVencto: 'empenho' | 'liquidacao' = 'liquidacao';
  public mesLiquidacao: number = 1
  public recursoInicio: Recurso;
  public recursoFinal: Recurso;

  public dataPosicaoAte: Date = new Date();
  public dataEmissaoEmpenhoInicial: Date = new Date();
  public dataEmissaoEmpenhoFinal: Date = new Date();
  public dataEmissaoLiquidacaoInicial: Date = new Date();
  public dataEmissaoLiquidacaoFinal: Date = new Date();
  public dataVencimentoEmpenhoInicial: Date = new Date();
  public dataVencimentoEmpenhoFinal: Date = new Date();
  public dataVencimentoLiquidacaoInicial: Date = new Date();
  public dataVencimentoLiquidacaoFinal: Date = new Date();

  public assinatura_tesouraria?: boolean;
  public login: LoginContabil = new LoginContabil();
  public relatoriosSelect = 'BOLETIM_BANCO';
  public orderSelect = 'c.numero_conta';
  public orderSelectConciliacao = 'data';
  public listaRelatorios: Array<any>;
  public dataCaixa: Date = new Date();
  public dataFinal: Date = new Date();
  public opcao: string;
  public opcaoRecurso: 'TODOS' | 'SELECIONAR' = 'TODOS';
  public opecaoPeriodo: 'MENSAL' | 'PERIODO' = 'MENSAL';
  public saldoConta: boolean = true;
  public ativo: boolean = true;
  protected unsubscribe: Subject<void> = new Subject();
  protected datepipe: DatePipe;
  public ptBR: any;
  public listaTipos: Array<any>;
  public listaDespesas: Array<any>;
  public listaRetencoes: Array<any>;
  public listaValorImpresso: Array<any>;
  public tipoConta: string = 'M';
  public conciliacaoSintetico = false;
  public agruparConta: boolean = false;
  public agruparRecurso: boolean = false;
  public considerar_bloqueios: boolean = false;
  public mes: number;
  public ano: number;
  public listaExercicios: Array<any>;
  public tipoDecendio: number = 1;
  public tipoDataEmp: number = 1;
  public tipoDespesa: string = 'T';
  public tipoRetencao: string = 'T';
  public tipoValorImpresso: string = 'T';
  public fonteRecurso: boolean = false;
  public listaRecurso: boolean = false;
  public ajusteVariacao: boolean = false;
  public codigoAplicacao: boolean = false;
  public comMovimento: boolean = false;
  public semMovimentoSaldo: boolean = false;
  public buscaFonte;
  public codigoFonteRecurso;
  public buscaRecursoConta: string;
  public formato: FormatoExportacao = 'pdf'
  public contaSelect: ContaBancaria;
  public diarioCaixaAnalitico: boolean = false;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public variavelAutoComplete: EddyAutoComplete<Recurso>;
  public contaAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recAutoComplete: EddyAutoComplete<ContaBancaria>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public executoraAutoComplete: EddyAutoComplete<Executora>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private router: Router,
    private pagtoService: PagamentoService,
    private recebimentoService: RecebimentoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaReceitaService,
    protected exercicioService: ExercicioService,
    protected contaBancariaService: ContaBancariaService,
    protected diarioCaixaService: DiarioCaixaService,
    protected progressoService: ProgressoService,
    protected ordemPagamentoService: OrdemPagamentoService,
    protected parametroContabilidadeServico: ParametroContabilService,
    protected empenhoServico: EmpenhoService,
    protected recursoService: RecursoService,
    protected executoraService: ExecutoraService,
    protected injector: Injector,
    protected favorecidoService: FavorecidoService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
    this.datepipe = new DatePipe('pt');
    this.mes = 1;
    this.ano = this.login.exercicio.id;
  }

  public verifica(x: number) {
    if (x == 1)
      this.listaRecurso == true ? this.fonteRecurso = false : '';

    if (x == 2)
      this.fonteRecurso == true ? this.listaRecurso = false : '';
  }

  ngOnInit() {
    this.listaRelatorios = [
      { id: 'CONCILIACAO_BANCARIA', nome: 'CONCILIAÇÃO BANCÁRIA' },
      { id: 'BOLETIM_BANCO', nome: 'BOLETIM DE BANCOS' },
      { id: 'BOLETIM_BANCO_RECURSO', nome: 'BOLETIM DE BANCO POR RECURSO' },
      { id: 'BOLETIM_CAIXA', nome: 'BOLETIM DE CAIXA' },
      { id: 'BOLETIM_RECEITA', nome: 'BOLETIM ANALÍTICO DA RECEITA' },
      { id: 'BOLETIM_PAGAMENTO', nome: 'BOLETIM ANALÍTICO DE PAGAMENTOS' },
      { id: 'DIARIO_CAIXA', nome: 'DIÁRIO DE CAIXA' },
      { id: 'DECENDIO', nome: 'REPASSE DESCENDIAL' },
      { id: 'DISPONIBILIDADE', nome: 'DISPONIBILIDADE DE FONTES DE RECURSOS' },
      { id: 'DOMICILIO_BANCARIO', nome: 'DOMICÍLIO BANCÁRIO' },
      { id: 'ORDEM_CRONOLOGICA', nome: 'ORDEM CRONOLÓGICA' },
      { id: 'EMPENHO_SEM_OP', nome: 'EMPENHOS SEM VINCULO DE OP' },
      { id: 'CONTAS_PAGAR', nome: 'CONTAS A PAGAR' },
    ];

    this.exercicioService.obterTodosOrdenadoPorAno(this.login.cidade.id).pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaExercicios = new Array();
        const lista = dados.content;
        for (const exercicio of lista as Array<Exercicio>) {
          this.listaExercicios.push({ id: exercicio.id, ano: exercicio.ano });
        }
      });

    this.contaAutoComplete = new EddyAutoComplete(
      null,
      this.contaBancariaService,
      "id",
      ["codigo", "banco.nome", "nome"],
      { orgao_id: this.login.orgao.id, ativo: true, relations: "banco", orderBy: "codigo" },
      { number: ["codigo"], text: ["banco.nome", "nome"] }
    );
    this.listaTipos = this.globalService.obterListaTipoContasBancarias();

    this.listaDespesas = [
      { id: 'T', nome: 'TODOS', titulo: 'EMPENHOS DE TODOS' },
      { id: 'EMO', nome: 'EMPENHOS ORÇAMENTÁRIOS', titulo: 'EMPENHOS ORÇAMENTÁRIOS' },
      { id: 'EMR', nome: 'EMPENHOS RESTOS A PAGAR', titulo: 'EMPENHOS RESTOS A PAGAR' },
      { id: 'EME', nome: 'EMPENHOS EXTRAS', titulo: 'EMPENHOS EXTRAS' },
    ];

    this.listaValorImpresso = [
      { id: 'T', nome: 'VALOR LÍQUIDO E RETENÇÕES' },
      { id: 'R', nome: 'SOMENTE RETENÇÕES' },
      { id: 'L', nome: 'SOMENTE VALOR LÍQUIDO' },
    ];

    this.listaRetencoes = [
      { id: 'T', nome: 'COM E SEM RETENÇÕES' },
      { id: 'C', nome: 'COM RETENÇÕES' },
      { id: 'S', nome: 'SEM RETENÇÕES' },
    ];

    this.recursoAutoComplete = new EddyAutoComplete(
      null,
      this.recursoService,
      "id",
      ["codigo", "nome"],
      { ativo: true, relations: "recurso", orderBy: "codigo" },
      { text: ["codigo", "nome"] }
    );
    this.variavelAutoComplete = new EddyAutoComplete(
      null,
      this.recursoService,
      "id",
      ["codigo", "nome"],
      { ativo: true, nivel: 2, orderBy: "codigo" },
      { text: ["codigo", "nome"] }
    );

    //Autocomplete favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(null,
      this.favorecidoService, "id", ["cpf_cnpj", "nome"],
      {
        cidade_id: this.login.cidade.id,
        orderBy: "nome",
        relations: "tipo"
      }, { number: ["id", "cpf_cnpj"], text: ["nome", 'cpf_cnpj'] }
    );

    this.executoraAutoComplete = new EddyAutoComplete(null, this.executoraService, 'id', ['codigo', 'nome'],
      { 'unidade.ppa.id': this.login.ppa.id, relations: 'unidade.ppa', orderBy: 'codigo,nome' },
      { text: ['codigo', 'nome'] }
    );

    this.ptBR = this.globalService.obterDataBR();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    new GlobalService().calendarMascara();
  }

  imprimir(formato: FormatoExportacao) {
    if (this.relatoriosSelect === 'BOLETIM_BANCO') {
      this.imprimirBoletimBanco(formato);
    } else if (this.relatoriosSelect === 'BOLETIM_BANCO_RECURSO') {
      this.imprimirBoletimBancoRecurso();
    } else if (this.relatoriosSelect === 'BOLETIM_CAIXA') {
      this.imprimirBoletimCaixa(formato);
    } else if (this.relatoriosSelect === 'CONCILIACAO_BANCARIA') {
      if ((this.contaAutoComplete && this.opcao === 'opt2') && (!this.contaSelect?.id)) {
        toastr.warning('Informe a conta bancária!')
      } else {
        this.imprimirConciliacaoBancaria(formato);
      }
    } else if (this.relatoriosSelect === 'DIARIO_CAIXA') {
      this.imprimirDiarioCaixa(formato);
    } else if (this.relatoriosSelect === 'BOLETIM_PAGAMENTO') {
      this.imprimirBoletimPagamento(formato);
    } else if (this.relatoriosSelect === 'BOLETIM_RECEITA') {
      this.imprimirBoletimReceita(formato);
    } else if (this.relatoriosSelect === 'DECENDIO') {
      this.imprimirDescendio(formato, +this.tipoDecendio)
    } else if (this.relatoriosSelect === 'DISPONIBILIDADE') {
      this.imprimirDisponibilidade(formato);
    } else if (this.relatoriosSelect === 'EMPENHO_SEM_OP') {
      this.imprimirEmpenhoSemOP(formato);
    } else if (this.relatoriosSelect === 'DOMICILIO_BANCARIO') {
      this.imprimirDomicilioBancario();
    } else if (this.relatoriosSelect === 'ORDEM_CRONOLOGICA') {
      this.imprimirOrdemCronologica();
    } else if (this.relatoriosSelect === 'CONTAS_PAGAR') {
      const param = {}

      param['ordem'] = this.orderContaPagar;
      param['tipo_empenho'] = this.tipoEmpenho;
      param['orgao'] = this.login.orgao.id;
      param['vencto'] = this.dataVencto;

      const nomeRel = `Contas a Pagar - ${this.tipoEmpenho == 'orcamentario' ? 'Orçamentário' : this.tipoEmpenho == 'extra' ? 'Extra' : 'Restos'}`

      if (this.tipoEmpenho != 'extra') param['liquidados'] = this.liquidados;

      if (this.filtroUnidadeExecutora && this.tipoEmpenho != 'extra') param['unidade_executora'] = this.executoraSelect.codigo;
      if (this.filtroFavorecido) param['favorecido'] = this.favorecido.id;

      if (this.filtroEmissaoEmpenho) {
        param['emissao_empenho_inicial'] = this.funcaoService.converteDataSQL(this.dataEmissaoEmpenhoInicial);
        param['emissao_empenho_final'] = this.funcaoService.converteDataSQL(this.dataEmissaoEmpenhoFinal);
      }

      if (this.filtroVencimentoEmpenho) {
        param['vencimento_empenho_inicial'] = this.funcaoService.converteDataSQL(this.dataVencimentoEmpenhoInicial);
        param['vencimento_empenho_final'] = this.funcaoService.converteDataSQL(this.dataVencimentoEmpenhoFinal);
      }

      if (this.filtroPosicaoAte) param['posicao_ate'] = this.funcaoService.converteDataSQL(this.dataPosicaoAte);
      if (this.filtroMesLiquidacao && this.liquidados === 'liquidado' && this.tipoEmpenho != 'extra') param['mes_liquidacao'] = this.mesLiquidacao;

      if (this.filtroEmissaoLiquidacao && this.liquidados === 'liquidado' && this.tipoEmpenho != 'extra') {
        param['emissao_liquidacao_inicial'] = this.funcaoService.converteDataSQL(this.dataEmissaoLiquidacaoInicial);
        param['emissao_liquidacao_final'] = this.funcaoService.converteDataSQL(this.dataEmissaoLiquidacaoFinal);
      }

      if (this.filtroVencimentoLiquidacao && this.liquidados === 'liquidado' && this.tipoEmpenho != 'extra') {
        param['vencimento_liquidacao_inicial'] = this.funcaoService.converteDataSQL(this.dataVencimentoLiquidacaoInicial);
        param['vencimento_liquidacao_final'] = this.funcaoService.converteDataSQL(this.dataVencimentoLiquidacaoFinal);
      }

      if (this.filtroRecurso) {
        param['recurso_inicial'] = this.recursoInicio.codigo;
        param['recurso_final'] = this.recursoFinal.codigo;
      }

      param['exercicio'] = this.login.exercicio.id

      const contasPagarRelatorio = new ContasPagarRelatorio(this.injector, this.empenhoServico, this.progressoService);
      contasPagarRelatorio.imprimirContasPagar(formato, this.login, param, nomeRel, this.tipoEmpenho, this.liquidados);
    }
  }

  private async imprimirDomicilioBancario() {
    this.contaBancariaService.domicilioBancario(this.login.exercicio.id, this.login.orgao.id, this.mes, this.contaSelect?.codigo, this.buscaRecursoConta)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async data => {
        if (this.formato === 'pdf') {
          Relatorio.imprimir(`CONFERENCIA RECURSO AUDESP - ${this.globalService.obterMes(this.mes)}`, this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao, data, this.colunas(), 'landscape',
            'Domicilio Bancario', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'], null, await this.assinaturaDomicilioBancario())
        } else {
          this.funcaoService.exportar(this.formato, data, `CONFERENCIA RECURSO AUDESP - ${this.globalService.obterMes(this.mes)}`, this.colunas() as Coluna[]);
        }
      });
  }

  private async assinaturaDomicilioBancario(): Promise<[{}]> {

    this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()).assinatura_tesouraria;

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

    if (this.assinatura_tesouraria) {
      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      }];
    } else {
      return
    }

  }

  private colunas(): string[] | Coluna[] {
    return [
      { titulo: 'Banco', coluna: 'banco', bold: true, alignment: 'center' },
      { titulo: 'Recurso', coluna: 'recursoConta' },
      { titulo: 'Inicial', coluna: 'inicial', decimais: 2, alignment: 'right' },
      { titulo: 'Receita Orç.', coluna: 'recebimentoOrcamentario', decimais: 2, alignment: 'right' },
      { titulo: 'Receita Extra', coluna: 'recebimentoExtra', decimais: 2, alignment: 'right' },
      { titulo: 'Restituição/Fundeb', coluna: 'fundeb', decimais: 2, alignment: 'right' },
      { titulo: 'Pagto Orç', coluna: 'pagamentoOrcamentario', decimais: 2, alignment: 'right' },
      { titulo: 'Pagto Resto', coluna: 'pagamentoResto', decimais: 2, alignment: 'right' },
      { titulo: 'Pagto Extra', coluna: 'pagamentoExtra', decimais: 2, alignment: 'right' },
      { titulo: 'Retenção', coluna: 'retido', decimais: 2, alignment: 'right' },
      { titulo: 'Receita Retido', coluna: 'receitaRetido', decimais: 2, alignment: 'right' },
      { titulo: 'Origem', coluna: 'origem', decimais: 2, alignment: 'right' },
      { titulo: 'Destino', coluna: 'destino', decimais: 2, alignment: 'right' },
      { titulo: 'Final', coluna: 'final', decimais: 2, alignment: 'right' },
    ];
  }

  private imprimirBoletimPagamento(formato: FormatoExportacao) {
    const parametros: {} = {};
    if (this.dataCaixa) {
      parametros['data'] = this.datepipe.transform(this.dataCaixa, 'yyyy-MM-dd');
    }
    parametros['exercicio'] = this.login.exercicio.id;
    parametros['orgao'] = this.login.orgao.id;
    this.pagtoService.boletimPagamento(parametros).subscribe(lista => {
      this.pagtoService.numeroBoletim(
        String(this.funcaoService.converteDataSQL(this.dataCaixa.toLocaleDateString())), this.login.exercicio.id, this.login.orgao.id).subscribe(numero => {
          const bp = new BoletimPagamento(this.injector, this.parametroContabilidadeServico);
          bp.numero = numero;
          bp.dataCaixa = this.dataCaixa;
          if (formato === 'pdf') {
            bp.imprimir(lista, this.login);
          } else if (formato === 'csv') {
            bp.imprimirCSV(lista);
          }
        });
    });
  }

  private imprimirBoletimReceita(formato: FormatoExportacao) {
    const parametros: {} = {};
    if (this.dataCaixa) {
      parametros['data'] = this.datepipe.transform(this.dataCaixa, 'yyyy-MM-dd');
    }
    parametros['exercicio'] = this.login.exercicio.id;
    parametros['orgao'] = this.login.orgao.id;
    this.recebimentoService.boletimRecebimento(parametros).subscribe(lista => {
      this.recebimentoService.numeroBoletim(
        String(this.funcaoService.converteDataSQL(this.dataCaixa.toLocaleDateString())), this.login.exercicio.id, this.login.orgao.id).subscribe(numero => {
          const bp = new BoletimReceita(this.injector, this.parametroContabilidadeServico);
          bp.numero = numero;
          bp.dataCaixa = this.dataCaixa;
          if (formato === 'pdf') {
            bp.imprimir(lista, this.login);
          } else if (formato === 'csv') {
            bp.imprimirCSV(lista);
          }
        });
    });
  }

  private imprimirConciliacaoBancaria(formato: FormatoExportacao) {
    const movimentos = {
      com_movimentacao: this.comMovimento,
      sem_movimentacao_com_saldo: this.semMovimentoSaldo
    };
    this.contaBancariaService.conciliacaoBancaria(this.login.exercicio.id, this.login.orgao.id,
      this.datepipe.transform(this.dataCaixa, 'yyyy-MM-dd'),
      this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd'),
      this.opcao === 'opt2' ? this.contaSelect?.id : null,
      this.opcao === 'opt2' ? this.contaSelect.tipo_conta : (this.opcao === 'opt3' ? this.tipoConta : undefined),
      this.ajusteVariacao,
      movimentos,
      this.codigoFonteRecurso?.codigo
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('EXTRATO CONTÁBIL', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudoconciliacao(dados, this.dataCaixa, this.dataFinal),
            'portrait', 'EXTRATO CONTÁBIL',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        } else {
          this.funcaoService.exportar(formato, this.conteudoConciliacaoCsv(dados), 'EXTRATO CONTÁBIL', this.colunasConciliacao());
        }
      });
  }

  private colunasConciliacao(): Coluna[] {
    return [
      { titulo: 'DATA', coluna: 'data' },
      { titulo: 'CONTA/BANCO', coluna: 'banco' },
      { titulo: 'HISTÓRICO', coluna: 'historico' },
      { titulo: 'DOCUMENTO', coluna: 'documento' },
      { titulo: 'DEPÓSITO', coluna: 'deposito' },
      { titulo: 'RETIRADA', coluna: 'retirada' },
      { titulo: 'SALDO', coluna: 'saldo' },
    ];
  }

  private conteudoConciliacaoCsv(dados: any[]) {
    const grupos = this.funcaoService.agrupar(dados, ['id', 'nome', 'numero_conta', 'digito_conta', 'banco_nome', 'saldo_ano_anterior', 'saldo_anterior']);
    const listaItens = new Array();
    let saldo_anterior: number = 0;

    for (const item of grupos) {
      saldo_anterior = +item.grupo['saldo_ano_anterior'] + +item.grupo['saldo_anterior'];
      listaItens.push({
        'data': 'SALDO ANTERIOR',
        'banco': '',
        'historico': '',
        'documento': '',
        'deposito': '',
        'retirada': '',
        'saldo': this.funcaoService.convertToBrNumber(saldo_anterior, 2),
      });

      if (this.orderSelectConciliacao === 'documento') {
        item.registros.sort(function (a, b) {
          let documentoa = a.documento ? a.documento : '';
          let documentob = b.documento ? b.documento : '';
          if (documentoa > documentob) {
            return 1;
          }
          if (documentoa < documentob) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }

      if (this.orderSelectConciliacao === 'data') {
        item.registros.sort((a, b) => {
          if (a.data === b.data) {
            if (a.id_historico > b.id_historico) {
              return 1;
            }
            if (a.id_historico < b.id_historico) {
              return -1;
            }
            // a must be equal to b
            return 0;
          }
          return a.data > b.data ? 1 : -1;
        });
      }

      if (this.orderSelectConciliacao === 'valor') {
        item.registros.sort(function (a, b) {
          let valora = +a.deposito !== 0 ? a.deposito : a.retirada;
          let valorb = +b.deposito !== 0 ? b.deposito : b.retirada;
          if (+valora > +valorb) {
            return 1;
          }
          if (+valora < +valorb) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      if (this.orderSelectConciliacao === 'data_historico') {
        item.registros.sort((a, b) => {
          if (a.data === b.data) {
            let ordem = a.deposito > 0 ? -1 : (b.deposito > 0 ? 1 : a.historico.localeCompare(b.historico))
            return ordem;
          }
          return a.data > b.data ? 1 : -1;
        });
      }
      if (this.orderSelectConciliacao === 'data_fornecedor') {
        item.registros.sort((a, b) => {
          if (a.data === b.data) {
            if (!a.favorecido) return -1;
            if (!b.favorecido) return 1;

            return a.favorecido.localeCompare(b.favorecido);
          }
          return a.data > b.data ? 1 : -1;
        });
      }

      for (const registro of item.registros) {
        saldo_anterior += +registro.deposito - +registro.retirada;
        listaItens.push({
          'data': registro.data,
          'banco': `${registro.id} ${registro.banco_nome} ${registro.nome} - ${registro.numero_conta} ${registro.digito_conta ? registro.digito_conta : ''}`,
          'historico': registro.historico.replace(/\n/g, ' '),
          'documento': registro.documento,
          'deposito': this.funcaoService.convertToBrNumber(registro.deposito, 2),
          'retirada': this.funcaoService.convertToBrNumber(registro.retirada, 2),
          'saldo': this.funcaoService.convertToBrNumber(saldo_anterior, 2),
        });
      }

      const totais = this.funcaoService.totalizar(item.registros, ['deposito', 'retirada']);
      listaItens.push({
        'data': 'TOTAL',
        'banco': '',
        'historico': '',
        'documento': '',
        'deposito': this.funcaoService.convertToBrNumber(totais['deposito'], 2),
        'retirada': this.funcaoService.convertToBrNumber(totais['retirada'], 2),
        'saldo': this.funcaoService.convertToBrNumber(saldo_anterior, 2),
      });
    }

    return listaItens;
  }

  private imprimirBoletimBanco(formato: FormatoExportacao) {
    this.contaBancariaService.boletimBanco(this.login.exercicio.id, this.login.orgao.id,
      this.datepipe.transform(this.dataCaixa, 'yyyy-MM-dd'),
      this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd'), this.orderSelect, this.agruparConta ? 'true' : 'false', (this.fonteRecurso ? this.buscaFonte.codigo : false), this.listaRecurso, this.ajusteVariacao).pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('BOLETIM DE BANCOS CONTÁBIL', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudoBoletimBanco(dados),
            'portrait', 'BOLETIM DE BANCOS CONTÁBIL',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        } else if (formato === 'csv') {
          let lista = dados;
          let ativo = lista;
          if (this.ativo) {
            lista = ativo.filter((elemento) => elemento.ativo === true);
          }

          let grupos;
          if (this.agruparConta && !this.agruparRecurso) {
            grupos = this.funcaoService.agrupar(lista, 'tipo_conta');
          } else if (this.agruparRecurso && !this.agruparConta) {
            grupos = this.funcaoService.agrupar(lista, 'tipo_conta');
          } else {
            grupos = this.funcaoService.agrupar(lista, '');
          }

          let retorno: {}[][] = [];
          if (this.fonteRecurso) {
            retorno.push([{ text: `FONTE DE RECURSO: ${this.buscaFonte.codigo} - ${this.buscaFonte.nome}` }]);
          }

          for (let grupo of grupos) {
            if (this.listaRecurso) {
              retorno.push([
                { text: 'FICHA' },
                { text: 'RECURSO' },
                { text: 'DESCRIÇÃO' },
                { text: 'ANTERIOR' },
                { text: 'DEPÓSITO' },
                { text: 'RETIRADA' },
                { text: 'SALDO' },
              ]);

              if (this.agruparConta) {
                retorno.push([{ text: `${grupo.grupo === 'M' ? 'BANCOS CONTA MOVIMENTO' : 'BANCOS CONTA VINCULADA'}` }]);
              }
            } else {
              retorno.push([
                { text: 'FICHA' },
                { text: 'DESCRIÇÃO' },
                { text: 'ANTERIOR' },
                { text: 'DEPÓSITO' },
                { text: 'RETIRADA' },
                { text: 'SALDO' },
              ]);

              if (this.agruparConta) {
                retorno.push([{ text: `${grupo.grupo === 'M' ? 'BANCOS CONTA MOVIMENTO' : 'BANCOS CONTA VINCULADA'}` }]);
              }
            }

            for (const entidade of grupo.registros) {
              let variavel = entidade.variavel;
              variavel === null ? variavel = '0000' : variavel;

              const registro = [];
              registro.push({ text: entidade.id });
              if (this.listaRecurso) {
                registro.push({ text: (entidade.aplicacao ? entidade.recurso.toString() + entidade.aplicacao.toString() + variavel.toString() : entidade.recurso) });
              }
              registro.push({ text: `${entidade.banco_nome} ${entidade.nome} ${entidade.numero_conta} ${entidade.digito_conta ? entidade.digito_conta : ''}` });
              registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.saldo_anterior + +entidade.saldo_ano_anterior, 2) });
              registro.push({ text: this.funcaoService.convertToBrNumber(entidade.deposito, 2) });
              registro.push({ text: this.funcaoService.convertToBrNumber(entidade.retirada, 2) });
              registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.saldo_atual + +entidade.saldo_ano_anterior, 2) });
              if (this.saldoConta) {
                if (((+entidade.saldo_atual + +entidade.saldo_ano_anterior) > 0) || (entidade.deposito > 0) || (entidade.retirada > 0)) {
                  retorno.push(registro);
                }
              } else {
                retorno.push(registro);
              }
            }
            const totais = this.funcaoService.totalizar(grupo.registros, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);
            const total = [];
            total.push({ text: 'TOTAL' });
            total.push({ text: '' });
            if (this.listaRecurso) {
              total.push({ text: '' });
            }
            total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_anterior'] + +totais['saldo_ano_anterior'], 2) });
            total.push({ text: this.funcaoService.convertToBrNumber(totais['deposito'], 2) });
            total.push({ text: this.funcaoService.convertToBrNumber(totais['retirada'], 2) });
            total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_atual'] + +totais['saldo_ano_anterior'], 2) });

            retorno.push(total);
          }

          if (this.agruparConta) {
            const totais = this.funcaoService.totalizar(lista, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);
            const total = [];
            total.push({ text: 'TOTAL' });
            total.push({ text: '' });
            if (this.listaRecurso) {
              total.push({ text: '' });
            }
            total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_anterior'] + +totais['saldo_ano_anterior'], 2) });
            total.push({ text: this.funcaoService.convertToBrNumber(totais['deposito'], 2) });
            total.push({ text: this.funcaoService.convertToBrNumber(totais['retirada'], 2) });
            total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_atual'] + +totais['saldo_ano_anterior'], 2) });

            retorno.push(total);
          }

          this.imprimirCSV(retorno, `BOLETIM DE BANCOS CONTÁBIL`);
        }
      });
  }

  private async montarConteudoBoletimBanco(lista: any[]): Promise<{}[]> {
    let ativo = lista;
    if (this.ativo) {
      lista = ativo.filter((elemento) => {
        return elemento.ativo === true;
      });
    }

    let grupos;
    if (this.agruparConta && !this.agruparRecurso) {
      grupos = this.funcaoService.agrupar(lista, 'tipo_conta');
    } else if (this.agruparRecurso && !this.agruparConta) {
      grupos = this.funcaoService.agrupar(lista, 'tipo_conta');
    } else {
      grupos = this.funcaoService.agrupar(lista, '');
    }

    let retorno: {}[] = [];
    retorno.push({
      text: `REFERÊNCIA: ${this.datepipe.transform(this.dataCaixa, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`,
      alignment: 'center', fontSize: 10, lineHeight: 1.5
    });

    if (this.fonteRecurso) {
      retorno.push({
        columns: [{ text: [{ text: 'FONTE DE RECURSO:', bold: true }, { text: `${this.buscaFonte.codigo} - ${this.buscaFonte.nome}` }] }],
        alignment: 'left', fontSize: 10, lineHeight: 1.5
      });
    }

    for (let grupo of grupos) {
      const conteudo = [];

      if (this.listaRecurso) {
        conteudo.push([
          { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'RECURSO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'DESCRIÇÃO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'ANTERIOR', fontSize: 8, alignment: 'center', bold: true },
          { text: 'DEPÓSITO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'RETIRADA', fontSize: 8, alignment: 'center', bold: true },
          { text: 'SALDO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
        ]);

        if (this.agruparConta) {
          conteudo.push([
            { text: `${grupo.grupo === 'M' ? 'BANCOS CONTA MOVIMENTO' : 'BANCOS CONTA VINCULADA'}`, colSpan: 6, bold: true, fontSize: 10 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` }
          ]);
        }
      } else {
        conteudo.push([
          { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
          { text: 'DESCRIÇÃO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'ANTERIOR', fontSize: 8, alignment: 'center', bold: true },
          { text: 'DEPÓSITO', fontSize: 8, alignment: 'center', bold: true },
          { text: 'RETIRADA', fontSize: 8, alignment: 'center', bold: true },
          { text: 'SALDO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
        ]);

        if (this.agruparConta) {
          conteudo.push([
            { text: `${grupo.grupo === 'M' ? 'BANCOS CONTA MOVIMENTO' : 'BANCOS CONTA VINCULADA'}`, colSpan: 6, bold: true, fontSize: 10 },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` },
            { text: `` }
          ]);
        }
      }

      for (const entidade of grupo.registros) {
        let variavel;
        variavel = entidade.variavel;
        variavel === null ? variavel = '0000' : variavel;

        const registro = [];
        registro.push({ text: entidade.id, fontSize: 8, alignment: 'center', bold: false });
        if (this.listaRecurso) {
          registro.push({ text: (entidade.aplicacao ? entidade.recurso.toString() + entidade.aplicacao.toString() + variavel.toString() : entidade.recurso), fontSize: 8, alignment: 'center', bold: false });
        }
        registro.push({
          text: `${entidade.banco_nome} ${entidade.nome} ${entidade.numero_conta} ${entidade.digito_conta ? entidade.digito_conta : ''}`,
          fontSize: 8, alignment: 'left', bold: false
        });
        registro.push({
          text: this.funcaoService.convertToBrNumber(+entidade.saldo_anterior + +entidade.saldo_ano_anterior, 2),
          fontSize: 8, alignment: 'right', bold: false
        });
        registro.push({ text: this.funcaoService.convertToBrNumber(entidade.deposito, 2), fontSize: 8, alignment: 'right', bold: false });
        registro.push({ text: this.funcaoService.convertToBrNumber(entidade.retirada, 2), fontSize: 8, alignment: 'right', bold: false });
        registro.push({ text: this.funcaoService.convertToBrNumber(+entidade.saldo_atual + +entidade.saldo_ano_anterior, 2), fontSize: 8, alignment: 'right', bold: false });
        if (this.saldoConta) {
          if (((+entidade.saldo_atual + +entidade.saldo_ano_anterior) > 0) || (entidade.deposito > 0) || (entidade.retirada > 0)) {
            conteudo.push(registro);
          }
        } else {
          conteudo.push(registro);
        }
      }
      const totais = this.funcaoService.totalizar(grupo.registros, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);
      const total = [];
      total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 1 });
      total.push('');
      if (this.listaRecurso) {
        total.push('');
      }
      total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_anterior'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(totais['deposito'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(totais['retirada'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_atual'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true });
      conteudo.push(total);

      this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()).assinatura_tesouraria;

      const ass = new Assinaturas(this.login.orgao, this.injector);
      const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

      if (this.assinatura_tesouraria) {
        retorno.push({
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: this.listaRecurso ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
            body: conteudo
          }, margin: [0, 0, 0, 15]
        }, {
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 0,
            widths: ['*', '*', '*'],
            body: assinaturas
          }
        });
      } else {
        retorno.push({
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: this.listaRecurso ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
            body: conteudo
          }, margin: [0, 0, 0, 15]
        });
      }
    }

    if (this.agruparConta) {
      const conteudo = [];
      const totais = this.funcaoService.totalizar(lista, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);
      const total = [];
      total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 1 });
      total.push('');
      if (this.listaRecurso) {
        total.push('');
      }
      total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_anterior'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(totais['deposito'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(totais['retirada'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(+totais['saldo_atual'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true });
      conteudo.push(total);

      const ass = new Assinaturas(this.login.orgao, this.injector);
      const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

      if (this.assinatura_tesouraria) {
        retorno.push({
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: this.listaRecurso ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
            body: conteudo
          }, margin: [0, 0, 0, 15]
        }, {
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 0,
            widths: ['*', '*', '*'],
            body: assinaturas
          }
        });
      } else {
        retorno.push({
          layout: 'linhas',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: this.listaRecurso ? ['auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
            body: conteudo
          }, margin: [0, 0, 0, 15]
        });
      }
    }

    return retorno;
  }

  private imprimirBoletimBancoRecurso() {
    let data_inicio = this.dataCaixa;
    let data_fim = this.dataFinal;

    const exercicio = this.listaExercicios.find((ex) => ex.id === +this.ano);

    if (this.opecaoPeriodo === 'MENSAL') {
      data_inicio = new Date(exercicio.ano, this.mes - 1, 1);
      data_fim = new Date(exercicio.ano, this.mes - 1, this.funcaoService.ultimoDiaMes(+this.mes, exercicio.ano));
    }

    this.contaBancariaService.boletimBanco(exercicio.id, this.login.orgao.id, this.datepipe.transform(data_inicio, 'yyyy-MM-dd'),
      this.datepipe.transform(data_fim, 'yyyy-MM-dd'), `coalesce(re.codigo, concat(rec.codigo, ap.codigo, '0000')),coalesce(re.nome, ap.nome),c.codigo`, 'false', (this.opcaoRecurso === 'SELECIONAR' ? this.buscaFonte.codigo : false), false, false)
      .pipe(takeUntil(this.unsubscribe)).subscribe(async dados => {
        if (this.formato === 'pdf')
          Relatorio.imprimirPersonalizado('BOLETIM DE BANCO POR RECURSO', this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
            await this.montarConteudoBoletimBancoRecurso(dados), 'portrait', 'BOLETIM DE BANCO POR RECURSO', this.layout(), false);
        else
          this.montarConteudoBoletimBancoRecursoXlsxCsv(dados, this.formato === 'csv');
      });
  }

  private montarConteudoBoletimBancoRecursoXlsxCsv(lista: any[], csv?: boolean) {
    const algCenter: Partial<Alignment> = { vertical: 'middle', horizontal: 'center' };
    const algRight: Partial<Alignment> = { vertical: 'middle', horizontal: 'right' };
    const algLeft: Partial<Alignment> = { vertical: 'middle', horizontal: 'left' };

    const wb = new Workbook();
    wb.creator = this.login.usuario.nome;
    wb.lastModifiedBy = this.login.usuario.nome;
    wb.created = new Date();
    wb.modified = new Date();
    wb.calcProperties.fullCalcOnLoad = true;

    const ws = wb.addWorksheet('BOLETIM DE BANCO POR RECURSO');

    ws.columns = [
      { key: 'ficha', width: 15, alignment: algCenter },
      { key: 'descricao', width: 100, alignment: algLeft },
      { key: 'anterior', width: 24, alignment: algRight },
      { key: 'deposito', width: 24, alignment: algRight },
      { key: 'retirada', width: 24, alignment: algRight },
      { key: 'saldo', width: 24, alignment: algRight }
    ];

    ws.getCell('A1').value = this.login.orgao.nome + ' - ' + this.login.cidade.nome;
    ws.getCell('A1').alignment = algCenter;
    ws.getCell('A1').font = { bold: true, size: 16 };
    if (!csv) {
      ws.mergeCells('A1:F1');
    } else {
      ws.getCell('B1').value = '';
      ws.getCell('C1').value = '';
      ws.getCell('D1').value = '';
      ws.getCell('E1').value = '';
      ws.getCell('F1').value = '';
    }

    ws.getCell('A2').value = 'BOLETIM DE BANCO POR RECURSO';
    ws.getCell('A2').alignment = algCenter;
    ws.getCell('A2').font = { bold: true, size: 12 };
    if (!csv)
      ws.mergeCells('A2:F2');

    let referencia = '';
    if (this.opecaoPeriodo === 'PERIODO')
      referencia = `${this.datepipe.transform(this.dataCaixa, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`;
    if (this.opecaoPeriodo === 'MENSAL') {
      const exercicio = this.listaExercicios.find((ex) => ex.id === +this.ano);
      referencia = `${this.globalService.obterMes(this.mes)}/${exercicio.ano}`;
    }

    ws.getCell('A3').value = `REFERÊNCIA: ${referencia}`;
    ws.getCell('A3').alignment = algCenter;
    ws.getCell('A3').font = { size: 12 };
    if (!csv)
      ws.mergeCells('A3:F3');

    ws.getRow(4).values = ['FICHA', 'DESCRIÇÃO', 'ANTERIOR', 'DEPÓSITO', 'RETIRADA', 'SALDO'];
    ws.getRow(4).alignment = algCenter;
    ws.getRow(4).font = { bold: true };

    const grupos = this.funcaoService.agrupar(lista.map((l) => this.tratarBoletim(l)), 'recurso_completo');

    let linha = 5;

    for (let grupo of grupos) {
      if (this.saldoConta && grupo.registros.filter((r) => (+r.saldo_atual + +r.saldo_ano_anterior) > 0 || +r.deposito > 0 || +r.retirada > 0).length === 0)
        continue;

      ws.getCell(`A${linha}`).value = `${grupo.grupo}`;
      ws.getCell(`A${linha}`).alignment = algLeft;
      ws.getCell(`A${linha}`).font = { bold: true };
      if (!csv)
        ws.mergeCells(`A${linha}:F${linha}`);

      linha++;

      for (const entidade of grupo.registros) {
        if (this.saldoConta && ((+entidade.saldo_atual + +entidade.saldo_ano_anterior) <= 0) && (+entidade.deposito <= 0) && (+entidade.retirada <= 0))
          continue;

        ws.getRow(linha).values = [
          (+entidade.id),
          `${entidade.banco_nome} ${entidade.nome} ${entidade.numero_conta} ${entidade.digito_conta ? entidade.digito_conta : ''}`,
          (+entidade.saldo_anterior + +entidade.saldo_ano_anterior),
          (+entidade.deposito),
          (+entidade.retirada),
          (+entidade.saldo_atual + +entidade.saldo_ano_anterior)
        ];
        ws.getCell(`C${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
        ws.getCell(`D${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
        ws.getCell(`E${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
        ws.getCell(`F${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';

        linha++;
      }

      const totais = this.funcaoService.totalizar(grupo.registros, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);

      ws.getRow(linha).values = [
        'TOTAL', '',
        (totais['saldo_anterior'] + +totais['saldo_ano_anterior']),
        (totais['deposito']),
        (totais['retirada']),
        (+totais['saldo_atual'] + +totais['saldo_ano_anterior'])
      ];
      ws.getRow(linha).font = { bold: true };
      ws.getCell(`C${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
      ws.getCell(`D${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
      ws.getCell(`E${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
      ws.getCell(`F${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';

      linha++;
    }

    const totais = this.funcaoService.totalizar(lista, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);

    ws.getRow(linha).values = [
      'TOTAL GERAL', '',
      (totais['saldo_anterior'] + +totais['saldo_ano_anterior']),
      (totais['deposito']),
      (totais['retirada']),
      (+totais['saldo_atual'] + +totais['saldo_ano_anterior'])
    ];
    ws.getRow(linha).font = { bold: true };

    ws.getCell(`C${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
    ws.getCell(`D${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
    ws.getCell(`E${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';
    ws.getCell(`F${linha}`).numFmt = '#,##0.00;[Red]\-#,##0.00';

    if (!csv)
      this.downloadxlsx(wb);
    else
      this.downloadCsv(wb, (value, index) => {
        if (index > 1 && typeof value === 'number')
          return this.funcaoService.convertToBrNumber(value);
        return value;
      });
  }

  private downloadxlsx(wb: Workbook) {
    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = `BOLETIM DE BANCO POR RECURSO.xlsx`;
      link.target = '_blank';
      link.click();
      window.URL.revokeObjectURL(downloadURL);
    });
  }

  private downloadCsv(wb: Workbook, map?: (value, index) => any) {
    wb.csv.writeBuffer({
      map, encoding: 'iso-8859-1',
      formatterOptions: { delimiter: ';', alwaysWriteHeaders: true }
    }).then((data) => {
      let blob = new Blob([data], { type: 'text/csv;charset=iso-8859-1;' });
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const arquivo = e.target.result;
        const link = document.createElement('a');
        link.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + arquivo));
        link.download = `BOLETIM DE BANCO POR RECURSO.csv`;
        link.target = '_blank';
        link.click();
      });

      reader.readAsText(blob);
    });
  }

  private async montarConteudoBoletimBancoRecurso(lista: any[]): Promise<{}[]> {
    const grupos = this.funcaoService.agrupar(lista.map((l) => this.tratarBoletim(l)), 'recurso_completo');

    let referencia = '';
    if (this.opecaoPeriodo === 'PERIODO')
      referencia = `${this.datepipe.transform(this.dataCaixa, 'dd/MM/yyyy')} a ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`;
    if (this.opecaoPeriodo === 'MENSAL') {
      const exercicio = this.listaExercicios.find((ex) => ex.id === +this.ano);
      referencia = `${this.globalService.obterMes(this.mes)}/${exercicio.ano}`;
    }

    let retorno: {}[] = [];
    retorno.push({ text: `REFERÊNCIA: ${referencia}`, alignment: 'center', fontSize: 10, lineHeight: 1.5 })

    const conteudo = [];
    conteudo.push([
      { text: 'FICHA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
      { text: 'DESCRIÇÃO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'ANTERIOR', fontSize: 8, alignment: 'center', bold: true },
      { text: 'DEPÓSITO', fontSize: 8, alignment: 'center', bold: true },
      { text: 'RETIRADA', fontSize: 8, alignment: 'center', bold: true },
      { text: 'SALDO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
    ]);

    for (let grupo of grupos) {
      if (this.saldoConta && grupo.registros.filter((r) => (+r.saldo_atual + +r.saldo_ano_anterior) > 0 || +r.deposito > 0 || +r.retirada > 0).length === 0)
        continue;

      conteudo.push([{ text: `${grupo.grupo}`, colSpan: 6, fontSize: 8, margin: 3 }, { text: `` }, { text: `` }, { text: `` }, { text: `` }, { text: `` }]);

      for (const entidade of grupo.registros) {
        if (this.saldoConta && ((+entidade.saldo_atual + +entidade.saldo_ano_anterior) <= 0) && (+entidade.deposito <= 0) && (+entidade.retirada <= 0))
          continue;

        conteudo.push([
          { text: this.funcaoService.strZero(entidade.id, 4), fontSize: 7, alignment: 'center', bold: false, border: [true, false, true, false] },
          { text: `${entidade.banco_nome} ${entidade.nome} ${entidade.numero_conta} ${entidade.digito_conta ? entidade.digito_conta : ''}`, fontSize: 7, alignment: 'left', bold: false, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(+entidade.saldo_anterior + +entidade.saldo_ano_anterior, 2), fontSize: 7, alignment: 'right', bold: false, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(entidade.deposito, 2), fontSize: 7, alignment: 'right', bold: false, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(entidade.retirada, 2), fontSize: 7, alignment: 'right', bold: false, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(+entidade.saldo_atual + +entidade.saldo_ano_anterior, 2), fontSize: 7, alignment: 'right', bold: false, border: [true, false, true, false] }
        ]);
      }

      const totais = this.funcaoService.totalizar(grupo.registros, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);

      conteudo.push([
        { text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 2 },
        { text: '' },
        { text: this.funcaoService.convertToBrNumber(+totais['saldo_anterior'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true },
        { text: this.funcaoService.convertToBrNumber(totais['deposito'], 2), fontSize: 8, alignment: 'right', bold: true },
        { text: this.funcaoService.convertToBrNumber(totais['retirada'], 2), fontSize: 8, alignment: 'right', bold: true },
        { text: this.funcaoService.convertToBrNumber(+totais['saldo_atual'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true }
      ]);
    }

    const totais = this.funcaoService.totalizar(lista, ['saldo_ano_anterior', 'saldo_anterior', 'deposito', 'retirada', 'saldo_atual']);

    conteudo.push([
      { text: 'TOTAL GERAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 2 },
      { text: '' },
      { text: this.funcaoService.convertToBrNumber(+totais['saldo_anterior'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(totais['deposito'], 2), fontSize: 8, alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(totais['retirada'], 2), fontSize: 8, alignment: 'right', bold: true },
      { text: this.funcaoService.convertToBrNumber(+totais['saldo_atual'] + +totais['saldo_ano_anterior'], 2), fontSize: 8, alignment: 'right', bold: true }
    ]);

    this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()).assinatura_tesouraria;

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

    if (this.assinatura_tesouraria) {
      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
          body: conteudo
        }, margin: [0, 0, 0, 15]
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      });
    } else {
      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
          body: conteudo
        }, margin: [0, 0, 0, 15]
      });
    }

    return retorno;
  }

  private tratarBoletim(boletim: any): any {
    const variavel = !boletim.variavel ? '0000' : boletim.variavel;
    const recurso = boletim.aplicacao ? boletim.recurso.toString() + boletim.aplicacao.toString() + variavel.toString() : boletim.recurso;
    boletim.recurso_completo = `${recurso} - ${boletim.nome_recurso}`;
    boletim.numero_completo = recurso;
    return boletim;
  }

  private imprimirBoletimCaixa(formato: FormatoExportacao) {
    this.contaBancariaService.boletimCaixa(this.login.exercicio.id, this.login.orgao.id,
      this.datepipe.transform(this.dataCaixa, 'yyyy-MM-dd'), this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd'), this.ajusteVariacao).pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('BOLETIM DE CAIXA DIÁRIO', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudoBoletimCaixa(dados),
            'portrait', 'BOLETIM DE CAIXA DIÁRIO',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        } else if (formato === 'csv') {
          let retorno: {}[][] = [];

          retorno.push([{ text: 'CAIXA' }]);
          retorno.push([
            { text: 'SALDO DO DIA ANTERIOR' },
            { text: this.funcaoService.convertToBrNumber(dados.saldo_anterior_dia) }
          ]);
          retorno.push([{ text: 'ENTRADAS DO DIA' }]);
          retorno.push([
            { text: 'Receitas orçamentárias' },
            { text: this.funcaoService.convertToBrNumber(dados.receitas_orcamentarias) }
          ]);
          retorno.push([
            { text: 'Receitas extra - orçamentárias' },
            { text: this.funcaoService.convertToBrNumber(dados.receitas_extras) }
          ]);
          retorno.push([
            { text: 'Interveniências ativas' },
            { text: this.funcaoService.convertToBrNumber(dados.interveniencias_ativas) }
          ]);
          retorno.push([
            { text: 'Retiradas de bancos' },
            { text: this.funcaoService.convertToBrNumber(dados.retiradas_bancos + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)) }
          ]);
          retorno.push([
            { text: 'Anulação de pagamentos' },
            { text: this.funcaoService.convertToBrNumber(dados.anulacao_pagamentos) }
          ]);

          if (this.considerar_bloqueios) {
            retorno.push([
              { text: 'Desbloqueios judiciais' },
              { text: this.funcaoService.convertToBrNumber(0) }
            ]);
          }

          const total_entradas = dados.receitas_orcamentarias + dados.receitas_extras + dados.interveniencias_ativas + dados.retiradas_bancos + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0) + dados.anulacao_pagamentos;
          retorno.push([
            { text: 'TOTAL DE ENTRADAS' },
            { text: this.funcaoService.convertToBrNumber(total_entradas) }
          ]);
          retorno.push([{ text: 'SAÍDAS DO DIA' }]);
          retorno.push([
            { text: 'Pagamentos orçamentários' },
            { text: this.funcaoService.convertToBrNumber(dados.pagamentos_orcamentarios) }
          ]);
          retorno.push([
            { text: 'Pagamentos extra - orçamentários' },
            { text: this.funcaoService.convertToBrNumber(dados.pagamentos_extras) }
          ]);
          retorno.push([
            { text: 'Interveniências passivas' },
            { text: this.funcaoService.convertToBrNumber(dados.interveniencias_passivas) }
          ]);
          retorno.push([
            { text: 'Depósitos em bancos' },
            { text: this.funcaoService.convertToBrNumber(dados.depositos_bancos - (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)) }
          ]);
          retorno.push([
            { text: 'Anulação de receitas' },
            { text: this.funcaoService.convertToBrNumber(+dados.anulacao_receitas + 0.0005) }
          ]);

          if (this.considerar_bloqueios) {
            retorno.push([
              { text: 'Bloqueios judiciais' },
              { text: this.funcaoService.convertToBrNumber(+dados.bloqueios_judiciais) }
            ]);
          }

          const total_saidas = +dados.pagamentos_orcamentarios + +dados.pagamentos_extras + +dados.interveniencias_passivas + +dados.depositos_bancos - (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0) + +dados.anulacao_receitas;
          retorno.push([
            { text: 'TOTAL DE SAÍDAS' },
            { text: this.funcaoService.convertToBrNumber(total_saidas) }
          ]);

          const saldo_caixa = (+dados.saldo_anterior_dia + +total_entradas) - +total_saidas;
          retorno.push([
            { text: 'SALDO EM CAIXA' },
            { text: this.funcaoService.convertToBrNumber(+saldo_caixa + 0.0005) }
          ]);
          retorno.push([{ text: 'BANCOS' }]);
          retorno.push([
            { text: 'SALDO ANTERIOR DE BANCOS' },
            { text: this.funcaoService.convertToBrNumber(dados.saldo_anterior_banco) }
          ]);
          retorno.push([
            { text: 'Depósitos do dia' },
            { text: this.funcaoService.convertToBrNumber(dados.depositos_bancos - (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)) }
          ]);
          retorno.push([
            { text: 'Retiradas do dia' },
            { text: this.funcaoService.convertToBrNumber(dados.retiradas_bancos + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)) }
          ]);

          const saldo_banco = dados.saldo_banco;
          retorno.push([
            { text: 'SALDO EM BANCOS' },
            { text: this.funcaoService.convertToBrNumber(+saldo_banco) }
          ]);
          retorno.push([
            { text: 'DISPONIBILIDADE FINANCEIRA' },
            { text: this.funcaoService.convertToBrNumber(+saldo_banco + +saldo_caixa) }
          ]);

          this.imprimirCSV(retorno, 'BOLETIM DE CAIXA DIÁRIO');
        }
      });
  }

  private async montarConteudoBoletimCaixa(dados: any): Promise<{}[]> {
    let retorno: {}[] = [];
    retorno.push({
      text: `DATA: ${this.datepipe.transform(this.dataCaixa, 'dd/MM/yyyy')} - ${this.datepipe.transform(this.dataFinal, 'dd/MM/yyyy')}`, alignment: 'center', fontSize: 10, lineHeight: 1.5
    });
    const conteudo = [];
    conteudo.push([
      { text: 'CAIXA', fontSize: 14, alignment: 'center', bold: true, border: [true, true, true, true], colSpan: 2, margin: [0, 6, 0, 6] }, ''
    ]);
    conteudo.push([
      { text: 'SALDO DO DIA ANTERIOR', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_anterior_dia), fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 4, 0, 4] }
    ]);
    conteudo.push([
      { text: 'ENTRADAS DO DIA', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], colSpan: 2, margin: [0, 5, 0, 5] }, ''
    ]);
    conteudo.push([
      { text: 'Receitas orçamentárias', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.receitas_orcamentarias), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Receitas extra - orçamentárias', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.receitas_extras), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Interveniências ativas', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.interveniencias_ativas), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Retiradas de bancos', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.retiradas_bancos + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Anulação de pagamentos', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.anulacao_pagamentos), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    if (this.considerar_bloqueios)
      conteudo.push([
        { text: 'Desbloqueios judiciais', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
        { text: this.funcaoService.convertToBrNumber(0), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
      ]);

    // const total_entradas: number = +dados.receitas_orcamentarias + +dados.receitas_extras + +dados.interveniencias_ativas + +dados.retiradas_bancos + +dados.anulacao_pagamentos
    const total_entradas = dados.receitas_orcamentarias + dados.receitas_extras + dados.interveniencias_ativas + dados.retiradas_bancos + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0) + dados.anulacao_pagamentos

    conteudo.push([
      { text: 'TOTAL DE ENTRADAS', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      {
        text: this.funcaoService.convertToBrNumber(total_entradas),
        fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3]
      }
    ]);
    conteudo.push([
      { text: 'SAÍDAS DO DIA', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], colSpan: 2, margin: [0, 5, 0, 5] }, ''
    ]);
    conteudo.push([
      { text: 'Pagamentos orçamentários', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.pagamentos_orcamentarios), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Pagamentos extra - orçamentários', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.pagamentos_extras), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Interveniências passivas', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.interveniencias_passivas), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Depósitos em bancos', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.depositos_bancos - (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Anulação de receitas', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(+dados.anulacao_receitas + 0.0005), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    if (this.considerar_bloqueios)
      conteudo.push([
        { text: 'Bloqueios judiciais', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
        { text: this.funcaoService.convertToBrNumber(+dados.bloqueios_judiciais), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
      ]);
    // const total_saidas: number = +dados.pagamentos_orcamentarios + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0) + +dados.pagamentos_extras + +dados.interveniencias_passivas + +dados.depositos_bancos + (+dados.anulacao_receitas * -1);
    const total_saidas = +dados.pagamentos_orcamentarios + +dados.pagamentos_extras + +dados.interveniencias_passivas + +dados.depositos_bancos - (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0) + +dados.anulacao_receitas;

    conteudo.push([
      { text: 'TOTAL DE SAÍDAS', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      {
        text: this.funcaoService.convertToBrNumber(total_saidas),
        fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3]
      }
    ]);
    // const saldo_caixa = (+dados.saldo_anterior_dia + +total_entradas) - +total_saidas + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0);

    const saldo_caixa = (+dados.saldo_anterior_dia + +total_entradas) - +total_saidas

    conteudo.push([
      { text: 'SALDO EM CAIXA', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(+saldo_caixa + 0.0005), fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'BANCOS', fontSize: 13, alignment: 'center', bold: true, border: [true, true, true, true], colSpan: 2, margin: [0, 6, 0, 6] }, ''
    ]);
    conteudo.push([
      { text: 'SALDO ANTERIOR DE BANCOS', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.saldo_anterior_banco), fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Depósitos do dia', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.depositos_bancos - (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'Retiradas do dia', fontSize: 10, alignment: 'left', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(dados.retiradas_bancos + (this.considerar_bloqueios ? +dados.bloqueios_judiciais : 0)), fontSize: 10, alignment: 'right', bold: false, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    const saldo_banco = dados.saldo_banco; // +dados.saldo_anterior_banco + (+dados.depositos_bancos - +dados.retiradas_bancos);
    conteudo.push([
      { text: 'SALDO EM BANCOS', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(+saldo_banco), fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);
    conteudo.push([
      { text: 'DISPONIBILIDADE FINANCEIRA', fontSize: 10, alignment: 'left', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] },
      { text: this.funcaoService.convertToBrNumber(+saldo_banco + +saldo_caixa), fontSize: 10, alignment: 'right', bold: true, border: [true, true, true, true], margin: [0, 3, 0, 3] }
    ]);

    this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()).assinatura_tesouraria;

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

    if (this.assinatura_tesouraria) {
      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 200],
          body: conteudo
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      });
    } else {
      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*', 200],
          body: conteudo
        }
      });
    }

    return retorno;
  }

  private async montarConteudoconciliacao(lista: any[], dataInicial: Date, dataFinal: Date): Promise<{}[]> {
    const dados = this.funcaoService.agrupar(lista, ['id', 'nome', 'numero_conta', 'digito_conta', 'banco_nome', 'saldo_ano_anterior', 'saldo_anterior']);
    let retorno: {}[] = [];
    retorno.push({ text: `REFERÊNCIA: ${this.datepipe.transform(dataInicial, 'dd/MM/yyyy')} a ${this.datepipe.transform(dataFinal, 'dd/MM/yyyy')}`, alignment: 'center', fontSize: 10, lineHeight: 1.5 });
    let primeiro = true;
    for (const entidade of dados) {
      const conteudo = [];
      conteudo.push([
        { text: 'DATA', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
        { text: 'HISTÓRICO', fontSize: 8, alignment: 'center', bold: true },
        { text: 'DOCUMENTO', fontSize: 8, alignment: 'center', bold: true },
        { text: 'DEPÓSITO', fontSize: 8, alignment: 'center', bold: true },
        { text: 'RETIRADA', fontSize: 8, alignment: 'center', bold: true },
        { text: 'SALDO', fontSize: 8, alignment: 'center', bold: true, border: [true, true, true, true] },
      ]);
      conteudo.push([
        { text: `CONTA: ${entidade.grupo['id']} ${entidade.grupo['banco_nome']} ${entidade.grupo['nome']} - ${entidade.grupo['numero_conta']} ${entidade.grupo['digito_conta'] ? entidade.grupo['digito_conta'] : ''}`, fontSize: 10, alignment: 'left', bold: true, colSpan: 6 },
        '',
        '',
        '',
        '',
        ''
      ]);

      let saldo_anterior = !entidade['sem_movimentacao'] ? +entidade.grupo['saldo_ano_anterior'] + +entidade.grupo['saldo_anterior'] : +entidade.grupo['cbs_saldo_anterior'] > 0 ? +entidade.grupo['cbs_saldo_anterior'] : entidade.grupo['saldo_ano_anterior']

      if (+saldo_anterior === 0 && entidade['sem_movimentacao']) continue;
      conteudo.push([
        { text: `SALDO ANTERIOR`, fontSize: 8, alignment: 'left', bold: true, colSpan: 2, border: [true, false, true, true] },
        '',
        { text: this.funcaoService.convertToBrNumber(+saldo_anterior, 2), fontSize: 8, alignment: 'right', bold: true, colSpan: 4, border: [true, false, true, true] },
        '',
        '',
        ''
      ]);

      if (this.conciliacaoSintetico) {
        const lista = this.funcaoService.agrupar(entidade.registros, ['data', 'documento'], ['deposito', 'retirada']);
        entidade.registros = lista.map(e => {
          let registro: any = [];
          registro['deposito'] = e.totalizadores['deposito'];
          registro['retirada'] = e.totalizadores['retirada']
          registro['data'] = e.grupo['data']
          registro['documento'] = e.grupo['documento']
          registro['historico'] = +registro['deposito'] !== 0 ? 'DEPOSITO EFETUADO NESTA DATA' : 'RETIRADA EFETUADA NESSA DATA';
          return registro;
        });
      }
      if (this.orderSelectConciliacao === 'documento') {
        entidade.registros.sort(function (a, b) {
          let documentoa = a.documento ? a.documento : '';
          let documentob = b.documento ? b.documento : '';
          if (documentoa > documentob) {
            return 1;
          }
          if (documentoa < documentob) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      if (this.orderSelectConciliacao === 'valor') {
        entidade.registros.sort(function (a, b) {
          let valora = +a.deposito !== 0 ? a.deposito : a.retirada;
          let valorb = +b.deposito !== 0 ? b.deposito : b.retirada;
          if (+valora > +valorb) {
            return 1;
          }
          if (+valora < +valorb) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
      if (this.orderSelectConciliacao === 'data_historico') {
        entidade.registros.sort((a, b) => {
          if (a.data === b.data) {
            let ordem = a.deposito > 0 ? -1 : (b.deposito > 0 ? 1 : a.historico.localeCompare(b.historico))
            return ordem;
          }
          return a.data > b.data ? 1 : -1;
        });
      }
      if (this.orderSelectConciliacao === 'data') {
        entidade.registros.sort((a, b) => {
          if (a.data === b.data) {
            if (a.id_historico > b.id_historico) {
              return 1;
            }
            if (a.id_historico < b.id_historico) {
              return -1;
            }
            // a must be equal to b
            return 0;
          }
          return a.data > b.data ? 1 : -1;
        });
      }
      if (this.orderSelectConciliacao === 'data_fornecedor') {
        entidade.registros.sort((a, b) => {
          if (a.data === b.data) {
            if (!a.favorecido) return -1;
            if (!b.favorecido) return 1;

            return a.favorecido.localeCompare(b.favorecido);
          }
          return a.data > b.data ? 1 : -1;
        });
      }

      for (const item of entidade.registros) {
        if (item['sem_movimentacao']) break;
        const registro = [];
        registro.push({ text: this.datepipe.transform(item['data'], 'dd/MM/yyyy'), fontSize: 8, alignment: 'center', bold: false, border: [true, false, true, false] });
        registro.push({ text: item['historico'], fontSize: 8, alignment: 'left', bold: false, border: [true, false, true, false] });
        registro.push({
          text: `${item['documento'] ? item['documento'] : ''}`,
          fontSize: 8, alignment: 'left', bold: false, border: [true, false, true, false]
        });
        saldo_anterior += +item['deposito'] - +item['retirada'];
        registro.push({ text: this.funcaoService.convertToBrNumber(item['deposito'], 2), fontSize: 8, alignment: 'right', bold: false, border: [true, false, true, false] });
        registro.push({ text: this.funcaoService.convertToBrNumber(item['retirada'], 2), fontSize: 8, alignment: 'right', bold: false, border: [true, false, true, false] });
        registro.push({ text: this.funcaoService.convertToBrNumber(saldo_anterior, 2), fontSize: 8, alignment: 'right', bold: false, border: [true, false, true, false] });
        conteudo.push(registro);
      }
      const totais = this.funcaoService.totalizar(entidade.registros, ['deposito', 'retirada']);
      const total = [];
      total.push({ text: 'TOTAL', fontSize: 8, alignment: 'left', bold: true, colSpan: 3 });
      total.push('');
      total.push('');
      total.push({ text: this.funcaoService.convertToBrNumber(totais['deposito'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(totais['retirada'], 2), fontSize: 8, alignment: 'right', bold: true });
      total.push({ text: this.funcaoService.convertToBrNumber(saldo_anterior, 2), fontSize: 8, alignment: 'right', bold: true });
      conteudo.push(total);

      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
          body: conteudo
        }, pageBreak: 'before'
      });

      if (primeiro) {
        delete retorno[retorno.length - 1]['pageBreak'];
        primeiro = false;
      }
    }

    const params = await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()
    this.assinatura_tesouraria = params?.assinatura_tesouraria

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

    if (this.assinatura_tesouraria) {
      retorno.push({
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: assinaturas
        }
      });
    }

    return retorno;
  }

  private imprimirDisponibilidade(formato: FormatoExportacao) {
    this.contaBancariaService.disponbilidadeRecurso(this.login.exercicio.id, this.login.orgao.id,
      this.datepipe.transform(this.dataCaixa, 'yyyy-MM-dd'),
      this.datepipe.transform(this.dataFinal, 'yyyy-MM-dd')).pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DISPONIBILIDADE DE FONTE RECURSOS', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudoDisponibilidade(dados),
            'landscape', 'DISPONIBILIDADE DE FONTE RECURSOS',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false);
        } else if (formato === 'csv') {
          const conteudo: {}[][] = [[
            { text: 'FONTES DE RECURSO' },
            { text: 'SALDO BANCO' },
            { text: 'LIQUIDADO' },
            { text: 'A' },
            { text: 'B' },
            { text: 'C' },
          ]];

          const grupos = this.funcaoService.agrupar(dados, ['recurso', 'recurso_nome'], ['valor', 'liquidado', 'liq_nao_processado', 'liq_processado', 'pago', 'pago_nao_processo', 'pago_processado']);
          for (let grupo of grupos) {
            conteudo.push([
              { text: `${grupo.grupo['recurso']} ${grupo.grupo['recurso_nome']}` },
              { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['valor']) },
              { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']) },
              { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liq_nao_processado']) },
              { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liq_processado']) },
              { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']) },
            ]);

            const subgrupos = this.funcaoService.agrupar(grupo.registros, ['aplicacao', 'aplicacao_nome'], ['valor', 'liquidado', 'liq_nao_processado', 'liq_processado', 'pago', 'pago_nao_processo', 'pago_processado']);
            for (let subgrupo of subgrupos) {
              conteudo.push([
                { text: `${subgrupo.grupo['aplicacao']} ${subgrupo.grupo['aplicacao_nome']}` },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['valor']) },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']) },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liq_nao_processado']) },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liq_processado']) },
                { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']) },
              ]);

              for (const entidade of subgrupo.registros) {
                if (entidade['variavel'] !== '0000') {
                  conteudo.push([
                    { text: `${entidade['variavel']} ${entidade['variavel_nome']}` },
                    { text: this.funcaoService.convertToBrNumber(entidade.valor) },
                    { text: this.funcaoService.convertToBrNumber(0) },
                    { text: this.funcaoService.convertToBrNumber(0) },
                    { text: this.funcaoService.convertToBrNumber(0) },
                    { text: this.funcaoService.convertToBrNumber(0) },
                  ]);
                }
              }
            }
          }

          this.imprimirCSV(conteudo, 'DISPONIBILIDADE DE FONTE RECURSOS');
        }
      });
  }

  private async montarConteudoDisponibilidade(lista: any[]): Promise<{}[]> {
    const grupos = this.funcaoService.agrupar(lista,
      ['recurso', 'recurso_nome'],
      ['valor', 'liquidado', 'liq_nao_processado', 'liq_processado', 'pago', 'pago_nao_processo', 'pago_processado']);

    const conteudo: {}[] = [
      [
        {
          text: 'FONTES DE RECURSO', bold: true, fontSize: 7
        },
        {
          text: 'SALDO BANCO', alignment: 'center', bold: true, fontSize: 7
        },
        {
          text: 'LIQUIDADO', alignment: 'center', bold: true, fontSize: 7
        },
        {
          text: 'A', alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: 'B', alignment: 'right', bold: true, fontSize: 7
        },
        {
          text: 'C', alignment: 'right', bold: true, fontSize: 7
        }
      ]
    ];

    for (let grupo of grupos) {
      conteudo.push([
        { text: `${grupo.grupo['recurso']} ${grupo.grupo['recurso_nome']}`, bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['valor']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liquidado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liq_nao_processado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['liq_processado']), alignment: 'right', bold: true, fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(grupo.totalizadores['pago']), alignment: 'right', bold: true, fontSize: 7 }
      ]);
      const subgrupos = this.funcaoService.agrupar(grupo.registros,
        ['aplicacao', 'aplicacao_nome'],
        ['valor', 'liquidado', 'liq_nao_processado', 'liq_processado', 'pago', 'pago_nao_processo', 'pago_processado']);

      for (let subgrupo of subgrupos) {
        conteudo.push([
          { text: `${subgrupo.grupo['aplicacao']} ${subgrupo.grupo['aplicacao_nome']}`, bold: false, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['valor']), alignment: 'right', bold: false, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liquidado']), alignment: 'right', bold: false, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liq_nao_processado']), alignment: 'right', bold: false, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['liq_processado']), alignment: 'right', bold: false, fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(subgrupo.totalizadores['pago']), alignment: 'right', bold: false, fontSize: 7 }
        ]);
        for (const entidade of subgrupo.registros) {
          if (entidade['variavel'] !== '0000') {
            conteudo.push([
              { text: `${entidade.recurso}${entidade.aplicacao}${entidade.variavel} ${entidade['variavel_nome']}`, fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(entidade.valor), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 7 },
              { text: this.funcaoService.convertToBrNumber(0), alignment: 'right', fontSize: 7 }
            ]);
          }
        }
      }
    }

    this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()).assinatura_tesouraria;

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

    return this.assinatura_tesouraria ? [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 3,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }
    }, {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 3,
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
        body: conteudo
      }
    }
    ]
  }

  private imprimirDescendio(formato: FormatoExportacao, tipoDecendio: number) {
    const parametros: {} = {};
    if (tipoDecendio === 1) {
      parametros['ficha.ensino$in'] = '5,25';
      parametros['orderBy'] = 'ficha.ensino,ficha.receita.codigo';

    } else {
      parametros['ficha.saude$in'] = '15';
      parametros['orderBy'] = 'ficha.saude,ficha.receita.codigo';
    }
    parametros['ficha.ativo'] = true;
    parametros['exercicio.id'] = this.ano;
    parametros['mes'] = this.mes;
    parametros['orgao.id'] = this.login.orgao.id;
    parametros['relations'] = 'ficha,ficha.receita,exercicio,orgao';
    this.recebimentoService.filtrar(1, -1, parametros).subscribe(lista => {
      if (lista.content?.length) {
        const decendioRelatorio = new DecendioRpt(this.injector, this.parametroContabilidadeServico);
        const infoExtras = {
          ano: lista.content[0].exercicio.ano,
          // ano: login.exercicio.ano,
          mes: this.mes
        };
        decendioRelatorio.imprimirDescendioRpt(formato, lista.content, this.login, tipoDecendio, infoExtras);
      } else {
        toastr.warning(`Nenhum registro foi encontrado!`);
        throw new Error(`Nenhum registro foi encontrado!`);
      }
    });
  }

  private imprimirEmpenhoSemOP(formato: FormatoExportacao) {
    if (!this.dataCaixa) {
      toastr.warning(`Informe uma data inicial`);
      throw new Error(`Informe uma data inicial`);
    }
    if (!this.dataFinal) {
      toastr.warning(`Informe uma data final`);
      throw new Error(`Informe uma data final`);
    }

    const tipoData = '' + this.tipoDataEmp;

    this.ordemPagamentoService.empenhoSemVinculoOP(this.ano, this.login.orgao.id, tipoData, this.dataCaixa, this.dataFinal, this.tipoDespesa, this.tipoRetencao, this.tipoValorImpresso).subscribe((lista: any) => {
      const empenhoSemOPRelatorio = new EmpenhoSemOPRpt(this.injector, this.parametroContabilidadeServico);
      const infoExtras = {
        dataIncial: this.dataCaixa,
        dataFinal: this.dataFinal,
        ano: this.ano,
        tipoData: this.tipoDataEmp,
        tipoDespesa: this.listaDespesas.find(desp => desp.id === this.tipoDespesa).titulo || '',
        tipoRetencao: this.listaRetencoes.find(ret => ret.id === this.tipoRetencao).nome || '',
        tipoValor: this.tipoValorImpresso
      };
      if (lista.emo || lista.emr || lista.eme) {
        empenhoSemOPRelatorio.imprimirEmpenhosSemOPRpt(formato, lista as Lista, this.login, infoExtras);
      } else {
        toastr.warning(`Não foram encontrado nenhum dado com estes filtros!`);
        throw new Error(`Não foram encontrado nenhum dado com estes filtros!`);
      }
    });
  }

  private imprimirDiarioCaixa(formato: FormatoExportacao) {
    if (this.dataCaixa.getFullYear() !== this.login.exercicio.ano || (this.dataFinal && this.dataFinal.getFullYear() !== this.login.exercicio.ano)) {
      toastr.warning('Data informada não pertence ao exercício logado!');
      return;
    }
    if (this.dataCaixa && this.dataFinal && this.dataCaixa > this.dataFinal) {
      toastr.warning('Data inicial não pode ser maior que a data final!');
      return;
    }
    this.diarioCaixaService.obterDiarioCaixa(this.funcaoService.converteDataSQL(this.dataCaixa), this.login.exercicio.id, this.login.orgao.id, this.diarioCaixaAnalitico, this.funcaoService.converteDataSQL(this.dataFinal))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado(
            '', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            await this.montarConteudoDiarioCaixa(dados),
            'landscape', 'diario de caixa',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            });
        } else if (formato === 'csv') {
          const conteudo: {}[][] = [];

          conteudo.push([
            { text: 'Receitas' }, { text: 'R$' },
            { text: 'Despesas' }, { text: 'R$' },
          ]);

          const [dadosReceitas, dadosDespesas, detalhamentoReceitas, detalhamentoDespesas] = dados;
          const grupoReceitas = this.funcaoService.agrupar(dadosReceitas.flat(), 'titulo', ['valor']);
          const grupoDespesas = this.funcaoService.agrupar(dadosDespesas.flat(), 'titulo', ['valor']);

          const receitas = [];
          for (const item of grupoReceitas) {
            receitas.push([
              { text: item.grupo },
              { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor']) },
            ]);

            item.registros.forEach(receita => {
              if (+receita.valor === 0) return;
              receitas.push([
                { text: `\u200B   ${receita.nome}` },
                { text: this.funcaoService.convertToBrNumber(receita.valor) },
              ]);
            });
          }

          const despesas = [];
          for (const item of grupoDespesas) {
            despesas.push([
              { text: item.grupo },
              { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor']) },
            ]);

            item.registros.forEach(despesa => {
              if (+despesa.valor === 0) return;
              despesas.push([
                { text: `\u200B   ${despesa.nome}` },
                { text: this.funcaoService.convertToBrNumber(despesa.valor) },
              ]);
            });
          }

          const dado = this.concatenarArraysDiarioCaixa(receitas, despesas);
          conteudo.push(...dado);

          const totalReceitas = grupoReceitas.reduce((acumulador, atual) => acumulador += +atual.totalizadores['valor'], 0);
          const totalDespesas = grupoDespesas.reduce((acumulador, atual) => acumulador += +atual.totalizadores['valor'], 0);

          conteudo.push([
            { text: 'TOTAL...' }, { text: this.funcaoService.convertToBrNumber(totalReceitas) },
            { text: 'TOTAL...' }, { text: this.funcaoService.convertToBrNumber(totalDespesas) },
          ]);

          const detalhesReceitas = [];
          let totalGeralReceitas = 0;
          for (const item of detalhamentoReceitas.flat()) {
            totalGeralReceitas += +item.valor;
            detalhesReceitas.push([
              { text: item.titulo },
              { text: this.funcaoService.convertToBrNumber(item.valor) },
            ]);
          }

          const detalhesDespesas = [];
          let totalGeralDespesas = 0;
          for (const item of detalhamentoDespesas.flat()) {
            totalGeralDespesas += +item.valor;
            detalhesDespesas.push([
              { text: item.titulo },
              { text: this.funcaoService.convertToBrNumber(item.valor) },
            ]);
          }

          const dadosDetalhes = this.concatenarArraysDiarioCaixa(detalhesReceitas, detalhesDespesas);
          conteudo.push(...dadosDetalhes);

          conteudo.push([
            { text: 'VALOR GERAL DA RECEITA' },
            { text: this.funcaoService.convertToBrNumber(totalGeralReceitas) },
            { text: 'VALOR GERAL DA DESPESA' },
            { text: this.funcaoService.convertToBrNumber(totalGeralDespesas) },
          ]);

          this.imprimirCSV(conteudo, 'diario de caixa');
        }
      });
  }

  private imprimirOrdemCronologica() {
    if (!this.dataCaixa) {
      toastr.warning(`Informe uma data inicial`);
      throw new Error(`Informe uma data inicial`);
    }
    if (!this.dataFinal) {
      toastr.warning(`Informe uma data final`);
      throw new Error(`Informe uma data final`);
    }

    this.pagtoService.ordemCronologica(this.login.exercicio.id, this.login.orgao.id, this.funcaoService.converteDataSQL(this.dataCaixa), this.funcaoService.converteDataSQL(this.dataFinal)).subscribe(lista => {
      const oc = new OrdemCronologica();
      oc.dataInicial = this.dataCaixa;
      oc.dataFinal = this.dataFinal;
      oc.imprimir(lista, this.login);
    });
  }

  private async montarConteudoDiarioCaixa(lista: any[]): Promise<{}[]> {
    const conteudo: {}[] = [];

    //Cabecalho
    conteudo.push([
      {
        text: `DIÁRIO DE CAIXA - ${this.funcaoService.converteDataBR(this.dataCaixa) + (this.dataFinal ? ' Á ' + this.funcaoService.converteDataBR(this.dataFinal) : '')}`, alignment: 'center',
        bold: true, fontSize: 14, colSpan: 4, border: [true, true, true, false], fillColor: '#eeeeee', margin: [0, 5],
      },
      '', '', ''
    ]);
    conteudo.push([
      { text: 'Receitas', fontSize: 11, bold: true, fillColor: '#eeeeee' },
      { text: 'R$', fontSize: 11, bold: true, fillColor: '#eeeeee', alignment: 'center' },
      { text: 'Despesas', fontSize: 11, bold: true, fillColor: '#eeeeee' },
      { text: 'R$', fontSize: 11, bold: true, fillColor: '#eeeeee', alignment: 'center' }
    ]);

    const [dadosReceitas, dadosDespesas, detalhamentoReceitas, detalhamentoDespesas] = lista;
    const grupoReceitas = this.funcaoService.agrupar(dadosReceitas.flat(), 'titulo', ['valor']);
    const grupoDespesas = this.funcaoService.agrupar(dadosDespesas.flat(), 'titulo', ['valor']);

    const receitas = [];
    for (const item of grupoReceitas) {
      receitas.push([
        { text: item.grupo, bold: true, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor']), bold: true, alignment: 'right', border: [true, false, true, false] },
      ]);

      item.registros.forEach(receita => {
        if (+receita.valor === 0) return;
        receitas.push([
          { text: `\u200B   ${receita.nome}`, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(receita.valor), alignment: 'right', border: [true, false, true, false] },
        ]);
      });
    }

    const despesas = [];
    for (const item of grupoDespesas) {
      despesas.push([
        { text: item.grupo, bold: true, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['valor']), bold: true, alignment: 'right', border: [true, false, true, false] },
      ]);

      item.registros.forEach(despesa => {
        if (+despesa.valor === 0) return;
        despesas.push([
          { text: `\u200B   ${despesa.nome}`, border: [true, false, true, false] },
          { text: this.funcaoService.convertToBrNumber(despesa.valor), alignment: 'right', border: [true, false, true, false] },
        ]);
      });
    }

    const dados = this.concatenarArraysDiarioCaixa(receitas, despesas);
    conteudo.push(...dados);

    const totalReceitas = grupoReceitas.reduce((acumulador, atual) => acumulador += +atual.totalizadores['valor'], 0);
    const totalDespesas = grupoDespesas.reduce((acumulador, atual) => acumulador += +atual.totalizadores['valor'], 0);

    conteudo.push([
      { text: 'TOTAL...', bold: true, fontSize: 9 },
      { text: this.funcaoService.convertToBrNumber(totalReceitas), bold: true, fontSize: 9, alignment: 'right' },
      { text: 'TOTAL...', bold: true, fontSize: 9 },
      { text: this.funcaoService.convertToBrNumber(totalDespesas), bold: true, fontSize: 9, alignment: 'right' }
    ]);

    const detalhesReceitas = [];
    let totalGeralReceitas = 0;
    for (const item of detalhamentoReceitas.flat()) {
      totalGeralReceitas += +item.valor;
      detalhesReceitas.push([
        { text: item.titulo, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(item.valor), alignment: 'right', border: [true, false, true, false] },
      ]);
    }

    const detalhesDespesas = [];
    let totalGeralDespesas = 0;
    for (const item of detalhamentoDespesas.flat()) {
      totalGeralDespesas += +item.valor;
      detalhesDespesas.push([
        { text: item.titulo, border: [true, false, true, false] },
        { text: this.funcaoService.convertToBrNumber(item.valor), alignment: 'right', border: [true, false, true, false] },
      ]);
    }

    const dadosDetalhes = this.concatenarArraysDiarioCaixa(detalhesReceitas, detalhesDespesas);
    conteudo.push(...dadosDetalhes);
    conteudo.push([
      { text: 'VALOR GERAL DA RECEITA', bold: true, fontSize: 9 },
      { text: this.funcaoService.convertToBrNumber(totalGeralReceitas), bold: true, fontSize: 9, alignment: 'right' },
      { text: 'VALOR GERAL DA DESPESA', bold: true, fontSize: 9 },
      { text: this.funcaoService.convertToBrNumber(totalGeralDespesas), bold: true, fontSize: 9, alignment: 'right' }
    ]);

    this.assinatura_tesouraria = (await this.parametroContabilidadeServico.obter({ orgao_id: this.login.orgao.id }).toPromise()).assinatura_tesouraria;

    const ass = new Assinaturas(this.login.orgao, this.injector);
    const assinaturas = await ass.dadosAssinatura(undefined, true, null, false, false, this.assinatura_tesouraria);

    return this.assinatura_tesouraria ? [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', 'auto', '*', 'auto'],
        body: conteudo
      }
    }, {
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }] : [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', 'auto', '*', 'auto'],
        body: conteudo
      }
    }
    ]
  }

  private concatenarArraysDiarioCaixa(receitas: any[], despesas: any[]) {
    const retorno = [];
    const vazio = { text: '', border: [true, false, true, false] }
    const length = receitas.length > despesas.length ? receitas.length : despesas.length;
    for (let i = 0; i < length; i++) {

      if (i < receitas.length && i < despesas.length) {
        retorno.push([
          ...receitas[i],
          ...despesas[i]
        ]);
        continue;
      }

      if (i < receitas.length && i >= despesas.length) {
        retorno.push([
          ...receitas[i],
          vazio, vazio
        ]);
        continue;
      }

      if (i >= receitas.length && i < despesas.length) {
        retorno.push([
          vazio, vazio,
          ...despesas[i],
        ]);
        continue;
      }
    }
    return retorno;
  }

  sair() {
    this.funcaoService.navegarPara(this.login.usuario.sistema, this.router);
  }

  public voltar() {
    switch (this.login.sistema) {
      case 'contabil':
        this.router.navigate(['/tesouraria']);
        break;
      default:
        this.sair();
        break;
    }
  }

  public trocarRelatorio() {
    setTimeout(() => {
      this.globalService.calendarMascara();
      if (this.relatoriosSelect === 'CONCILIACAO_BANCARIA') {
        this.opcao = 'opt1'
      }
    }, 100);
  }

  public tituloImprimir() {
    if (this.relatoriosSelect === 'BOLETIM_BANCO_RECURSO') {
      if (this.formato === 'xlsx')
        return 'Gerar xlsx';
      else if (this.formato === 'csv')
        return 'Gerar csv';
    }
    return 'Gerar pdf';
  }

  public iconImprimir() {
    if (this.relatoriosSelect === 'BOLETIM_BANCO_RECURSO') {
      if (this.formato === 'xlsx')
        return 'assets/img/global/xls.png';
      else if (this.formato === 'csv')
        return 'assets/img/global/csv.png';
    }
    return 'assets/img/global/file_pdf.png';
  }

  private layout(): {} {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }

  private imprimirCSV(conteudo: {}[][], titulo: string) {
    let csv = '';
    for (let i = 0; i < conteudo.length; i++) {
      const linha = conteudo[i];
      if (i > 0) csv += '\n';
      for (let x = 0; x < linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }

    conteudo = null;
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", titulo);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}

type Lista = {
  emo?: any[],
  eme?: EmpenhoExtra[],
  emr?: any[]
};