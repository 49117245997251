import { DatePipe } from '@angular/common';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContaBancariaService, RecursoService } from 'administrativo-lib';
import {
  BaseResourceFormComponent, ContaBancaria, DateFormatPipe, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil,
  Orgao, OrgaoService, Recurso, TransferenciaAutorizacao, TransferenciaEspecie
} from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { ContaBancariaSaldoService } from '../../conta-bancaria/service/conta-bancaria-saldo.service';
import { TransferenciaDescendialService } from '../../transferencia-bancaria/service/transferencia-descendial.service';
import { TransferenciaEspecieService } from '../../transferencia-especie/service/transferencia-especie.service';
import { TransferenciaAutorizacaoService } from '../service/transferencia-autorizacao.service';
import * as toastr from 'toastr';

declare var $: any;
@Component({
  selector: 'app-transferencia-autorizacao-form',
  templateUrl: './transferencia-autorizacao-form.component.html'
})
export class TransferenciaAutorizacaoFormComponent extends BaseResourceFormComponent<TransferenciaAutorizacao, LoginContabil> {
  /**
   * Declaração de variáveis
   */
  @ViewChild('especie_') inputField: ElementRef;

  public orgaoPagadora: Orgao;
  public orgaoRecebedora: Orgao;
  public pagadoraAutoComplete: EddyAutoComplete<ContaBancaria>;
  public recebedoraAutoComplete: EddyAutoComplete<ContaBancaria>;
  public listaEspecies: Array<any>;
  public listaFinalidades: Array<any>;
  public listaOrgaos: Orgao[];
  public saldoPagadora = 0;
  public saldoRecebedora = 0;
  public totalDescendio = 0;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public convenioAutoComplete: EddyAutoComplete<Recurso>;
  public recursoRecebedoraAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoRecebedoraAutoComplete: EddyAutoComplete<Recurso>;
  public convenioRecebedoraAutoComplete: EddyAutoComplete<Recurso>;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private messageService: MessageService,
    protected injector: Injector,
    protected globalService: GlobalService,
    protected especieService: TransferenciaEspecieService,
    protected recursoService: RecursoService,
    protected funcaoService: FuncaoService,
    protected descendioService: TransferenciaDescendialService,
    protected contaService: ContaBancariaService,
    protected contaSaldoService: ContaBancariaSaldoService,
    protected orgaoService: OrgaoService,
    protected transferenciaService: TransferenciaAutorizacaoService) {
    super(new TransferenciaAutorizacao(), injector, TransferenciaAutorizacao.converteJson, transferenciaService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(entidade: TransferenciaAutorizacao): Promise<boolean> {
    const sistemasAdmin = this.login.usuario.sistemas_administrador.split(',')

    // Se for admin e estiver dentro dos prazos, pode editar
    if (sistemasAdmin.includes('contabil') && this.podeAlterarAudesp(entidade.data_transfere)) return true
    // Se não for admin e não tiver dentro do prazo, não pode editar
    else if (!this.podeAlterarAudesp(entidade.data_transfere)) return false
    // Se não for admim, estiver dentro do prazo, mas já foi exportado ou baixado, não pode editar
    if (entidade.exportado_febraban || entidade.exportado_obn) return false
    // Se não é admin, está no prazo e não foi exportado ou baixado, pode editar
    else return true
  }
  
  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_transfere: [null, [Validators.required]],
      data_referencia: [null],
      valor_transferido: null,
      mes: [null],
      finalidade: [0],
      transferencia_automatica: [false],
      exportado_febraban: [false],
      exportado_obn: [false],
      aux: [0],
      especie: [null, [Validators.required]],
      documento: ['TRANSFERÊNCIA', [Validators.required]],
      historico: ['TRANSFERÊNCIA BANCÁRIA', [Validators.required, Validators.minLength(2)]],
      tipo: [1, [Validators.required]],
      recurso_pagadora: [null],
      aplicacao_pagadora: [null],
      convenio_pagadora: [null],
      recurso_recebedora: [null],
      aplicacao_recebedora: [null],
      convenio_recebedora: [null],
      exercicio: [this.login.exercicio, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      conta_pagadora: [null, [Validators.required]],
      conta_recebedora: [null, [Validators.required]],
      descendial: [null],
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'conta_pagadora,conta_pagadora.banco,especie,' +
        'conta_pagadora.orgao,conta_recebedora,conta_recebedora.orgao,conta_recebedora.banco,recurso_pagadora,convenio_pagadora,aplicacao_pagadora' +
        ',recurso_recebedora,convenio_recebedora,aplicacao_recebedora'
    };
  }

  protected afterLoad() {
    if (!this.podeAlterarAudesp(this.entidade.data_transfere)) {
      this.router.navigate(['/transferencias-autorizacao']);
      toastr.warning('Não é possível alterar. Prazo esgotado!');
      return;
    }

    this.entidade.data_transfere = new DateFormatPipe().transform(this.entidade.data_transfere, []);
    this.entidade.data_referencia = new DateFormatPipe().transform(this.entidade.data_referencia, []);

    this.recebedoraAutoComplete.id = this.entidade.conta_recebedora ? this.entidade.conta_recebedora.codigo : null;
    this.pagadoraAutoComplete.id = this.entidade.conta_pagadora ? this.entidade.conta_pagadora.codigo : null;

    this.orgaoPagadora = this.entidade.conta_pagadora.orgao;
    this.orgaoRecebedora = this.entidade.conta_recebedora.orgao;

    this.recursoAutoComplete.id = this.entidade.recurso_pagadora ? this.entidade.recurso_pagadora.id : null;
    this.convenioAutoComplete.id = this.entidade.convenio_pagadora ? this.entidade.convenio_pagadora.id : null;
    this.aplicacaoAutoComplete.id = this.entidade.aplicacao_pagadora ? this.entidade.aplicacao_pagadora.id : null;

    this.recursoRecebedoraAutoComplete.id = this.entidade.recurso_recebedora ? this.entidade.recurso_recebedora.id : null;
    this.convenioRecebedoraAutoComplete.id = this.entidade.convenio_recebedora ? this.entidade.convenio_recebedora.id : null;
    this.aplicacaoRecebedoraAutoComplete.id = this.entidade.aplicacao_recebedora ? this.entidade.aplicacao_recebedora.id : null;

    this.buscarSaldoConta('P');
    this.buscarSaldoConta('R');

    this.descendioService.filtrar(0, -1, { autorizacao_id: this.entidade.id })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        for (const item of res.content) {
          this.totalDescendio += +item.valor_descendio;
        }
        this.buscarSaldoConta('P');
        this.buscarSaldoConta('R');
      });
  }

  protected afterInit(): void {
    this.orgaoPagadora = this.login.orgao;
    this.orgaoRecebedora = this.login.orgao;
    this.carregarPagadora();
    this.carregarRecebedora();
    this.orgaoService.obterTodos()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dados => {
        this.listaOrgaos = dados;
      });

    this.listaFinalidades = [
      { id: 50, nome: '50 - TRANSFERÊNCIAS TRIBUTOS RETIDOS' },
      { id: 51, nome: '51 - TRANSFERÊNCIAS MUNICÍPIOS SEM GESTÃO PLENA Saúde' },
      { id: 52, nome: '52 - FOPAG (FUNDEB e FNS)' },
      { id: 53, nome: '53 - PAGAMENTO A PRESTADORES PÚBLICOS DE SAÚDE' },
      { id: 54, nome: '54 - PAGAMENTO A PESQUISAS DE SAÚDE' },
      { id: 55, nome: '55 - RESSARCIMENTO POR ESCOLA MUNICIPALIZADA' },
      { id: 56, nome: '56 - RETIFICAÇÃO DE ARRECADAÇÃO' },
      { id: 57, nome: '57 - TRANSFERÊNCIA PARA TRANSPORTE ESCOLAR MUNICIPAL' },
      { id: 0, nome: '00 - OUTROS' }
    ];

    if (this.currentActionRoute === 'novo') {
      this.transferenciaService.ultimaDataTransferencia(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res === 0) {
            this.entidade.data_transfere = new Date();
          } else {
            this.entidade.data_transfere = new DateFormatPipe().transform(res, []);
          }
          this.entidadeForm.get('data_transfere').setValue(this.entidade.data_transfere);
        });
    }
    this.especieService.filtrar(0, -1, { ativo: true, orderBy: 'nome' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaEspecies = res.content;
        if (this.currentActionRoute === 'novo') {
          for (const especie of this.listaEspecies as TransferenciaEspecie[]) {
            if (especie.nome === 'NORMAL') {
              this.entidadeForm.get('especie').setValue(especie);
            }
          }
        }
      });
    this.carregarAutoCompletes();
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      const dataTransfere = new DateFormatPipe().transform(this.entidadeForm.get('data_transfere').value, []);
      this.entidadeForm.get('mes').setValue(+this.funcaoService.converteDataSQL(dataTransfere)?.split('-')?.[1]);
      this.entidadeForm.get('aux').setValue(0);
      if (!this.entidadeForm.get('especie').value) {
        throw new Error('Preencha a espécie!');
      }
      if (!this.entidadeForm.get('valor_transferido').value) {
        throw new Error('Informe o valor da transferência');
      }
      if (this.entidadeForm.get('conta_pagadora').value['id'] === this.entidadeForm.get('conta_recebedora').value['id']) {
        throw new Error('A conta recebedora e pagadora são as mesma, selecione outra conta!');
      } else if (this.entidadeForm.get('especie').value['nome'] === 'EDUCAÇÃO' && !this.entidadeForm.get('descendial').value) {
        $('#dialogDescendial').modal('show');
        throw new Error('É nessário informar as transferências descendiais, clique no botão "Transferência descendial"');
      }
      if (+this.entidadeForm.get('valor_transferido').value <= 0) {
        throw new Error(`O valor da transferência deve ser maior que zero!`);
      }
      if ((+this.entidadeForm.get('valor_transferido').value) > (+this.saldoPagadora.toFixed(2)) && this.entidadeForm.get('conta_pagadora').value['tipo_conta'] !== 'O') {
        throw new Error(`Não possui saldo na conta pagodora! \n Saldo Atual: R$ ${this.funcaoService.convertToBrNumber((+this.saldoPagadora.toFixed(2)))}`);
      }

      if (!this.funcaoService.podeAlterarAudesp(this.entidadeForm.get('data_transfere').value, this.login)) {
        throw new Error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
      }

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(ent: TransferenciaAutorizacao): void {
    if (this.currentActionRoute === 'novo') {
      this.router.navigate(['/transferencias-autorizacao', ent.id, 'editar']);
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public mudarData(valor: any) {
    if (this.currentActionRoute === 'editar') {
      valor = valor.slice(3, 5) + '/' + valor.slice(0, 2) + '/' + valor.slice(6);
      const data_atual = new Date(this.entidade.data_transfere);
      const data_alterada = new Date(valor);
      const diferenca_dias = (data_atual.getTime() - data_alterada.getTime()) / (1000 * 60 * 60 * 24);
      if (diferenca_dias > this.login.dias_bloquear_alteracoes) {
        alert('Data inserida passa do prazo de dias definido!');
        this.entidadeForm.get('data_transfere').setValue(data_atual);
        this.entidadeForm.get('data_transfere').disable();
      } else if (diferenca_dias) {
        this.entidadeForm.get('data_transfere').setValue(data_alterada);
      }
    }
  }

  public carregarDescendio(event: any) {
    this.entidadeForm.get('descendial').setValue(event);
    this.totalDescendio = 0;
    for (const item of event) {
      if (item.selecionado) {
        this.totalDescendio += +item.valor_descendio;
      }
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para convenio
    this.convenioAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para recurso
    this.recursoRecebedoraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para aplicacao
    this.aplicacaoRecebedoraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );

    // autocomplete para convenio
    this.convenioRecebedoraAutoComplete = new EddyAutoComplete(null, this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'codigo,nome' }, { text: ['codigo', 'nome'] }
    );
  }

  public carregarPagadora() {
    this.entidadeForm.get('conta_pagadora').setValue(null);
    this.pagadoraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_pagadora'), this.contaService,
      'codigo', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.orgaoPagadora.id, orderBy: 'nome', ativo: true, relations: 'orgao,banco' },
      { number: ['numero_conta', 'codigo'], text: ['nome'] },
      () => {
        this.buscarSaldoConta('P');
      }
    );
  }

  public carregarRecebedora() {
    this.entidadeForm.get('conta_recebedora').setValue(null);
    this.recebedoraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('conta_recebedora'), this.contaService,
      'codigo', ['numero_conta', 'banco.nome', 'nome'], { orgao_id: this.orgaoRecebedora.id, orderBy: 'nome', ativo: true, relations: 'orgao,banco' },
      { number: ['numero_conta', 'codigo'], text: ['nome'] },
      () => {
        this.buscarSaldoConta('R');
      }
    );
  }

  private buscarSaldoConta(tipo: 'P' | 'R') {
    const contaId = Number(tipo === 'P' ? this.entidadeForm.get('conta_pagadora')?.value?.id : this.entidadeForm.get('conta_recebedora')?.value?.id);
    const orgaoId = Number(tipo === 'P' ? this.orgaoPagadora.id : this.orgaoRecebedora.id);
    if (contaId) {
      let datepipe: DatePipe = new DatePipe('pt');
      const dataTransfere = this.entidadeForm.get('data_transfere').value ? datepipe.transform(this.entidadeForm.get('data_transfere').value, 'yyyy-MM-dd', 'GMT') :
        datepipe.transform(this.entidade.data_transfere, 'yyyy-MM-dd', 'GMT');
      this.contaService.obterSaldoBancarioConta(contaId, this.login.exercicio.id, orgaoId, dataTransfere)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (tipo === 'P') {
            this.saldoPagadora = res;
          } else {
            this.saldoRecebedora = res;
          }
        });
    }
  }

  public voltar() {
    if (this.login.sistema === 'contabil') {
      this.router.navigate(['/tesouraria']);
    } else {
      this.sair();
    }
  }
}
