import { Injectable, Injector } from '@angular/core';
import {
  Exercicio,
  ExercicioService,
  FormatoExportacao,
  FuncaoService, GlobalService, Login, OrgaoAssinaturaService, OrgaoService,
  Relatorio
} from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FichaReceitaService } from '../../../../planejamento/ficha-receita/service/ficha-receita.service';
import { Assinaturas } from '../../assinaturas/assinaturas';

@Injectable({
  providedIn: 'root'
})
export class BalanceteReceitaService {

  protected unsubscribe: Subject<void> = new Subject();
  public login: Login = new Login();

  constructor(
    protected frService: FichaReceitaService,
    protected exercicioService: ExercicioService,
    protected orgaoService: OrgaoService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected injector: Injector,
  ) { this.login = GlobalService.obterSessaoLogin(); }
  /* -------------------------- balancete da receita -------------------------*/

  async montarBalanceteReceita(formato: FormatoExportacao, mes: number, exercicioId: number, orgaos: number[], login: any, periodoInicial: string, periodoFinal: string, sintetico?: boolean, recurso?: number, aplicacao?: number, variavel?: number, agruparFr?: boolean) {
    const parametros: {} = {};
    if (mes) {
      parametros['mes'] = mes;
    } else {
      parametros['data_inicio'] = periodoInicial;
      parametros['data_limite'] = periodoFinal;
    }
    if (exercicioId) {
      parametros['ano'] = exercicioId;
    }
    parametros['orgaos'] = orgaos.join();
    parametros['sintetico'] = sintetico ? 'S' : agruparFr ? 'A' : ''
    if (recurso) {
      parametros['recurso'] = recurso;
    }
    if (aplicacao) {
      parametros['aplicacao'] = aplicacao;
    }
    if (variavel) {
      parametros['variavel'] = variavel;
    }
    const ex = await this.exercicioService.obterId(exercicioId).toPromise();
    this.frService.balancete(parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async dados => {
        let subtitulo = '';
        if (mes) {
          subtitulo = this.globalService.obterDataBR().monthNames[mes - 1].toLocaleUpperCase() + '/' + ex.ano
        } else {
          let dt = periodoInicial.split("-");
          subtitulo = dt[2] + '/' + dt[1] + '/' + dt[0] + ' à ';
          dt = periodoFinal.split("-");
          subtitulo += dt[2] + '/' + dt[1] + '/' + dt[0];
        }

        if (formato === 'pdf') {
          Relatorio.imprimirPersonalizado('DEMONSTRAÇÃO DA RECEITA ORÇAMENTÁRIA SEGUNDO A CATEGORIA ECONÔMICA',
            login.usuario.nome, login.usuario.sobrenome, login.orgao.nome, login.brasao,
            await this.balanceteReceitaConteudo(dados, sintetico, agruparFr, ex, mes, periodoFinal),
            'landscape', 'Balancete Analítico da Receita',
            {
              linhas: {
                hLineWidth() {
                  return 1;
                },
                vLineWidth() {
                  return 1;
                },
                hLineColor() {
                  return 'black';
                },
                paddingLeft() {
                  return 3;
                },
                paddingRight() {
                  return 3;
                }
              }
            }, false, false, 'pdf', `REFERÊNCIA: ${subtitulo}`);
        } else if (formato === 'csv') {
          this.balanceteReceitaCSV(dados, sintetico);
        }
      });
  }

  private async balanceteReceitaConteudo(dados: any[], sintetico: boolean, agrupadoFr: boolean, exercicio?: Exercicio, mes?: number, dataFinal?: string): Promise<{}[]> {
    let conteudo: {}[] = []

    if (sintetico) conteudo.push(...await this.conteudoSintetico(dados))
    else if (agrupadoFr) conteudo.push(...await this.conteudoAgrupadoFR(dados))
    else conteudo.push(...await this.conteudoNormal(dados))

    conteudo.push(...await this.montarAssinatura(exercicio, mes, dataFinal));

    return conteudo
  }

  private async conteudoNormal(dados: any[]) {
    const registros: {}[] = [
      [
        { text: '\nFICHA', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
        { text: '\nRECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
        { text: '\nESPECIFICAÇÕES', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
        { text: 'MOVIMENTO DA RECEITA', alignment: 'center', colSpan: 3, bold: true, fontSize: 8 },
        '', '',
        { text: 'CÁLCULOS', alignment: 'center', colSpan: 3, bold: true, fontSize: 8 },
        '', '',
      ],
      ['', '', '',
        { text: 'ANTERIOR', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'ACUMULADA', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'PREVISÃO', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'AJUSTE', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'DIFERENÇA', alignment: 'center', bold: true, fontSize: 8 }
      ]
    ];

    const categoria = {};
    let totalAnterior = 0.00;
    let totalMes = 0.00;
    let totalArrecadado = 0.00;
    let totalPrevisao = 0.00;
    let totalAjuste = 0.00;
    let totalDiferenca = 0.00;
    const grupos = this.funcaoService.agrupar(dados, ['o_codigo', 'o_nome'], ['arrecadado']);

    for (let grupo of grupos) {
      registros.push([
        { text: `${grupo.grupo['o_codigo']} ${grupo.grupo['o_nome']}`, alignment: 'left', fontSize: 10, bold: true, colSpan: 9 },
        '', '', '', '', '', '', '', ''
      ]);

      for (const registro of grupo.registros) {
        if (!categoria['nome'] || categoria['nome'] !== registro['categoria']) {
          if (categoria['nome']) {
            registros.push(this.retornarLinhaSomaCategoria(categoria));
          }
          categoria['ficha'] = registro['fh_numero'];
          categoria['codigo'] = registro['r_codigo'];
          categoria['recurso'] = `${registro['re_codigo']} ${registro['a_codigo']}`;
          categoria['nome'] = registro['categoria'];
          categoria['anterior'] = 0.00;
          categoria['mes'] = 0.00;
          categoria['arrecadado'] = 0.00;
          categoria['previsao'] = 0.00;
          categoria['ajuste'] = 0.00;
          categoria['diferenca'] = 0.00;
          registros.push([
            {
              text: `${registro['categoria_codigo']} ${categoria['nome']}`, colSpan: 9,
              bold: true, fontSize: 9
            }, '', '', '', '', '', '', '', ''
          ]);
        }
        const vlAnterior: number = registro['arrecadado_anterior'];
        const vlMes: number = registro['arrecadado_mes'];
        const vlArrecadado: number = registro['arrecadado'];
        const vlPrevisao: number = registro['fh_valor_orcado'] ? registro['fh_valor_orcado'] : 0.00;
        const vlAjuste: number = registro['ajuste'];
        const vlDiferenca: number = +vlPrevisao + +vlAjuste - +vlArrecadado;
        categoria['anterior'] += +vlAnterior;
        categoria['mes'] += +vlMes;
        categoria['arrecadado'] += +vlArrecadado;
        categoria['previsao'] += +vlPrevisao;
        categoria['ajuste'] += +vlAjuste;
        categoria['diferenca'] += +vlDiferenca;
        totalAnterior += +vlAnterior;
        totalMes += +vlMes;
        totalArrecadado += +vlArrecadado;
        totalAjuste += +vlAjuste;
        totalPrevisao += +vlPrevisao;
        totalDiferenca += +vlDiferenca;

        registros.push([
          {
            text: this.funcaoService.strZero(registro['fh_numero'], 3),
            border: [true, false, true, false], alignment: 'center', fontSize: 7
          },
          {
            text: `${registro['re_codigo']} ${registro['a_codigo']}`,
            border: [true, false, true, false], alignment: 'center', fontSize: 7
          },
          {
            text: this.funcaoService.mascarar('####.##.##.###', registro['r_codigo']) + ' ' + registro['r_nome'],
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(vlAnterior), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlArrecadado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlPrevisao), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlAjuste), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlDiferenca * -1), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }
      registros.push(this.retornarLinhaSomaCategoria(categoria));

      registros.push([
        {
          text: 'TOTAL DO ÓRGÃO:', colSpan: 3,
          bold: true, fontSize: 7
        }, '', '',
        { text: this.funcaoService.convertToBrNumber(totalAnterior), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalMes), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalArrecadado), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalPrevisao), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalAjuste), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1), bold: true, alignment: 'right', fontSize: 7 },
      ]);

      if (categoria['nome']) {
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 7
          }, '', '',
          { text: this.funcaoService.convertToBrNumber(totalAnterior), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalArrecadado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPrevisao), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalAjuste), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', '*', 60, 60, 60, 60, 60, 60],
        body: registros
      }
    }];
  }

  private async conteudoSintetico(dados: any[]) {
    const registros: {}[] = [
      [
        { text: '\nRECURSO', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8, colSpan: 2 }, '',
        { text: '\nESPECIFICAÇÕES', alignment: 'center', rowSpan: 2, bold: true, fontSize: 8 },
        { text: 'MOVIMENTO DA RECEITA', alignment: 'center', colSpan: 3, bold: true, fontSize: 8 },
        '', '',
        { text: 'CÁLCULOS', alignment: 'center', colSpan: 3, bold: true, fontSize: 8 },
        '', '',
      ],
      ['', '', '',
        { text: 'ANTERIOR', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'ACUMULADA', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'PREVISÃO', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'AJUSTE', alignment: 'center', bold: true, fontSize: 8 },
        { text: 'DIFERENÇA', alignment: 'center', bold: true, fontSize: 8 }
      ]
    ];

    const categoria = {};
    let totalAnterior = 0.00;
    let totalMes = 0.00;
    let totalArrecadado = 0.00;
    let totalPrevisao = 0.00;
    let totalAjuste = 0.00;
    let totalDiferenca = 0.00;
    const grupos = this.funcaoService.agrupar(dados, ['o_codigo', 'o_nome'], ['arrecadado']);

    for (let grupo of grupos) {
      registros.push([
        { text: `${grupo.grupo['o_codigo']} ${grupo.grupo['o_nome']}`, alignment: 'left', fontSize: 10, bold: true, colSpan: 9 },
        '', '', '', '', '', '', '', ''
      ]);

      const sintetico = this.funcaoService.agrupar(dados, ['re_codigo', 'a_codigo', 'r_n1', 'r_n2', 'r_n3', 'r_n4', 'r_n5', 'av_variavel'], ['arrecadado_anterior', 'arrecadado_mes', 'arrecadado', 'fh_valor_orcado', 'recebimento_acumulado', 'valor_anulado', 'ajuste']);

      for (const registro of sintetico) {
        if (!categoria['nome'] || categoria['nome'] !== registro.registros[0]['categoria']) {
          if (categoria['nome']) {
            registros.push(this.retornarLinhaSomaCategoria(categoria));
          }
          categoria['ficha'] = registro.registros[0]['fh_numero'];
          categoria['codigo'] = registro.registros[0]['r_codigo'];
          categoria['recurso'] = `${registro.grupo['re_codigo']} ${registro.grupo['a_codigo']}`;
          categoria['nome'] = registro.registros[0]['categoria'];
          categoria['anterior'] = 0.00;
          categoria['mes'] = 0.00;
          categoria['arrecadado'] = 0.00;
          categoria['previsao'] = 0.00;
          categoria['ajuste'] = 0.00;
          categoria['diferenca'] = 0.00;
          registros.push([
            {
              text: `${registro.registros[0]['categoria_codigo']} ${categoria['nome']}`, colSpan: 9,
              bold: true, fontSize: 9
            }, '', '', '', '', '', '', '', ''
          ]);
        }
        const vlAnterior: number = registro.totalizadores['arrecadado_anterior'];
        const vlMes: number = registro.totalizadores['arrecadado_mes'];
        const vlArrecadado: number = registro.totalizadores['arrecadado'];
        const vlPrevisao: number = registro.totalizadores['fh_valor_orcado'] ? registro.totalizadores['fh_valor_orcado'] : 0.00;
        const vlAjuste: number = registro.totalizadores['ajuste'];
        const vlDiferenca: number = +vlPrevisao + +vlAjuste - +vlArrecadado;
        categoria['anterior'] += +vlAnterior;
        categoria['mes'] += +vlMes;
        categoria['arrecadado'] += +vlArrecadado;
        categoria['previsao'] += +vlPrevisao;
        categoria['ajuste'] += +vlAjuste;
        categoria['diferenca'] += +vlDiferenca;
        totalAnterior += +vlAnterior;
        totalMes += +vlMes;
        totalArrecadado += +vlArrecadado;
        totalAjuste += +vlAjuste;
        totalPrevisao += +vlPrevisao;
        totalDiferenca += +vlDiferenca;

        registros.push([
          {
            text: `${registro.grupo['re_codigo']} ${registro.grupo['a_codigo']} ${registro.grupo['av_variavel']}`,
            border: [true, false, true, false], alignment: 'center', fontSize: 7, colSpan: 2
          }, '',
          {
            text: `${registro.grupo['r_n1']}.${registro.grupo['r_n2']}.${registro.grupo['r_n3']}.${registro.grupo['r_n4']}.${registro.grupo['r_n5']}.00.000` + ' ' + registro.registros[0]['r2_nome'],
            border: [true, false, true, false], fontSize: 7
          },
          { text: this.funcaoService.convertToBrNumber(vlAnterior), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlMes), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlArrecadado), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlPrevisao), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlAjuste), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(vlDiferenca * -1), alignment: 'right', border: [true, false, true, false], fontSize: 7 },
        ]);
      }
      registros.push(this.retornarLinhaSomaCategoria(categoria));

      registros.push([
        {
          text: 'TOTAL DO ÓRGÃO:', colSpan: 3,
          bold: true, fontSize: 7
        },
        '',
        '',
        { text: this.funcaoService.convertToBrNumber(totalAnterior), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalMes), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalArrecadado), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalPrevisao), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalAjuste), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1), bold: true, alignment: 'right', fontSize: 7 },
      ]);

      if (categoria['nome']) {
        registros.push([
          {
            text: 'TOTAL GERAL:', colSpan: 3,
            bold: true, fontSize: 7
          },
          '',
          '',
          { text: this.funcaoService.convertToBrNumber(totalAnterior), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalMes), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalArrecadado), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalPrevisao), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalAjuste), bold: true, alignment: 'right', fontSize: 7 },
          { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1), bold: true, alignment: 'right', fontSize: 7 },
        ]);
      }
    }

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['auto', 'auto', '*', 60, 60, 60, 60, 60, 60],
        body: registros
      }
    }];
  }

  private async conteudoAgrupadoFR(dados: any[]) {
    const registros: {}[] = [
      [
        { text: '\nESPECIFICAÇÕES', alignment: 'center', rowSpan: 2, bold: true, fontSize: 7, colSpan: 3 }, '', '',
        { text: 'MOVIMENTO DA RECEITA', alignment: 'center', colSpan: 3, bold: true, fontSize: 7 },
        '', '',
        { text: 'CÁLCULOS', alignment: 'center', colSpan: 3, bold: true, fontSize: 7 },
        '', '',
      ],
      ['', '', '',
        { text: 'ANTERIOR', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DO MÊS', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'ACUMULADA', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'PREVISÃO', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'AJUSTE', alignment: 'center', bold: true, fontSize: 7 },
        { text: 'DIFERENÇA', alignment: 'center', bold: true, fontSize: 7 }
      ]
    ];

    let anteriorGeral = 0
    let mesGeral = 0
    let acumuladoGeral = 0
    let previstoGeral = 0
    let ajusteGeral = 0
    let diferencaGeral = 0

    const agruparOrgaos = this.funcaoService.agrupar(dados, ['o_codigo', 'o_nome'], ['arrecadado_anterior', 'arrecadado_mes', 'arrecadado', 'fh_valor_orcado', 'ajuste']);

    for (const orgao of agruparOrgaos) {
      registros.push([
        { text: `${orgao.grupo['o_codigo']} ${orgao.grupo['o_nome']}`, alignment: 'left', fontSize: 9, bold: true, colSpan: 9 },
        '', '', '', '', '', '', '', ''
      ]);

      const agruparFonteRecursos = this.funcaoService.agrupar(orgao.registros, ['re_codigo', 're_nome'], ['arrecadado_anterior', 'arrecadado_mes', 'arrecadado', 'fh_valor_orcado', 'ajuste'])

      for (const fonteRecurso of agruparFonteRecursos) {

        registros.push([
          { text: `${fonteRecurso.grupo['re_codigo']} - ${fonteRecurso.grupo['re_nome']}`, border: [true, true, true, true], bold: true, fontSize: 7, colSpan: 9 },
          '', '', '', '', '', '', '', ''
        ]);

        const agruparReceitas = this.funcaoService.agrupar(fonteRecurso.registros, ['r_n1', 'r_n2', 'r_n3', 'r_n4', 'r_n5', 'r_n6', 'r_n7', 'receita_nome_principal'], ['arrecadado_anterior', 'arrecadado_mes', 'arrecadado', 'fh_valor_orcado', 'ajuste'])

        for (const receita of agruparReceitas) {
          let especificacao = `${receita.grupo['r_n1']}${receita.grupo['r_n2']}${receita.grupo['r_n3']}${receita.grupo['r_n4']}${receita.grupo['r_n5']}${receita.grupo['r_n6']}${receita.grupo['r_n7']}`

          const diferenca = receita.totalizadores['fh_valor_orcado'] + fonteRecurso.totalizadores['ajuste'] - fonteRecurso.totalizadores['arrecadado']

          registros.push([
            { text: `${this.funcaoService.mascarar('#.#.#.#.##.##', especificacao)} - ${receita.grupo['receita_nome_principal']}`, colSpan: 3, border: [true, true, true, true], bold: true, fontSize: 6 },
            '', '',
            { text: this.funcaoService.convertToBrNumber(receita.totalizadores['arrecadado_anterior']), alignment: 'right', border: [true, true, true, true], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(receita.totalizadores['arrecadado_mes']), alignment: 'right', border: [true, true, true, true], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(receita.totalizadores['arrecadado']), alignment: 'right', border: [true, true, true, true], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(receita.totalizadores['fh_valor_orcado']), alignment: 'right', border: [true, true, true, true], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(receita.totalizadores['ajuste']), alignment: 'right', border: [true, true, true, true], fontSize: 6 },
            { text: this.funcaoService.convertToBrNumber(diferenca * -1), alignment: 'right', border: [true, true, true, true], fontSize: 6 }
          ]);
        }

        const diferenca = fonteRecurso.totalizadores['fh_valor_orcado'] + fonteRecurso.totalizadores['ajuste'] - fonteRecurso.totalizadores['arrecadado']

        registros.push([
          { text: 'SOMA', colSpan: 3, border: [true, true, true, true], bold: true, fontSize: 8 },
          '', '',
          { text: this.funcaoService.convertToBrNumber(fonteRecurso.totalizadores['arrecadado_anterior']), alignment: 'right', border: [true, true, true, true], fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(fonteRecurso.totalizadores['arrecadado_mes']), alignment: 'right', border: [true, true, true, true], fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(fonteRecurso.totalizadores['arrecadado']), alignment: 'right', border: [true, true, true, true], fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(fonteRecurso.totalizadores['fh_valor_orcado']), alignment: 'right', border: [true, true, true, true], fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(fonteRecurso.totalizadores['ajuste']), alignment: 'right', border: [true, true, true, true], fontSize: 8 },
          { text: this.funcaoService.convertToBrNumber(diferenca * -1), alignment: 'right', border: [true, true, true, true], fontSize: 8 }
        ]);
      }

      const diferenca = +orgao.totalizadores['fh_valor_orcado'] + +orgao.totalizadores['ajuste'] - +orgao.totalizadores['arrecadado']

      registros.push([
        { text: 'TOTAL DO ÓRGÃO:', colSpan: 3, bold: true, fontSize: 7 },
        '', '',
        { text: this.funcaoService.convertToBrNumber(orgao.totalizadores['arrecadado_anterior']), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(orgao.totalizadores['arrecadado_mes']), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(orgao.totalizadores['arrecadado']), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(orgao.totalizadores['fh_valor_orcado']), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(orgao.totalizadores['ajuste']), bold: true, alignment: 'right', fontSize: 7 },
        { text: this.funcaoService.convertToBrNumber(diferenca * -1), bold: true, alignment: 'right', fontSize: 7 },
      ]);

      anteriorGeral += +orgao.totalizadores['arrecadado_anterior']
      mesGeral += +orgao.totalizadores['arrecadado_mes']
      acumuladoGeral += +orgao.totalizadores['arrecadado']
      previstoGeral += +orgao.totalizadores['fh_valor_orcado']
      ajusteGeral += +orgao.totalizadores['ajuste']
      diferencaGeral += +diferenca
    }

    registros.push([
      { text: 'TOTAL GERAL:', colSpan: 3, bold: true, fontSize: 7 },
      '', '',
      { text: this.funcaoService.convertToBrNumber(anteriorGeral), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(mesGeral), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(acumuladoGeral), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(previstoGeral), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(ajusteGeral), bold: true, alignment: 'right', fontSize: 7 },
      { text: this.funcaoService.convertToBrNumber(diferencaGeral * -1), bold: true, alignment: 'right', fontSize: 7 },
    ]);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: ['*', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: registros
      }
    }];
  }

  private async montarAssinatura(exercicio: Exercicio, mes: number, dataFinal: string) {
    const data = dataFinal ?? `${exercicio.ano}-${this.funcaoService.strZero(mes, 2)}-${this.funcaoService.ultimoDiaMes(+mes, exercicio.ano)}`;
    const ass = new Assinaturas(this.login.orgao, this.injector);
    let assinaturas = await ass.dadosAssinatura(15, null, {
      assinatura_esquerda: 'ordenador_despesa',
      cargo_esquerda: 'cargo_ordenador_despesa',
      assinatura_centro: 'contador',
      cargo_centro: 'cargo_contador',
      assinatura_direita: 'tesoureiro',
      cargo_direita: 'cargo_tesoureiro'
    }, null, null, null, data, true);

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*', '*', '*'],
        body: assinaturas
      }
    }];
  }

  balanceteReceitaCSV(dados: any[], sintetico) {
    let registros: {}[][] = [];

    if (sintetico) {
      registros.push([
        { text: 'RECURSO' },
        { text: 'ESPECIFICAÇÕES' },
        { text: 'RECEITA ANTERIOR' },
        { text: 'RECEITA DO MÊS' },
        { text: 'RECEITA ACUMULADA' },
        { text: 'PREVISÃO' },
        { text: 'AJUSTE' },
        { text: 'DIFERENÇA' },
      ]);
    } else {
      registros.push([
        { text: 'FICHA' },
        { text: 'RECURSO' },
        { text: 'ESPECIFICAÇÕES' },
        { text: 'RECEITA ANTERIOR' },
        { text: 'RECEITA DO MÊS' },
        { text: 'RECEITA ACUMULADA' },
        { text: 'PREVISÃO' },
        { text: 'AJUSTE' },
        { text: 'DIFERENÇA' },
      ]);
    }

    const categoria = {};
    let totalAnterior = 0.00;
    let totalMes = 0.00;
    let totalArrecadado = 0.00;
    let totalPrevisao = 0.00;
    let totalAjuste = 0.00;
    let totalDiferenca = 0.00;
    const grupos = this.funcaoService.agrupar(dados, ['o_codigo', 'o_nome'], ['arrecadado']);
    for (let grupo of grupos) {
      registros.push([{ text: `${grupo.grupo['o_codigo']} ${grupo.grupo['o_nome']}` }]);

      if (!sintetico) {
        for (const registro of grupo.registros) {
          if (!categoria['nome'] || categoria['nome'] !== registro['categoria']) {
            if (categoria['nome']) {
              registros.push([
                { text: 'SOMA' }, { text: '' }, { text: '' },
                { text: this.funcaoService.convertToBrNumber(categoria['anterior']) },
                { text: this.funcaoService.convertToBrNumber(categoria['mes']) },
                { text: this.funcaoService.convertToBrNumber(categoria['arrecadado']) },
                { text: this.funcaoService.convertToBrNumber(categoria['previsao']) },
                { text: this.funcaoService.convertToBrNumber(categoria['ajuste']) },
                { text: this.funcaoService.convertToBrNumber(categoria['diferenca'] * -1) },
              ]);
            }
            categoria['ficha'] = registro['fh_numero'];
            categoria['codigo'] = registro['r_codigo'];
            categoria['recurso'] = `${registro['re_codigo']} ${registro['a_codigo']}`;
            categoria['nome'] = registro['categoria'];
            categoria['anterior'] = 0.00;
            categoria['mes'] = 0.00;
            categoria['arrecadado'] = 0.00;
            categoria['previsao'] = 0.00;
            categoria['ajuste'] = 0.00;
            categoria['diferenca'] = 0.00;
            registros.push([{ text: `${registro['categoria_codigo']} ${categoria['nome']}` }]);
          }
          const vlAnterior: number = registro['arrecadado_anterior'];
          const vlMes: number = registro['arrecadado_mes'];
          const vlArrecadado: number = registro['arrecadado'];
          const vlPrevisao: number = registro['fh_valor_orcado'] ? registro['fh_valor_orcado'] : 0.00;
          const vlAjuste: number = registro['ajuste'];
          const vlDiferenca: number = +vlPrevisao + +vlAjuste - +vlArrecadado;
          categoria['anterior'] += +vlAnterior;
          categoria['mes'] += +vlMes;
          categoria['arrecadado'] += +vlArrecadado;
          categoria['previsao'] += +vlPrevisao;
          categoria['ajuste'] += +vlAjuste;
          categoria['diferenca'] += +vlDiferenca;
          totalAnterior += +vlAnterior;
          totalMes += +vlMes;
          totalArrecadado += +vlArrecadado;
          totalAjuste += +vlAjuste;
          totalPrevisao += +vlPrevisao;
          totalDiferenca += +vlDiferenca;

          registros.push([
            { text: this.funcaoService.strZero(registro['fh_numero'], 3) },
            { text: `${registro['re_codigo']} ${registro['a_codigo']}` },
            { text: this.funcaoService.mascarar('####.##.##.###', registro['r_codigo']) + ' ' + registro['r_nome']?.trim() },
            { text: this.funcaoService.convertToBrNumber(vlAnterior) },
            { text: this.funcaoService.convertToBrNumber(vlMes) },
            { text: this.funcaoService.convertToBrNumber(vlArrecadado) },
            { text: this.funcaoService.convertToBrNumber(vlPrevisao) },
            { text: this.funcaoService.convertToBrNumber(vlAjuste) },
            { text: this.funcaoService.convertToBrNumber(vlDiferenca * -1) },
          ]);
        }

        registros.push([
          { text: 'SOMA' }, { text: '' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(categoria['anterior']) },
          { text: this.funcaoService.convertToBrNumber(categoria['mes']) },
          { text: this.funcaoService.convertToBrNumber(categoria['arrecadado']) },
          { text: this.funcaoService.convertToBrNumber(categoria['previsao']) },
          { text: this.funcaoService.convertToBrNumber(categoria['ajuste']) },
          { text: this.funcaoService.convertToBrNumber(categoria['diferenca'] * -1) },
        ]);

        registros.push([
          { text: 'TOTAL DO ÓRGÃO:' }, { text: '' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalAnterior) },
          { text: this.funcaoService.convertToBrNumber(totalMes) },
          { text: this.funcaoService.convertToBrNumber(totalArrecadado) },
          { text: this.funcaoService.convertToBrNumber(totalPrevisao) },
          { text: this.funcaoService.convertToBrNumber(totalAjuste) },
          { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1) },
        ]);

        if (categoria['nome']) {
          registros.push([
            { text: 'TOTAL GERAL:' }, { text: '' }, { text: '' },
            { text: this.funcaoService.convertToBrNumber(totalAnterior) },
            { text: this.funcaoService.convertToBrNumber(totalMes) },
            { text: this.funcaoService.convertToBrNumber(totalArrecadado) },
            { text: this.funcaoService.convertToBrNumber(totalPrevisao) },
            { text: this.funcaoService.convertToBrNumber(totalAjuste) },
            { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1) },
          ]);
        }
      } else {
        const sintetico = this.funcaoService.agrupar(dados, ['re_codigo', 'a_codigo', 'r_n1', 'r_n2', 'r_n3', 'r_n4', 'r_n5', 'av_variavel'], ['arrecadado_anterior', 'arrecadado_mes', 'arrecadado', 'fh_valor_orcado', 'recebimento_acumulado', 'valor_anulado', 'ajuste']);

        for (const registro of sintetico) {
          if (!categoria['nome'] || categoria['nome'] !== registro.registros[0]['categoria']) {
            if (categoria['nome']) {
              registros.push([
                { text: 'SOMA' }, { text: '' },
                { text: this.funcaoService.convertToBrNumber(categoria['anterior']) },
                { text: this.funcaoService.convertToBrNumber(categoria['mes']) },
                { text: this.funcaoService.convertToBrNumber(categoria['arrecadado']) },
                { text: this.funcaoService.convertToBrNumber(categoria['previsao']) },
                { text: this.funcaoService.convertToBrNumber(categoria['ajuste']) },
                { text: this.funcaoService.convertToBrNumber(categoria['diferenca'] * -1) },
              ]);
            }
            categoria['ficha'] = registro.registros[0]['fh_numero'];
            categoria['codigo'] = registro.registros[0]['r_codigo'];
            categoria['recurso'] = `${registro.grupo['re_codigo']} ${registro.grupo['a_codigo']}`;
            categoria['nome'] = registro.registros[0]['categoria'];
            categoria['anterior'] = 0.00;
            categoria['mes'] = 0.00;
            categoria['arrecadado'] = 0.00;
            categoria['previsao'] = 0.00;
            categoria['ajuste'] = 0.00;
            categoria['diferenca'] = 0.00;
            registros.push([{ text: `${registro.registros[0]['categoria_codigo']} ${categoria['nome']}` }]);
          }
          const vlAnterior: number = registro.totalizadores['arrecadado_anterior'];
          const vlMes: number = registro.totalizadores['arrecadado_mes'];
          const vlArrecadado: number = registro.totalizadores['arrecadado'];
          const vlPrevisao: number = registro.totalizadores['fh_valor_orcado'] ? registro.totalizadores['fh_valor_orcado'] : 0.00;
          const vlAjuste: number = registro.totalizadores['ajuste'];
          const vlDiferenca: number = +vlPrevisao + +vlAjuste - +vlArrecadado;
          categoria['anterior'] += +vlAnterior;
          categoria['mes'] += +vlMes;
          categoria['arrecadado'] += +vlArrecadado;
          categoria['previsao'] += +vlPrevisao;
          categoria['ajuste'] += +vlAjuste;
          categoria['diferenca'] += +vlDiferenca;
          totalAnterior += +vlAnterior;
          totalMes += +vlMes;
          totalArrecadado += +vlArrecadado;
          totalAjuste += +vlAjuste;
          totalPrevisao += +vlPrevisao;
          totalDiferenca += +vlDiferenca;

          registros.push([
            { text: `${registro.grupo['re_codigo']} ${registro.grupo['a_codigo']} ${registro.grupo['av_variavel']}` },
            { text: `${registro.grupo['r_n1']}.${registro.grupo['r_n2']}.${registro.grupo['r_n3']}.${registro.grupo['r_n4']}.${registro.grupo['r_n5']}.00.000` + ' ' + registro.registros[0]['r2_nome']?.trim() },
            { text: this.funcaoService.convertToBrNumber(vlAnterior) },
            { text: this.funcaoService.convertToBrNumber(vlMes) },
            { text: this.funcaoService.convertToBrNumber(vlArrecadado) },
            { text: this.funcaoService.convertToBrNumber(vlPrevisao) },
            { text: this.funcaoService.convertToBrNumber(vlAjuste) },
            { text: this.funcaoService.convertToBrNumber(vlDiferenca * -1) },
          ]);
        }
        registros.push([
          { text: 'SOMA' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(categoria['anterior']) },
          { text: this.funcaoService.convertToBrNumber(categoria['mes']) },
          { text: this.funcaoService.convertToBrNumber(categoria['arrecadado']) },
          { text: this.funcaoService.convertToBrNumber(categoria['previsao']) },
          { text: this.funcaoService.convertToBrNumber(categoria['ajuste']) },
          { text: this.funcaoService.convertToBrNumber(categoria['diferenca'] * -1) },
        ]);

        registros.push([
          { text: 'TOTAL DO ÓRGÃO:' }, { text: '' },
          { text: this.funcaoService.convertToBrNumber(totalAnterior) },
          { text: this.funcaoService.convertToBrNumber(totalMes) },
          { text: this.funcaoService.convertToBrNumber(totalArrecadado) },
          { text: this.funcaoService.convertToBrNumber(totalPrevisao) },
          { text: this.funcaoService.convertToBrNumber(totalAjuste) },
          { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1) },
        ]);

        if (categoria['nome']) {
          registros.push([
            { text: 'TOTAL GERAL:' }, { text: '' },
            { text: this.funcaoService.convertToBrNumber(totalAnterior) },
            { text: this.funcaoService.convertToBrNumber(totalMes) },
            { text: this.funcaoService.convertToBrNumber(totalArrecadado) },
            { text: this.funcaoService.convertToBrNumber(totalPrevisao) },
            { text: this.funcaoService.convertToBrNumber(totalAjuste) },
            { text: this.funcaoService.convertToBrNumber(totalDiferenca * -1) },
          ]);
        }
      }
    }

    dados = null;
    let csv = '';
    for (let i = 0; i < registros.length; i++) {
      const linha = registros[i];
      if (i > 0) csv += '\n';
      for (let x = 0; x < linha.length; x++) {
        if (x > 0) csv += ';';
        csv += String(linha[x]['text']);
      }
    }
    registros = null;

    const element = document.createElement("a");
    element.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent("\uFEFF" + csv));
    element.setAttribute("download", `Balancete Analítico da Receita.csv`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  retornarLinhaSomaCategoria(categoria: {}) {
    return [
      {
        text: 'SOMA', colSpan: 3,
        bold: true, fontSize: 8
      },
      '',
      '',
      { text: this.funcaoService.convertToBrNumber(categoria['anterior']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(categoria['mes']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(categoria['arrecadado']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(categoria['previsao']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(categoria['ajuste']), bold: true, alignment: 'right', fontSize: 8 },
      { text: this.funcaoService.convertToBrNumber(categoria['diferenca'] * -1), bold: true, alignment: 'right', fontSize: 8 },
    ];
  }

}
