import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ContratoService, EmpenhoService, MemorialService } from 'administrativo-lib';
import { Ajuste, Contrato, ContratoItem, ContratoResponsavel, Empenho, FuncaoService, Licitacao, LicitacaoAudesp, RegistroPrecos, TipoArquivoFase4 } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ActivatedRoute } from '@angular/router';
import { ContratoResponsavelService } from '../../../contrato/service/contrato-responsavel.service';
import { LicitacaoAudespService } from '../../service/licitacao-audesp.service';
import { BaseLicitacaoAudesp } from '../../util/base-licitacao-audesp';
import { exit } from 'process';
import { CompraItemService } from '../../../compra/service/compra-item.service';
import { CompraTabelaDesconto } from '../../../compra/service/compra-tabela-desconto.service';
import { ContratoItemService } from '../../../contrato/service/contrato-item.service';

@Component({
  selector: 'lib-licitacao-audesp-ajuste',
  templateUrl: './licitacao-audesp-ajuste.component.html'
})

export class LicitacaoAudespAjusteComponent extends BaseLicitacaoAudesp implements OnInit, OnChanges, OnDestroy {

  @Input() public entidade: LicitacaoAudesp;
  @Input() public registroPreco: LicitacaoAudesp;
  @Input() public tipoAjuste: TipoArquivoFase4;
  @Input() public contrato: Contrato;
  @Input() public empenho: Empenho;
  @Input() public licitacao: Licitacao;

  public arquivo: Ajuste;
  public opcoesVigencia: { id: 'DATA' | 'ORDEM_SERVICO', nome: string }[];
  public listaReceita: { id: number, nome: string }[];
  public listaDespesa: { id: number, nome: string }[];
  public opcoesTipoExecucao: { id: number, nome: string }[];
  public listaContrato: Contrato[] = []
  public listaEmpenho: Empenho[];
  public filtroEmpenho: string;
  public codigoClassificacao: number;
  public tipoClassificacao: number;
  public mostrarEmpenho: any;
  public contratoId: number;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    private audespService: LicitacaoAudespService,
    private contratoService: ContratoService,
    private empenhoService: EmpenhoService,
    private responsavelContratoService: ContratoResponsavelService,
    // private proponenteService: ProponenteService,
    private funcaoService: FuncaoService,
    private compraItemService: CompraItemService,
    private compraTabelaDescontoService: CompraTabelaDesconto,
    private memorialService: MemorialService,
    private contratoItemService: ContratoItemService,
    private route: ActivatedRoute,
  ) {
    super()
  }

  // ngAfterViewInit(): void {
  //   this.route.params.pipe(takeUntil(this.unsubscribe))
  //     .subscribe(params => {
  //       console.log(params)
  //       if (params['registroPreco'] && this.tipoAjuste === 'AJUSTE_CONTRATO') {
  //         this.audespService.obter({ id: +params['registroPreco'] }).pipe(takeUntil(this.unsubscribe))
  //           .subscribe((data) => {
  //             if (data?.tipo !== 'AJUSTE_EMPENHO') {
  //               this.audespService.obter({ id: +params['registroPreco'], relations: 'licitacao.modalidade' }).pipe(takeUntil(this.unsubscribe))
  //                 .subscribe((dados) => {
  //                   this.registroPreco = dados;
  //                   this.audespService.filtrar(0, 0, { id: this.registroPreco.id }).pipe(takeUntil(this.unsubscribe))
  //                     .subscribe((dados) => {
  //                       this.entidade.arquivo = new Ajuste();
  //                       this.entidade.arquivo = JSON.parse(dados.content[0].arquivo);
  //                       this.codigoClassificacao = this.entidade.arquivo.ComprasServicos.Lote[0].ClassificacaoEconomica.CodigoClassificacaoEconomica;
  //                       this.tipoClassificacao = this.entidade.arquivo.ComprasServicos.Lote[0].ClassificacaoEconomica.TipoClassificacaoEconomica;
  //                     })
  //                 });
  //             }
  //           })
  //       }
  //     });
  // }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.entidade.id) {
        if (this.entidade.arquivo['NotaEmpenho']?.length > 0 && this.entidade.arquivo['NotaEmpenho'][0]['empenhoId']) {
          this.empenho = (await this.empenhoService.obter({ id: this.entidade.arquivo['NotaEmpenho'][0]['empenhoId'], relations: 'exercicio,ficha,ficha.recurso,compra,licitacao,contrato' }).toPromise()) as Empenho
          if (this.empenho?.compra?.id) {
            this.empenho.compra.itens = (await this.compraItemService.filtrar(1, -1, { 'compra.id': this.empenho.compra.id, relations: 'produto_unidade.produto' }).toPromise()).content;

            this.empenho.compra.tabelas_desconto = (await this.compraTabelaDescontoService.filtrar(1, -1, { 'compra.id': this.empenho.compra.id, relations: 'memorial' }).toPromise()).content;
          }

          if (this.empenho?.licitacao?.id) {
            this.empenho.licitacao.itens = (await this.memorialService.filtrar(1, -1, { 'licitacao.id': this.empenho.licitacao.id, relations: 'produto_unidade.produto' }).toPromise()).content;
          }

          if (this.empenho?.contrato?.id) {
            this.empenho.contrato.itens = (await this.contratoItemService.filtrar(1, -1, { 'contrato.id': this.empenho.contrato.id, relations: 'produto_unidade.produto' }).toPromise()).content;
          }
        }
        this.arquivo = this.entidade.arquivo as Ajuste;
        if (this.registroPreco) {
          this.carregarContratos();
          this.carregarEmpenhos();
        }
      } else {

        if (!this.registroPreco?.licitacao || !this.entidade) {
          this.iniciarObjeto();
        } else {
          this.importarInformacoes();
        }

        if (this.registroPreco) {
          this.arquivo.lotes = [];
          const regPreco: RegistroPrecos = JSON.parse(this.registroPreco.arquivo);
          const condicoes = {
            COMPRA_SERVICO: () => {
              this.arquivo.lotes = [];
              regPreco.ComprasServicos.Lote.forEach(item => {
                this.arquivo.lotes.push({
                  marcado: true, NumeroLote: item.NumeroLote, DescricaoLote: item.DescricaoLote,
                  Quantidade: item.Quantidade, UnidadeMedida: item.UnidadeMedida, OrcamentoLoteNao: item.OrcamentoLoteNao
                })
              })
            },
            COMPRA_SERVICO_TI: () => {
              this.arquivo.lotes = [];
              regPreco.ComprasServicosTI.Lote.forEach(item => {
                this.arquivo.lotes.push({
                  marcado: true, NumeroLote: item.NumeroLote, DescricaoLote: item.DescricaoLote,
                  Quantidade: item.Quantidade, UnidadeMedida: item.UnidadeMedida, OrcamentoLoteNao: item.OrcamentoLoteNao
                })
              })
            },
            ENGENHARIA: () => {
              this.arquivo.lotes = [];
              regPreco.ObrasServicosEngenharia.Lote.forEach(item => {
                this.arquivo.lotes.push({
                  marcado: true, NumeroLote: item.NumeroLote, DescricaoLote: item.DescricaoLote,
                  Quantidade: 1, UnidadeMedida: 'SV', OrcamentoLoteNao: item.OrcamentoLoteNao
                })
              })
            }
          };
          condicoes[`${regPreco.tipoObjeto}`]();
        }
        if (this.contrato) {
          this.arquivo.contratoId = this.contrato.id;
          this.carregarContratos();
          this.atribuirContrato();
        }

        if (this.tipoAjuste === 'AJUSTE_EMPENHO') {
          if (this.empenho?.compra?.id) {
            this.empenho.compra.itens = (await this.compraItemService.filtrar(1, -1, { 'compra.id': this.empenho.compra.id, relations: 'produto_unidade.produto' }).toPromise()).content;

            this.empenho.compra.tabelas_desconto = (await this.compraTabelaDescontoService.filtrar(1, -1, { 'compra.id': this.empenho.compra.id, relations: 'memorial' }).toPromise()).content;
          }

          if (this.empenho?.licitacao?.id) {
            this.empenho.licitacao.itens = (await this.memorialService.filtrar(1, -1, { 'licitacao.id': this.empenho.licitacao.id, relations: 'produto_unidade.produto' }).toPromise()).content;
          }
        }
        if (this.empenho?.contrato?.id) {
          this.carregarContratos();
        }
        this.carregarEmpenhos();
      }
    }
  }

  ngOnInit(): void {
    this.iniciarObjeto();
    this.opcoesVigencia = [
      { id: 'DATA', nome: 'Por Data' },
      { id: 'ORDEM_SERVICO', nome: 'A partir da emissão da ordem de serviço' },
    ]
    this.opcoesTipoExecucao = [
      { id: 4, nome: 'Tarefa' },
      { id: 5, nome: 'Empreitada Integral' },
      { id: 9, nome: 'Contratação semi-integrada' },
      { id: 1, nome: 'Execução Direta' },
      { id: 2, nome: 'Empreitada por Preço Global' },
      { id: 3, nome: 'Empreitada por Preço Unitário' },
      { id: 6, nome: 'Contratação Integrada (RDC)' },
      { id: 7, nome: 'Concessão Pública com obra de engenharia' },
      { id: 8, nome: 'Não se aplica' },
    ];
    this.listaReceita = this.audespService.listarTipoReceita();
    this.listaDespesa = this.audespService.listarTipoDespesa();
    this.carregarContratos();
    this.carregarEmpenhos();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }

  private iniciarObjeto() {
    this.entidade.arquivo = new Ajuste();
    this.arquivo = this.entidade.arquivo;

    if (!this.arquivo.ClausulasFinanceira) {
      this.arquivo.ClausulasFinanceira = this.licitacao?.clausulas_financeiras
    }

    if (!this.arquivo.ClausulasPenaisSim) {
      this.arquivo.ClausulasPenaisSim = this.licitacao?.clausulas_penais
    }
  }

  private importarInformacoes() {
    if (!this.registroPreco || !this.entidade) return;

    this.iniciarObjeto();
    this.arquivo.CodigoLicitacao = +this.registroPreco.codigo;
  }

  private carregarEmpenhos() {
    if (!this.registroPreco?.licitacao || !this.login?.orgao || !this.arquivo.tipoDoc) return
    const documento = this.arquivo.tipoDoc === 'CPF' ? this.arquivo.CPF : this.arquivo.tipoDoc === 'CNPJ' ? this.arquivo.CNPJ : this.arquivo.OutroDoc;
    // if (!documento) return
    this.empenhoService.obterPorLicitacao(this.registroPreco.licitacao.id, this.login.orgao.id, documento).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaEmpenho = res.content
        if (this.empenho) {
          // identificação
          if (this.empenho.favorecido) {
            if (this.empenho.favorecido.cpf_cnpj?.length === 11) {
              this.arquivo.tipoDoc = 'CPF'
              this.arquivo.CPF = this.empenho.favorecido.cpf_cnpj
            } else if (this.empenho.favorecido.cpf_cnpj?.length === 14) {
              this.arquivo.tipoDoc = 'CNPJ'
              this.arquivo.CNPJ = this.empenho.favorecido.cpf_cnpj
            } else {
              this.arquivo.tipoDoc = 'OUTRO'
              this.arquivo.OutroDoc = this.empenho.favorecido.cpf_cnpj
            }
            this.arquivo.NomeRazaoSocial = this.empenho.favorecido.nome;
          }

          // itens
          // if (this.tipoAjuste === 'AJUSTE_CONTRATO') {
          //   this.arquivo.lotes = this.arquivo.lotes.map(lote => ({ ...lote, marcado: this.verificaMarcado(lote) }));
          // } else {
          //   this.arquivo.lotes = this.arquivo.lotes.map(lote => ({ ...lote, marcado: this.verificaMarcado(lote) }));
          // }

          // if (this.empenho.compra?.itens && this.empenho.licitacao?.itens) {
          //   if (this.empenho.contrato.tipo === 'REGISTRO_PRECO') {
          //     this.arquivo.lotes.forEach(lote => {
          //       const compraItem = this.empenho.compra.itens.find(cI => cI.ordem === lote.NumeroLote)
          //       if (!lote.marcado && compraItem) {
          //         lote.marcado = true;
          //         lote.Quantidade = +compraItem.quantidade;
          //       } else {
          //         lote.marcado = false;
          //       }
          //     })
          //   } else {
          //     for (const item of this.empenho.compra.itens.filter(i => i.produto_unidade)) {
          //       const memorial = this.empenho.licitacao.itens.filter(i => i.produto_unidade).find(m => m.produto_unidade.id === item.produto_unidade.id)
          //       if (memorial && this.arquivo.lotes.find(l => l.NumeroLote === memorial.ordem)) {
          //         this.arquivo.lotes.find(l => l.NumeroLote === memorial.ordem).marcado = true;
          //       } else {
          //         this.arquivo.lotes.find(l => l.NumeroLote === memorial.ordem).marcado = false;
          //       }
          //     }
          //     if ((this.empenho.licitacao?.tipo_licitacao === 5 || this.empenho.licitacao?.natureza === 3) && this.empenho?.licitacao?.tabela_desconto) {
          //       this.empenho.compra.tabelas_desconto.forEach((tabela) => {
          //         this.arquivo.lotes.forEach(lote => {
          //           if (lote.NumeroLote === tabela.memorial.ordem) {
          //             lote.marcado = true;
          //           } else {
          //             lote.marcado = false;
          //           }
          //         })
          //       })
          //     }
          //   }
          // }

          // financeiro
          this.atribuirEmpenho(this.empenho)
        } else if (this.listaEmpenho?.length === 1 && !this.entidade.id)
          if (this.tipoAjuste === 'AJUSTE_CONTRATO' || this.entidade.tipo === 'AJUSTE_CONTRATO') {
            this.atribuirEmpenho(this.listaEmpenho.find(empenho => empenho.contrato === this.contrato));
          } else {
            this.atribuirEmpenho(this.listaEmpenho[0]);
          }
      });
  }

  verificaMarcado(lote) {
    const loteDescricao: String = lote.DescricaoLote;
    const item = this.empenho?.compra?.itens.find(c => c.produto_unidade.produto.descricao === loteDescricao || c.produto_unidade.produto.nome === loteDescricao);
    if (item) {
      return true;
    } else {
      return false;
    }
  }

  public atribuirEmpenho(empenho: Empenho) {
    if (!empenho) return;

    this.atualizarArquivos(empenho.contrato);

    this.arquivo.NotaEmpenho = [{
      empenhoId: empenho.id, NumNotaEmpenho: `${empenho.numero}/${empenho.exercicio.ano}`,
      NotaEmpenhoDt: this.funcaoService.converteDataBR(empenho.data_empenho),
      NotaEmpenhoValor: empenho.valor_empenho,
      FonteRecursos: this.converterFonteRecurso(empenho.ficha.recurso.codigo)
    }];
    if (empenho?.contrato?.responsaveis) {
      this.arquivo.TermoCienciaContratados = [];
      this.arquivo.TermoCienciaContratantes = [];
      let contratante = empenho.contrato?.responsaveis.find(resp => resp.contratante === true)
      this.arquivo.TermoCienciaContratantes = [{
        CPF: contratante?.responsavel?.cpf_cnpj, Nome: contratante?.responsavel.nome, EmailPessoal: contratante?.responsavel?.email,
        EmailProfissional: contratante?.responsavel?.email_profissional, AssinouContrato: 'S', pessoaId: contratante?.responsavel.id
      }]
      let contrato = empenho.contrato?.responsaveis.find(resp => resp.contratante === false)
      this.arquivo.TermoCienciaContratados = [{
        CPF: contrato?.responsavel?.cpf_cnpj, Nome: contrato?.responsavel?.nome, EmailPessoal: contrato?.responsavel?.email,
        EmailProfissional: contrato?.responsavel?.email_profissional, AssinouContrato: 'S', pessoaId: contrato?.responsavel.id
      }]
    }
    if (this.entidade.tipo === 'AJUSTE_EMPENHO' || this.tipoAjuste === 'AJUSTE_EMPENHO') {
      this.arquivo.lotes.forEach(lote => {
        const achou = !!empenho.compra?.itens?.find(item => {
          return !!empenho.licitacao?.itens.find(memorial => {
            if (!empenho.licitacao.tabela_desconto) {
              if (memorial.produto_unidade.id === item.produto_unidade.id && ((memorial.produto_unidade.produto.descricao === lote.DescricaoLote || memorial.produto_unidade.produto.nome === lote.DescricaoLote) || memorial.ordem === lote.NumeroLote)) {
                return item;
              }
            } else {
              return !!empenho.compra.tabelas_desconto.find(tabela => {
                if (memorial.id === tabela.memorial.id && memorial.ordem === lote.NumeroLote) {
                  return item;
                }
              })
            }
          })
        })

        if (achou) {
          lote.marcado = true;
        } else {
          lote.marcado = false;
        }
      })
    }


  }

  public buscarEmpenhos() {
    this.listaEmpenho?.forEach(e => delete e['classeCSS'])
    if (this.filtroEmpenho)
      this.listaEmpenho.filter(e => !String(e.numero).startsWith(this.filtroEmpenho)).forEach(e => e['classeCSS'] = 'd-none')
  }

  private converterFonteRecurso(codigo: string): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 {
    if (!codigo) return 6; // Outras Fontes de Recursos

    if (codigo === '010000000') return 1; // Tesouro
    else if (codigo === '02' || codigo === '02000000') return 2; // Transferências e convênios estaduais-vinculados
    else if (codigo === '03' || codigo === '03000000') return 3; // Recursos Próprios de Fundos Especiais de Despesa-Vinculados
    else if (codigo === '04' || codigo === '04000000') return 4; // Recursos Próprios da Administração Indireta
    else if (codigo === '05' || codigo === '05000000') return 5; // Transferências e convênios Federais-Vinculados
    else if (codigo === '06' || codigo === '06000000') return 6; // Outras Fontes de Recursos
    else if (codigo === '07' || codigo === '07000000') return 7; // Operações de Crédito
    else if (codigo === '08' || codigo === '08000000') return 8; // Emendas Parlamentares Individuais
    else return 6; // Outras Fontes de Recursos
  }

  private carregarContratos() {
    if (!this.registroPreco?.licitacao || !this.login?.orgao) return
    this.contratoService.filtrar(0, 0, {
      relations: 'favorecido,itens.memorial', 'licitacao.id': this.registroPreco.licitacao.id, orgao_id: this.login.orgao.id, excluido: false
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        console.log(res)
        this.listaContrato = res.content
        if (this.listaContrato?.length === 0) {
          if (this.tipoAjuste === 'AJUSTE_CONTRATO')
            toastr.warning('Nenhum contrato foi localizado para essa licitação')
          return
        }
        if (this.listaContrato?.length === 1)
          this.arquivo.contratoId = this.listaContrato[0].id;

        if (!this.entidade.id) {
          if (this.tipoAjuste === 'AJUSTE_EMPENHO' || this.entidade.tipo === 'AJUSTE_EMPENHO') {
            this.atualizarArquivos(this.listaContrato.find(contrato => contrato === this.empenho?.contrato));
            this.arquivo.contratoId = this.empenho?.contrato?.id;
          } else {
            if (this.contrato !== undefined) {
              this.atualizarArquivos(this.contrato);
            } else {
              this.atualizarArquivos(this.listaContrato[0]);
            }
          }
          this.atribuirContrato();
        }
      });
  }

  public atribuirContrato() {
    if (this.listaContrato.length > 0 && this.arquivo.contratoId) {
      this.contrato = this.listaContrato.find(c => c.id === this.arquivo.contratoId)
      if (this.contrato) {
        // identificação
        this.arquivo.NumContrato = this.contrato.numero;
        this.arquivo.AnoContrato = this.contrato.ano;
        this.arquivo.ValorContrato = this.contrato.valor_contrato;
        this.arquivo.ObjetoContrato = this.contrato.objeto;
        this.arquivo.UnidadeMedida = this.contrato.unidade_medida == null ? this.arquivo.UnidadeMedida : this.contrato.unidade_medida;
        let qtde = 0;
        this.contrato.itens.map(i => qtde += +i.quantidade)
        this.arquivo.Quantidade = !this.entidade?.id ? Math.floor(qtde) : this.arquivo.Quantidade
        const doc: string = this.contrato.favorecido.cpf_cnpj;
        if (doc.length === 11) {
          this.arquivo.tipoDoc = 'CPF';
          this.arquivo.CPF = doc;
        } else if (doc.length === 14) {
          this.arquivo.tipoDoc = 'CNPJ';
          this.arquivo.CNPJ = doc;
        } else {
          this.arquivo.tipoDoc = 'OUTRO';
          this.arquivo.OutroDoc = doc;
        }
        this.arquivo.NomeRazaoSocial = this.contrato.favorecido.nome;

        // // itens
        // this.proponenteService.obter({
        //   licitacao_id: this.contrato.licitacao.id, favorecido_id: this.contrato.favorecido.id, 'propostas.situacao': 'VENCEDOR', relations: 'propostas.memorial'
        // }).pipe(takeUntil(this.unsubscribe))
        //   .subscribe((res) => {
        //     const memorial: Memorial[] = res.propostas.map(p => p.memorial)
        //     this.arquivo.lotes.forEach(l => {
        //       l.marcado = new Boolean(memorial.find(m => m.ordem === l.NumeroLote)).valueOf();
        //     })
        //   })
        if (this.entidade.tipo === 'AJUSTE_CONTRATO' || this.tipoAjuste === 'AJUSTE_CONTRATO') {
          this.arquivo.lotes.forEach(lote => {
            const achou = !!this.contrato.itens.find(item => {
              if (item.memorial?.ordem === lote.NumeroLote) {
                return item;
              }
            })

            if (achou) {
              lote.marcado = true
            } else {
              lote.marcado = false
            }
          })
          const ar = JSON.parse(this.registroPreco.arquivo)
          if (ar.ComprasServicos.Lote.length > 0) {
            this.arquivo.ClassificacaoEconomica = ar.ComprasServicos.Lote[0].ClassificacaoEconomica;
          } else if (ar.ComprasServicosTI.Lote.length > 0) {
            this.arquivo.ClassificacaoEconomica = ar.ComprasServicosTI.Lote[0].ClassificacaoEconomica;
          } else if (ar.ObrasServicosEngenharia.Lote.length > 0) {
            this.arquivo.ClassificacaoEconomica = ar.ObrasServicosEngenharia.Lote[0].ClassificacaoEconomica;
          }
        }

        // financeiro
        this.empenhoService.obterPorContrato(this.contrato.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            const listaEmpenho: Empenho[] = res.content

            if (listaEmpenho?.length > 0 && this.tipoAjuste !== 'AJUSTE_EMPENHO') {
              this.arquivo.NotaEmpenho = undefined;
              this.arquivo.NotaEmpenho = [];
              listaEmpenho.forEach(e => {
                this.arquivo.NotaEmpenho.push({
                  empenhoId: e.id, NumNotaEmpenho: `${e.numero}/${e.exercicio.ano}`,
                  NotaEmpenhoDt: this.funcaoService.converteDataBR(e.data_empenho),
                  NotaEmpenhoValor: e.valor_empenho, FonteRecursos: this.converterFonteRecurso(e.ficha.recurso.codigo)
                })
              })
            } else {
              if (this.tipoAjuste !== 'AJUSTE_EMPENHO')
                this.arquivo.NotaEmpenho = [];
            }
          });

        // gestor e vigência
        if (this.contrato.gestor_exige) {
          this.arquivo.GestorDoContratoNao = 'S'
          this.arquivo.GestorDoContratoSim = { GestorCPF: this.contrato.gestor_cpf, GestorCargo: this.contrato.gestor_cargo }
        } else {
          this.arquivo.GestorDoContratoNao = 'N'
        }
        if (this.entidade?.id) {
          this.arquivo.VigenciaPorData = {
            InicioVigenciaDt: this.funcaoService.formatarData(new Date(this.contrato.data_inicio)).substring(0, 10),//this.funcaoService.converteDataBR(this.contrato.data_inicio),
            FimVigenciaDt: this.funcaoService.formatarData(new Date(this.contrato.data_termino)).substring(0, 10),//this.funcaoService.converteDataBR(this.contrato.data_termino),
          }
          this.arquivo.AssinaturaDt = this.funcaoService.formatarData(new Date(this.contrato.data_assinatura)).substring(0, 10)//this.funcaoService.converteDataBR(this.contrato.data_assinatura);
        }
        this.arquivo.VigenciaPorData = {
          InicioVigenciaDt: this.funcaoService.converteDataBR(this.contrato.data_inicio),
          FimVigenciaDt: this.funcaoService.converteDataBR(this.contrato.data_termino),
        }
        this.arquivo.AssinaturaDt = this.funcaoService.converteDataBR(this.contrato.data_assinatura);

        // conclusao
        this.responsavelContratoService.filtrar(0, 0, { contrato_id: this.contrato.id, relations: 'responsavel', ativo: true }).pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            const responsaveis: ContratoResponsavel[] = res.content;
            if (responsaveis?.length === 0) return

            this.arquivo.TermoCienciaContratados = [];
            this.arquivo.TermoCienciaContratantes = [];
            responsaveis.forEach(r => {
              if (r.contratante) {
                this.arquivo.TermoCienciaContratantes.push({
                  CPF: r.responsavel.cpf_cnpj, Nome: r.responsavel.nome, EmailPessoal: r.responsavel.email,
                  EmailProfissional: r.responsavel.email_profissional, AssinouContrato: 'S', pessoaId: r.responsavel.id
                })
              } else {
                this.arquivo.TermoCienciaContratados.push({
                  CPF: r.responsavel.cpf_cnpj, Nome: r.responsavel.nome, EmailPessoal: r.responsavel.email,
                  EmailProfissional: r.responsavel.email_profissional, AssinouContrato: 'S', pessoaId: r.responsavel.id
                })
              }
            })
          });
      }
    }
  }

  private atualizarArquivos(contrato: Contrato) {
    if (!contrato) return;

    if (!contrato.favorecido) {
      contrato.favorecido = this.listaContrato.find(contra => contra.id === contrato.id)?.favorecido;
    }

    this.contratoId = contrato.id;
    this.arquivo.NumContrato = contrato.numero;

    if (contrato?.favorecido?.cpf_cnpj?.length === 11) {
      this.arquivo.tipoDoc = 'CPF';
      this.arquivo.CPF = contrato?.favorecido?.cpf_cnpj;
    } else if (contrato.favorecido?.cpf_cnpj.length === 14) {
      this.arquivo.tipoDoc = 'CNPJ';
      this.arquivo.CNPJ = contrato?.favorecido?.cpf_cnpj;
    } else {
      this.arquivo.tipoDoc = 'OUTRO';
      this.arquivo.OutroDoc = contrato?.favorecido?.cpf_cnpj;
    }
    this.arquivo.NomeRazaoSocial = contrato?.favorecido?.nome;
  }

  async chamaTabLotes() {
    const arquivoAjuste = this.entidade.arquivo;
    if (this.entidade.arquivo.lotes?.length === 0 && this.entidade?.id) {
      if (!this.entidade.arquivo.lotes.length) {
        this.entidade.arquivo.lotes = this.entidade.licitacao.itens.map(memorial => {
          return {
            NumeroLote: memorial.ordem,
            DescricaoLote: memorial.descricao,
            Quantidade: memorial.quantidade,
            UnidadeMedida: memorial.unidade,
            OrcamentoLoteSim: [],
            OrcamentoLoteNao: 'S',
            LoteCompostoItensSim: [],
            LoteCompostoItensNao: 'N',
          }
        })
        if (this.entidade.tipo === 'AJUSTE_CONTRATO') {
          this.atribuirContrato();
        }
        if (this.entidade.tipo === 'AJUSTE_EMPENHO') {
          this.atribuirEmpenho(this.empenho);
        }
        this.entidade.arquivo.Quantidade = this.arquivo.Quantidade;
        this.entidade.arquivo.UnidadeMedida = this.arquivo.UnidadeMedida;
        this.entidade.arquivo = JSON.stringify(this.entidade.arquivo);
        await this.audespService.atualizar(this.entidade).toPromise();
      }
    }

    if (!arquivoAjuste.lotes.find(lote => lote.marcado) && this.entidade?.id) {
      if (this.entidade.tipo === 'AJUSTE_CONTRATO') {
        this.atribuirContrato();
      }
      if (this.entidade.tipo === 'AJUSTE_EMPENHO') {
        this.atribuirEmpenho(this.empenho);
      }

      this.entidade.arquivo.Quantidade = this.arquivo.Quantidade;
      this.entidade.arquivo.UnidadeMedida = this.arquivo.UnidadeMedida;
      this.entidade.arquivo = JSON.stringify(this.entidade.arquivo);
      await this.audespService.atualizar(this.entidade).toPromise();
    }
    this.entidade.arquivo = arquivoAjuste;
    // else {
    //   if (this.entidade.tipo === 'AJUSTE_CONTRATO') {
    //     this.atribuirContrato();
    //   }
    //   if (this.entidade.tipo === 'AJUSTE_EMPENHO') {
    //     this.atribuirEmpenho(this.empenho);
    //   }
    //   this.entidade.arquivo = JSON.stringify(this.entidade.arquivo);
    //   this.audespService.atualizar(this.entidade).toPromise();
    // }
    if (!arquivoAjuste.lotes.find(lote => lote.marcado) && !this.entidade.id) {
      if (this.entidade.tipo === 'AJUSTE_CONTRATO' || this.tipoAjuste === 'AJUSTE_CONTRATO') {
        this.atribuirContrato();
      }
      if (this.entidade.tipo === 'AJUSTE_EMPENHO' || this.tipoAjuste === 'AJUSTE_EMPENHO') {
        this.atribuirEmpenho(this.empenho);
      }
    }
  }
}
