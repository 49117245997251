import { Component, OnInit, OnChanges, ElementRef, Input, ViewChild, SimpleChanges, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RetencaoExtraService } from '../service/retencao-extra.service';
import {
  RetencaoExtra, EddyAutoComplete, FichaExtra,
  Login, GlobalService, FuncaoService, FavorecidoCei, EmpenhoExtra
} from 'eddydata-lib';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';
import { FavorecidoCeiService } from 'administrativo-lib';

@Component({
  selector: 'app-retencao-extra',
  templateUrl: './retencao-extra.component.html'
})
export class RetencaoExtraComponent implements OnInit, OnChanges, OnDestroy {
  public ptBR: any;
  public entity: RetencaoExtra;
  public entityTemp: RetencaoExtra;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public ceiAutoComplete: EddyAutoComplete<FavorecidoCei>;
  public totalRetido = 0;
  public exibirAnulados: boolean = true;
  public favorecidoCei;
  public retencaoReinf: RetencaoExtra;
  protected unsubscribe: Subject<void> = new Subject();

  @Input() listaRetencoes: Array<any>;
  @Input() entidade: EmpenhoExtra;
  @Input() data_vencimento: string;
  @Input() favorecido: number;
  @Input() login: Login;
  @Input() edicao: boolean = true;
  @ViewChild('btnAdicionarRetencao') public btnAdicionarRetencao: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };
  
  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaExtraService,
    protected favorecidoCeiService: FavorecidoCeiService,
    protected retencaoService: RetencaoExtraService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.carregarAutoCompletes();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listaRetencoes) {
      this.somarRetencao(this.listaRetencoes);
      this.carregarAutoCompletes();
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarRetencao.nativeElement.disabled = desabilitar;
  }

  public adicionarRetencao() {
    this.carregarAutoCompletes();
    this.entity = new RetencaoExtra();
    this.entity.aux = 0;
    this.entity.data_vencimento = new Date(this.data_vencimento);
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaRetencoes) {
      this.listaRetencoes = new Array();
    }
    this.listaRetencoes.unshift(this.entity);
    this.desabilitarEdicao(true);
  }

  public salvarRetencao(item: RetencaoExtra) {
    try {
      if (this.favorecidoCei) {
        item.cei = '' + this.favorecidoCei.cei;
      }
      if (!item.ficha) {
        throw new Error('Informe a ficha de retenção!');
      }
      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
      this.somarRetencao(this.listaRetencoes);
    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public editarRetencao(item: RetencaoExtra) {
    this.entityTemp = JSON.parse(JSON.stringify(item));

    this.entity = item;
    this.entity['salvo'] = true;
    this.entity.editavel = true;
    this.favorecidoCeiService.obter({ cei: item.cei, favorecido_id: item.empenho_extra.favorecido.id, orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.favorecidoCei = res;
      this.ceiAutoComplete.id = this.favorecidoCei ? this.favorecidoCei.id : null;
    });
    this.desabilitarEdicao(true)
  }

  public cancelarRetencao(item: RetencaoExtra, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.data_vencimento = this.entityTemp.data_vencimento;
      item.ficha = this.entityTemp.ficha;
      item.valor_retido = this.entityTemp.valor_retido;
      item.cei = this.entityTemp.cei;
      item.gps = this.entityTemp.gps;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaRetencoes.splice(index, 1);
    }
    this.desabilitarEdicao(false);
  }

  public confirmarRemocao(item: RetencaoExtra, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerRetencao(item, index)
      },
    });
  }

  public removerRetencao(item: RetencaoExtra, index: number) {
    if (item.id) {
      item['orgao'] = this.login.orgao.id
      item['exercicio'] = this.login.exercicio.id
      this.retencaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaRetencoes.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaRetencoes.splice(index, 1);
    }
  }

  private carregarAutoCompletes() {
    // autocomplete para ficha
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaService,
      'numero', ['numero', 'nome'],
      { exercicio_id: this.login.exercicio.id, excluida: false, orgao_id: this.login.orgao.id, relations: 'plano,favorecido,ficha', orderBy: 'nome' },
      { number: ['numero'], text: ['nome'] }
    );

    this.ceiAutoComplete = new EddyAutoComplete(null, this.favorecidoCeiService, 'cei', ['cei'],
      { favorecido_id: this.favorecido, orgao_id: this.login.orgao.id, ativo: true },
      { text: ['cei'] }
    )
  }

  private somarRetencao(list: Array<RetencaoExtra>) {
    if (list) {
      this.totalRetido = 0;
      for (const item of list) {
        this.totalRetido += +item.valor_retido
      }
    }
  }

  private carregarRetencoes() {
    this.retencaoService.extendido(0, -1, {
      empenho_id: this.entidade.id,
      relations: 'ficha,ficha.ficha,ficha.plano,ficha.favorecido,empenho,empenho.favorecido,'
        + 'empenho.orgao,empenho.exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async (data: any) => {
          this.listaRetencoes = data.content;
          this.somarRetencao(this.listaRetencoes);
        });
  }

  atualizarReinf(event: RetencaoExtra) {
    this.carregarRetencoes();
    this.retencaoReinf = null;
  }
}
