import { Injector } from "@angular/core";
import { FuncaoService, GlobalService, LoginContabil, Orgao, OrgaoAssinatura, OrgaoAssinaturaService, Unidade, UnidadeService } from "eddydata-lib";

type CamposAssinatura = {
  assinatura_esquerda: string,
  cargo_esquerda: string,
  assinatura_centro: string,
  cargo_centro: string,
  assinatura_direita: string,
  cargo_direita: string,
};
export class Assinaturas {

  private assinatura: OrgaoAssinatura;
  public login: LoginContabil = new LoginContabil();
  private unidade: Unidade;
  private orgaoID: number;
  private assinaturaService: OrgaoAssinaturaService;
  private unidadeService: UnidadeService;
  private funcaoService: FuncaoService;

  constructor(
    protected orgao: Orgao,
    protected injector: Injector) {
    this.login = GlobalService.obterSessaoLogin();
    this.orgaoID = orgao.id;
    this.assinaturaService = new OrgaoAssinaturaService(injector);
    this.unidadeService = new UnidadeService(injector);
    this.funcaoService = new FuncaoService();
  }

  // montar assinaturas
  public async dadosAssinatura(margemSuperior?: number, considerarTesoureiro?: boolean, campos?: CamposAssinatura, considerarControleInterno?: boolean, considerarEducacao?: boolean, considerarTesouraria?: boolean, dataLimite?: string, crcContabilidade?: boolean) {
    const data = dataLimite ?? this.funcaoService.converteDataSQL(new Date(Date.now()));
    this.assinatura = await this.assinaturaService.obter({ orgao_id: this.orgaoID, 'data_limite$ge': data, 'orderBy': 'data_limite$ASC' }).toPromise();
    if (considerarEducacao) {
      this.unidade = await this.unidadeService.obter({ orgao_id: this.orgaoID, tipo: 'EDUCACAO', ppa_id: this.login?.ppa?.id }).toPromise();
    }

    if (!this.assinatura) {
      toastr.error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      throw new Error(`Não foi encontrado assinatura vigente para emitir o relatório!`);
    }

    const superior = margemSuperior ? margemSuperior : 30;
    const conteudo = considerarControleInterno ? [
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
      ],
      [
        { text: campos ? this.assinatura?.[campos.assinatura_esquerda] : this.assinatura?.assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_centro] : this.assinatura?.assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_direita] : considerarTesoureiro ? this.assinatura?.tesoureiro : this.assinatura?.assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        { text: campos ? this.assinatura?.[campos.cargo_esquerda] : this.assinatura?.cargo_assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_centro] : this.assinatura?.cargo_assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_direita] : considerarTesoureiro ? this.assinatura?.cargo_tesoureiro : this.assinatura?.cargo_assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        {
          text: '                                          ', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '                                          ', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        }
      ],
      [
        { text: '', alignment: 'center', border: [false, false, false, false] },
        { text: this.assinatura?.almoxarife, alignment: 'center', border: [false, false, false, false] },
        { text: '', alignment: 'center', border: [false, false, false, false] }
      ],
      [
        { text: '', alignment: 'center', border: [false, false, false, false] },
        { text: this.assinatura?.cargo_almoxarife, alignment: 'center', border: [false, false, false, false] },
        { text: '', alignment: 'center', border: [false, false, false, false] }
      ],
    ] : considerarEducacao ? [
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
      ],
      [
        { text: campos ? this.assinatura?.[campos.assinatura_esquerda] : this.assinatura?.assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_centro] : this.assinatura?.assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_direita] : considerarTesoureiro ? this.assinatura?.tesoureiro : this.assinatura?.assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        { text: campos ? this.assinatura?.[campos.cargo_esquerda] : this.assinatura?.cargo_assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_centro] : this.assinatura?.cargo_assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_direita] : considerarTesoureiro ? this.assinatura?.cargo_tesoureiro : this.assinatura?.cargo_assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: !this.login.parametro.contabil.assinatura_por_funcao ? '                                          ' : '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        }
      ],
      [
        { text: this.unidade?.responsavel, alignment: 'center', border: [false, false, false, false] },
        { text: this.unidade?.presidente_conselho, alignment: 'center', border: [false, false, false, false] },
        { text: !this.login.parametro.contabil.assinatura_por_funcao ? '' : this.assinatura?.ordenador_despesa_funcao_12, alignment: 'center', border: [false, false, false, false] }
      ],
      [
        { text: this.unidade?.cargo, alignment: 'center', border: [false, false, false, false] },
        { text: this.unidade?.cargo_presidente_conselho, alignment: 'center', border: [false, false, false, false] },
        { text: !this.login.parametro.contabil.assinatura_por_funcao ? '' : this.assinatura?.cargo_ordenador_despesa_funcao_12, alignment: 'center', border: [false, false, false, false] }
      ],
    ] : considerarTesouraria ? [
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
      ],
      [
        { text: campos ? this.assinatura?.[campos.assinatura_esquerda] : this.assinatura?.assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_centro] : this.assinatura?.assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_direita] : considerarTesoureiro ? this.assinatura?.tesoureiro : this.assinatura?.assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        { text: campos ? this.assinatura?.[campos.cargo_esquerda] : this.assinatura?.cargo_assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_centro] : this.assinatura?.cargo_assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_direita] : considerarTesoureiro ? this.assinatura?.cargo_tesoureiro : this.assinatura?.cargo_assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
    ] : crcContabilidade ? [
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
      ],
      [
        { text: campos ? this.assinatura?.[campos.assinatura_esquerda] : this.assinatura?.assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_centro] : this.assinatura?.assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_direita] : considerarTesoureiro ? this.assinatura?.tesoureiro : this.assinatura?.assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        { text: campos ? this.assinatura?.[campos.cargo_esquerda] : this.assinatura?.cargo_assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: (campos ? this.assinatura?.[campos.cargo_centro] : this.assinatura?.cargo_assinatura2) + (this.assinatura?.crc_contador ? ` - ${this.assinatura?.crc_contador}` : ''), alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_direita] : considerarTesoureiro ? this.assinatura?.cargo_tesoureiro : this.assinatura?.cargo_assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
    ] : [
      [
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
        {
          text: '__________________________________________', alignment: 'center',
          border: [false, false, false, false], margin: [0, superior, 0, 0]
        },
      ],
      [
        { text: campos ? this.assinatura?.[campos.assinatura_esquerda] : this.assinatura?.assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_centro] : this.assinatura?.assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.assinatura_direita] : considerarTesoureiro ? this.assinatura?.tesoureiro : this.assinatura?.assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
      [
        { text: campos ? this.assinatura?.[campos.cargo_esquerda] : this.assinatura?.cargo_assinatura1, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_centro] : this.assinatura?.cargo_assinatura2, alignment: 'center', border: [false, false, false, false] },
        { text: campos ? this.assinatura?.[campos.cargo_direita] : considerarTesoureiro ? this.assinatura?.cargo_tesoureiro : this.assinatura?.cargo_assinatura3, alignment: 'center', border: [false, false, false, false] },
      ],
    ];

    return conteudo;
  }
}