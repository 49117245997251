import { DatePipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { DespesaService } from 'administrativo-lib';
import { ProdutoService } from 'almoxarifado-lib';
import {
  BaseResourceFormComponent, DateFormatPipe, Despesa, EddyAutoComplete, ExercicioService, FuncaoService, GlobalService,
  LoginContabil, PlanoContratacaoAnual, PlanoContratacaoItem, Produto, Unidade, UnidadeService
} from 'eddydata-lib';
import { ConfirmationService, MessageService } from 'primeng/api';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { CompraItemService } from '../../compra/service/compra-item.service';
import { PlanoContratacaoAnualItensService } from '../service/plano-contratacao-anual-itens.service';
import { PlanoContratacaoAnualService } from '../service/plano-contratacao-anual.service';

declare var $: any;

interface FiltroSituacao {
  val: 'todos' | 'revisados' | 'pendentes';
  label: string;
}

@Component({
  selector: 'lib-plano-contratacao-anual-form',
  templateUrl: './plano-contratacao-anual-form.component.html'
})
export class PlanoContratacaoAnualFormComponent extends BaseResourceFormComponent<PlanoContratacaoAnual, LoginContabil> {

  datepipe: DatePipe;
  etapa: number = 1;
  unidadeAutoComplete: EddyAutoComplete<Unidade>;
  subElementoAutoComplete: EddyAutoComplete<Despesa>;
  dataInicio: Date;
  dataFim: Date;
  indiceFinanceiro = 0;
  indiceQuantitativo = 0;
  preLista = [];
  totalPCA: number = 0;
  listaSubelemento: { sub: string, total: number }[] = [];
  listaItens: Array<PlanoContratacaoItem> = new Array<PlanoContratacaoItem>();
  itemAtual: PlanoContratacaoItem = null;
  public filtros: FiltroSituacao[] = [
    { label: 'Exibir todos os cadastros', val: 'todos' },
    { label: 'Exibir cadastros revisados', val: 'revisados' },
    { label: 'Exibir todos não revisados', val: 'pendentes' },
  ];
  public filtroSelecionado: FiltroSituacao['val'] = 'todos';
  exibirSoMaterial: boolean = false;
  exibirSoServico: boolean = false;
  subelemento: string = '';
  subelementoList: Array<string> = new Array<string>();
  subelementoComplete = [];
  todos: boolean = false;
  public produtoAutoComplete: EddyAutoComplete<Produto>;
  public produto: Produto;

  //Variaveis ajuste rapido
  categoria: number = 0;
  dataDesejada: Date;
  subelementoGeral: Despesa;
  marcarCompleto: boolean = false;
  marcarIncompleto: boolean = false;
  seguir: boolean = false;

  public imaskConfig = {
    mask: Number,
    scale: 2,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public tiposCatalogo: { id: number, nome: string }[] = [
    { id: 1, nome: 'CNBS' },
    { id: 2, nome: 'Outros' },
  ];

  public ptBR: any;

  constructor(
    protected injector: Injector,
    private messageService: MessageService,
    protected funcaoService: FuncaoService,
    protected globalService: GlobalService,
    protected unidadeService: UnidadeService,
    private despesaService: DespesaService,
    protected service: PlanoContratacaoAnualService,
    protected itensService: PlanoContratacaoAnualItensService,
    protected produtoService: ProdutoService,
    private compraItemService: CompraItemService,
    protected exercicioService: ExercicioService,
    private confirmationService: ConfirmationService) {
    super(new PlanoContratacaoAnual(), injector, PlanoContratacaoAnual.converteJson, service);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(entidade: PlanoContratacaoAnual): Promise<boolean> {
    return !entidade?.excluido && !entidade?.cadastrado_pncp;
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      orgao: [null, [Validators.required]],
      unidade: [null, [Validators.required]],
      exercicio: [null, [Validators.required]],
      sequencial_pncp: [null],
      cadastrado_pncp: [false],
      itens: [null],
      ignorar_pncp: [false],
      data_pesquida_inicio: [null],
      data_pesquida_fim: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'orgao,unidade,exercicio,itens',
      orderBy: 'itens.numero'
    };
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
    this.unidadeAutoComplete = new EddyAutoComplete(this.entidadeForm.get('unidade'), this.unidadeService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orgao_id: this.login.orgao.id, orderBy: 'nome' }, { number: ['codigo'], text: ['nome', 'codigo'] }
    );
    this.datepipe = new DatePipe('pt');
    this.dataInicio = new DateFormatPipe().transform(new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0), []);
    this.dataFim = new DateFormatPipe().transform(new Date(new Date().getFullYear() - 1, 11, 31, 0, 0, 0), []);
    this.carregarAutoCompletes()
  }

  protected beforeSubmit() {
    try {
      if (this.listaItens.length == 0) {
        // throw new Error('É obrigatório o cadastro de pelo menos 1 item.');
        this.entidadeForm.get('itens').setValue([]);
      } else {
        this.entidadeForm.get('itens').setValue(this.listaItens);
      }

      if (this.entidade.id == null) {
        this.entidadeForm.get('data_pesquida_inicio').setValue(this.dataInicio);
        this.entidadeForm.get('data_pesquida_fim').setValue(this.dataFim);
      }

      this.entidadeForm.get('orgao').setValue(this.login.orgao);
      this.entidadeForm.get('exercicio').setValue(this.login.exercicio);

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterLoad() {
    if (this.entidade.excluido) {
      this.router.navigate(['/plano-contratacao']);
      toastr.warning('Não é possível editar o PCA. PCA Excluído!');
      return;
    }
    this.listaItens = this.entidade?.itens;
    this.subelementoList.push('');
    const parametros = { exercicio_id: this.login.exercicio.id, nivel: 6, orderBy: 'codigo', ativo: true }

    this.subElementoAutoComplete = new EddyAutoComplete(null, this.despesaService, 'codigo', ['nome'],
      parametros,
      { number: ['id', 'codigo'], text: ['nome'] },
      async () => {
        if (this.itemAtual != null) {
          this.itemAtual.subelemento = this.subelementoGeral.codigo + ' - ' + this.subelementoGeral.nome;
        }
      }, 10
    );
    if (!this.listaItens) {
      this.listaItens = new Array<PlanoContratacaoItem>();
    } else {
      for (const i of this.listaItens) {
        if (this.subelementoList.indexOf(i.subelemento) == -1) {
          this.subelementoList.push(i.subelemento);
        }

        i['exibir'] = true;
      }
      toastr.success(`Lista do PCA carregada, ${this.listaItens.length} itens sendo exibidos.`)
    }
    if (this.entidade.id) {
      this.etapa = 3;
    }
    this.carregarAutoCompletes()

    console.log(this.listaItens);
    
  }

  search(event: any) {
    this.subelementoComplete = this.subelementoList.filter((i) => i.includes(event.query.toUpperCase()));
  }

  async filtrarLista() {
    const copia = [].concat(this.listaItens);
    this.listaItens = [];
    toastr.info('Aguarde, processando lista...');
    await timer(1000).toPromise();
    let qtd = 0;
    for (const i of copia) {
      i['selecionado'] = false;
      i['exibir'] = true;

      if (this.subelemento != null && this.subelemento?.trim().length > 0 && i.subelemento != this.subelemento) {
        i['exibir'] = false;
      }

      if (this.filtroSelecionado === 'pendentes' && !i['pendente']) {
        i['exibir'] = false;
      } else if (this.filtroSelecionado === 'revisados' && i['pendente']) {
        i['exibir'] = false;
      } else if (this.filtroSelecionado === 'todos') {
        i['exibir'] = true;
      }

      if (this.exibirSoMaterial && i.classificacao_catalogo == 2) {
        i['exibir'] = false;
      } else if (this.exibirSoServico && i.classificacao_catalogo == 1) {
        i['exibir'] = false;
      }

      qtd = i['exibir'] ? qtd + 1 : qtd;


      this.listaItens.push(i);
    }

    toastr.info(`Filtro aplicado, ${qtd} itens sendo exibidos.`);
  }

  protected afterSubmit(ent: PlanoContratacaoItem) {
    if (this.etapa == 2) {
      this.etapa = 3;
    }
  }

  mudarEtapa(proxima: boolean) {
    if (!proxima) {
      this.etapa--;
      return;
    }

    if (this.etapa == 1 && this.entidadeForm.get('unidade').value == null) {
      toastr.info('Necessário selecionar qual unidade para o PCA.');
      return;
    } else if (this.etapa == 1) {
      this.etapa++;
      return;
    }

    if (this.etapa == 2) {
      this.iniciarPCA();
    }
  }

  carregarPreLista() {
    const param = {
      dtInicial: this.datepipe.transform(this.dataInicio, 'yyyy-MM-dd'),
      dtFinal: this.datepipe.transform(this.dataFim, 'yyyy-MM-dd'),
      orgao_id: this.login.orgao.id,
      indFinanceiro: this.indiceFinanceiro,
      indQuant: this.indiceQuantitativo,
      orderBy: 'p.nome',
      unidade: this.entidadeForm.get('unidade').value.id
    };

    this.compraItemService.planoCompraAnual(param).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
      this.preLista = lista;

      if (this.preLista.length === 0) {
        toastr.info('Não há resultado para sua pesquisa!');
      } else {
        this.calcularTotaisPlanejamento();
      }
    });
  }

  public calcularTotaisPlanejamento() {
    this.totalPCA = 0;
    this.listaSubelemento = [];

    this.preLista.sort((a, b) => a.subelemento > b.subelemento ? 1 : -1);

    this.funcaoService.agrupar(this.preLista, ['subel_cod_nome'], ['valor_total']).forEach(item => {
      this.listaSubelemento.push({ sub: item.grupo['subel_cod_nome'], total: item.totalizadores['valor_total'] });
    });

    this.totalPCA = this.listaSubelemento.reduce((a, b) => a + b.total, 0);
  }

  public iniciarPCA() {
    let ordemItem: number = 1;
    for (const item of this.preLista) {
      const planoItem = new PlanoContratacaoItem();

      // atributos padrão da entidade
      planoItem.catalogo = 2
      const cod = item.classificacao.split('.');
      planoItem.codigo_item = cod[3];
      planoItem.classificacao_superior = `${cod[0]}.${cod[1]}.${cod[2]}`;
      planoItem.classificacao_superior_nome = this.funcaoService.abreviarStr(item.codigo_produto.split('-').slice(1).join('-').trim(), 254);
      planoItem.descricao = (item.descricao.length > 1000 ? item.descricao.substring(0, 1000) : item.descricao);
      planoItem.subelemento = item.subel_cod_nome;
      planoItem.unidade_fornecimento = item.un_nome;
      planoItem.quantidade = item.quantidade_corrigido;
      planoItem.valor_unitario = item.valor_corrigido;
      planoItem.unidade_requisitante = this.entidadeForm.get('unidade').value.nome;

      planoItem.data_desejada = this.buscaDesejadaPadrao();

      planoItem.numero = ordemItem;
      ordemItem++;
      planoItem.categoria_item = item.servico ? 2 : 1;
      planoItem.classificacao_catalogo = item.servico ? 2 : 1;
      planoItem.valor_orcamentario = +(planoItem.quantidade * planoItem.valor_unitario).toFixed(2);

      planoItem['valor_total'] = item.valor_total

      this.listaItens.push(planoItem)
    }

    this.service.filtrar(1, -1, {
      'orgao_id': this.login.orgao.id,
      'exercicio_id': this.login.exercicio.id,
      'unidade_id': this.entidadeForm.get('unidade').value.id
    }).pipe(takeUntil(this.unsubscribe)).subscribe(lista => {
      let pcaComMesmaUnidadeAdministrativa = lista.content

      if (pcaComMesmaUnidadeAdministrativa.length > 0) {
        let pcas = pcaComMesmaUnidadeAdministrativa.map(pca => pca.excluido)
        let todosForamExcluidos = pcas.every(e => e === true);

        if (!todosForamExcluidos) {
          const message = 'Já existe um cadastro não excluído para essa unidade administrativa.'
          this.messageService.add({ severity: 'error', summary: 'PCA duplicado', detail: message });
          throw new Error(message)
        }
      }

      this.submitForm();
    })
  }

  removerPreLista(item, idx) {
    this.preLista.splice(idx, 1)
    toastr.success(`Item ${item.codigo_produto} removido da pre lista!`)
  }

  adicionarItem() {
    this.itemAtual = new PlanoContratacaoItem();
    // this.itemAtual['alterarProduto'] = true;
    this.itemAtual.categoria_item = 1
    this.itemAtual.classificacao_catalogo = 1
    this.itemAtual.data_desejada = this.buscaDesejadaPadrao();

    this.produto = null;
    this.produtoAutoComplete.id = null;
    this.subelementoGeral = null;
    this.carregarAutoCompletes();
  }

  public async editar(item: PlanoContratacaoItem) {
    item.data_desejada = new DateFormatPipe().transform(
      item?.data_desejada,
      []
    );
    await this.encontraProdutoSelecionado(item)
    this.itemAtual = item
  }

  private async encontraProdutoSelecionado(item: PlanoContratacaoItem): Promise<void> {
    this.produto = (await this.produtoService.filtrar(1, 1, {
      orgao_id: this.login.orgao.id,
      nome: item.classificacao_superior_nome,
      relations: 'material.sub_grupo.grupo,aliquota,unidades.unidade'
    }).toPromise()).content[0] ?? null;

    this.produto = null;
    this.produto = (await this.produtoService.filtrar(1, 1,
      {
        codigo: item.codigo_item, orgao_id: this.login.orgao.id,
        relations: 'material.sub_grupo.grupo,aliquota,unidades.unidade'
      }).toPromise()).content[0] ?? null;
    if (this.produto?.id) {
      this.produtoAutoComplete.id = this.produto?.id
      this.carregaAutoCompleteSubElemento()
    }
  }

  async removerItem(item: PlanoContratacaoItem) {
    this.listaItens = this.listaItens.filter(i => i.numero !== item.numero);

    this.listaItens = this.listaItens.map((i, index) => {
      return {
        ...i,
        numero: index + 1
      };
    });

    if (item.id) {
      await this.itensService.remover(item.id).toPromise();
      await this.submitForm()
    }

  }


  async salvarItem(completo?: boolean) {
    if (completo != null) {
      this.itemAtual.pendente = completo;
    }
    if (!this.itemAtual.codigo_item || (this.itemAtual.codigo_item !== '' + this.produto && this.produto !== null)) {
      this.itemAtual.codigo_item = '' + this.produto
    }
    if (!this.itemAtual.id) {
      try {
        this.itemAtual['exibir'] = true;
        if (this.itemAtual.numero) {
          this.listaItens[this.itemAtual.numero - 1] = this.itemAtual;
        } else {
          this.itemAtual.numero = this.itemAtual.numero ? this.itemAtual.numero : this.listaItens.length + 1;
          this.listaItens.push(this.itemAtual);
        }
        if (this.itemAtual.codigo_item !== this.produto?.codigo) {
          this.itemAtual.codigo_item = '' + this.produto.codigo
        }
        if (!this.itemAtual.id) {
          this.itemAtual['exibir'] = true;

          if (!this.itemAtual.numero) {
            let ultimoNumeroInserido = (await this.itensService.filtrar(1, 1, {
              plano_id: this.entidade.id,
              orderBy: 'numero$DESC'
            }).toPromise()).content[0].numero

            this.itemAtual.numero = ultimoNumeroInserido + 1;
          }

          this.itemAtual = await this.itensService.inserir(this.itemAtual).toPromise();

          const existingItemIndex = this.listaItens.findIndex(i => i.numero === this.itemAtual.numero);
          if (existingItemIndex !== -1) {
            this.listaItens[existingItemIndex] = this.itemAtual;
          } else {
            this.listaItens.push(this.itemAtual);
          }
        } else {
          this.itemAtual = await this.itensService.atualizar(this.itemAtual).toPromise();
          this.listaItens = this.listaItens.map(i =>
            i.numero === this.itemAtual.numero ? this.itemAtual : i
          );
        }
        this.itemAtual = null;
        toastr.success('Item salvo com sucesso!');
        await this.submitForm();
      } catch (err) {
        this.messageService.add({ severity: 'error', summary: 'Erro ao salvar item', detail: err.error.payload });
      }
    }
  }

  async marcarTodos() {
    const copia = [].concat(this.listaItens);
    this.listaItens = [];
    toastr.info('Aguarde, processando lista...');
    await timer(1000).toPromise();
    for (const i of copia) {
      if (i['exibir']) {
        i['selecionado'] = true;
        this.listaItens.push(i);
        continue;
      }

      if (this.exibirSoMaterial && i.classificacao_catalogo != 1) {
        i['selecionado'] = false;
        this.listaItens.push(i);
        continue;
      }

      if (this.exibirSoServico && i.classificacao_catalogo != 2) {
        i['selecionado'] = false;
        this.listaItens.push(i);
        continue;
      }

      i['selecionado'] = this.todos;
      this.listaItens.push(i);
    }
  }

  async efetivar() {
    toastr.info('Aguarde, processando solicitação...');
    await timer(1000).toPromise();

    for (const i of this.listaItens) {
      if (!i['selecionado']) continue;

      if (this.categoria) {
        i.categoria_item = this.categoria;
        i.classificacao_catalogo = this.categoria == 1 ? 1 : 2;
      }

      if (this.dataDesejada) {
        i.data_desejada = this.dataDesejada;
      }

      if (this.marcarCompleto) i.pendente = false;

      if (this.marcarIncompleto) i.pendente = true;

      if (this.subelementoGeral) {
        i.subelemento = this.subelementoGeral.codigo + ' - ' + this.subelementoGeral.nome;
      }

      this.atualizaIndiceFinanceiroEQuantitativo(i);
    }

    await this.submitForm();

    toastr.success('Finalizado!');
  }

  private atualizaIndiceFinanceiroEQuantitativo(item: PlanoContratacaoItem) {
    item.quantidade = item.quantidade * (1 + (this.indiceQuantitativo / 100))
    item.valor_unitario = item.valor_unitario * (1 + (this.indiceQuantitativo / 100))

    item.valor_orcamentario = +(item.quantidade * item.valor_unitario).toFixed(2);
  }

  async desmarcarTudo() {
    const copia = [].concat(this.listaItens);
    this.listaItens = [];
    toastr.info('Aguarde, processando lista...');
    await timer(1000).toPromise();
    for (const i of copia) {
      i['selecionado'] = false;
      this.listaItens.push(i);
    }
  }

  private carregarAutoCompletes() {
    this.produtoAutoComplete = new EddyAutoComplete(null, this.produtoService,
      'codigo', ['codigo'], { orgao_id: this.login.orgao.id, orderBy: 'codigo,nome', relations: 'material.sub_grupo.grupo,aliquota,unidades.unidade', 'inativo': false, 'material.servico': this.itemAtual?.classificacao_catalogo == 2 }, { text: ['codigo', 'nome'] }, () => {
        this.itemAtual.codigo_item = this.produto.codigo;
        this.itemAtual.classificacao_superior_nome = this.produto.nome;
        this.itemAtual.classificacao_superior = this.produto?.material?.sub_grupo?.grupo?.codigo + '.' + this.produto?.material?.sub_grupo?.codigo + '.' + this.produto?.material?.codigo;
        this.itemAtual.unidade_fornecimento = this.produto?.unidades[0]?.unidade?.nome;
        this.itemAtual.descricao = this.produto.descricao;
        this.itemAtual.unidade_requisitante = this.entidade?.unidade?.nome ?? '';
        // this.itemAtual['alterarProduto'] = false;
        this.carregaAutoCompleteSubElemento()
      }
    );
  }

  private carregaAutoCompleteSubElemento() {
    const parametros = {
      exercicio_id: this.login.exercicio.id,
      nivel: 6,
      ativo: true,
      relations: 'sub_grupos.sub_grupo',
      orderBy: 'codigo',
    }

    if (this.produto) {
      parametros['sub_grupos.sub_grupo.id'] = this.produto.material.sub_grupo.id;
    }

    this.subElementoAutoComplete = new EddyAutoComplete(null, this.despesaService, 'codigo', ['nome'],
      parametros,
      { number: ['id', 'codigo'], text: ['nome'] },
      async () => {
        if (this.itemAtual != null) {
          this.itemAtual.subelemento = this.subelementoGeral.codigo + ' - ' + this.subelementoGeral.nome;
        }
      }, 10
    );
  }

  // public alterarCampo() {
  //   this.itemAtual['alterarProduto'] = true;
  // }

  public atualizaValorOrcamentarioTotal() {
    this.itemAtual.valor_orcamentario = (this.itemAtual?.valor_unitario ?? 0) * (this.itemAtual?.quantidade ?? 0)
  }

  public voltar() {
    if (this.itemAtual != null) {
      this.itemAtual = null
    } else {
      this.router.navigate(['/plano-contratacao']);
    }
  }

  private buscaDesejadaPadrao(): Date {
    return new Date(this.login.exercicio.ano + 1, 0, 1)
  }

  public cancelaEdicaoItem() {
    this.adicionarItem()
    this.itemAtual = null
  }

  confirmarRemocao(event: Event, item: PlanoContratacaoItem) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `O item "${item.classificacao_superior_nome}" será removido do plano, tem certeza dessa operação?`,
      header: 'Confirmar Remoção',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptIcon: "none",
      rejectIcon: "none",

      accept: () => {
        this.removerItem(item)
      },
    });
  }
}
