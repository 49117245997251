import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import * as toastr from 'toastr';
import {
  BaseResourceFormComponent, Favorecido, LoginContabil, GlobalService, FavorecidoService,
  Convenio, EddyAutoComplete, Recurso, DateFormatPipe, UnidadeService, Unidade, ConvenioComissao,
  TipoConvenio, ConvenioHistorico, ConvenioAditamento,
  ConvenioAlteracao, ConvenioReajuste, ConvenioSituacao, Recebimento, ConvenioRecurso, Licitacao, ConvenioStorage
} from 'eddydata-lib';
import { ConvenioService } from '../service/convenio.service';
import { RecursoService } from '../../../contabil/recurso/service/recurso.service';
import { takeUntil } from 'rxjs/operators';
import { MessageService, SelectItem } from 'primeng/api';
import { ConvenioPrestacaoService } from '../service/convenio-prestacao.service';
import { ConvenioComissaoService } from '../convenio-comissao/service/convenio-comissao.service';
import { TipoConvenioService } from '../../tipo-convenio/service/tipo-convenio.service';
import { ConvenioHistoricoService } from '../service/convenio-historico.service';
import { ConvenioAditamentoService } from '../service/convenio-aditamento.service';
import { ConvenioSituacaoService } from '../service/convenio-situacao.service';
import { ConvenioAlteracaoService } from '../convenio-alteracao/service/convenio-alteracao.service';
import { ConvenioReajusteService } from '../service/convenio-reajuste.service';
import { FichaDespesaService, FichaReceitaService, RecebimentoService } from 'contabil-lib';
import { ConvenioRecursoService } from '../convenio-recurso/service/convenio-recurso.service';
import { ConvenioStorageService } from '../service/convenio-storage.service';

@Component({
  selector: 'lib-convenio-frm',
  templateUrl: './convenio-frm.component.html'
})
export class ConvenioFrmComponent extends BaseResourceFormComponent<Convenio, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  public unidadeAutoComplete: EddyAutoComplete<Unidade>;
  public listaHistoricos: Array<ConvenioHistorico> = new Array<any>();
  public listaAditamentos: Array<ConvenioAditamento> = new Array<any>();
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public recursoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelAutoComplete: EddyAutoComplete<Recurso>;
  public recursoContraAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoContraAutoComplete: EddyAutoComplete<Recurso>;
  public aplicacaoVariavelContraAutoComplete: EddyAutoComplete<Recurso>;
  public listaComissoes: Array<ConvenioComissao> = new Array<any>();
  public listaTipoConvenio: TipoConvenio[];
  public listaAlteracoes: ConvenioAlteracao[];
  public listaTipoFavorecido: Array<any> = []
  public listaFichasDespesas: Array<any> = []
  public listaFichasReceitas: Array<any> = []
  public listaOrigem: Array<any> = []
  public listaEspecie: Array<any> = []
  public listaLegislacao: Array<any> = []
  public listaSituacoes: Array<any> = []
  public listaRecursos: Array<any> = []
  public listaStorage: ConvenioStorage[];
  public liquidacoes: Array<{ id: number, resto: boolean }> = [];
  public pagamentos: Array<{ id: number, especie: string }> = [];
  public recebimentos: Recebimento[] = [];
  public listaReajustes: Array<ConvenioReajuste> = new Array<any>();
  public listaLicitacoes: Array<Licitacao> = new Array<any>();
  public itens: SelectItem[];
  public selectedItem: any;
  public tipoConvenioPossuiOrigem: boolean = false;
  public bloquearOrigemPeloRecurso: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    protected prestacaoService: ConvenioPrestacaoService,
    protected unidadeService: UnidadeService,
    protected convenioService: ConvenioService,
    protected comissaoService: ConvenioComissaoService,
    protected historicoService: ConvenioHistoricoService,
    protected aditamentoService: ConvenioAditamentoService,
    protected recursoService: RecursoService,
    protected tipoConvenioService: TipoConvenioService,
    protected situacaoService: ConvenioSituacaoService,
    protected alteracaoService: ConvenioAlteracaoService,
    protected reajusteService: ConvenioReajusteService,
    protected recebimentoService: RecebimentoService,
    protected fichaReceitaService: FichaReceitaService,
    protected fichaDespesaService: FichaDespesaService,
    protected convenioStorageService: ConvenioStorageService,
    protected convenioRecursoService: ConvenioRecursoService,
    protected favorecidoService: FavorecidoService) {
    super(new Convenio(), injector, Convenio.converteJson, convenioService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected async podeAlterar(_entidade: Convenio): Promise<boolean> {
    return this.login.sistema != 'controle-interno'
  }

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      ano: [this.login.exercicio.ano, Validators.required],
      unidade: [null, [Validators.required]],
      processo: ['', [Validators.required]],
      especie: ['D', [Validators.required]],
      origem: [null, [Validators.required]],
      siconv: [null],
      objeto: [null, [Validators.required]],
      finalidade: [null, [Validators.required]],
      aditivo: [null],
      lei_numero: [null],
      tipo_convenio: ['4', [Validators.required]],
      legislacao: ['0', [Validators.required]],
      enviar_email: [null],
      autorizacao: ['', [Validators.required]],
      data_assinatura: [null, [Validators.required]],
      data_contabilizacao: [null, [Validators.required]],
      data_inicio: [null, [Validators.required]],
      data_termino: [null],
      data_prestacao: [null],
      data_quitacao: [null],
      tipo_fornecedor: [null, [Validators.required]],
      valor_convenio: 0,
      valor_contrapartida: 0,
      saldo_anterior: [null],
      valor_aplicacao: [null],
      valor_devolvido: [null],
      valor_outros: [null],
      valor_recurso: [null],
      valor_convenente: [0],
      numero_convenio: [null],
      arquivo_morto: [null],
      observacao: [null],
      recurso: [null, [Validators.required]],
      aplicacao: [null, [Validators.required]],
      aplicacao_variavel: [null, [Validators.required]],
      recurso_contra: [null],
      aplicacao_contra: [null],
      aplicacao_variavel_contra: [null],
      favorecido: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      exercicio_logado: [this.login.exercicio],
      parente: [null],
      prestacoes: [null],
      comissoes: [null],
      historicos: [null],
      aditamentos: [null],
      recursos: [[]],
      situacoes: [null],
      ativo: [false]
    });
  }

  protected async afterLoad() {
    this.entidade.data_assinatura = new DateFormatPipe().transform(this.entidade.data_assinatura, []);
    this.entidade.data_inicio = new DateFormatPipe().transform(this.entidade.data_inicio, []);
    this.entidade.data_termino = new DateFormatPipe().transform(this.entidade.data_termino, []);
    this.entidade.data_contabilizacao = new DateFormatPipe().transform(this.entidade.data_contabilizacao, []);
    this.favorecidoAutoComplete.id = this.entidade.favorecido ? this.entidade.favorecido.id : null;
    this.recursoAutoComplete.id = this.entidade.recurso ? this.entidade.recurso.id : null;
    this.aplicacaoAutoComplete.id = this.entidade.aplicacao ? this.entidade.aplicacao.id : null;
    this.aplicacaoVariavelAutoComplete.id = this.entidade.aplicacao_variavel ? this.entidade.aplicacao_variavel.id : null;
    this.entidadeForm.get('data_contabilizacao').disable();
    if (!this.podeAlterarAudesp(this.entidade.data_contabilizacao)) {
      this.entidadeForm.get('data_contabilizacao').disable();
      this.entidadeForm.get('data_assinatura').disable();
      this.entidadeForm.get('valor_convenio').disable();
      this.entidadeForm.get('valor_contrapartida').disable();
      this.entidadeForm.get('origem').disable();
      this.entidadeForm.get('tipo_convenio').disable();
      this.entidadeForm.get('tipo_fornecedor').disable();
      this.entidadeForm.get('numero').disable();
    }

    const param = {};
    param['convenio.id'] = this.entidade.id;

    this.historicoService.filtrar(0, -1, param).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      this.listaHistoricos = data ? data.content : new Array<ConvenioHistorico>();
    }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

    this.comissaoService.filtrar(0, -1, param).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      this.listaComissoes = data ? data.content : new Array<ConvenioComissao>();
    }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

    this.convenioStorageService.filtrar(0, -1, param).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      this.listaStorage = data ? data.content : new Array<ConvenioStorage>();
    }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));

    this.itens = [];
    this.itens.push({ label: 'EMPENHOS', value: '1' })
    this.itens.push({ label: 'LIQUIDAÇÕES', value: '2' });
    this.itens.push({ label: 'PAGAMENTOS', value: '3' });
    this.itens.push({ label: 'RECEBIMENTOS', value: '4' });

    this.selectedItem = { label: 'PAGAMENTOS', value: '3' };

    if (['02', '05'].includes(this.entidade.recurso.codigo)) {
      this.bloquearOrigemPeloRecurso = true;
    }

    if (this.entidade.tipo_convenio.origem) {
      this.tipoConvenioPossuiOrigem = true;
    }
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'favorecido,favorecido.tipo,orgao,orgao.cidade,recurso,aplicacao,aplicacao_variavel,unidade,tipo_convenio,' +
        'empenhos.liquidacoes.pagamentos.conta.banco,empenhos.liquidacoes.pagamentos.conta_recurso.recurso,' +
        'empenhos.liquidacoes.pagamentos.conta_recurso.aplicacao,empenhos.liquidacoes.empenho.exercicio,' +
        'recurso_contra,aplicacao_contra,aplicacao_variavel_contra,empenhos.liquidacoes.pagamentos.exercicio,' +
        'empenhos_resto.liquidacoes.pagamentos,empenhos_resto.liquidacoes.empenho.exercicio'
    };
  }

  protected afterInit(): void {
    this.carregarAutoCompletes();
    this.listaTipoFavorecido = [
      { nome: 'FORNECEDOR', id: 'F' },
      { nome: 'CREDOR', id: 'C' }
    ];
    this.listaOrigem = [
      { nome: 'MUNICIPAL', id: 'M' },
      { nome: 'ESTADUAL', id: 'E' },
      { nome: 'FEDERAL', id: 'U' }
    ];
    this.listaEspecie = [
      { nome: '-', id: 'X' },
      { nome: 'CHAMAMENTO', id: 'C' },
      { nome: 'DISPENSA', id: 'D' },
      { nome: 'INEXIGIBILIDADE', id: 'I' },
      { nome: 'OUTROS', id: 'O' }
    ];
    this.listaLegislacao = [
      { nome: '-', id: '0' },
      { nome: 'LEI FEDERAL', id: '1' },
      { nome: 'LEI ESTADUAL', id: '2' },
      { nome: 'LEI MUNICIPAL', id: '3' },
      { nome: 'DECRETO FEDERAL', id: '4' },
      { nome: 'DECRETO ESTADUAL', id: '5' },
      { nome: 'DECRETO MUNICIPAL', id: '6' }
    ];

    this.tipoConvenioService.filtrar(0, 0, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaTipoConvenio = res ? res.content : [];
      }, error => toastr.error(error.message ? error.message : error));

    this.entidadeForm.get('data_contabilizacao').disable()
    if (!this.entidade.id) this.entidadeForm.get('data_contabilizacao').setValue(new Date())
  }

  atualizarSituacao(lista: Array<ConvenioSituacao>) {
    this.listaSituacoes = lista;
    this.salvar(false);

  }

  public verificarDataContabilizacao() {
    let dataContabilizacao: Date = this.entidade.data_contabilizacao;
    if (dataContabilizacao) {
      if (dataContabilizacao < new Date()) {
        return 'disabled';
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  protected beforeSubmit(): void {
    try {
      if (!this.entidadeForm.get('data_contabilizacao').value) {
        throw new Error(`Informe a data de contabilização!`);
      }
      if (!this.entidadeForm.get('numero').value) {
        throw new Error(`Informe o número do convênio!`);
      }
      if (!this.entidadeForm.get('data_assinatura').value) {
        throw new Error(`Informe a data de assinatura!`);
      }
      if (!this.entidadeForm.get('favorecido').value) {
        throw new Error(`Informe o favorecido!`);
      }
      if (!this.entidadeForm.get('finalidade').value) {
        throw new Error(`Informe a finalidade!`);
      }
      if (!this.entidadeForm.get('data_inicio').value) {
        throw new Error(`Informe a data de inicio!`);
      }
      if (!this.entidadeForm.get('processo').value) {
        throw new Error(`Informe o número processo!`);
      }
      if (!this.entidadeForm.get('favorecido').value) {
        throw new Error(`Informe o favorecido para o convênio!`);
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error(`Informe o orgão público do convênio!`);
      }
      if (this.entidadeForm.get('valor_convenio').value && +this.entidadeForm.get('valor_convenio').value == 0) {
        throw new Error(`Informe o valor do convênio`);
      }
      if (!this.podeAlterarAudesp(this.entidadeForm.get('data_contabilizacao').value) && !this.entidade.id) {
        throw new Error(`Data de contabilização não pode se inferior a ${this.login['dias_bloquear_alteracoes']} dia(s)`);
      }

      if (!this.listaRecursos.length) this.listaRecursos.push(this.criarRecursoPrincipal())

      this.entidadeForm.get('historicos').setValue(this.listaHistoricos);
      this.entidadeForm.get('comissoes').setValue(this.listaComissoes);
      this.entidadeForm.get('recursos').setValue(this.listaRecursos);
      this.entidadeForm.get('aditamentos').setValue(this.listaAditamentos);
      this.entidadeForm.get('situacoes').setValue(this.listaSituacoes);
    } catch (e) {
      this.messageService.add({ severity: 'warn', summary: 'Validação', detail: e });
      throw e;
    }
  }

  protected afterSubmit(entidade: Convenio): void {
    // Atualiza a lista atual para que não fique enviando duplicatas
    this.handleChange({ index: 2 });
    this.handleChange({ index: 4 });
    this.handleChange({ index: 6 });

    this.loadResource();
    window.scrollTo(0, 0);
    toastr.success('Registro salvo com sucesso!')
  }

  private carregarAutoCompletes() {
    // autocomplete para unidade
    this.unidadeAutoComplete = new EddyAutoComplete(this.entidadeForm.get('unidade'), this.unidadeService,
      'id', ['codigo', 'nome'], { ppa_id: this.login.ppa.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, relations: 'tipo', orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }
    );
    // autocomplete para recurso
    this.recursoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('recurso'), this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] },
      () => {
        if (this.entidadeForm.get('recurso')?.value?.['codigo'] === '02') {
          this.entidadeForm.get('origem').setValue('E');
          this.bloquearOrigemPeloRecurso = true;
        } else if (this.entidadeForm.get('recurso')?.value?.['codigo'] === '05') {
          this.entidadeForm.get('origem').setValue('U');
          this.bloquearOrigemPeloRecurso = true;
        } else {
          this.bloquearOrigemPeloRecurso = false;
        }
      }
    );
    // autocomplete para aplicacao
    this.aplicacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aplicacao'), this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao variavao
    this.aplicacaoVariavelAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aplicacao_variavel'), this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, 'OR': 'ativo$eq=true;!;!;ativo$null=true', orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
    this.recursoContraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('recurso_contra'), this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 0, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao
    this.aplicacaoContraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aplicacao_contra'), this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 1, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
    // autocomplete para aplicacao variavao
    this.aplicacaoVariavelContraAutoComplete = new EddyAutoComplete(this.entidadeForm.get('aplicacao_variavel_contra'), this.recursoService,
      'id', ['codigo', 'nome'], { nivel: 2, cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['codigo'], text: ['codigo', 'nome'] }
    );
  }

  async handleChange(e) {
    var index = e.index;
    if (index === 1 && this.entidade.id) {
      this.alteracaoService.filtrar(0, -1, {
        'convenio.id': this.entidade.id,
        relations: 'convenio,convenio.tipo_convenio'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaAlteracoes = data ? data.content : new Array<ConvenioAlteracao>();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } else if (index === 2 && this.entidade.id) {
      // Fontes de recurso ------------------------------------------------------------------
      this.convenioRecursoService.filtrar(0, -1, {
        'convenio.id': this.entidade.id,
        relations: 'convenio,recurso,aplicacao,aplicacao_variavel,recurso_contra,aplicacao_contra,aplicacao_variavel_contra'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaRecursos = data ? data.content : new Array<ConvenioRecurso>();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } else if (index === 3 && this.entidade.id) {
      this.convenioRecursoService.obterRelacionamentosFichas(this.entidade.id, this.entidade.orgao.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.listaFichasDespesas = [];
          this.listaFichasReceitas = [];
          for (const item of res) {
            if (item.especie == 'receita') {
              this.listaFichasReceitas.push(item);
            } else {
              this.listaFichasDespesas.push(item);
            }
          }
        });
    } else if (index === 4 && this.entidade.id) {
      // Aditamentos  ----------------------------------------------------------------------
      this.aditamentoService.filtrar(0, -1, {
        'convenio.id': this.entidade.id, relations: 'convenio,convenio.tipo_convenio'
      }).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.listaAditamentos = data ? data.content : new Array<ConvenioAditamento>();
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } else if (index === 5 && this.entidade.id) {
      // reajustes  -------------------------------------------------------------------------
      this.reajusteService.filtrar(0, -1, {
        'convenio.id': this.entidade.id, relations: 'convenio'
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaReajustes = data ? data.content : new Array<ConvenioReajuste>();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } else if (index === 6 && this.entidade.id) {
      // prestação de contas ---------------------------------------------------------------
      if (this.entidade.tipo_fornecedor === 'F') {
        let res = await this.situacaoService.filtrar(0, -1, {
          'convenio.id': this.entidade.id,
          relations: 'itens,usuario,convenio,convenio.orgao,convenio.orgao.cidade',
          orderBy: 'id$DESC'
        }).toPromise();
        this.listaSituacoes = res.content;
      }
    } else if (index === 7 && this.entidade.id) {
      // licitações e contratos ----------------------------------------------------------
      this.convenioService.obterLicitacaoVinculada(this.entidade.id).pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (data: any) => {
            this.listaLicitacoes = data ? data : new Array<Licitacao>();
          }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    } else if (index === 8) {
      // liquidacoes e pagamentos -----------------------------------------------------------
      let empenhos = this.entidade.empenhos;
      for (let empenho of empenhos) {
        for (let liquidacao of empenho.liquidacoes) {
          this.liquidacoes.push({ id: liquidacao.id, resto: false });
          for (let pagamento of liquidacao.pagamentos) {
            this.pagamentos.push({ id: pagamento.id, especie: 'EMO' });
          }
        }
      }
      let empenhos_resto = this.entidade.empenhos_resto;
      for (let empenho of empenhos_resto) {
        for (let liquidacao of empenho.liquidacoes) {
          this.liquidacoes.push({ id: liquidacao.id, resto: true });
          for (let pagamento of liquidacao.pagamentos) {
            this.pagamentos.push({ id: pagamento.id, especie: 'EMR' });
          }
        }
      }
      // recebimentos ----------------------------------------------------------------------
      this.recebimentoService.filtrar(0, -1, {
        'ficha.aplicacao_variavel.id': this.entidade.aplicacao_variavel.id,
        orgao_id: this.login.orgao.id,
        ignoreCondObrig: true,
        relations: 'ficha,ficha.receita,conta'
      }).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.recebimentos = data ? data.content : new Array<Recebimento>();
      }, error => this.messageService.add({ severity: 'error', summary: 'Atenção', detail: error }));
    }
  }

  public salvar(limparTela: boolean): void {
    this.submitForm(limparTela, (entidade: Convenio) => {
      this.listaSituacoes = Object.assign([], entidade['situacoes']);
      this.afterSubmit(entidade);
    });
  }

  public verificarFavorecidoTipoConvenio() {
    const tipoConvenio: TipoConvenio = this.entidadeForm.get('tipo_convenio').value;
    if (tipoConvenio.origem) {
      this.entidadeForm.get('tipo_fornecedor').setValue(tipoConvenio.origem);
      this.tipoConvenioPossuiOrigem = true;
      return;
    }

    this.tipoConvenioPossuiOrigem = false;
  }

  private criarRecursoPrincipal() {
    const model = new ConvenioRecurso()
    model.id = null
    model.principal = true
    model.recurso = this.entidadeForm.get('recurso').value
    model.aplicacao = this.entidadeForm.get('aplicacao').value
    model.aplicacao_variavel = this.entidadeForm.get('aplicacao_variavel').value
    model.recurso_contra = this.entidadeForm.get('recurso_contra').value
    model.aplicacao_contra = this.entidadeForm.get('aplicacao_contra').value
    model.aplicacao_variavel_contra = this.entidadeForm.get('aplicacao_variavel_contra').value

    return model
  }
}
