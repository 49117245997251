import { Component, Injector, Input, OnInit } from '@angular/core';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/api';
import { FuncaoService } from '../../../util/funcao.service';
import { ProgressoService } from '../../../components/progresso/service/progresso.service';
import { PNCPService } from '../../../util/pncp.service';
import { Login } from '../../../entidade/login/login';
import { Empenho } from '../../../entidade/contabil/empenho.model';
import { EmpenhoService } from 'administrativo-lib';
import { NotaEmpenho } from 'contabil-lib';
import { OrgaoAssinaturaService } from '../../../orgao/service/orgao-assinatura.service';

declare var $: any;
@Component({
  selector: 'app-pncp-empenho-cadastro',
  templateUrl: './pncp-empenho-cadastro.component.html',
  styleUrls: ['./pncp-empenho-cadastro.component.css']
})
export class PncpEmpenhosCadastroComponent implements OnInit {

  @Input() login: Login;
  @Input() empenho: Empenho;

  public arquivoApagando: Empenho = new Empenho();

  historicoCompra: Array<any> = new Array<any>();

  // resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected empenhoService: EmpenhoService,
    protected confirmationService: ConfirmationService,
    protected pncpService: PNCPService,
    protected assinaturaService: OrgaoAssinaturaService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService,
  ) {
  }

  public ngOnInit(): void {
  }

  public abrirPncp(tipo: 'C' | 'L') {
    const ano = (tipo == 'L' ? this.empenho.licitacao.exercicio.ano : new Date(this.empenho.data_empenho).getFullYear());
    const sequencial = (tipo == 'L' ? this.empenho.licitacao.sequencial_pncp : this.empenho.sequencial_pncp);

    window.open(this.pncpService.abrirPortalPNCP(this.empenho.orgao.cnpj, ano, sequencial, tipo == 'L' ? 'E' : 'C'), '_blank');
  }

  public idPncp(tipo: 'C' | 'L'): string {
    if (tipo == 'L') {
      return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.empenho.licitacao.sequencial_pncp).slice((this.empenho.licitacao.sequencial_pncp + '').length)}/${this.empenho.licitacao.exercicio.ano}`;
    } else {
      return `${this.login.orgao.cnpj.replace(/\D/g, '')}-1-${("000000" + this.empenho.sequencial_pncp).slice((this.empenho.sequencial_pncp + '').length)}/${new Date(this.empenho.data_empenho).getFullYear()}`;
    }
  }


  public apagarEmpenho() {
    this.confirmationService.confirm({
      header: 'Apagar Empenho',
      key: "empenhoCadastro",
      message: `<pre>Confirma a exclusão desse Empenho? Essa ação NÃO pode ser desfeita.</pre>.`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.excluirEmpenho(this.empenho).subscribe(async (res) => {
          this.cancelarEdicao();
        }, (e) => {
          this.funcaoService.acaoErro(e);
        });
      }
    });
  }

  public cancelarEdicao() {
    this.empenho = null;
    // this.resultados = [];
  }

  public async enviarArquivo() {
    const empenho = await this.buscarDadosEmpenho()

    if (!empenho) {
      toastr.info('Empenho não encontrado')
      return
    }

    const notaEmpenhoPdf = await new NotaEmpenho(this.assinaturaService).criarPdf(empenho.content, this.login)

    notaEmpenhoPdf.getBase64(data => {

      this.confirmationService.confirm({
        header: 'Envio de documento',
        key: "empenhoEnvioArquivo",
        message: `<pre>Confirma o envio do arquivo?</pre>`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim, confirmo envio.',
        rejectLabel: 'Não',
        accept: async () => {

          this.pncpService.enviarArquivoEmpenho(data, this.empenho.id).subscribe((idTransacao) => {
            this.progressoService.show(idTransacao, async (retorno) => {
              this.empenho.nota_emp_sequencial_pncp = retorno
            })
          }, e => {
            this.funcaoService.acaoErro(e);
          })
        }
      })

    })

  }

  private async buscarDadosEmpenho() {
    const parametros = {};

    let relations = 'ficha.executora.unidade,ficha.despesa,ficha.funcao,ficha.subfuncao,ficha.programa,ficha.acao,ficha.recurso,ficha.aplicacao,ficha.despesa,ficha.aplicacao_variavel,';
    relations += 'licitacao,modalidade,subelemento,favorecido.contas.banco,favorecido.tipo,contrato,convenio,liquidacoes,compra,compra.rcms,compra.rcms.requerente,compra.rcms.prazo'

    parametros['relations'] = relations;
    parametros['exercicio_id'] = this.login.exercicio.id;
    parametros['orgao_id'] = this.login.orgao.id;
    parametros['id'] = this.empenho.id;
    parametros['orderBy'] = 'liquidacoes.parcela$ASC';

    return await this.empenhoService.extendido(1, -1, parametros).toPromise()
  }

  public apagarArquivo() {
    this.confirmationService.confirm({
      header: 'Apagar documento',
      key: 'apagarArquivoEmpenho',
      message: `<pre>Confirma a remoção do documento?</pre>`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo exclusão.',
      rejectLabel: 'Não',
      accept: async () => {
        this.pncpService.apagarArquivoEmpenho(this.arquivoApagando).subscribe(async (res) => {
          this.empenho.nota_emp_sequencial_pncp = null
          toastr.success('Arquivo removido com sucesso do pncp!');
          this.fecharDlgApagarArquivo()
        }, e => {
          if (e.error?.message) {
            toastr.error(e.error.message);
          } else {
            this.funcaoService.acaoErro(e);
          }
          this.fecharDlgApagarArquivo()
        });
      }
    });
  }

  public dlgApagarArquivo() {
    this.arquivoApagando = Object.assign({}, this.empenho);
    $('#dlgApagarItemEmpenho').modal('show');
  }

  public fecharDlgApagarArquivo() {
    this.arquivoApagando = new Empenho();
    $('#dlgApagarItemEmpenho').modal('hide');
  }

  public async buscarHistoricoEmpenho() {
    this.historicoCompra = await this.pncpService.consultarHistoricoContrato(this.login.orgao.cnpj.replace(/[./-]/g, ''), this.empenho.exercicio.ano, this.empenho.sequencial_pncp).toPromise();
  }

}
