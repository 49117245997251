import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, ContratoItem, ProdutoUnidade } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ItensRestantes {
  id: number;
  ordem?: number;
  quantidade: string;
  valor_unitario: string;
  descricao: string;
  nome_favorecido: string;
  unidade: string;
  proposta_id?: string;
  rcms?: string;
  compra?: string;
  empenho?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContratoItemService extends BaseResourceService<ContratoItem> {

  constructor(
    protected injector: Injector
  ) {
    super(`contrato-itens`, injector);
  }

  public obterSaldo(orgao: number, id: number,
    parametros?: {
      desconRcmsId?: number, desconCompraId?: number,
      cota?: 'P' | 'R', contrato_id?: number, dataReferencia?: string
    }):
    Observable<{ saldo_qtd: number, saldo_vl: number, valor_unitario: number }> {
    let params = this.converterParametrosStr(parametros);

    return this.http.get<{ saldo_qtd: number, saldo_vl: number, valor_unitario: number, item_id: number }>(
      `${this.login.cidade.id}/${this.api}/saldo/${orgao}/${id}${params}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public itensContratoSaldo(contrato_id: number, orgao_id: number): Observable<ContratoItem[]> {
    return this.http.get<ContratoItem[]>(`${this.login.cidade.id}/${this.api}/itens-contrato-saldo/${contrato_id}/${orgao_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public obterSaldoProdutoContrato(orgao: number, contrato: number, produto: number, parametros?: { desconRcmsId?: number }): Observable<{ saldo_qtd: number, saldo_vl: number, valor_unitario: number }> {
    let params = this.converterParametrosStr(parametros);

    return this.http.get<{ saldo_qtd: number, saldo_vl: number, valor_unitario: number }>(
      `${this.login.cidade.id}/${this.api}/saldo-contrato-produto/${orgao}/${contrato}/${produto}${params}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public bloquearItem(id: number, bloquear: boolean): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/bloquear-item/${id}/${bloquear ? 'S' : 'N'}`, null, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarProdutoUnidade(produto_unidade: ProdutoUnidade, produto: { produto: string, unidade: string, contrato_id: number }): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade`, { produto_unidade, produto }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarProdutoUnidadeId(produto_unidade: ProdutoUnidade, contrato_item_id: number): Observable<void> {
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/atualizar-produto-unidade/${contrato_item_id}`, { produto_unidade }, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public buscarItensRestantes(contrato_id: number): Observable<ItensRestantes[]> {
    const params = new HttpParams().append('contrato_id', contrato_id);

    const options = { ...this.httpOptions(), params };
    return this.http.get<ItensRestantes[]>(`${this.login.cidade.id}/${this.api}/buscar-itens-restantes`, options).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public renegociarPregaoContrato(orgao_id: number, contratosItens: any[]) {
    const body = {
      contratosItens: contratosItens
    }
    return this.http.post(
      `${this.login.cidade.id}/${this.api}/renegociar-contrato/${orgao_id}`, body, {
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token
      }))
    })
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }
}
