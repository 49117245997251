// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Orgao } from '../comum/orgao.model';
import { PlanoConta } from '../contabil/plano-conta.model';
import { GrupoEstoque } from './grupo-estoque.model';
import { Material } from './material.model';
import { SubGrupoSubElementoEstoque } from './sub-grupo-sub-elemento-estoque.model';

export class SubGrupoEstoque extends BaseResourceModel {
  constructor(
    public id?: number,
    public codigo?: number,
    public nome?: string,
    public bec?: boolean,
    public grupo?: GrupoEstoque,
    public editavel?: boolean,
    public materiais?: Material[],
    public plano?: PlanoConta,
    public sub_elementos?: SubGrupoSubElementoEstoque[],
    public orgao?: Orgao
  ) {
    super();
  }
  static converteJson(json: any): SubGrupoEstoque {
    return Object.assign(new SubGrupoEstoque(), json);
  }
}
