import {
  Component, OnInit, OnChanges, ElementRef, Input,
  ViewChild, SimpleChanges, OnDestroy, ViewChildren, QueryList
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { RetencaoService } from '../service/retencao.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Retencao, EddyAutoComplete, FichaExtra, Liquidacao,
  Login, GlobalService, FuncaoService, DateFormatPipe, FavorecidoCei
} from 'eddydata-lib';
import { FichaExtraService } from '../../ficha-extra/service/ficha-extra.service';
import { FavorecidoCeiService } from 'administrativo-lib';


@Component({
  selector: 'lib-retencao',
  templateUrl: './retencao.component.html'
})
export class RetencaoComponent implements OnInit, OnChanges, OnDestroy {

  public ptBR: any;
  public entity: Retencao;
  public entityTemp: Retencao;
  public fichaAutoComplete: EddyAutoComplete<FichaExtra>;
  public ceiAutoComplete: EddyAutoComplete<FavorecidoCei>;
  protected unsubscribe: Subject<void> = new Subject();
  public totalRetido = 0;
  public favorecidoCei;
  retencaoReinf: Retencao;

  @Input() listaRetencoes: Array<any>;
  @Input() entidade: Liquidacao;
  @Input() login: Login;
  @Input() vencimento: Date;
  @Input() data_liquidacao: Date;
  @Input() edicao: boolean = true;
  @Input() objetoReinfTabela1: any = null;
  @Input() valor_liquidado: any = null;
  @Input() visualizacao: boolean = false;

  @ViewChild('btnAdicionarRetencao') public btnAdicionarRetencao: ElementRef;
  @ViewChildren('btnsEditar') public btnsEditar: QueryList<ElementRef>;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  imaskConfigAliquota = {
    mask: Number,
    scale: 6,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
    protected globalService: GlobalService,
    protected funcaoService: FuncaoService,
    protected fichaService: FichaExtraService,
    protected favorecidoCeiService: FavorecidoCeiService,
    protected retencaoService: RetencaoService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    if (this.entidade?.id) {
      this.carregarAutoCompletes();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listaRetencoes && this.entidade?.id) {
      for (let item of this.listaRetencoes) {
        this.calcularRetencao(item)
        this.calcularAliquota(item)
      }
      this.somarRetencao(this.listaRetencoes);
      this.carregarAutoCompletes();
    }
  }

  private desabilitarEdicao(desabilitar: boolean) {
    this.btnsEditar.forEach((btn) => btn.nativeElement.disabled = desabilitar)
    this.btnAdicionarRetencao.nativeElement.disabled = desabilitar;
  }

  public adicionarRetencao() {
    this.carregarAutoCompletes()
    this.entity = new Retencao();
    this.entity.aux = 0;
    this.entity.liquidacao = this.entidade;
    this.entity.data_vencimento = this.vencimento;
    this.entity.anulado = false;
    this.entity.editavel = true;
    this.entity['salvo'] = false;
    if (!this.listaRetencoes) {
      this.listaRetencoes = new Array();
    }
    this.listaRetencoes.unshift(this.entity);
    this.desabilitarEdicao(true);

    setInterval(() => new GlobalService().calendarMascara(), 500)
  }

  public salvarRetencao(item: Retencao) {
    try {
      if (this.favorecidoCei) {
        item.cei = '' + this.favorecidoCei.cei;
      }
      if (item.data_vencimento < this.data_liquidacao) {
        throw new Error('A data de vencimento da retenção não pode ser menor que a data de liquidação')
      }
      if (!item.ficha) {
        throw new Error('Informe a ficha de retenção!');
      }
      this.somarRetencao(this.listaRetencoes);
      if (+item?.valor_retido?.toFixed(2) > this.entidade.valor_liquidado) {
        throw new Error('O valor retido não pode ser maior que o valor liquidado!')
      }
      if (item.ficha?.plano.conta_corrente === '3' && !item.ficha?.favorecido) {
        throw new Error('Informe o favorecido da ficha de renteção em Contabilidade > Cadastros > Ficha Extra')
      }
      if (+this.totalRetido?.toFixed(2) > +this.entidade.valor_liquidado) {
        throw new Error('Os valores retidos não podem ser maior que o valor liquidado')
      }
      if (!+item.valor_retido || +item.valor_retido <= 0) {
        throw new Error('O valor da retenção não pode ser menor ou igual a zero!')
      }

      item.editavel = false;
      item['salvo'] = true;
      this.desabilitarEdicao(false);
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  public editarRetencao(item: Retencao) {
    this.entityTemp = JSON.parse(JSON.stringify(item));
    this.entity = item;
    this.entity.editavel = true;
    this.entity.data_vencimento = new DateFormatPipe().transform(this.entity.data_vencimento, []);
    this.entity['salvo'] = true;
    this.fichaAutoComplete.id = this.entity.ficha ? this.entity.ficha.numero : null;
    this.favorecidoCeiService.obter({ cei: item.cei, favorecido_id: item.liquidacao.empenho.favorecido.id, orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.favorecidoCei = res;
      this.ceiAutoComplete.id = this.favorecidoCei ? this.favorecidoCei.id : null;
    });
    this.desabilitarEdicao(true);
  }

  public cancelarRetencao(item: Retencao, index: number) {
    if (item['salvo'] && item.id === this.entityTemp?.id) {
      item.data_vencimento = this.entityTemp.data_vencimento;
      item.ficha = this.entityTemp.ficha;
      item.valor_retido = this.entityTemp.valor_retido;
      item.valor_aliquota = this.entityTemp.valor_aliquota;
      item.cei = this.entityTemp.cei;
      item.gps = this.entityTemp.gps;

      this.entity = item;
      this.entity.editavel = false;
    } else {
      this.listaRetencoes.splice(index, 1);
    }
    this.desabilitarEdicao(false);
    this.somarRetencao(this.listaRetencoes);
  }

  public confirmarRemocao(item: Retencao, index: number) {
    this.confirmationService.confirm({
      message: 'Tem certeza que deseja remover item?',
      acceptLabel: "Sim",
      rejectLabel: "Não",
      header: 'Remoção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removerRetencao(item, index)
      },
    });
  }

  private removerRetencao(item: Retencao, index: number) {
    if (item.id) {
      this.retencaoService.remover(item.id).pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.listaRetencoes.splice(index, 1);
          this.messageService.add({ severity: 'info', summary: 'Exclusão', detail: 'Registro removido com sucesso!' });
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Exclusão', detail: error.error.payload });
          throw error;
        });
    } else {
      this.listaRetencoes.splice(index, 1);
    }
    this.somarRetencao(this.listaRetencoes);
  }

  private carregarAutoCompletes() {
    // autocomplete para ficha extra
    this.fichaAutoComplete = new EddyAutoComplete(null, this.fichaService,
      'numero', ['numero', 'nome'],
      {
        exercicio_id: this.login.exercicio.id, orgao_id: this.login.orgao.id, retencao: true, excluida: false,
        relations: 'plano,favorecido,ficha', orderBy: 'especie$DESC,nome'
      },
      { number: ['id', 'numero'], text: ['nome'] }
    );

    this.ceiAutoComplete = new EddyAutoComplete(null, this.favorecidoCeiService, 'cei', ['cei'],
      { favorecido_id: this.entidade.empenho?.favorecido?.id, orgao_id: this.login.orgao.id, ativo: true },
      { text: ['cei'] }
    );
  }

  private carregarRetencoes() {
    this.retencaoService.extendido(0, -1, {
      liquidacao_id: this.entidade.id,
      relations: 'ficha,ficha.ficha,ficha.plano,ficha.favorecido,liquidacao,liquidacao.empenho,liquidacao.empenho.favorecido,'
        + 'liquidacao.orgao,liquidacao.exercicio'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        async (data: any) => {
          this.listaRetencoes = data.content;
          this.somarRetencao(this.listaRetencoes);
        });
  }

  public calcularRetencao(item: Retencao) {
    if (item.valor_aliquota > 0) {
      item.valor_retido = this.entidade.empenho.valor_empenho * (item.valor_aliquota / 100)

    }
  }

  public calcularAliquota(item: Retencao) {
    if (item.valor_retido > 0) {
      item.valor_aliquota = item.valor_retido * (100 / this.entidade.empenho.valor_empenho)

    }
  }

  private somarRetencao(list: Array<Retencao>) {
    if (list) {
      this.totalRetido = 0;
      for (const item of list) {
        this.totalRetido += +item.valor_retido
      }
    }
  }

  atualizarReinf(event: Retencao) {
    this.carregarRetencoes();
    this.retencaoReinf = null;
  }

}
