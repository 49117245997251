import { Agenda } from '../entidade/comum/agenda.model';
import { Orgao } from '../entidade/comum/orgao.model';
import { Usuario } from '../entidade/comum/usuario.model';

export class Coluna {
    // padrões
    titulo: string;
    coluna: string;
    agrupar?: boolean;
    converter?: Array<Converter>;
    decimais?: number;
    funcao?: [Expressao];
    removeNegativo?: boolean
    quebrarPaginaAgrupamento?: boolean;
    largura?: any;
    mesclar?: boolean;
    concattitulogrupo?: boolean = true;
    adicionaisHeader?: any; //pode ser adicionado qualquer parametro correpondente ao pdfmaker, vai ser vinculado ao header da tabela
    adicionaisBody?: any; //pode ser adicionado qualquer parametro correpondente ao pdfmaker, vai ser vinculado ao corpo da tabela
    adicionaisBodyEspaco?: any;
    callBack?: (coluna?: Coluna) => any

    // pdfmake
    font?: string; //name of the font
    fontSize?: number; //size of the font in pt
    fontFeatures?: string[]; // array of advanced typographic features supported in TTF fonts (supported features depend on font file)
    lineHeight?: number; //the line height (default: 1)
    bold?: boolean; //whether to use bold text (default: false)
    italics?: boolean; //whether to use italic text (default: false)
    alignment?: 'left' | 'center' | 'right' | 'justify'; //the alignment of the text
    margin?: number[]; //the alignment of the text
    characterSpacing?: number; //size of the letter spacing in pt
    color?: string; //the color of the text (color name e.g., ‘blue’ or hexadecimal color e.g., ‘#ff5500’)
    background?: string; //he background color of the text
    markerColor?: string; //the color of the bullets in a buletted list
    decoration?: 'underline' | 'lineThrough' | 'overline'; //the text decoration to apply
    decorationStyle?: 'dashed' | 'dotted' | 'double' | 'wavy'; //the style of the text decoration
    decorationColor?: string; //the color of the text decoration, see color

    // relatório personalizado 
    // Tipo Selection usado para gerar a tag <selection> com as opções do opcoesSelecao
    subTitulo?: string;
    tipo?: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML' | 'Selection' | 'AutoComplete' | 'Processo' | 'MonthSelector' | 'MultipleAutoComplete';
    autocomplete?: any;
    opcoesSelecao?: { valor: any, label: string }[];
    value?: any;
    padrao?: boolean;
    filtro1?: any;
    filtro2?: any;
    itensMultiAutoComplete?: any[] = [];
    itemTempMultiAutoComplete?: any;
    semExcluir?: boolean;

    cols?: number;
    colsPart?: number[];
    mask?: string | { mask: string }[];
    notUnmask?: boolean;
    colunas?: Coluna[];
    colunasFiltro?: string[];
    layout?: {};
    larguras?: (string | number)[];
    totalizar?: (string | {})[];
    distinct?: string;
}

export class Expressao {
    valor?: (number | '-' | '+' | '(' | ')' | '*' | '/' | string)[];
    se?: Se;
}

export class Se {
    condicao: string;
    verdadeiro: (number | '-' | '+' | '(' | ')' | '*' | '/' | string | {})[];
    falso: (number | '-' | '+' | '(' | ')' | '*' | '/' | string | {})[];
}

export class Converter {
    id: any;
    nome: any;
}

export type Sistemas =
    'controle-interno' | 'planejamento' | 'tesouraria' | 'compras' | 'contabil' | 'patrimonio' | 'recursos-humanos' | 'protocolo' |
    'almoxarifado' | 'terceiro-setor' | 'indicador-gestao' | 'transparencia' | 'fatura' | 'frota' | 'e-sic' | 'protocolo-cidadao' | 'legislativo' | 'diario-oficial' | 'site' | 'comum' | 'teleonco' |
    'licitacao' | 'requisicao' | 'portal-entidade';

export type CondicoesFiltrar = 'eq' | 'ne' | 'gt' | 'ge' | 'lt' | 'le' | 'like' | 'in' | 'not_in' | 'null' | 'not_null';

export type AudespXML =
    'PLAN-CADASTRO' |
    'PLAN-LOA-INICIAL' | 'PLAN-LOA-ATUALIZADA' |
    'PLAN-LDO-INICIAL' | 'PLAN-LDO-ATUALIZADA' |
    'PLAN-PPA-INICIAL' | 'PLAN-PPA-ATUALIZADO' |
    'CADASTROS-CONTABEIS' |
    'BALANCETE-ISOLADO-CONTA-CORRENTE' | 'BALANCETE-ISOLADO-CONTA-CONTABIL';

export type ChaveValor = { chave: string, valor: any };

export type FieldPersonalizado = {
    nome: string;
    campo: string;
    tipoPrototype: any;
    tipo?: 'String' | 'Number' | 'Date' | 'Boolean' | 'HTML';
    classe?: string;
    nomeClasse?: string;
    nomeClasseSimples?: string;
    modulos?: ModuloSistema[];
};

export interface DropdownValue<T> {
    label: string;
    value: T
}

export class ModuloSistema {
    modulo: string;
    sistema: Sistemas;
    campos: FieldPersonalizado[];
}

export class FullCalendarProperties {
    agenda: Agenda;

    id?: number;
    allDay?: boolean;
    start?: string | Date;
    end?: string | Date;
    title?: string;
    color?: any;
    extendedProps?: any;

    descricao?: string;
    recorrente?: 'N' | 'D' | 'DS' | 'S' | 'M' | 'A';
    concluido?: boolean;
    orgao?: Orgao;
    usuario?: Usuario;

    url?: string;
    groupId?: any;
    daysOfWeek?: any;
    startTime?: string;
    endTime?: string;
    startRecur?: any;
    endRecur?: any;
    classNames?: any;
    editable?: any;
    startEditable?: any;
    durationEditable?: any;
    resourceEditable?: any;
    resourceIds?: any;
    rendering?: any;
    overlap?: any;
    constraint?: any;
    backgroundColor?: any;
    borderColor?: any;
    textColor?: any;
}

export const DIA_TIME: number = 24 * 60 * 60 * 1000;

/**
 * B = Baixa,
 * C = Compra,
 * I = Inventário,
 * O = Doação,
 * V = Devolução,
 * T = Transferência,
 * E = Estorno
 */
export type EspecieAlmoxarifado = 'B' | 'C' | 'I' | 'O' | 'V' | 'T' | 'E' | 'L' | 'D' | 'F' | 'R' | 'A';

export type StatusMovimentacao = 'P' | 'A' | 'R' | 'C' | 'F';

export type loteSaldo = { nome: string, saldo: number, vencimento: Date };

export type unidadeSaldo = { unidade: ChaveValor, lotes: loteSaldo[], saldo: number };

export type produtoSaldo = { produto: ChaveValor, unidades: unidadeSaldo[], saldo: number };


export class Ordem {
    campo: Coluna;
    asc: boolean;
}

export class FiltroPersonalizado {
    campo: Coluna;
    operador: CondicoesFiltrar;
    valor: any;
    valorTemp: any;

    retornarOperador(oper: string): CondicoesFiltrar {
        if (oper) {
            switch (oper) {
                case 'like':
                    return 'like';
                case 'gt':
                    return 'gt';
                case 'ge':
                    return 'ge';
                case 'lt':
                    return 'lt';
                case 'le':
                    return 'le';
                case 'ne':
                    return 'ne';
                case 'null':
                    return 'null';
                case 'not_null':
                    return 'not_null';
                case 'in':
                    return 'in';
                case 'not_in':
                    return 'not_in';
                default:
                    return 'eq';
            }
        } else {
            return null;
        }
    }
}

export type FormatoExportacao = 'pdf' | 'xlsx' | 'xml' | 'JSON' | 'csv' | 'docx' | 'txt';

export type SituacaoLicitacao = 'PENDENTE' | 'ADJUDICADA' | 'ATIVA' | 'ANULADA' | 'SUSPENSA' |
    'DESERTA' | 'FRACASSADA' | 'HOMOLOGADA' | 'HOMOLOGADA – PARCIAL' | 'REVOGADA' | 'REMOVIDO' | 'OUTRA';

export type SituacaoPregao = 'PENDENTE' | 'EM ANDAMENTO' | 'PARALISADO' | 'SUSPENSO' | 'CONCLUIDO COM RECURSO' |
    'CONCLUIDO SEM RECURSO' | 'REVOGADO' | 'FRACASSADO' | 'DESERTO';

export type FormatoLicitacao = 'REGISTRO_PRECO' | 'RESERVA_VALOR';

export type CriterioJulgamento = 'MENOR_PRECO' | 'MAIOR_PRECO' | 'MENOR_DESCONTO' | 'MAIOR_DESCONTO';

export type CriterioJulgamentoValor = 'VALOR_UNITARIO' | 'VALOR_TOTAL';

export type TipoPregao = 'REGISTRO_PRECO' | 'PROCEDIMENTO_COMUM';

export type CriterioDesempatePregao = 'CREDENCIAMENTO' | 'SORTEIO';

export type CriterioReducaoMemorial = 'V' | 'P';

export type FavorecidoEnquadramento = 'EMP_NORMAL' | 'EPP' | 'ME' | 'MEI' | 'PF' | 'GRAN_PORTE' | 'OUTRA' | 'S_A';

export type SituacaoProponente = 'HABILITADO' | 'INABILITADO' | 'DESCLASSIFICADO';

export type SituacaoProposta = 'NAO_JULGADO' | 'EMPATADO' | 'NAO_CLASSIFICADO' | 'CLASSIFICADO' | 'FRACASSADO' | 'DESCLASSIFICADO' | 'VENCEDOR' | 'DECLINADO'
    | 'EM_NEGOCIACAO' | 'DESISTIDO' | 'DESISTIDO_EPPME' | 'INABILITADO' | 'INEXEQUIVEL' | 'SUSPENSO';

export type ComissaoMembroAtribuicao = '1 - PRESIDENTE' | '2 - MEMBRO' | '3 - PREGOEIRO' | '4 - EQUIPE DE APOIO' | '5 - SERVIDOR DESIGNADO' |
    '6 - LEILOEIRO' | '7 - SECRETÁRIO' | '8 - AUTORIDADE PREGÃO' | '9 - RESPONSÁVEL' | '10 - AUTORIDADE CONVITE' | '11 - AGENTE DE CONTRATAÇÃO' |
    '12 - COMISSÃO DE CONTRATAÇÃO';

export type ComissaoMembroNaturezaCargo = '1 - EFETIVO' | '2 - COMISSIONADO' | '3 - AGENTE POLÍTICO' | '4 - EMPREGADO TEMPORÁRIO' | '5 - EMPREGADO PÚBLICO' | '6 - OUTROS';

export type TipoComissao = '1 - PERMANENTE' | '2 - ESPECIAL' | '3 - SERVIDOR DESIGNADO';

export type TipoContrato = 'CONCESSAO' | 'PERMISSAO' | 'OUTROS';

export enum TipoContato {
    TELEFONE = 'TE',
    EMAIL = 'EM',
    SITE = 'SI',
    OUTROS = 'OU'
}

export enum TipoFavorecido {
    FORNECEDOR = 'F',
    CREDOR = 'C'
}

export type CamposEnderecoViaCEP = 'cep' | 'logradouro' | 'complemento' | 'bairro' | 'localidade' | 'uf' | 'ibge' | 'gia' | 'ddd' | 'siafi';

export type TipoArquivoFase4 = 'LIC_REG_SIM' | 'LIC_REG_NAO1' | 'LIC_REG_NAO2' | 'LIC_REG_NAO3' | 'AJUSTE_CONTRATO' | 'AJUSTE_EMPENHO' | 'TERMO_ADITIVO' | 'EXECUCAO'
    | 'EMPENHO' | 'DOC_FISCAL' | 'PAGAMENTO' | 'EXIGENCIA_OBRAS';

export type TipoObjetoLicitacao = 'COMPRA_SERVICO' | 'COMPRA_SERVICO_TI' | 'ENGENHARIA';

export type TipoHistoricoTombo = 'TOMBAMENTO' | 'BAIXA' | 'MANUTENCAO' | 'TRANSFERENCIA' | 'EMPRESTIMO' | 'REATIVACAO' | 'OUTRO' | 'INVENTARIO';

export type SituacaoPatrimonio = 'ATIVO' | 'EXCLUIDO' | 'BAIXADO' | 'CESSAO' | 'NAO_LOCALIZADO' | 'MANUTENCAO' | 'SALVO';

export type TipoDoacaoPatrimonio = 'DE' | 'DU' | 'DM' | 'DA' | 'DJ' | 'DF'
/** DE - Doação do Estado, DU - Doação da União, DM - Doação de outros municípios, DA - Doação de Autarquias, DJ - Doação Pessoa Jurídica (CNPJ),  DF - Doação Pessoa Física (CPF) */

export type SituacaoImovel = 'PROPRIO' | 'EM_AQUISICAO' | 'CEDIDO_A' | 'CEDIDO_POR' | 'LOCADO' | 'TEM_POSSE';
// | 'RECEBIDO' | 'EXCLUIDO' | 'ALIENACAO' | 'COMPRA' | 'OBRAS' | 'CESSAO' | 'PERMISSAO'
//     | 'PERMISSAO_ONEROSA' | 'PERMISSAO_GRATUITA' | 'CONCESSAO' | 'COMODATO' | 'DEVOLUCAO' | 'INTERESSE' | 'TRANSFERENCIA' | 'PERMUTA' | 'APURACAO' | 'DEMOLICAO'
//     | 'INVASAO' | 'MANUTENCAO' | 'DOACAO' | 'REGULARIZACAO' | 'COMPARTILHADO' | 'DESAPROPRIACAO' | 'DOACAO_SEM' | 'CESSAO_SEM';

export type ConservacaoPatrimonio = 'EXCELENTE' | 'BOM' | 'REGULAR' | 'RUIM' | 'INSERVIVEL';

export type TipoAcaoNotificacao = 'REDIRECIONAR' | 'ACAO';

export type CloudArmazenamento = 'FIREBASE' | 'SFTP' | 'FTP';

export type TipoIntegracaoLicitacao = 'PCP' | 'BLL' | 'BNC' | 'NENHUMA';

export type PrioridadeRCMS = 'NORMAL' | 'MEDIA' | 'URGENTE';

export type TipoDestinoMerenda = 'M' | 'MI' | 'E' | 'EI' | 'C' | 'F' | 'T' | 'H';

export type TipoOcorrenciaLicitacao = 'MEMORIAL' | 'RECURSO' | 'ANULACAO' | 'REVOGACAO' | 'IMPUGNACAO' | 'PARECER' | 'HABILITACAO' | 'OUTRO';

export type TipoOcorrenciaVeiculo = 'PLACA' | 'OUTRO';

export type PeriodosMerenda = 'M' | 'T' | 'N' | 'D';

export type TipoOcorrenciaContrato = 'PENALIDADE' | 'OUTRO';

export type TipoAquisicaoVeiculo = 'CEDIDO' | 'LOCADO' | 'TERCEIRIZADO' | 'PROPRIO';

export type TipoOrdemServico = 'OLEO' | 'PNEU' | 'CORREIA' | 'REVISAO' | 'EXTINTOR' | 'BATERIA' | 'PECAS' | 'MANUTENCAO';

export type SituacaoEmprestimo = 'PENDENTE' | 'EMPRESTADO' | 'EMPRESTADO_PARCIAL' | 'DEVOLVIDO' | 'DEVOLVIDO_PARCIAL' | 'NAO_DEVOLVIDO'

export type TipoUnidade = 'ASSISTENCIA_SOCIAL' | 'EDUCACAO' | 'SAUDE' | 'OUTROS';

export type TipoLogSigs = 'Lote' | 'Medicamento' | 'Fralda';

export type TipoMovimentoLogSigs = 'Entrada' | 'Saída';
export type TipoInvasao = 'Uso espaço' | 'Uso terreno';

export type EquipeManutencao = 'Própria' | 'Terceiros';

export type TipoTransferencia = 'Transferência' | 'Solicitação de Transferência';

export type SituacaoFatura = 'Aguardando' | 'Aprovada' | 'Recusada';
