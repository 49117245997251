import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SetorService } from '../../almoxarifado/setor/service/setor.service';
import { Relatorio } from '../../components/report';
import { Coluna, ConservacaoPatrimonio, FiltroPersonalizado, FormatoExportacao, ModuloSistema, Ordem, SituacaoPatrimonio } from '../../components/types';
import { Setor } from '../../entidade/almoxarifado/setor.model';
import { RelatorioPersonalizado } from '../../entidade/comum/relatorio-personalizado.model';
import { LoginContabil } from '../../entidade/login/login-contabil';
import { BaseResourceFormComponent } from '../../models/base-resource-form';
import { BaseResourceService } from '../../models/services/base-resource.service';
import { FuncaoService } from '../../util/funcao.service';
import { GlobalService } from '../../util/global.service';
import { RelatorioPersonalizadoService } from '../service/relatorio-personalizado.service';
import { Sistemas } from '../../components/types';
import { RelatorioPersonalizadoModules } from '../relatorio-personalizado-form/relatorio-personalizado-form.component';

export interface System {
  id: Sistemas;
  name: string;
}

declare var $: any;

@Component({
  selector: 'lib-relatorio-personalizado-shw',
  templateUrl: './relatorio-personalizado-shw.component.html',
  providers: [ConfirmationService]
})
export class RelatorioPersonalizadoShwComponent
  extends BaseResourceFormComponent<RelatorioPersonalizado, LoginContabil>
  implements OnChanges {

  /**
   * Declaração de variáveis
   */
  @ViewChild('titulo_') inputField: ElementRef;

  @Input() modulos: RelatorioPersonalizadoModules[];

  @Output() systemChange = new EventEmitter<Sistemas>();

  public service: BaseResourceService<RelatorioPersonalizado>;
  public listaOperadores: any[];
  public listDados: any[];
  public listDadosFormatado: any[];
  public campos: Coluna[] = [];
  public camposDisponiveis: Coluna[] = [];
  public camposSelecionados: Coluna[] = [];
  public filtros: FiltroPersonalizado[] = [];
  public filtrosAux: FiltroPersonalizado[];
  public ordenacao: Ordem[] = [];
  public selectOrdenacao: Coluna;
  public agrupamento: Coluna[] = [];
  public selectAgrupamento: Coluna;
  public totalizador: Coluna[] = [];
  public selectTotalizador: Coluna;
  public listCampos: Coluna[];
  public listGrupos: { id: string, nome: string }[];
  public listModulos: ModuloSistema[];
  public moduloAtual: ModuloSistema;
  public grupoAtual: string;
  public orientacao = false;
  public paginaCorrente: number;
  public paginaTotal: number;
  public url: string;
  public filtrosDinamicos: FiltroPersonalizado[] = [];
  private habilitarSalvar = true;
  public modalPDF: boolean = false;
  private parametros: {} = {};
  public systemsList: System[] = [
    { id: 'contabil', name: 'Contábil' },
    { id: 'almoxarifado', name: 'Almoxarifado' },
    { id: 'patrimonio', name: 'Patrimônio' },
    { id: 'compras', name: 'Compras' },
    { id: 'requisicao', name: 'Requisição' },
    { id: 'licitacao', name: 'Licitação' }
  ];

  public selectedSystemId: Sistemas;

  // filtros especificos
  public listaSetor: Setor[];
  public listaSituacao: { id: SituacaoPatrimonio, nome: string, estilo: string }[];
  public listaConservacao: { id: ConservacaoPatrimonio, nome: string, estilo: string }[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected messageService: MessageService,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected setorService: SetorService,
    protected relatorioService: RelatorioPersonalizadoService) {
    super(new RelatorioPersonalizado(), injector, RelatorioPersonalizado.converteJson, relatorioService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      nome: [null, [Validators.required]],
      descricao: [null],
      campos: [null],
      agrupamentos: [null],
      totalizadores: [null],
      url: [null, [Validators.required]],
      administrador: [0, [Validators.required]],
      sistema: [this.login.usuario.sistema, [Validators.required]],
      modulo: [null, [Validators.required]],
      orientacao: ['landscape', [Validators.required]],
      usuario: [this.login.usuario, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
    });
  }

  protected parametrosExtras(): {} {
    return {};
  }

  protected afterInit(): void {
    this.selectedSystemId = this.login.sistema;
    this.findModules();

    this.listaOperadores = [
      { id: 'eq', nome: 'Igual' },
      { id: 'ne', nome: 'Diferente' },
      { id: 'gt', nome: 'Maior' },
      { id: 'ge', nome: 'Maior ou igual' },
      { id: 'lt', nome: 'Menor' },
      { id: 'le', nome: 'Menor ou igual' },
      { id: 'like', nome: 'Contém' },
      { id: 'in', nome: 'Contido em' },
      { id: 'not_in', nome: 'Não contido em' },
      { id: 'null', nome: 'Vazio/Nulo' },
      { id: 'not_null', nome: 'Preenchido/Não nulo' }
    ];
    this.paginaCorrente = 1;

    // filtros especificos
    this.setorService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id, orderBy: 'nome,codigo' }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => this.listaSetor = res ? res.content : [], error => this.funcaoService.acaoErro(error));
    this.listaSituacao = this.globalService.obterSituacaoPatrimonio()
    this.listaConservacao = this.globalService.obterConservacaoPatrimonio()

  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected beforeSubmit() {
    try {
      // constroi campo url através dos vetores
      this.montarUrl();

      // valida preenchimento dos campos
      if (!this.entidadeForm.get('usuario').value) {
        throw new Error('Informe o usuário do relatório');
      }
      if (!this.entidadeForm.get('orgao').value) {
        throw new Error('Informe o órgão do relatório');
      }
      if (!this.entidadeForm.get('modulo').value || this.entidadeForm.get('modulo').value.length === 0) {
        throw new Error('Informe o módulo do relatório');
      }
      if (!this.entidadeForm.get('url').value || this.entidadeForm.get('url').value.length === 0) {
        throw new Error('Informe a url do relatório');
      }
      if (!this.entidadeForm.get('administrador').value) {
        this.entidadeForm.get('administrador').setValue(false);
      }
      this.entidadeForm.get('orientacao').setValue(this.orientacao ? 'portrait' : 'landscape');

      // valida informações preenchidas
      if (this.entidadeForm.get('nome').value.length > 50) {
        throw new Error('O nome do relatório deve conter até 50 caracteres');
      }
      if (this.entidadeForm.get('descricao').value && this.entidadeForm.get('descricao').value.length > 200) {
        throw new Error('A descrição do relatório devem conter até 200 caracteres');
      }
      if (this.entidadeForm.get('campos').value.length > 500) {
        throw new Error('Os campos do relatório devem conter até 500 caracteres');
      }
      if (this.entidadeForm.get('agrupamentos').value && this.entidadeForm.get('agrupamentos').value.length > 200) {
        throw new Error('Os agrupamentos do relatório devem conter até 200 caracteres');
      }
      if (this.entidadeForm.get('totalizadores').value && this.entidadeForm.get('totalizadores').value.length > 200) {
        throw new Error('Os totalizadores do relatório devem conter até 200 caracteres');
      }

      this.entidadeForm.get('sistema').setValue(this.selectedSystemId);
    } catch (e) {
      this.funcaoService.acaoErro(e);
    }
  }

  protected afterLoad() {
    this.selectedSystemId = this.entidade.sistema;
    this.changeSystem();
  }

  public filtrarDisponiveis() {
    this.camposDisponiveis = [];
    if (this.entidade.id) {
      this.carregarCamposEntidade();
    }

    for (const col of this.campos) {
      if (!col) continue
      if (col.subTitulo === this.grupoAtual) {
        this.camposDisponiveis.push(col);
      }
    }

    for (const c of this.camposSelecionados) {
      if (!c) continue
      for (let i = 0; i < this.camposDisponiveis.length; i++) {
        if (c.coluna === this.camposDisponiveis[i].coluna) {
          this.camposDisponiveis.splice(i, 1);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modulos.previousValue && changes.modulos.previousValue !== changes.modulos.currentValue) {
      this.findModules();
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private limparDados(): void {
    this.camposSelecionados = [];
    this.filtros = [];
    this.ordenacao = [];
    this.agrupamento = [];
    this.totalizador = [];
  }

  private carregarCamposEntidade(): void {
    this.orientacao = this.entidade.orientacao === 'landscape';

    const campos = this.entidadeForm.get('campos').value || this.entidade.campos;
    const agrupamentos = this.entidadeForm.get('agrupamentos').value || this.entidade.agrupamentos;
    const totalizadores = this.entidadeForm.get('totalizadores').value || this.entidade.totalizadores;
    const url = this.entidadeForm.get('url').value || this.entidade.url;

    if (campos) {
      for (const c of String(campos).split(',')) {
        if (!c) continue
        this.camposSelecionados.push(this.campos.find(campo => campo.coluna === c));
      }
    }
    if (agrupamentos) {
      for (const c of String(agrupamentos).split(',')) {
        if (!c) continue
        this.agrupamento.push(this.campos.find(campo => campo.coluna === c));
      }
    }
    if (totalizadores) {
      for (const c of String(totalizadores).split(',')) {
        if (!c) continue
        this.totalizador.push(this.campos.find(campo => campo.coluna === c));
      }
    }
    if (url) {
      for (const propName of String(url).split('&')) {
        if (!propName) continue
        const valores = propName.split('=');
        if (valores[0] === 'orderBy') {
          for (const str of String(valores[1]).split(',')) {
            if (!str) continue
            const ord: string[] = str.split('$');
            const ordem: Ordem = new Ordem();
            ordem.campo = this.campos.find(campo => campo.coluna === ord[0]);
            ordem.asc = ord.length > 1 ? ord[1] !== 'DESC' : true;
            this.ordenacao.push(ordem);
          }
        } else if (valores[0] !== 'relations' && valores[0] !== 'fields') {
          const filtro: FiltroPersonalizado = new FiltroPersonalizado();
          const flt: string[] = propName.split('=');

          if (flt[0].includes('$')) {
            const oper = flt[0].split('$');
            filtro.campo = this.campos.find(campo => campo.coluna === oper[0]);
            filtro.operador = filtro.retornarOperador(oper[1]);
          } else {
            filtro.campo = this.campos.find(campo => campo.coluna === flt[0]);
            filtro.operador = 'eq';
          }
          filtro.valor = flt.length > 1 ? flt[1] : '';
          filtro.valor = filtro.valor === '???' ? '' : filtro.valor;
          // filtro.valor = filtro.campo.tipo === 'Date' ? this.funcaoService.converteDataBR(filtro.valor) : filtro.valor;

          const fRepetido = this.filtros.find(item => item.valor == filtro.valor && item.campo.titulo === filtro.campo.titulo && item.operador == filtro.operador)

          if (!fRepetido) {
            this.filtros.push(filtro);
          }
        }
      }
    }
    this.atualizarCamposAgrupados();
  }

  public montarUrl() {
    this.url = '';

    // campos
    let campos = '';
    for (const campo of this.camposSelecionados) {
      if (!campo) continue
      if (campo.coluna.includes('.')) {
        this.url += (this.url.length > 0 ? '' : 'fields=') + campo.coluna + ',';
      }
      campos += campo.coluna + ',';
    }
    // agrupamentos
    let agrupamentos = '';
    for (const campo of this.agrupamento) {
      if (!campo) continue
      agrupamentos += campo.coluna + ',';
    }
    // totalizadores
    let totalizadores = '';
    for (const campo of this.totalizador) {
      if (!campo) continue
      totalizadores += campo.coluna + ',';
    }

    // ordenacao
    this.url += this.agrupamento.length || this.ordenacao.length > 0 ? '&orderBy=' : '';
    // inclui agrupamentos antes da ordenação para agrupar corretamente
    if (agrupamentos) {
      this.url += agrupamentos;
    }
    for (const ordem of this.ordenacao) {
      if (!ordem || !ordem.campo) continue
      // apenas permite ordenacao de campos que nao fazem parte do agrupamento
      if (!this.agrupamento.find(g => g.coluna === ordem.campo.coluna)) {
        this.url += ordem.campo.coluna + '$' + (ordem.asc ? 'ASC' : 'DESC') + ',';
      }
    }

    // filtros
    let urlEntidade = this.url;
    let encontrou = false;
    for (const filtro of this.filtros) {
      if (filtro && filtro.campo && filtro.operador) {
        if (filtro.campo.tipo === 'Boolean') {
          filtro.valor = String(filtro.valor).includes('undefined') ? false : filtro.valor;
          filtro.valorTemp = String(filtro.valorTemp).includes('undefined') ? false : filtro.valorTemp;
        }
        const skipValor: boolean = filtro.operador === 'null' || filtro.operador === 'not_null';
        encontrou = true;

        if (filtro.campo.tipo === 'Date') {
          this.url += (this.url.length === 0 ? '' : '&')
            + filtro.campo.coluna
            + (filtro.operador !== 'eq' ? '$' + filtro.operador : '')
            + (skipValor ? '' : '='
              + ((this.funcaoService.converteDataSQL(filtro.valor) && !String(this.funcaoService.converteDataSQL(filtro.valor)).includes('undefined')) ? this.funcaoService.converteDataSQL(filtro.valor) : filtro.valorTemp)
            );
        } else {
          this.url += (this.url.length === 0 ? '' : '&')
            + filtro.campo.coluna
            + (filtro.operador !== 'eq' ? '$' + filtro.operador : '')
            + (skipValor ? '' : '='
              + ((filtro.valor && !String(filtro.valor).includes('undefined')) ? filtro.valor : filtro.valorTemp)
            );
        }

        urlEntidade += (urlEntidade.length === 0 ? '' : '&')
          + filtro.campo.coluna
          + (filtro.operador !== 'eq' ? '$' + filtro.operador : '')
          + (skipValor ? '' : '='
            + ((filtro.valorTemp && !String(filtro.valorTemp).includes('undefined')) ? '???' : filtro.valor)
          );
      }
    }

    if (!encontrou) {
      toastr.error('Nenhum filtro informado!');
      return
    }

    // atribui valores aos objetos do form
    this.entidadeForm.get('modulo').setValue(this.moduloAtual.modulo);
    this.entidadeForm.get('campos').setValue(campos);
    this.entidadeForm.get('agrupamentos').setValue(agrupamentos);
    this.entidadeForm.get('totalizadores').setValue(totalizadores);
    this.entidadeForm.get('url').setValue(urlEntidade);
  }

  protected afterSubmit(ent: RelatorioPersonalizado) {
    if (this.currentActionRoute === 'novo') {
      this.habilitarSalvar = false;
    }
  }

  public buscarDados(salvar?: boolean) {
    try {
      salvar = this.habilitarSalvar ? salvar : false;
      // filtros sem valor preenchido é solicitado ao usuário
      this.filtrosDinamicos = [];
      for (const filtro of this.filtros) {
        if (filtro && filtro.campo && filtro.operador) {
          if (filtro.campo.tipo === 'Boolean' || filtro.operador === 'not_null' || filtro.operador === 'null') {
            continue
          }
          if (!filtro.valor || String(filtro.valor).includes('undefined')) {
            if (!filtro.valorTemp || String(filtro.valorTemp).includes('undefined')) {
              this.filtrosDinamicos.push(filtro);
            }
          }
        }
      }
      if (this.filtrosDinamicos.length > 0) {
        this.filtrosAux = JSON.parse(JSON.stringify(this.filtros));
        $('#modalFiltros').modal('show');
        return;
      } else {
        $('#modalFiltros').modal('hide');
      }

      // se não informou o nome do relatório apenas gera os dados sem salvar o relatório
      if (!this.entidadeForm.get('nome').value || this.entidadeForm.get('nome').value.length === 0) {
        toastr.info('Para salvar o relatório e acessá-lo futuramente informe um nome');
        this.montarUrl();
      } else if (salvar) {
        this.montarUrl();
        this.submitForm();
      } else {
        this.montarUrl();
      }

      // converte string url em vetor de parametros
      for (const propName of String(this.url).split('&')) {
        if (!propName) continue
        const valores = propName.split('=');
        this.parametros[valores[0]] = valores[1];
      }

      let limite = this.login.limite;
      limite = 0;
      if (this.filtrosAux) {
        this.filtros = this.filtrosAux;
        this.filtrosAux = undefined;
      }
      this.parametros['ignoreCondObrig'] = true;
      // busca dados
      this.service
        .extendido(this.paginaCorrente, limite, this.parametros)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((dados) => {
          this.listDados = dados.content;
          this.paginaTotal = dados.totalPages;
          this.listDadosFormatado = [];
          for (const res of dados.content) {
            if (!res) continue
            const prop = {};
            for (const col of this.camposSelecionados) {
              if (!col) continue
              prop[col.coluna] = res[String(col.coluna)];
            }
            this.listDadosFormatado.push(prop);
          }
          const tot: string[] = [];
          for (const t of this.totalizador) {
            if (!t) continue
            tot.push(t.coluna);
          }
          this.listDadosFormatado = Relatorio.retornarConteudo(this.camposSelecionados, this.listDados, tot);
          this.listDadosFormatado = this.listDadosFormatado.slice(1, this.listDadosFormatado.length);
          if (salvar) {
            $('#btn-dados').trigger('click');
          }
        }, (error) => new Error(error)
        );
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  public filtrarCampos(event) {
    this.listCampos = [];
    for (let i = 0; i < this.campos.length; i++) {
      const campo = this.campos[i];
      if (campo.titulo.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.listCampos.push(campo);
      }
    }
  }

  public campoConversor(campo: Coluna) {
    return campo ? `${campo.titulo} (${campo.subTitulo})` : '';
  }

  public adicionarOrdenacao() {
    const ordem = new Ordem();
    ordem.campo = this.selectOrdenacao;
    ordem.asc = true;
    this.ordenacao.push(ordem);
    this.selectOrdenacao = null;
  }

  public removerOrdenacao(index: number) {
    this.ordenacao.splice(index, 1);
  }

  public toggleOrdenacao(filtro: Ordem): void {
    if (filtro) {
      filtro.asc = !filtro.asc;
    }
  }

  public adicionarAgrupamento() {
    this.agrupamento.push(this.selectAgrupamento);
    this.selectAgrupamento = null;
    this.atualizarCamposAgrupados();
  }

  public removerAgrupamento(index: number) {
    this.agrupamento.splice(index, 1);
    this.atualizarCamposAgrupados();
  }

  private atualizarCamposAgrupados() {
    const camposAux = this.camposSelecionados;
    this.camposSelecionados = [];
    for (const c of camposAux) {
      if (!c) continue
      const col: Coluna = this.agrupamento.find(campo => campo.coluna === c.coluna);
      if (col) {
        this.camposSelecionados.push(c);
      }
    }
    for (const c of camposAux) {
      if (!c) continue
      const col: Coluna = this.agrupamento.find(campo => campo.coluna === c.coluna);
      if (!col) {
        this.camposSelecionados.push(c);
      }
    }
    for (const c of this.camposSelecionados) {
      if (!c) continue
      const col: Coluna = this.agrupamento.find(campo => campo.coluna === c.coluna);
      c.agrupar = col ? true : false; // js não permite ternario com a mesma variavel de atribuição
      c.bold = col ? true : false;
    }
  }

  public adicionarTotalizador() {
    this.totalizador.push(this.selectTotalizador);
    this.selectTotalizador = null;
  }

  public removerTotalizador(index: number) {
    this.totalizador.splice(index, 1);
  }

  public adicionarFiltro() {
    const filtro = new FiltroPersonalizado();
    filtro.operador = 'eq';
    this.filtros.push(filtro);
  }

  public removerFiltro(index: number) {
    this.filtros.splice(index, 1);
  }

  public retornaConteudoTabela(): string {
    this.diferenciarCampos()
    let conteudo = '';
    if (this.listDadosFormatado && this.listDadosFormatado.length > 0) {
      for (const item of this.listDadosFormatado) {
        if (!item) continue
        let linha = '';
        let colStep = 0;
        let agrupamento = false;
        for (const col of item) {
          if (colStep > 0) {
            colStep--;
            continue;
          }
          if (col.colSpan) {
            colStep = col.colSpan - 1;
            agrupamento = true;
          }
          const classes: string[] = [];
          if (col.bold) {
            classes.push('font-weight-bold');
          }
          if (col.italics) {
            classes.push('font-italic');
          }
          if (col.decoration) {
            classes.push(`font-${col.decoration}`);
          }

          linha = linha.concat(`<td colSpan='${colStep + 1}' class='text-${col.alignment ? col.alignment : 'left'}' >`);
          linha = linha.concat(`<span class='${classes.join(' ')}'>`);
          linha = linha.concat(col.text ? col.text : '');
          linha = linha.concat('</span>');
          linha = linha.concat('</td>');
        }
        conteudo = conteudo.concat(`<tr class=\'${agrupamento ? 'linha-agrupada' : ''}\'>`);
        conteudo = conteudo.concat(linha);
        conteudo = conteudo.concat('</tr>');
      }
    }
    return conteudo;
  }

  public opcoesPDF() {
    this.modalPDF = true;
    this.exportarListagem('pdf');
  }

  public exportarListagem(formato: FormatoExportacao) {
    const tot: string[] = [];
    for (const t of this.totalizador) {
      if (!t) continue
      tot.push(t.coluna);
    }
    const larguras: string[] = [];
    for (const c of this.camposSelecionados) {
      if (!c) continue
      if (!c.agrupar) {
        larguras.push(c.tipo === 'String' && !larguras.find(l => l === '*') ? '*' : 'auto');
      }

      if (this.camposSelecionados.length > 14) {
        c.fontSize = 5;
      } else if (this.camposSelecionados.length > 11) {
        c.fontSize = 7;
      }
    }

    if (formato === 'pdf') {
      $('#modalOpcoesPdf').modal('show');
      if (this.modalPDF) {
        if(this.camposSelecionados.length>10){
          toastr.error('O máximo de campos permitidos é 10!');
          return
        }else{
          if(!this.orientacao && this.camposSelecionados.length>6){
            toastr.error('O máximo de campos permitidos para orientação em retrato é 6!');
            return
          }
        }
        Relatorio.imprimir(this.nomeArquivo(), this.login.usuario.nome, this.login.usuario.sobrenome, this.login.orgao.nome, this.login.brasao,
          this.listDados, this.camposSelecionados, this.orientacao ? 'landscape' : 'portrait', this.nomeArquivo(), larguras, tot, null, null, null, true
        );
        this.modalPDF = false;
        $('#modalOpcoesPdf').modal('hide');
      }
    } else {
      this.funcaoService.exportar(formato, this.listDados, this.nomeArquivo(), this.camposSelecionados);
    }
  }

  public proximaPagina() {
    this.paginaCorrente = this.paginaCorrente === this.paginaTotal ? this.paginaTotal : this.paginaCorrente + 1;
    this.buscarDados();
  }

  public paginaAnterior() {
    this.paginaCorrente = this.paginaCorrente === 1 ? 1 : this.paginaCorrente - 1;
    this.buscarDados();
  }

  public paginaDigitada(pagina: number) {
    this.paginaCorrente =
      +pagina >= this.paginaTotal
        ? this.paginaTotal
        : +pagina;
    this.buscarDados();
    window.scrollTo(0, 0);
  }

  public carregarModulos(limparSelecionados?: boolean): void {
    try {
      this.limparDados();

      const modulo = this.modulos.find((mod => mod.modulo === this.moduloAtual.modulo));
      if (!modulo) {
        throw new Error(`Módulo não localizado! ${this.moduloAtual.modulo}`);
      }

      this.parametros = modulo.parametros;
      this.service = modulo.service;

      this.carregarCampos(limparSelecionados);
    } catch (err) {
      toastr.error(err);
      return;
    }
  }

  private carregarCampos(limparSelecionados: boolean): void {

    this.campos = [];
    this.listGrupos = [];
    if (limparSelecionados) {
      this.camposSelecionados = [];
    }

    const subTitulo: string = this.moduloAtual.modulo;
    this.listGrupos.push({ id: subTitulo, nome: subTitulo });

    for (const campo of this.moduloAtual.campos) {
      if (!campo) continue
      const col = new Coluna();
      col.coluna = (campo.classe ? campo.classe + '.' : '') + campo.campo;
      col.titulo = campo.nome;
      col.tipo = campo.tipo;
      if (campo.classe) {
        col.subTitulo = campo.nomeClasse;
        if (!this.listGrupos.find(grp => grp.id === campo.classe)) {
          this.listGrupos.push({ id: campo.classe, nome: campo.nomeClasse });
        }
      } else {
        col.subTitulo = subTitulo;
      }

      this.campos.push(col);
    }

    this.listGrupos = this.listGrupos.sort((a, b) => { return a.nome.localeCompare(b.nome) });
    this.grupoAtual = this.listGrupos[0]?.id;

    this.filtrarDisponiveis();
  }

  private nomeArquivo(): string {
    if (this.entidadeForm.get('nome').value) {
      return this.entidadeForm.get('nome').value;
    } else {
      return 'Relatório Personalizado';
    }
  }

  public campoEspecial(campo: string): boolean {
    if (!campo) return false;

    const camposEspeciais = [
      'setor.nome', 'conservacao', 'situacao'
    ]

    return Boolean(camposEspeciais.find(c => c === campo));
  }

  private diferenciarCampos() {
    let aux
    for (let coluna of this.camposSelecionados) {
      if (!coluna.titulo.includes('\n')) {
        aux = coluna.subTitulo.split('-').slice(-1)
        coluna.titulo = `${coluna.titulo}\n(${aux[0].trim()})`
      }
    }
  }

  public changeSystem(): void {
    this.systemChange.emit(this.selectedSystemId);
  }

  public findModules(): void {
    this.relatorioService.obterModulos(this.selectedSystemId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async m => {
        this.listModulos = m;
        if (this.listModulos.length === 0) {
          toastr.error('Nenhum módulo encontrado!');
          return;
        }

        this.moduloAtual = null;

        // chamada sincrona para esperar resultado do bloco acima
        await new Promise(res => setTimeout(res, 500));
        if (this.entidade.modulo) {
          for (const mod of this.listModulos) {
            if (mod.modulo === this.entidade.modulo) {
              this.moduloAtual = mod;
              break;
            }
          }
        }

        if (!this.moduloAtual) {
          this.moduloAtual = this.listModulos[0];
        }

        this.carregarModulos();

      });
  }
}
