import { ContratoItemService } from '../service/contrato-item.service';
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratoService, FavorecidoSocioService } from 'administrativo-lib';
import { Contrato, ContratoItem, ContratoResponsavel, FavorecidoSocio, FuncaoService, GlobalService, Licitacao, Login, Pessoa, Prazo, Proponente, TipoContratacao, TipoContrato } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { MemorialItemService } from '../../memorial-item/service/memorial-item.service';
import { PrazoService } from '../../prazo/service/prazo.service';
import { TipoContratacaoService } from '../../tipo-contratacao/service/tipo-contratacao.service';
import { ConfirmationService } from 'primeng/api';

declare var $: any;

@Component({
  selector: 'lib-contrato-licitacao-dlg',
  templateUrl: './contrato-licitacao-dlg.component.html'
})
export class ContratoLicitacaoDlgComponent implements OnInit, OnChanges {
  @Input() public login: Login;
  @Input() public licitacao: Licitacao;
  @Input() public credenciamento = false;
  @Input() public proponente: Proponente;
  @Input() tipoContrato?: 'CONTRATO' | 'REGISTRO_PRECO' = 'CONTRATO';
  @Input() abrir: boolean;
  @Output() callback: EventEmitter<Contrato> = new EventEmitter();
  @Output() abrirChange: EventEmitter<boolean> = new EventEmitter();

  public tipos: { id: 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO', nome: string }[];
  public listaTipos: { id: TipoContrato, nome: string }[];
  public listaContratacao: TipoContratacao[];
  public listaPrazos: Prazo[];
  public listaResponsaveis: ContratoResponsavel[] = [];
  public entidade: Contrato;
  public entidadeForm: FormGroup;
  protected router: Router;
  protected activatedRoute: ActivatedRoute;
  protected fb: FormBuilder;
  protected unsubscribe: Subject<void> = new Subject();
  public contratoAtual: Contrato;
  public mostrarDescricao: boolean = false;
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public subelemento: number;
  public dlgNome: string = 'dlgServicoConversao'
  public buscaProdutoDlg: string = 'dlgBuscaProdutoAjusteConversao'
  public cadastroProdutoDlg: string = 'dlgCadastroProdutoAjusteConversao'
  public totalMaterialContrato = 0;
  public totalServicoContrato = 0;
  public totalLocacaoContrato = 0;
  public tipoContratacaoContrato: TipoContratacao;

  public imaskValor = {
    mask: Number,
    scale: 6,
    signed: false,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    private favResponsavelService: FavorecidoSocioService,
    protected globalService: GlobalService,
    protected contratacaoService: TipoContratacaoService,
    protected memorialItemService: MemorialItemService,
    protected prazoService: PrazoService,
    protected contratoService: ContratoService,
    protected contratoItemService: ContratoItemService,
    protected confirmationService: ConfirmationService,) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.proponente?.licitacao) {
        this.entidade = new Contrato();
        this.entidade.ano = new Date().getFullYear();
        this.entidade.enviar_email = true;
        this.entidade.inativo = false;
        this.entidade.excluido = false;
        this.entidade.tipo = this.tipoContrato;
        this.entidade.tipo_contrato = undefined;
        this.entidade.valor_caucao = 0;
        this.entidade.valor_garantia = 0;
        this.entidade.valor_material = 0;
        this.entidade.valor_servico = 0;
        this.entidade.data_contabilizacao = new Date();
        this.entidade.tipo_contratacao = this.proponente.licitacao.tipo_contratacao;
        this.entidadeForm.get('tipo_contratacao').setValue(this.entidade.tipo_contratacao)
        this.entidade.data_assinatura = this.proponente.licitacao.data_assinatura;
        this.entidade.data_inicio = this.proponente.licitacao.data_inicio;
        this.entidade.data_termino = this.proponente.licitacao.data_termino;
        this.entidade.favorecido = this.proponente.favorecido;
        this.entidade.licitacao = this.proponente.licitacao;
        this.entidade.modalidade = this.proponente.licitacao.modalidade;
        this.entidade.objeto = this.proponente.licitacao.objeto;
        this.entidade.orgao = this.login.orgao;
        this.entidade.processo = this.proponente.licitacao.processo;
        this.entidade.prazo = this.proponente.licitacao.prazo_pagamento;
        this.entidade.entrega = this.proponente.licitacao.prazo_entrega;
        this.entidade.licitacao.criterio_julgamento = this.proponente.licitacao.criterio_julgamento
        this.entidade.lei_14133_2021 = this.proponente.licitacao.lei_14133_2021;

        let total = 0;
        this.entidade.itens = [];
        for (let p of this.proponente.propostas) {
          const item = new ContratoItem();
          if (p.memorial.lote === true) {
            let itensLotes = await this.memorialItemService.filtrar(1, -1, { relations: ['produto', 'produto.unidades', 'produto.unidades.unidade', 'produto.unidades.produto.material'], 'memorial_id': p.memorial.id }).pipe(takeUntil(this.unsubscribe)).toPromise();
            for (let itemLote of itensLotes.content) {
              const itemL = new ContratoItem();
              itemL['nomeLote'] = p.memorial.descricao;
              itemL['valorLote'] = +p.valor_final;
              itemL.memorial = p.memorial;
              itemL.produto_unidade = itemLote.produto.unidades[0];
              itemL.descricao = itemLote.produto.nome;
              itemL.unidade = itemLote.produto.unidades[0].unidade.nome;
              itemL.quantidade = +itemLote.quantidade;
              itemL.valor_unitario = 0;
              itemL.editavel = true;
              itemL.ordem = p.memorial.ordem;
              this.entidade.itens.push(itemL);
            }
          } else {
            item.memorial = p.memorial;
            item.produto_unidade = item.memorial.produto_unidade;
            item.descricao = item.memorial.descricao;
            item.unidade = item.memorial.unidade;
            item.quantidade = p.quantidade_final;
            item.valor_unitario = +p.valor_final
            item.ordem = p.memorial.ordem;
            this.entidade.itens.push(item);
          }
          total += this.funcaoService.arredondar(+Number(+p.quantidade_final * +p.valor_final), 2);
        };
        this.entidade.valor_contrato = this.funcaoService.arredondar(total, 2);
        this.entidadeForm.patchValue(this.entidade);
        this.changeTipoContratacao()
        this.contratoService.proximoNumero(this.login.exercicio.ano)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((res) => {
            this.entidadeForm.get('numero').setValue(res);
          });

        setTimeout(() => this.globalService.calendarMascara(), 1000);
        this.abrir = false;
        this.abrirChange.emit(false);
      }
    }
  }

  ngOnInit(): void {
    this.router = this.injector.get(Router);
    this.fb = this.injector.get(FormBuilder);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.criarCamposForm();

    this.tipos = [{ id: 'CONTRATO', nome: 'Contrato' }, { id: 'REGISTRO_PRECO', nome: 'Registro de Preço' }];
    this.listaTipos = this.globalService.obterTiposContrato();
    this.contratacaoService.filtrar(0, 0, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaContratacao = res ? res.content : [];
      }, error => toastr.error(error.message ? error.message : error));
    this.prazoService.filtrar(0, 0, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaPrazos = res ? res.content : [];
      }, error => toastr.error(error.message ? error.message : error));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public submitForm() {
    if (this.entidadeForm.invalid) {
      toastr.error('Há campos obrigatórios não informados!');
      return;
    }

    let valorTotalLote = 0;
    let valorTotalItem = 0;
    let valorTotalContrato = 0;

    for (let item of this.entidadeForm.get('itens').value) {
      if (item.valorLote) {
        valorTotalLote += this.funcaoService.arredondar(+Number(+item.valor_unitario * +item.quantidade), 2);
      } else {
        valorTotalItem += this.funcaoService.arredondar(+Number(+item.valor_unitario * +item.quantidade), 2);
      }
      if (item.quantidade === 0 || !item.quantidade) {
        toastr.error(`Há campos obrigatórios não informados! </br><strong>Qtd.</strong>`);
        return;
      }
      if (item.valor_unitario === 0 || !item.valor_unitario) {
        toastr.error(`Há campos obrigatórios não informados! </br><strong>Valor Unitário</strong>`);
        return;
      }

      //Atualizar licitacao
      //Itens -> propostas
      for (const i of this.licitacao.itens) {
        for (const p of i.propostas) {
          if (item.memorial.id == p.memorial.id && p.proponente.favorecido.id == this.entidadeForm.get('favorecido').value.id) {
            p['habilitar'] = false;
          }
        }
      }

      //Proponentes -> propostas
      for (const i of this.licitacao.proponentes) {
        for (const p of i.propostas) {
          if (item.memorial.id == p.memorial.id && p.proponente?.favorecido?.id == this.entidadeForm.get('favorecido')?.value?.id) {
            p['habilitar'] = false;
          }
        }
      }
    }

    valorTotalContrato = valorTotalItem + valorTotalLote;

    if (this.funcaoService.arredondar(+valorTotalContrato, 2) > this.funcaoService.arredondar(this.entidadeForm.get('valor_contrato').value, 2)) {
      toastr.error(`A soma do valor total dos itens ultrapassa o valor do contrato, verifique!`);
      return;
    }

    if (this.funcaoService.arredondar(+valorTotalContrato, 2) !== this.funcaoService.arredondar(this.entidadeForm.get('valor_contrato').value, 2)) {
      toastr.error(`A soma do valor total dos itens tem que ser igual ao valor do contrato, verifique!`);
      return;
    }

    let numeroContrato = this.entidadeForm.get('numero').value
    if (numeroContrato.length === 8 ? +numeroContrato.substring(4, 8) !== new Date().getFullYear() && +numeroContrato.substring(4, 8) !== (+new Date().getFullYear() - 1) : +numeroContrato.substring(5, 9) !== new Date().getFullYear() && +numeroContrato.substring(5, 9) !== (+new Date().getFullYear() - 1)) {
      $('#dialogContratoLicitacao').modal('hide')
      this.confirmationService.confirm({
        header: 'Incluir contrato',
        message: `<pre> O exercício logado e/ou exercício do contrato é diferente do exercício vigente, deseja continuar?</pre>.`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          this.entidadeForm.get('ignorarAno').setValue(true)
          this.salvar();
          // this.entidade.data_contabilizacao = new Date();
        },
        reject: async () => {
          $('#dialogContratoLicitacao').modal('show')
          return;
        }
      });
    } else {
      this.salvar();
    }
  }

  private salvar() {
    if (this.entidade.favorecido.inativo && !this.entidade.favorecido.autorizado) {
      toastr.warning('Favorecido está inativo e não autorizado!');
      return;
    }
    if (this.entidade.favorecido.inativo) {
      toastr.warning('Favorecido está inativo!');
      return;
    }
    if (!this.entidade.favorecido.autorizado) {
      toastr.warning('Favorecido não está autorizado!');
      return;
    }
    this.favResponsavelService.filtrar(0, 0, { favorecido_id: this.entidade.favorecido.id, orgao_id: this.entidade?.orgao?.id, relations: 'pessoa,orgao' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        const responsaveis: FavorecidoSocio[] = res.content;
        if (responsaveis?.length > 0) {
          responsaveis.forEach(f => {
            if (!this.listaResponsaveis.find(c => c.responsavel.id === f.pessoa.id)) {
              this.listaResponsaveis.push({
                responsavel: f.pessoa,
                ativo: true,
                contratante: false
              });
            }
          });
        }

        if (this.login.parametro['licitacao']['contratante_padrao'] && this.login.parametro['licitacao']['contratante']) {
          this.listaResponsaveis.push({
            contratante: true,
            responsavel: this.login.parametro['licitacao']['contratante'],
            ativo: true,
          })
        } else if (this.licitacao?.fiscais?.length) {
          let pessoa: Pessoa = null
          this.licitacao?.fiscais.forEach((fiscal) => {
            if (fiscal?.requerente_gestor === true) {
              pessoa = fiscal?.pessoa
              this.listaResponsaveis.push({
                contratante: true,
                responsavel: pessoa,
                ativo: true,
              })
            }
          })
        }

        let fiscal = this.licitacao.fiscais.filter(f => f.requerente_gestor === false)

        const entidade: Contrato = Contrato.converteJson(this.entidadeForm.value);
        entidade.valor_locacao = this.totalLocacaoContrato;
        entidade.valor_material = this.totalMaterialContrato;
        entidade.valor_servico = this.totalServicoContrato;
        entidade.responsaveis = this.listaResponsaveis;
        entidade.excluido = false;
        entidade.fiscais = fiscal;

        if (this.login.parametro['licitacao']?.contratos_inativos) {
          entidade.data_contabilizacao = null;
          entidade.inativo = true;
          entidade['ignoreContabilizacao'] = true;
        }

        this.contratoService.inserir(entidade).pipe(takeUntil(this.unsubscribe))
          .subscribe(res => {
            this.acaoSucesso(res);
            this.criarCamposForm();
            this.listaResponsaveis = [];
          }, error => this.acaoErro(error));
      });
  }

  private acaoSucesso(entidade: Contrato) {
    toastr.success('Registro salvo com sucesso!');
    this.callback.emit(entidade);
    this.fechar();
  }

  private acaoErro(error: any) {
    toastr.options.timeOut = 10000;
    toastr.options.closeButton = true;
    toastr.options.tapToDismiss = false;
    if (error.error && error.error.payload) {
      toastr.error(error.error.payload);
    } else {
      toastr.error('Ocorreu um erro ao processar a sua solicitação');
    }
  }

  public totalizar(itens: ContratoItem[]) {
    return itens.reduce((acumulador, item) => this.funcaoService.arredondar((item.quantidade * item.valor_unitario) + acumulador, 2), 0)
  }

  public exibirDescricao() {
    this.mostrarDescricao = !this.mostrarDescricao;
  }

  public criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      ordem: [null],
      numero: [null, [Validators.required]],
      ano: [null, [Validators.required]],
      data_assinatura: [null, [Validators.required]],
      data_inicio: [null, [Validators.required]],
      data_termino: [null, [Validators.required]],
      data_contabilizacao: [new Date(), [Validators.required]],
      processo: [null, [Validators.required]],
      tipo_contrato: [null, [Validators.required]],
      tipo: [this.tipoContrato, Validators.required],
      objeto: [null, [Validators.required]],
      valor_contrato: [0, [Validators.required]],
      valor_garantia: [0, [Validators.required]],
      valor_caucao: [0, [Validators.required]],
      valor_material: [0, [Validators.required]],
      valor_servico: [0, [Validators.required]],
      enviar_email: [false, [Validators.required]],
      inativo: [false, [Validators.required]],
      parente: [null],
      licitacao: [null, [Validators.required]],
      modalidade: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      favorecido: [null, [Validators.required]],
      entrega: [null],
      prazo: [null, [Validators.required]],
      tipo_contratacao: [null, [Validators.required]],
      itens: [null],
      ignorarAno: [false], // Exercício posterior. 
      lei_14133_2021: [false],
    });
  }

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public fechar() {
    this.criarCamposForm();
    $('#dialogContratoLicitacao').modal('hide');
  }

  public imaskQtd = {
    mask: Number,
    scale: 0,
    signed: false
  };

  public imaskConfig = {
    mask: Number,
    scale: 4,
    signed: true,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  public changeTipoContratacao(): void {
    this.contratacaoService.obterId(this.entidadeForm.get('tipo_contratacao').value.id).subscribe(tipoContratacao => {
      this.tipoContratacaoContrato = tipoContratacao;
      this.totalMaterialContrato = this.entidade.itens.reduce((acc, cur) => {
        if (!cur.produto_unidade?.produto?.material?.servico) {
          return this.funcaoService.arredondar(acc + cur.valor_unitario * cur.quantidade, 2);
        }

        return acc;
      }, 0);

      this.totalServicoContrato = this.entidade.itens.reduce((acc, cur) => {
        if (cur.produto_unidade?.produto?.material?.servico && !cur.produto_unidade?.produto?.material?.locacao) {
          return acc + cur.valor_unitario * cur.quantidade;
        }

        return acc;
      }, 0);

      this.totalLocacaoContrato = this.entidade.itens.reduce((acc, cur) => {
        if (cur.produto_unidade?.produto?.material?.locacao) {
          return acc + cur.valor_unitario * cur.quantidade;
        }

        return acc;
      }, 0);
    });
  }
}
