// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Orgao } from '../comum/orgao.model';
import { Usuario } from '../comum/usuario.model';
import { ContaBancaria } from './conta-bancaria.model';
import { TransferenciaEspecie } from './transferencia-especie.model';
import { TransferenciaRecurso } from './transferencia-recurso.model';
import { TransferenciaRetencaoItem } from './transferencia-retencao-item.model';
import { Recurso } from '../planejamento/recurso.model';

export class TransferenciaBancaria extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_transfere?: Date,
    public data_referencia?: Date,
    public mes?: number,
    public especie?: TransferenciaEspecie,
    public documento?: string,
    public tipo?: string,
    public historico?: string,
    public valor_transferido?: number,
    public conta_recebedora?: ContaBancaria,
    public conta_pagadora?: ContaBancaria,
    public exercicio?: Exercicio,
    public orgao?: Orgao,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public transferencia_retencao_item?: TransferenciaRetencaoItem,
    public transferencia_recurso?: TransferenciaRecurso,
    public usuario_cadastro?: Usuario,
    public recurso_pagadora?: Recurso,
    public aplicacao_pagadora?: Recurso,
    public convenio_pagadora?: Recurso,
    public recurso_recebedora?: Recurso,
    public aplicacao_recebedora?: Recurso,
    public convenio_recebedora?: Recurso,
    public impresso?: boolean,
    public exportado_febraban?: boolean,
    public exportado_obn?: boolean
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaBancaria {
    return Object.assign(new TransferenciaBancaria(), json);
  }
}
