import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DespesaService } from "administrativo-lib";
import { ProdutoService, ProdutoUnidadeService } from "almoxarifado-lib";
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Coluna, CompraItem, EddyAutoComplete, EstoqueService, FavorecidoService, Produto, ProdutoUnidade, SetorService } from "eddydata-lib";
import * as toastr from 'toastr';
import { CompraItemService } from "../../compra/service/compra-item.service";

@Component({
  selector: 'lib-compra-material-rpt',
  templateUrl: './compra-material-rpt.component.html'
})
export class CompraMaterialoRptComponent extends BaseResourceRptPersonalizadoComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  public produtoUnAutoComplete: EddyAutoComplete<ProdutoUnidade>;

  public tipo: 'M' | 'U' = 'M';

  public produtos: ProdutoUnidade[] = []
  public produto: ProdutoUnidade;

  public dataInicio: Date;
  public dataFim: Date;

  public parametros: { session?: any, filtros?: any } = {};

  protected datepipe: DatePipe;

  constructor(
    private estoqueService: EstoqueService,
    private setorService: SetorService,
    private despesaService: DespesaService,
    private favorecidoService: FavorecidoService,
    private produtoService: ProdutoService,
    private compraItemService: CompraItemService,
    protected assinaturaService: AssinaturaService,
    private produtoUnService: ProdutoUnidadeService
  ) {
    super(assinaturaService);
  }

  protected afterInit(): void {
    this.datepipe = new DatePipe('pt');
    this.formato = 'pdf';
    this.orientacao = 'landscape';
    this.carregarAutoComplete();
  }

  public tituloRelatorio(): string {
    return `RELATÓRIO DE PRODUTOS E SERVIÇOS`;
  }

  protected subtituloRelatorio(): string {
    if (!this.dataInicio && !this.dataFim)
      return `EXERCÍCIO ${this.login.exercicio.ano}`;
    else
      return `PERÍODO ${this.dataInicio ? this.funcaoService.converteDataBR(this.dataInicio) : 'XX/XX/XXXX'} À ${this.dataFim ? this.funcaoService.converteDataBR(this.dataFim) : 'XX/XX/XXXX'}`;
  }

  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({
      titulo: 'Fornecedor', coluna: 'compra.favorecido.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.favorecidoService,
        'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] }), cols: 12
    });
    retorno.push({
      titulo: 'Elemento', coluna: 'compra.ficha.despesa.codigo', alignment: 'center', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.despesaService,
        'codigo', ['codigo', 'nome'], { 'exercicio.id': this.login.exercicio.id, orderBy: 'codigo', nivel: 4 }, { number: ['codigo'], text: ['nome'] }
      ), cols: 12,
    });
    retorno.push({
      titulo: 'Setor', coluna: 'compra.setor.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.setorService,
        'id', ['id', 'nome'], { orderBy: 'nome', 'orgao.id': this.login.orgao.id }, { number: ['id'], text: ['nome'] }), cols: 12
    });
    retorno.push({
      titulo: 'Estoque', coluna: 'compra.estoque.id', tipo: 'AutoComplete', autocomplete: new EddyAutoComplete(null, this.estoqueService,
        'id', ['id', 'nome'], { orderBy: 'nome', 'orgao.id': this.login.orgao.id }, { number: ['id'], text: ['nome'] }), cols: 12
    });
    retorno.push({ titulo: 'Compra Direta', coluna: 'compra.contrato.id$null,compra.licitacao.id$null,compra.processo$null,', tipo: 'Boolean' });
    retorno.push({ titulo: 'Contrato', coluna: 'compra.contrato.id$not_null', tipo: 'Boolean' });
    return retorno;
  }

  private carregarAutoComplete() {
    this.produtoUnAutoComplete = new EddyAutoComplete(null, this.produtoUnService,
      'id', ['produto.codigo', 'produto.nome', 'unidade.nome'], { orderBy: 'produto.codigo,produto.nome', relations: ['produto.material.sub_grupo.grupo', 'unidade'] }, { number: ['id', 'produto.codigo'], text: ['produto.codigo', 'produto.nome', 'produto_codigo_comp'] },
      null, null, (entidade: ProdutoUnidade) => `${entidade.produto.material.sub_grupo.grupo.codigo}.${entidade.produto.material.sub_grupo.codigo}.${entidade.produto.material.codigo}.${entidade.produto.codigo} - ${entidade.produto.nome}`
    );
  }

  public onSelectProduto(produto: Produto) {
    if (this.tipo !== 'M' || !produto) {
      setTimeout(() => {
        this.produto = null;
      }, 100);
      return;
    } else if (this.produtos.filter((p) => p.id === produto.id).length > 0) {
      toastr.warning(`Produto já adicionado`);
    } else {
      this.produtos.push(produto);
    }
    setTimeout(() => {
      this.produto = null;
    }, 100);
  }

  public removerProduto(index: number) {
    this.produtos.splice(index, 1);
  }

  public imprimir(): void {
    if (!this.validar())
      return null;
    return super.imprimir();
  }

  public validar(): boolean {
    if (this.tipo === 'M' && this.produtos.length === 0) {
      toastr.warning(`Informe produto(s)/serviço(s) para levantamento`);
      return false;
    }
    if (this.tipo === 'U' && !this.produto) {
      toastr.warning(`Informe produto(s)/serviço(s) para levantamento`);
      return false;
    }
    return true;
  }




  protected modalRelatorio(): string {
    return null;
  }

  protected montarConteudo(): Promise<{}[]> { 
    let parametros = {
      'compra.orgao.id': this.login.orgao.id, relations: ['produto_unidade.produto.material.sub_grupo.grupo', 'compra.exercicio', 'compra.favorecido',
        'compra.ficha.despesa', 'compra.estoque', 'compra.setor', 'compra.licitacao'].join(','), orderBy: ['compra.numero','produto_unidade.produto.codigo', 'compra.data_compra', 'ordem', 'id'].join(',')
    };
    if (this.tipo === 'M')
      parametros['produto_unidade.produto.id$in'] = this.produtos.map((p) => p.produto.id).join(',');
    if (this.tipo === 'U')
      parametros['produto_unidade.produto.id'] = this.produto.produto.id;

    if (this.dataInicio)
      parametros['compra.data_compra$ge'] = this.funcaoService.converteDataSQL(this.dataInicio);
    if (this.dataFim)
      parametros['compra.data_compra$le'] = this.funcaoService.converteDataSQL(this.dataFim);

    if (!this.dataInicio || !this.dataFim)
      parametros['compra.exercicio.id'] = this.login.exercicio.id;

    if (this.parametros?.filtros)
      parametros = Object.assign(parametros, this.parametros.filtros);

    return new Promise<{}[]>((resolve) => {
      const conteudo: {}[] = [];
      this.compraItemService.filtrar(1, -1, parametros)
        .subscribe((data) => {
          if (!data.content || data.content.length === 0)
            resolve(conteudo)
          else {
            const produtos = [];
            for (let item of data.content) {
              const produto = produtos.find((p) => p.produto.id === item.produto_unidade.produto.id);
              if (produto)
                produto.itens.push(item);
              else
                produtos.push({ produto: item.produto_unidade.produto, itens: [item] })
            }

            let iniciado = false;

            for (const entidade of produtos) {
              if (iniciado) {
                conteudo.push([{ text: '', pageBreak: 'after' }]);
              }
              conteudo.push(this.dadosProduto(entidade.produto)
                .concat(this.dadosItens(entidade.itens)));
              iniciado = true;
            }
            resolve(conteudo);
          }
        })
    });
  }


  private dadosProduto(produto: Produto): {}[] {
    const conteudo = [];

    const codigoGrupo = produto.material.sub_grupo.grupo.codigo;
    const codigoSubGrupo = `${codigoGrupo}.${produto.material.sub_grupo.codigo}`;
    const codigoMaterial = `${codigoSubGrupo}.${produto.material.codigo}`;
    const codigoProduto = `${codigoMaterial}.${produto.codigo}`;

    conteudo.push([{ text: [{ text: `${produto.material.servico ? 'SERVIÇO' : 'PRODUTO'} :   `, bold: true }, { text: `${codigoProduto} - ${produto.nome}` }], margin: [0, 1], border: [false, true, false, false] }]);
    conteudo.push([{ text: [{ text: `GRUPO DE ${produto.material.servico ? 'SERVIÇO' : 'MATERIAL'} :   `, bold: true }, { text: `${codigoMaterial} - ${produto.material.nome}` }], margin: [0, 1], border: [false, false, false, false] }]);
    conteudo.push([{ text: [{ text: `SUB-GRUPO :   `, bold: true }, { text: `${codigoSubGrupo} - ${produto.material.sub_grupo.nome}` }], margin: [0, 1], border: [false, false, false, false] }]);
    conteudo.push([{ text: [{ text: `GRUPO :   `, bold: true }, { text: `${codigoGrupo} - ${produto.material.sub_grupo.grupo.nome}` }], margin: [0, 1], border: [false, false, false, false] }]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosItens(itens: CompraItem[]): {}[] {
    const conteudo = [];

    let quantidade = 0;
    let valor_unitario = 0;
    let media = 0;

    conteudo.push([
      { text: 'OF./EXERCÍCIO', bold: true, alignment: 'center', margin: [0, 2], fontSize: 7 },
      { text: 'FORNECEDOR', bold: true, alignment: 'left', margin: [0, 2], fontSize: 7 },
      { text: 'ELEMENTO', bold: true, alignment: 'left', margin: [0, 2], fontSize: 7 },
      { text: 'ESTOQUE', bold: true, alignment: 'left', margin: [0, 2], fontSize: 7 },
      { text: 'SETOR', bold: true, alignment: 'left', margin: [0, 2], fontSize: 7 },
      { text: 'QUANTIDADE', bold: true, alignment: 'right', margin: [0, 2], fontSize: 7 },
      { text: 'VALOR UNITÁRIO', bold: true, alignment: 'right', margin: [0, 2], fontSize: 7 }
    ]);

    for (let item of itens) {

      quantidade += +item.quantidade;
      valor_unitario += +item.valor_unitario;

      conteudo.push([
        { text: `${item.compra.numero}/${item.compra.exercicio.ano}`, alignment: 'center', margin: [0, 2], fontSize: 7, border: [false, false, false, false] },
        { text: item.compra.favorecido.nome, alignment: 'left', margin: [0, 2], fontSize: 7, border: [false, false, false, false] },
        { text: item.compra?.ficha?.despesa?.codigo ? `${item.compra.ficha.despesa.codigo} - ${item.compra.ficha.despesa.nome}` : '', alignment: 'left', margin: [0, 2], fontSize: 7, border: [false, false, false, false] },
        { text: item.compra.estoque?.nome ? item.compra.estoque.nome : '', alignment: 'left', margin: [0, 2], fontSize: 7, border: [false, false, false, false] },
        { text: item.compra.setor?.nome ? item.compra.setor.nome : '', alignment: 'left', margin: [0, 2], fontSize: 7, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item.quantidade, 2), alignment: 'right', margin: [0, 2], fontSize: 7, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item.valor_unitario, 2), alignment: 'right', margin: [0, 2], fontSize: 7, border: [false, false, false, false] }
      ]);
    }

    media = +valor_unitario / itens.length;

    conteudo.push([
      { text: [{ text: `QUANTIDADE: `, bold: true }, { text: quantidade }], colSpan: 5, margin: [0, 2], alignment: 'left', border: [true, true, false, true] }, { text: '' }, { text: '' }, { text: '' }, { text: '' },
      { text: [{ text: 'MÉDIA: ', bold: true }, { text: this.funcaoService.convertToBrNumber(media, 2) }], alignment: 'right', colSpan: 2, margin: [0, 2], border: [false, true, true, true] }, { text: '' }

    ]);

    return [{
      layout: 'linhas',
      dontBreakRows: true,
      headerRows: 1,
      table: {
        widths: [60, '*', '*', 'auto', 'auto', 60, 60],
        body: conteudo
      }
    }];
  }

  protected layoutRelatorio(): {} {
    return {
      hLineWidth() {
        return 1;
      },
      vLineWidth(i, node) {
        return 1;
      },
      hLineColor(i) {
        return i === 1 || i === 0 ? 'black' : '0aaa';
      },
      paddingLeft(i) {
        return 2;
      },
      paddingRight(i, node) {
        return 2;
      }
    }
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }
}
