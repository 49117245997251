import { NgModule } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { IMaskModule } from "angular-imask";
import { SharedModule } from "eddydata-lib";
import { MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { EditorModule } from "primeng/editor";
import { ToastModule } from "primeng/toast";
import { BloqueioRequisicaoDirective } from "./directives/bloqueio-requisicao.directive";
import { ParametroRequisicaoFormComponent } from "./parametro-requisicao-form/parametro-requisicao-form.component";
import { ParametroRequisicaoRoutingModule } from "./parametro-requisicao-routing.module";
import { ParametroRequisicaoViewComponent } from "./parametro-requisicao-view/parametro-requisicao-view.component";
import { CalendarModule } from "primeng/calendar";

@NgModule({
  declarations: [
    ParametroRequisicaoFormComponent,
    ParametroRequisicaoViewComponent,
    BloqueioRequisicaoDirective,
  ],
  imports: [
    ParametroRequisicaoRoutingModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    ToastModule,
    IMaskModule,
    EditorModule,
    CalendarModule
  ],
  exports: [BloqueioRequisicaoDirective],
  providers: [MessageService],
})
export class ParametroRequisicaoModule { }
