import { LoginContabil, FuncaoService, Relatorio, AssinaturaPessoa, RequisicaoMovimento, GlobalService, StatusRequisicao } from "eddydata-lib";

export class ComprovanteRequisicaoRpt {

    public orientacao: 'portrait' | 'landscape' = 'portrait';

    protected funcaoService: FuncaoService = new FuncaoService();


    constructor(
        private login: LoginContabil,
        public globalService: GlobalService
    ) { }

    public async imprimir(model: RequisicaoMovimento) {
        Relatorio.imprimirPersonalizado('COMPROVANTE - RETIRADA DE MATERIAIS', this.login.usuario.nome, this.login.usuario.sobrenome,
            this.login.orgao.nome, this.login.brasao,
            this.montarConteudo(model),
            'portrait', 'COMPROVANTE - RETIRADA DE MATERIAIS', this.layoutRelatorio(), true, false);
    }


    public tituloRelatorio(): string {
        return `COMPROVANTE - RETIRADA DE MATERIAIS`;
    }

    protected ocultarCabecalho(): boolean {
        return true;
    }

    protected ocultarRodape(): boolean {
        return true;
    }

    protected layoutRelatorio(): {} {
        return {
            linhas: {
                hLineWidth() {
                    return 1;
                },
                vLineWidth() {
                    return 1;
                },
                hLineColor() {
                    return 'black';
                },
                paddingLeft() {
                    return 3;
                },
                paddingRight() {
                    return 3;
                }
            }
        };
    }

    protected montarConteudo(movimento: RequisicaoMovimento): {}[] {
        const conteudo: {}[] = [];

        conteudo.push(this.dadosCabecalho()
            .concat(this.dadosExemplo(movimento))
            .concat(this.dadosItens(movimento))
            .concat(this.dadosAssinatura(movimento)));

        return conteudo;
    }

    private dadosCabecalho(): {}[] {
        let brasaoImage: {};
        if (this.login.brasao) {
            brasaoImage = {
                image: this.login.brasao,
                width: 70,
                alignment: 'left',
                margin: [0, 0, 0, 0],
                border: [false, false, false, false]
            };
        } else {
            brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [false, false, false, false] };
        }

        brasaoImage['rowSpan'] = 2;

        const conteudo = [];

        conteudo.push([brasaoImage, { text: this.login.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false] }]);
        conteudo.push([
            { text: '' },
            { text: this.tituloRelatorio(), bold: true, alignment: 'center', fontSize: 13, border: [false, false, false, false], margin: [0, 10] }
        ]);

        return [{
            layout: 'linhas',
            table: {
                widths: [50, '*'],
                body: conteudo
            }
        }];
    }


    private dadosExemplo(movimento: RequisicaoMovimento): {}[] {
        const conteudo = [
            [
                { text: [{ text: 'DESTINO:  ', bold: true }, { text: movimento.setor_almoxarifado.setor.nome }], fontSize: 8, border: [false, true, false, false], margin: [0, 5] },
                { text: [{ text: 'REQUISITANTE:  ', bold: true, alignment: 'right' }, { text: movimento.usuario?.nome, alignment: 'right' }], fontSize: 7, border: [false, true, false, false], margin: [0, 5] },
                { text: [{ text: 'REQUISIÇÃO:  ', bold: true, alignment: 'right' }, { text: movimento.codigo, alignment: 'right' }], fontSize: 7, border: [false, true, false, false], margin: [0, 5] },
            ],];

        if (movimento.paciente) {
            conteudo.push([
                { text: [{ text: 'PACIENTE:  ', bold: true }, { text: movimento.nome_paciente }], fontSize: 8, border: [false, true, false, false], margin: [0, 5] },
                { text: [{ text: '', bold: true, alignment: 'right' }], fontSize: 7, border: [false, true, false, false], margin: [0, 5] },
                { text: [{ text: 'MCV:  ', bold: true, alignment: 'right' }, { text: movimento.mcv_paciente, alignment: 'right' }], fontSize: 7, border: [false, true, false, false], margin: [0, 5] },
            ]);
        }

        return [{
            layout: 'linhas',
            margin: [0, 10],
            table: {
                widths: ['auto', '*', '*'],
                body: conteudo
            }
        }];
    }

    public tituloStatusRequisicao(status: StatusRequisicao): string {
        return this.globalService.tituloStatusRequisicao(status);
    }

    private dadosItens(movimento: RequisicaoMovimento): {}[] {
        const conteudo = [];

        const titulos: any[] = [
            { text: 'DATA', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'CÓDIGO', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'MATERIAL', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'LOTE', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'VALIDADE', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'UNID.', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'QTDE.', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
            { text: 'STATUS', alignment: 'center', bold: true, margin: [0, 5, 0, 5]},
        ];

        conteudo.push(titulos);

        for (const item of movimento.itens) {
            
            if(item.status !== 'R' && +item.qtd_aceita > 0){
                const linha = [
                    { text: this.funcaoService.converteDataBR(item.data_cadastro), alignment: 'center', border: [false, false, false, false] },
                    { text: item.produto_unidade.produto.codigo, alignment: 'center', border: [false, false, false, false] },
                    { text: item.produto_unidade.produto.nome, alignment: 'center', border: [false, false, false, false] },
                    { text: item.lote, alignment: 'center', border: [false, false, false, false] },
                    { text: this.funcaoService.converteDataBR(item.vencimento), alignment: 'center', border: [false, false, false, false] },
                    { text: item.produto_unidade.unidade.nome, alignment: 'center', border: [false, false, false, false] },
                    { text: this.funcaoService.convertToBrNumber(item.qtd_aceita, 2), alignment: 'center', border: [false, false, false, false] },
                    { text: this.globalService.tituloStatusRequisicao(item.status), alignment: 'center', border: [false, false, false, false] },
                ];
                conteudo.push(linha);
            }
        }

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                widths: [50, 50, '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: conteudo
            }
        }];
    }

    protected imprimirAssinaturas(assinaturas: AssinaturaPessoa[]): {}[] {
        if (!assinaturas || assinaturas.length === 0) return [{}];
        if (assinaturas.length > 3) {
            let retorno: {}[] = [];
            for (let i = 0; i < assinaturas.length; i += 3) {
                retorno = retorno.concat(this.imprimirAssinaturas(assinaturas.slice(i, i + 3)));
            }
            return retorno;
        } else if (assinaturas.length > 2) {
            return this.imprimirAssinatura3Colunas(assinaturas);
        } else if (assinaturas.length > 1) {
            return this.imprimirAssinatura2Colunas(assinaturas);
        } else {
            return this.imprimirAssinatura1Coluna(assinaturas[0]);
        }
    }

    private imprimirAssinatura1Coluna(assinatura: AssinaturaPessoa): {}[] {
        return [
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 0, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 0, lineWidth: 0.5, border: [true, false, true, false] }] },
            {
                columns: [
                    { text: assinatura.pessoa.nome, alignment: 'center', bold: true, fontSize: 10, margin: [0, 3, 0, 3] },
                ]
            },
            {
                columns: [
                    { text: assinatura.cargo, alignment: 'center', fontSize: 10, margin: [0, 0, 0, 50] },
                ]
            },
        ];
    }

    private imprimirAssinatura2Colunas(assinaturas: AssinaturaPessoa[]): {}[] {
        return [
            {
                columns: [
                    assinaturas[0]?.base64 ? { image: assinaturas[0].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' },
                    assinaturas[1]?.base64 ? { image: assinaturas[1].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' }
                ]
            },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 55 : 115, y1: 0, x2: this.orientacao === 'portrait' ? 215 : 275, y2: 0, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 320 : 505, y1: 0, x2: this.orientacao === 'portrait' ? 480 : 665, y2: 0, lineWidth: 0.5 }] },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].pessoa.nome, 25), alignment: 'center', bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].pessoa.nome, 25), alignment: 'center', bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                ]
            },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].cargo, 25), alignment: 'center', fontSize: 12, margin: [0, 0, 0, 50] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].cargo, 25), alignment: 'center', fontSize: 12, margin: [0, 0, 0, 50] },
                ]
            },
        ];
    }

    private imprimirAssinatura3Colunas(assinaturas: AssinaturaPessoa[], margin?: []): {}[] {
        return [
            {
                columns: [
                    assinaturas[0]?.base64 ? { image: assinaturas[0].base64, fit: ['50%', 25], alignment: 'center' } : { text: '' },
                    assinaturas[1]?.base64 ? { image: assinaturas[1].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' },
                    assinaturas[2]?.base64 ? { image: assinaturas[2].base64, fit: ['50%', 30], alignment: 'center' } : { text: '' }
                ]
            },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 7 : 53, y1: 100, x2: this.orientacao === 'portrait' ? 167 : 213, y2: 100, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 0, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 0, lineWidth: 0.5 }] },
            { canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 365 : 570, y1: 0, x2: this.orientacao === 'portrait' ? 525 : 730, y2: 0, lineWidth: 0.5 }] },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].pessoa.nome, 30), alignment: 'center', bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].pessoa.nome, 30), alignment: 'center', bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                    { text: this.funcaoService.abreviarStr(assinaturas[2].cargo, 30), alignment: 'center', bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                ]
            },
            {
                columns: [
                    { text: this.funcaoService.abreviarStr(assinaturas[0].cargo, 31), alignment: 'center', fontSize: 12, margin: [0, 0, 0, 50] },
                    { text: this.funcaoService.abreviarStr(assinaturas[1].cargo, 30), alignment: 'center', fontSize: 12, margin: [0, 0, 0, 50] },
                    { text: this.funcaoService.abreviarStr(assinaturas[2].pessoa.cargo, 30), alignment: 'center', fontSize: 12, margin: [0, 0, 0, 50] },
                ]
            },
        ];
    }

    private dadosAssinatura(movimento: RequisicaoMovimento): {}[] {
        let assinaturas = [];

        assinaturas.push({ pessoa: { nome: movimento.usuario?.nome }, cargo: 'Requisitante' });
        assinaturas.push({ pessoa: { nome: movimento.recebedor?.nome }, cargo: 'Recebedor' });
        assinaturas.push({ cargo: 'Responsável', pessoa: '', });

        const conteudo = [
            [{ columns: [this.imprimirAssinaturas(assinaturas)], border: [false, false, false, false], margin: [0, 20] }],
        ];
        if (movimento.paciente) {
            let assinaturapaciente = [];
            assinaturapaciente.push({ pessoa: { nome: movimento.nome_paciente }, cargo: 'Paciente' });
            conteudo.push( [{ columns: [this.imprimirAssinaturas(assinaturapaciente)], border: [false, false, false, false], margin: [0, 10] }]);
        }

        return [{
            layout: 'linhas',
            table: {
                dontBreakRows: true,
                widths: ['*'],
                body: conteudo
            }
        }];
    }
}