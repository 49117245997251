import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  Contrato,
  EddyAutoComplete,
  Exercicio,
  ExercicioService,
  FuncaoService,
  GlobalService,
  Licitacao,
  LoginContabil,
  Rcms,
  Setor,
  SetorService,
} from "eddydata-lib";
import * as toastr from "toastr";
import { ContratoService, LicitacaoService } from "administrativo-lib";
import { ImprimirNotaRcmsRpt } from "../../rcms/nota-rcms-rpt/imprimir-nota-rcms-rpt/imprimir-nota-rcms-rpt.component";
import { RcmsService } from "../../rcms/service/rcms.service";

@Component({
  selector: "lib-rcms-compra-rpt",
  templateUrl: "./rcms-compra-rpt.component.html",
})
export class RcmsCompraRptComponent {

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(ImprimirNotaRcmsRpt)

  imprimirNotaRcmsRptComponent: ImprimirNotaRcmsRpt;

  public rcms: Rcms[];

  public login: LoginContabil;

  public titulo: string;

  public listExercicio: Exercicio[];
  public exercicio: Exercicio;

  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public licitacao: Licitacao;

  public contratoAutoComplete: EddyAutoComplete<Contrato>;
  public contrato: Contrato;

  public setorAutoComplete: EddyAutoComplete<Setor>;
  public setor: Setor;

  // public tipo: "N" | "D" = "N";

  public numeroInicial: number;
  public numeroFinal: number;

  public dataInicial: Date;
  public dataFinal: Date;

  public ptBR: any;

  constructor(
    private exercicioService: ExercicioService,
    private setorService: SetorService,
    private contratoService: ContratoService,
    private licitacaoService: LicitacaoService,
    private funcaoService: FuncaoService,
    protected rcmsService: RcmsService,
    private cd: ChangeDetectorRef
  ) {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngAfterViewInit(): void {
    this.titulo = 'REQUISIÇÕES DE COMPRA';
    this.cd.detectChanges();
  }

  protected afterInit(): void {}

  protected modalRelatorio(): string {
    return "";
  }

  ngOnInit(): void {
    this.carregarAutoCompletes();
    this.exercicioService
      .obterTodosOrdenadoPorAno(this.login.cidade.id)
      .subscribe((dados) => {
        this.listExercicio = dados.content;
        this.exercicio = this.login.exercicio;
      });
  }

  atualizarMascara() {
    new GlobalService().calendarMascara();
  }

  private carregarAutoCompletes() {
    this.setorAutoComplete = new EddyAutoComplete(null, this.setorService,
      "id", ["codigo", "nome"],
      { orgao_id: this.login.orgao.id, relations: "orgao", orderBy: "nome" },
      { text: ["nome", "codigo"] }
    );
    this.contratoAutoComplete = new EddyAutoComplete(null, this.contratoService,
      "id", ["numero"],
      { orgao_id: this.login.orgao.id, orderBy: "numero" },
      { number: ["numero"], text: ["numero"] }
    );
    this.licitacaoAutoComplete = new EddyAutoComplete(null, this.licitacaoService,
      "processo", ["processo", "modalidade.nome"],
      { orgao_id: this.login.orgao.id, orderBy: "processo", relations: "modalidade,exercicio" },
      { text: ["processo", "modalidade.nome"] }
    );
  }

  public imprimirRelatorio() {
    if (!this.validar()) return;

    const parametros = {
      "orgao.id": this.login.orgao.id,
      "exercicio.id": this.exercicio.id,
    };

    parametros["relations"] = [
      "favorecidos.favorecido",
      "setor",
      "licitacao",
      "contrato",
      "exercicio",
      "orgao",
      "ficha",
      "ficha.despesa",
      "subelemento",
      "requerente",
      "modalidade",
      "operador",
      "ficha.executora.unidade",
      "ficha.recurso",
      "ficha.acao",
      "ficha.aplicacao",
      "responsavel_cotacao",
      "responsavel_fiscalizacao",
      "estoque",
      "itens",
      "itens.produto_unidade",
      "itens.produto_unidade.unidade",
      "itens.produto_unidade.produto",
      "itens.cotacoes",
      "itens.cotacoes.rcmsFavorecido",
      "itens.cotacoes.rcmsFavorecido.favorecido",
    ].join(",");

    if (this.setor) {
      parametros["setor_id"] = this.setor.id;
    }

    if (this.contrato) {
      parametros["contrato_id"] = this.contrato.id;
    }

    if (this.licitacao) {
      if (this.licitacao.processo) {
        const processo = this.licitacao.processo.replace('/', '');
        parametros['OR'] = `processo=${processo};!;!;processo=${this.funcaoService.mascarar('00000/0000', processo)}`;
      }
      if (this.licitacao.modalidade) parametros['modalidade_id'] = this.licitacao.modalidade.id;
    }

    // if (!this.rcms) {
    if (this.numeroInicial && !this.numeroFinal) {
      parametros["numero"] = this.numeroInicial;
    } else {
      if (this.numeroInicial) parametros["numero$ge"] = this.numeroInicial;
      if (this.numeroFinal) parametros["numero$le"] = this.numeroFinal;
    }
    if (this.dataInicial) parametros["data_rcms$ge"] = this.funcaoService.converteDataSQL(this.dataInicial);
    if (this.dataFinal) parametros["data_rcms$le"] = this.funcaoService.converteDataSQL(this.dataFinal);

    if (!this.dataInicial || !this.dataFinal) parametros["exercicio.id"] = this.login.exercicio.id;
    // } else {
    //   parametros["id$in"] = this.rcms.map(({ id }) => id).join(',');
    // }
    parametros["orderBy"] = "numero,itens.ordem";
    this.rcmsService.filtrar(1, -1, parametros).subscribe(
      async (data) => {
        if (!data?.content || data.content.length === 0) {
          toastr.warning(`Requisição(ões) de Compra(s) não encontrada.`);
          return;
        }
        this.rcms = data.content;
        this.rcms = this.rcms.filter((rcms) => rcms.itens.length > 0);
        this.cd.detectChanges();
        this.imprimirNotaRcmsRptComponent.imprimir();
      }, (error) => toastr.error(`Foi identificado um erro na impressão da Requisição de Compras.`)
    );
  }

  private validar(): boolean {
    if (!this.rcms) {
      if (!this.exercicio || !this.exercicio.id) {
        toastr.warning("Informe exercicio para imprimir relação");
        return false;
      }
      if (this.numeroFinal && !this.numeroInicial) {
        toastr.warning(`Informe valor para ambos os campos de número de OF para buscar OF por intervalo ou informe 'Número inicial' para buscar especifica'`);
        return false;
      }
    }
    return true;
  }

  public onHide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 && c1.id && c2.id ? c1.id === c2.id : c1 === c2;
  }
}
