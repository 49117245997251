import { Component, Injector, ElementRef } from '@angular/core';
import { RecebimentoService } from '../service/recebimento.service';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, Recebimento,
  LoginContabil, GlobalService, DateFormatPipe
} from 'eddydata-lib';

@Component({
  selector: 'app-recebimento-view',
  templateUrl: './recebimento-view.component.html'
})
export class RecebimentoViewComponent extends BaseResourceFormComponent<Recebimento, LoginContabil> {

  /**
   * Declaração de variáveis
   */
  private queryParameters;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    protected recebimentoService: RecebimentoService) {
    super(new Recebimento(), injector, Recebimento.converteJson, recebimentoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      guia: [null, [Validators.required]],
      valor_recebido: 0,
      data_recebimento: [null, [Validators.required]],
      data_referencia: [null, [Validators.required]],
      especie: [null, [Validators.required]],
      impresso: [null, [Validators.required]],
      historico: [null, [Validators.required, Validators.minLength(2)]],
      conta: [null, [Validators.required]],
      exercicio: this.fb.group({
        ano: [null, [Validators.required]],
      }),
      orgao: [null, [Validators.required]],
      ficha: this.fb.group({
        numero: [null, [Validators.required]],
        valor_orcado: [null, [Validators.required]],
        receita: this.fb.group({
          codigo: [null, [Validators.required]],
        })
      })
    });
  }

  protected parametrosExtras(): {} {
    return { relations: 'conta,conta.banco,ficha,ficha.receita,ficha.recurso,ficha.aplicacao,exercicio,orgao' };
  }

  protected afterLoad() {
    // data eh criada com 1 dia a menos, esta eh uma conversão forçada
    let dataRecebimento = new Date(this.entidade.data_recebimento)
    this.queryParameters = {
      data_recebimento: `${dataRecebimento.getFullYear()}-${dataRecebimento.getMonth()+1}-${dataRecebimento.getDate()+1}` ,
      conta_bancaria: this.entidade.conta.id,
      receita: this.entidade.ficha.receita.id,
      recurso: this.entidade.ficha.recurso.id,
      aplicacao: this.entidade.ficha.aplicacao.id,
      ficha: this.entidade.ficha.id
    }

    this.entidade.data_recebimento = new DateFormatPipe().transform(this.entidade.data_recebimento, []);
  }

  protected afterInit(): void {
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected beforeSubmit() {
  }

  protected afterSubmit(ent: Recebimento) {
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

}
