import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as toastr from 'toastr';
import { Subject } from 'rxjs';
import { Login, GlobalService, ContratoHistorico, FuncaoService, TipoContratacao, Contrato } from 'eddydata-lib';
import { ContratoHistoricoService } from '../service/contrato-historico.service';
import { ContratoService, EmpenhoService, LicitacaoService, TombamentoService } from 'administrativo-lib';
import { RcmsService } from '../../rcms/service/rcms.service';
import { CompraService } from '../../compra/service/compra.service';
import { takeUntil } from 'rxjs/operators';
import { MovimentoEstoqueService } from 'almoxarifado-lib';

declare var $: any;

@Component({
  selector: 'app-contrato-historico-dlg',
  templateUrl: './contrato-historico-dlg.component.html'
})
export class ContratoHistoricoDlgComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  protected datepipe: DatePipe;
  public ptBR: any;
  protected unsubscribe: Subject<void> = new Subject();
  public contratoAtualizar: Contrato;
  public msgError: string = '';

  @Input() listaContratacao: TipoContratacao[];

  @Input() entidade: ContratoHistorico;
  @Input() lista: Array<any>;
  @Input() login: Login;
  @Output() alteracaoEvent: EventEmitter<Contrato> = new EventEmitter();

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected messageService: MessageService,
    public funcaoService: FuncaoService,
    public historicoService: ContratoHistoricoService,
    public contratoService: ContratoService,
    public licitacaoService: LicitacaoService,
    public rcmsService: RcmsService,
    public compraService: CompraService,
    public empenhoService: EmpenhoService,
    public movimentoEstoqueService: MovimentoEstoqueService,
    public tombamentoService: TombamentoService,
    protected confirmationService: ConfirmationService,
    private globalService: GlobalService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      setTimeout(() => this.globalService.calendarMascara(), 100);
    }
  }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
  }

  public async salvar() {
    try {
      if (!this.entidade.data_historico) {
        throw new Error('Informe a data do histórico!');
      }
      if (!this.entidade.data_contabilizacao) {
        throw new Error('Informe a data de contabilização!');
      }
      if (!this.funcaoService.podeAlterarAudesp(this.entidade.data_contabilizacao, this.login)) {
        throw new Error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
      }
      // envia a entidade para ser salva no banco -----
      this.entidade.usuario = this.login.usuario;
      this.entidade.editavel = false;
      if (this.entidade.processo !== this.entidade.contrato.processo) {
        await this.verificarProcesso();
      } else {
        await this.alterarProcesso();
      }

    } catch (e) {
      this.messageService.add({ severity: 'error', summary: 'Validação', detail: e });
      throw e;
    }
  }

  public adicionarAlteracao() {
    //adiciona ao historico
    this.historicoService.inserir(this.entidade).subscribe((dados) => {
      this.messageService.add({ severity: 'info', summary: 'Validação', detail: 'Registro inserido com sucesso!' });
      this.lista.unshift(this.entidade);
    },
      (error) => {

        this.messageService.add(
          { severity: 'error', summary: 'Atenção!', detail: error.error && error.error.payload ? error.error.payload : error }
        )
      }
    );
  }

  /**
  * Método para verificação de objetos, usados em combos `<select>`
 */
  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) {
        return c1.id === c2.id;
      } else if (c1.chave && c2.chave) {
        return c1.chave == c2.chave;
      } else {
        return c1 === c2;
      }
    } else {
      return false;
    }
  }

  public async verificarProcesso() {
    let listaContrato;
    let contratos = false;

    await this.verificaEmpenhos(this.entidade.contrato);

    listaContrato = await this.contratoService.filtrar(1, -1, { 'licitacao.id': this.entidade.contrato.licitacao.id, 'orgao.id': this.entidade.contrato.orgao.id, 'id$ne': this.entidade.contrato.id, relations: 'modalidade,favorecido,tipo_contratacao,prazo' }).toPromise();

    if (await this.verificaCompraRequisicao(this.entidade.contrato)) {
      toastr.info('Este processo possui OF e/ou Requisição!');
    }

    for await (const contrato of listaContrato.content) {
      await this.verificaEmpenhos(contrato);

      if (await this.verificaCompraRequisicao(contrato)) {
        toastr.info('Este processo possui OF e/ou Requisição!');
        contratos = true;
        break
      }
    }
    if (contratos || listaContrato.content.length > 0) {
      this.confirmationService.confirm({
        message: `Existem contratos vinculados a este processo, deseja prosseguir?`,
        header: `Autorização`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        key: 'ContratoHistorico',
        accept: async () => {
          if (listaContrato.content) {
            for await (const contrato of listaContrato.content) {
              let alteracaoContrato: Contrato;
              alteracaoContrato = contrato
              alteracaoContrato.processo = this.entidade.processo;
              alteracaoContrato['data_historico'] = new Date();
              const empenhos = (await this.empenhoService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, relations: 'exercicio,orgao,subelemento,favorecido.tipo,ficha.despesa,usuario_cadastro,modalidade,licitacao' }).toPromise()).content;
              if (empenhos.length > 0) {
                for await (const empenho of empenhos) {
                  empenho.processo = this.entidade.processo;
                  empenho['atualizar_precatorio'] = true;
                  this.empenhoService.atualizar(empenho).pipe(takeUntil(this.unsubscribe))
                    .subscribe(async () => {
                      //await this.adicionarAlteracao(alteracaoContrato);
                    },
                      (msgError) => this.messageService.add(
                        { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                      )
                    );
                  const tombamentos = (await this.tombamentoService.filtrar(1, -1, { 'orgao.id': empenho.orgao.id, 'empenho.id': empenho.id, relations: 'setor,produto,plano_conta' }).toPromise()).content;
                  if (tombamentos.length > 0) {
                    for await (const tombamento of tombamentos) {
                      tombamento.processo = this.entidade.processo;
                      tombamento['alteracaoContrato'] = true;
                      this.tombamentoService.atualizar(tombamento).pipe(takeUntil(this.unsubscribe))
                        .subscribe(async () => {
                          //await this.adicionarAlteracao(alteracaoContrato);
                        },
                          (msgError) => this.messageService.add(
                            { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                          )
                        );
                    }
                    toastr.info('Aguarde, há tombamentos neste processo. Poderá levar alguns minutos.');
                  }

                }
              }

              const compras = (await this.compraService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false, relations: 'favorecido,contrato,convenio,ficha,subelemento,modalidade,prazo_entrega,prazo_pagamento,prazo,estoque,setor,licitacao,itens' }).toPromise()).content;
              if (compras.length > 0) {
                for await (const compra of compras) {
                  compra['alteracaoContrato'] = true;
                  compra.processo = this.entidade.processo;
                  this.compraService.atualizar(compra).pipe(takeUntil(this.unsubscribe))
                    .subscribe(async () => {
                      //await this.adicionarAlteracao(alteracaoContrato);
                    },
                      (msgError) => this.messageService.add(
                        { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                      )
                    );
                  const movimentoEstoque = (await this.movimentoEstoqueService.filtrar(1, -1, { 'orgao.id': compra.orgao.id, 'excluido': false, 'compra.id': compra.id, relations: 'recebedor,setorAlmoxarifado.estoque,setorDestinoAlmoxarifado,favorecido,tipo,itens.produto_unidade.produto,exercicio,orgao,compra.exercicio' }).toPromise()).content

                  if (movimentoEstoque.length > 0) {
                    for await (const movimento of movimentoEstoque) {
                      movimento.n_processo = this.entidade.processo;
                      movimento['alteracaoContrato'] = true;
                      this.movimentoEstoqueService.atualizar(movimento).pipe(takeUntil(this.unsubscribe))
                        .subscribe(async () => {
                          //await this.adicionarAlteracao(alteracaoContrato);
                        },
                          (msgError) => this.messageService.add(
                            { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                          )
                        );
                    }
                  }
                }
              }
              const rcmss = (await this.rcmsService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false, relations: 'modalidade,licitacao,ficha,setor.unidade' }).toPromise()).content;
              if (rcmss.length > 0) {
                for await (const rcms of rcmss) {
                  rcms.processo = this.entidade.processo;
                  rcms['alteracaoContrato'] = true;
                  this.rcmsService.atualizar(rcms).pipe(takeUntil(this.unsubscribe))
                    .subscribe(async () => {
                      //await this.adicionarAlteracao(alteracaoContrato);
                    },
                      (msgError) => this.messageService.add(
                        { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                      )
                    );
                }
              }

              this.contratoService.atualizar(alteracaoContrato).pipe(takeUntil(this.unsubscribe))
                .subscribe(async () => {
                  //await this.adicionarAlteracao(alteracaoContrato);
                },
                  (msgError) => this.messageService.add(
                    { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                  )
                );
            }
          }

          const licitacao = (await this.licitacaoService.filtrar(1, 1, { 'id': this.entidade.contrato.licitacao.id, 'orgao.id': this.entidade.contrato.orgao.id, relations: 'modalidade,tipo_contratacao,setor,exercicio' }).toPromise()).content;

          if (licitacao.length) {
            licitacao[0]['processo'] = this.entidade.processo;
            this.licitacaoService.atualizar(licitacao[0]).pipe(takeUntil(this.unsubscribe))
              .subscribe(() => {
              },
                (msgError) => this.messageService.add(
                  { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                )
              );
          }
          await this.alterarProcesso();
        },
        reject: () => {
          return;
        }
      });
    } else {
      const licitacao = (await this.licitacaoService.filtrar(1, 1, { 'id': this.entidade.contrato.licitacao.id, 'orgao.id': this.entidade.contrato.orgao.id, relations: 'modalidade,tipo_contratacao,setor,exercicio' }).toPromise()).content;

      if (licitacao.length) {
        licitacao[0]['processo'] = this.entidade.processo;
        this.licitacaoService.atualizar(licitacao[0]).pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
          },
            (msgError) => this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
            )
          );
      }
      await this.alterarProcesso();
    }



  }

  public async verificaEmpenhos(contrato: Contrato) {
    const empenhos = (await this.empenhoService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id }).toPromise()).content;
    let empenho = empenhos.reduce((acc, interavel) => {
      return +(+(acc + +interavel.valor_empenho).toFixed(2)).toFixed(2);
    }, 0)
    if ((empenho > 0 && empenhos.length) && this.funcaoService.diferencaEmDias(new Date(), new Date(contrato.data_assinatura)) > this.login['dias_bloquear_alteracoes']) {
      throw 'O número do processo não pode ser alterado: Dias de alterações contabeis execedidas!';
    }
  }

  public async verificaCompraRequisicao(contrato: Contrato) {
    const compra = (await this.compraService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false }).toPromise()).content;
    const rcms = (await this.rcmsService.filtrar(1, -1, { 'contrato.id': contrato.id, 'orgao.id': contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false }).toPromise()).content;

    return compra.length > 0 || rcms.length > 0 ? true : false;
  }

  public async alterarProcesso() {
    if (!this.lista) {
      this.lista = new Array();
    }
    if (this.entidade.contrato.processo !== this.entidade.processo) {
      const empenhos = (await this.empenhoService.filtrar(1, -1, { 'contrato.id': this.entidade.contrato.id, 'orgao.id': this.entidade.contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, relations: 'exercicio,orgao,subelemento,favorecido.tipo,ficha.despesa,usuario_cadastro,modalidade,licitacao' }).toPromise()).content;
      if (empenhos.length > 0) {
        for await (const empenho of empenhos) {
          empenho.processo = this.entidade.processo;
          empenho['atualizar_precatorio'] = true;
          this.empenhoService.atualizar(empenho).pipe(takeUntil(this.unsubscribe))
            .subscribe(async () => {
              //await this.adicionarAlteracao(alteracaoContrato);
            },
              (msgError) => this.messageService.add(
                { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
              )
            );
          const tombamentos = (await this.tombamentoService.filtrar(1, -1, { 'orgao.id': empenho.orgao.id, 'empenho.id': empenho.id, relations: 'setor,produto,plano_conta' }).toPromise()).content;
          if (tombamentos.length > 0) {
            toastr.info('Aguarde, há tombamentos neste processo. Poderá levar alguns minutos.');
            for await (const tombamento of tombamentos) {
              tombamento.processo = this.entidade.processo;
              tombamento['alteracaoContrato'] = true;
              this.tombamentoService.atualizar(tombamento).pipe(takeUntil(this.unsubscribe))
                .subscribe(async () => {
                  //await this.adicionarAlteracao(alteracaoContrato);
                },
                  (msgError) => this.messageService.add(
                    { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                  )
                );
            }
          }

        }
      }
      const compras = (await this.compraService.filtrar(1, -1, { 'contrato.id': this.entidade.contrato.id, 'orgao.id': this.entidade.contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false, relations: 'favorecido,contrato,convenio,ficha,subelemento,modalidade,prazo_entrega,prazo_pagamento,prazo,estoque,setor,licitacao,itens' }).toPromise()).content;
      if (compras.length > 0) {
        for await (const compra of compras) {
          compra['alteracaoContrato'] = true;
          compra.processo = this.entidade.processo;
          this.compraService.atualizar(compra).pipe(takeUntil(this.unsubscribe))
            .subscribe(async () => {
              //await this.adicionarAlteracao(alteracaoContrato);
            },
              (msgError) => this.messageService.add(
                { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
              )
            );
          const movimentoEstoque = (await this.movimentoEstoqueService.filtrar(1, -1, { 'orgao.id': compra.orgao.id, 'excluido': false, 'compra.id': compra.id, relations: 'recebedor,setorAlmoxarifado.estoque,setorDestinoAlmoxarifado,favorecido,tipo,itens.produto_unidade.produto,exercicio,orgao' }).toPromise()).content

          if (movimentoEstoque.length > 0) {
            for await (const movimento of movimentoEstoque) {
              movimento.n_processo = this.entidade.processo;
              movimento['alteracaoContrato'] = true;
              this.movimentoEstoqueService.atualizar(movimento).pipe(takeUntil(this.unsubscribe))
                .subscribe(async () => {
                  //await this.adicionarAlteracao(alteracaoContrato);
                },
                  (msgError) => this.messageService.add(
                    { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
                  )
                );
            }
          }
        }
      }
      const rcmss = (await this.rcmsService.filtrar(1, -1, { 'contrato.id': this.entidade.contrato.id, 'orgao.id': this.entidade.contrato.orgao.id, 'exercicio.id': this.login.exercicio.id, 'excluido': false, relations: 'modalidade,licitacao,ficha,setor.unidade' }).toPromise()).content;
      if (rcmss.length > 0) {
        for await (const rcms of rcmss) {
          rcms['alteracaoContrato'] = true;
          rcms.processo = this.entidade.processo;
          this.rcmsService.atualizar(rcms).pipe(takeUntil(this.unsubscribe))
            .subscribe(async () => {
              //await this.adicionarAlteracao(alteracaoContrato);
            },
              (msgError) => this.messageService.add(
                { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
              )
            );
        }
      }
    }

    if (!this.entidade.id) {
      //update no contrato
      this.contratoAtualizar = this.entidade.contrato
      this.contratoAtualizar.processo = this.entidade.processo;
      this.contratoAtualizar.data_inicio = new Date(this.entidade.data_inicio);
      this.contratoAtualizar.data_termino = new Date(this.entidade.data_termino);
      this.contratoAtualizar.tipo_contratacao = this.entidade.tipo_contratacao;
      this.contratoAtualizar.valor_caucao = this.entidade.valor_caucao;
      this.contratoAtualizar.valor_contrato = this.entidade.valor_contrato;
      this.contratoAtualizar['data_historico'] = new Date();
      this.contratoAtualizar['data_contabilizacao_alteracao'] = new Date(this.entidade.data_contabilizacao);
      this.contratoAtualizar['ignoreEstrituracao'] = true;

      this.contratoService.atualizar(this.contratoAtualizar).pipe(takeUntil(this.unsubscribe))
        .subscribe(async () => {
          // await this.adicionarAlteracao();
          toastr.success('Alteração realizada com sucesso!');
          $('#dialogHistorico').modal('hide');
          this.alteracaoEvent.emit(this.contratoAtualizar);
        },
          (msgError) => {
            this.messageService.add(
              { severity: 'error', summary: 'Atenção!', detail: msgError.error && msgError.error.payload ? msgError.error.payload : msgError }
            )
          }
        );
    }
  }

}
