import { Component, ElementRef, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BaseResourceFormComponent, FuncaoService,
  GlobalService, LoginContabil, PessoaService, RequisicaoItemMovimento, RequisicaoMovimento, SetorAlmoxarifado, StatusRequisicao
} from 'eddydata-lib';
import { ComprovanteRequisicaoRpt } from '../../relatorio/comprovante-requisicao-rpt/comprovante-requisicao-rpt';
import { RequisicaoMovimentoService } from '../../requisicao/service/requisicao-movimento.service';
import { RequisicaoItemMovimentoService } from '../service/requisicao-item-movimento.service';

@Component({
  selector: 'lib-requisicao-autorizacao-view',
  templateUrl: './requisicao-autorizacao-view.component.html'
})
export class RequisicaoAutorizacaoViewComponent extends BaseResourceFormComponent<RequisicaoMovimento, LoginContabil> {
  /**
   * Declaração de variáveis
   */

  public listSetores: SetorAlmoxarifado[];
  public itens: RequisicaoItemMovimento[];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected requisicaoMovService: RequisicaoMovimentoService,
    protected requisicaoItemService: RequisicaoItemMovimentoService,
    protected pessoaService: PessoaService) {
    super(new RequisicaoMovimento(), injector, RequisicaoMovimento.converteJson, requisicaoMovService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      data_cadastro: [new Date()],
      status: [StatusRequisicao.AGUARDANDO, Validators.required],
      destinatario: [null],
      setor_almoxarifado: [null, Validators.required],
      usuario: [this.entidade.usuario],
      usuario_aceite: [this.entidade.usuario_aceite],
      data_retirada: [this.entidade.data_retirada],
      exercicio: [this.login.exercicio],
      orgao: [this.login.orgao],
      recebedor: [null, [Validators.required]],
      itens: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        'usuario', 'setor_almoxarifado', 'setor_almoxarifado.setor', 'setor_almoxarifado.estoque',
        'exercicio', 'orgao', 'usuario', 'recebedor', 'itens', 'itens.produto_unidade',
        'itens.produto_unidade.produto', 'itens.produto_unidade.unidade', 'usuario_aceite',
        'itens.produto_unidade_troca.produto', 'itens.produto_unidade_troca.unidade'
      ]
    };
  }

  protected afterInit(): void { }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected async afterLoad() {
    this.requisicaoItemService.extendido(1, -1, {
      'requisicao.id': this.entidade.id,
      'relations': ['produto_unidade', 'produto_unidade.produto', 'produto_unidade.unidade', 'produto_unidade_troca',
        'produto_unidade_troca.produto', 'produto_unidade_troca.unidade']
    }).subscribe((res) => {
      this.itens = res ? res.content : new Array<RequisicaoItemMovimento>();
      this.itens = this.itens.map(i => {
        if (i.qtd_aceita > 0) {
          return { ...i, checked: true }
        } else {
          return { ...i, checked: false }
        }
      });
    }, error => this.funcaoService.acaoErro(error))
  }

  protected beforeSubmit() { }

  protected afterSubmit(ent: RequisicaoMovimento) { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public valorRequisitado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (+it.qtd_requisitada)
        vl += Number.parseFloat(it.qtd_requisitada.toString());
    }
    return vl;
  }

  public valorAceito() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.qtd_aceita)
        vl += Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public valorRecusado() {
    if (!this.itens)
      return 0.0;
    let vl = 0.0;
    for (let it of this.itens) {
      if (it.status && it.status !== StatusRequisicao.AGUARDANDO)
        vl += Number.parseFloat(it.qtd_requisitada.toString()) - Number.parseFloat(it.qtd_aceita.toString());
    }
    return vl;
  }

  public tituloStatusRequisicao(status: StatusRequisicao): string {
    return this.globalService.tituloStatusRequisicao(status);
  }

  public iconStatusRequisicao(status: StatusRequisicao): {} {
    let icon = this.globalService.iconStatusRequisicao(status);
    let ngIcon = {};
    ngIcon[icon] = true;
    return ngIcon;
  }

  public corStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`bg-${cor}`] = true;
    return ngCor;
  }

  public badgeStatusRequisicao(status: StatusRequisicao): {} {
    let cor = this.globalService.corStatusRequisicao(status);
    let ngCor = {};
    ngCor[`badge-${cor}`] = true;
    return ngCor;
  }

  public emitirComprovante() {
    new ComprovanteRequisicaoRpt(this.login, this.globalService).imprimir(this.entidade);
  }

}
