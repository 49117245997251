// tslint:disable: variable-name
import { Orgao } from '../comum/orgao.model';
import { Exercicio } from '../comum/exercicio.model';
import { BaseResourceModel } from '../../models/base-resource.model';
import { EventoContabilConta } from './evento-contabil-conta.model';
import { PlanoConta } from './plano-conta.model';
import { EventoContabilEspecie } from './evento-contabil-especie.model';

export class EventoContabil extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public observacao?: string,
    public aux?: number,
    public orgao?: Orgao,
    public exercicio?: Exercicio,
    public exige_contrato?: boolean,
    public exige_ficha_extra?: boolean,
    public exige_empenho?: boolean,
    public exige_conta_contabil?: boolean,
    public exige_receita?: boolean,
    public exige_recurso?: boolean,
    public exige_aplicacao?: boolean,
    public exige_aplicacao_variavel?: boolean,
    public exige_convenio?: boolean,
    public exige_fornecedor?: boolean,
    public exige_mes?: boolean,
    public exige_domicilio_bancario?: boolean,
    public exige_referencia?: boolean,
    public exige_despesa?: boolean,
    public exige_cadastro_contrato?: boolean,
    public exige_ficha_despesa?: boolean,
    public exige_processo?: boolean,
    public exige_dotacao?: boolean,
    public exige_acerto_conta_corrente?: boolean,
    public exige_acerto_audesp?: boolean,
    public exige_orgao_recebedor?: boolean,
    public exige_empenho_orcamentario_anterior?: boolean,
    public cancelamento?: boolean,
    public ativo?: boolean,
    public contas?: EventoContabilConta[],
    public plano_conta?: PlanoConta,
    public evento_especie?: EventoContabilEspecie,
  ) {
    super();
  }

  static converteJson(json: any): EventoContabil {
    return Object.assign(new EventoContabil(), json);
  }

}
