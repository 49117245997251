// tslint:disable: variable-name
import { BaseResourceModel } from '../../models/base-resource.model';
import { EmpenhoExtra } from '../contabil/empenho-extra.model';
import { LiquidacaoResto } from '../contabil/liquidacao-resto.model';
import { Liquidacao } from '../contabil/liquidacao.model';
import { TransferenciaRecurso } from './transferencia-recurso.model';

export class TransferenciaRecursoItem extends BaseResourceModel {
  constructor(
    public id?: number,
    public especie?: string,
    public numero?: number,
    public parcela?: number,
    public valor_retencao?: number,
    public valor?: number,
    public transferencia?: TransferenciaRecurso,
    public liquidacao?: Liquidacao,
    public empenho_extra?: EmpenhoExtra,
    public liquidacao_resto?: LiquidacaoResto,
    public editavel?: boolean
  ) {
    super();
  }

  static converteJson(json: any): TransferenciaRecursoItem {
    return Object.assign(new TransferenciaRecursoItem(), json);
  }
}
