import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseResourceService, Depreciacao, Page, Tombamento, TombamentoHistorico } from 'eddydata-lib';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TombamentoService extends BaseResourceService<Tombamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`tombamentos`, injector);
  }

  public obterPorPlaca(placa: number, orgaoId: number): Observable<Tombamento> {
    return this.http.get<Tombamento>(`${this.login.cidade.id}/${this.api}/obter?placa=${placa}&orgao_id=${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public proximaPlaca(orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/proxima-placa?orgao=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorNome(nome: string, orgaoId: number): Observable<Tombamento> {
    return this.http.get<Tombamento>(
      `${this.login.cidade.id}/${this.api}/filtrar?produto.nome$like=${nome}%&orgao_id=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorDataIncorporado(data1: string, data2: string, orgaoId: number): Observable<Array<Tombamento>> {
    return this.http.get<Array<Tombamento>>(`${this.login.cidade.id}/${this.api}/filtrar-incorporados/${data1}/${data2}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterPorDataBaixado(data1: string, data2: string, orgaoId: number): Observable<Array<Tombamento>> {
    return this.http.get<Array<Tombamento>>(`${this.login.cidade.id}/${this.api}/filtrar-baixados/${data1}/${data2}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterHistorico(id: number): Observable<TombamentoHistorico[]> {
    return this.http.get<TombamentoHistorico[]>(`${this.login.cidade.id}/${this.api}/historico/${id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarSituacao(setor: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizar-situacao/${setor}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarConservacao(setor: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizar-conservacao/${setor}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizarAquisicaoAno(setor: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizar-aquisicao-ano/${setor}`, this.httpOptions()).pipe(
        map(res => res.reverse()),
        catchError(err => this.handleError(err))
      );
  }

  public listarPendencias(setor: number): Observable<{ tipo: string, descricao: string, id: number, qtde: number }[]> {
    return this.http.get<{ tipo: string, descricao: string, id: number, qtde: number }[]>(
      `${this.login.cidade.id}/${this.api}/listar-pendencias/${setor}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public conferenciaContabil(data1: string, data2: String, orgao?: number, empenho?: number): Observable<{}[]> {
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/conferencia-contabil/${data1}/${data2}/${orgao}?${empenho > 0 ? `empenho=${empenho}` : ''}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizacaoSubGrupoContabil(orgao: number, data1: string, data2: string): Observable<{}[]> {
    let query = '?';
    if (data1) query += `data1=${data1}&`
    if (data2) query += `data2=${data2}&`

    query = query.substring(0, query.length - 1)
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizacao-subgrupo-contabil/${orgao}${query}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalizacaoSubGrupo(orgao: number, depreciacao: Depreciacao, data1: string, data2: string, depreciacao2: Depreciacao): Observable<{}[]> {
    let query = '?';
    if (depreciacao) query += `depreciacao=${depreciacao.id}&`
    if (data1) query += `data1=${data1}&`
    if (data2) query += `data2=${data2}&`
    if (depreciacao2) query += `depreciacao2=${depreciacao2.id}&`

    query = query.substring(0, query.length - 1)
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/totalizacao-subgrupo/${orgao}${query}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public relatorioDepreciacaoGerencial(orgao: number, depreciacao: Depreciacao,orderby: string,placaInicial:number,placaFinal:number,grupo:number,subgrupo:number,paginaCorrente: number,limite: number): Observable<Page> {
    let query = '?';
    if (depreciacao) query += `depreciacao=${depreciacao.id}&`
    query += `order=${orderby}&`
    query += `placaInicial=${placaInicial}&`
    query += `placaFinal=${placaFinal}&`
    query += `grupo=${grupo}&`
    query += `subgrupo=${subgrupo};`

    query = query.substring(0, query.length - 1)
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/relatorio-depreciacao-gerencial/${orgao}/${paginaCorrente}/${limite}${query}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public reativarBem(tombamentoId: number, entidade: Tombamento): Observable<Tombamento> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/reativar/${tombamentoId}`, entidade, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }

  public vincularMovimento(tombamentos: number[], movItem_id: number): Observable<any> {
    return this.http.put(`${this.login.cidade.id}/${this.api}/vincular-movimento/${movItem_id}`, { tombos: tombamentos }, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    )
  }

  public listagemGeralDeBens(param): Observable<any> {
    const dataAquisicaoInicial = param.data_aquisicao$ge;
    const dataAquisicaoFinal = param.data_aquisicao$le;
    const situacao = param?.situacao;
    const ordenacao = param.orderBy;
    const placaLike = param?.placa$like ? param.placa$like.replace('%', '') : 0;
    const placaInicial = param?.placa$ge ? param.placa$ge : 0;
    const placaFinal = param?.placa$le ? param.placa$le : 0;
    const setor = param['setor.id'] ? param['setor.id'] : 0;
    const favorecido = param['favorecido.id'] ? param['favorecido.id'] : 0;
    const subGrupo = param['produto.material.sub_grupo.id'] ? param['produto.material.sub_grupo.id'] : 0;
    const despesa = param['plano_conta.id'] ? param['plano_conta.id'] : 0;
    const produto = param['produto.id'] ? param['produto.id'] : 0;
    const somenteVeiculo = param['veiculo.id$not_null'] ? param['veiculo.id$not_null'] : false;

    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/listagem-geral-de-bens/${this.login.orgao.id}/${dataAquisicaoInicial}/${dataAquisicaoFinal}/${situacao}/${ordenacao}/${placaLike}/${placaInicial}/${placaFinal}/${setor}/${favorecido}/${subGrupo}/${despesa}/${produto}/${somenteVeiculo}/`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public downloadListagem(): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/download-listagem`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders(this.adicionarCriptografia({
        Authorization: this.login.token,
      }))
    }).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }
}
