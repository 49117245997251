import { Component, Injector, Input, OnInit } from '@angular/core';
import { Login } from '../../entidade/login/login';
import * as toastr from 'toastr';
import { FuncaoService } from '../../util/funcao.service';
import { ConfirmationService } from 'primeng/api';
import { PNCPService } from '../../util/pncp.service';
import { ProgressoService } from '../../components/progresso/service/progresso.service';
import { EmpenhoService } from 'administrativo-lib';
import { Empenho } from '../../entidade/contabil/empenho.model';

@Component({
  selector: 'app-pncp-empenhos',
  templateUrl: './pncp-empenhos.component.html',
  styleUrls: ['./pncp-empenhos.component.css']
})
export class PncpEmpenhoComponent implements OnInit {

  @Input() login: Login;
  lista: Array<any> = new Array<any>();
  tipoFiltro: number = 0;
  todos: boolean = false;
  numeroEmpenho: string;
  pendentes: boolean = false;
  historicoCompra: Array<any> = new Array<any>();

  public empenho: Empenho = null;

  resultados: Array<{ item: string, sucesso: boolean, mensagem: string }> = new Array<{ item: string, sucesso: boolean, mensagem: string }>();
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected empenhoService: EmpenhoService,
    protected confirmationService: ConfirmationService,
    protected pncpService: PNCPService,
    protected progressoService: ProgressoService,
    private funcaoService: FuncaoService) {
  }

  public ngOnInit(): void {
    this.filtrarEmpenhos();
  }

  public filtrarEmpenhos() {
    const parametros = {};

    parametros['ignoreCondObrig'] = true;
    parametros['cadastrado_pncp'] = false;
    parametros['licitacao_id$not_null'] = true
    parametros['contrato_id$null'] = true
    parametros['licitacao.lei_14133_2021'] = true
    parametros['licitacao.sequencial_pncp$not_null'] = true
    parametros['licitacao.cadastrado_pncp'] = true
    parametros['relations'] = 'orgao,favorecido,exercicio,licitacao.tipo_contratacao,licitacao.modalidade,licitacao.exercicio'
    parametros['orgao_id'] = this.login.orgao.id

    if (this.tipoFiltro == 1) {
      parametros['sequencial_pncp$null'] = true;
    } else if (this.tipoFiltro == 2) {
      parametros['sequencial_pncp$not_null'] = true;
      parametros['cadastrado_pncp'] = false;
    } else if (this.tipoFiltro < 0) {
      parametros['sequencial_pncp$not_null'] = true;
      parametros['cadastrado_pncp'] = true;
    }

    this.empenhoService.filtrar(0, -1, parametros).subscribe((res) => {
      this.lista = res.content;
    }, () => {
      toastr.error('Erro ao filtrar contratos para o PNCP');
    });

  }

  public podeIncluir(url?: string) {
    if (this.login) {
      if (new FuncaoService().campoJsonToken(this.login.token, "administrador") == true) {
        return true;
      }

      return new FuncaoService().podeIncluir(this.login, url)
    }
    return false;
  }

  public marcarTodos() {
    this.lista.forEach(i => {
      i.enviar = this.todos;
    });
  }

  public voltarListagemEmpenhos() {
    this.resultados = [];
    this.filtrarEmpenhos();
  }

  public editarEmpenho(item: Empenho) {
    this.empenho = item;
  }

  public cancelarEdicao() {
    this.empenho = null;
    this.resultados = [];
  }

  public buscarEmpenho() {
    this.empenho = this.lista.find(i => i.numero = +this.numeroEmpenho);
  }

  public confirmarEnvio() {
    const envios: Array<number> = new Array<number>();
    let texto: string = '';
    for (const i of this.lista) {
      if (i.enviar) {
        envios.push(i.id);
        texto += '\n' + i.numero + ` (${i.sequencial_pncp ? 'Retificação' : 'Novo'})`;
      }
    }

    this.confirmationService.confirm({
      header: 'Confirmar Envio',
      message: `<pre>Confirma o envio dos empenhos seguir para o PNCP? \nAlterações posteriores apenas via retificação ou exclusão: \n${texto}</pre>`,
      key: 'enviarEmpenho',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, confirmo envios.',
      rejectLabel: 'Não',
      accept: async () => {
        this.enviar(envios);
      }
    });
  }

  private enviar(lista: Array<number>) {
    if (!this.login.dados_pncp?.id) {
      toastr.error('Não autenticado no portal do PNCP');
      return;
    }
    this.pncpService.enviarEmpenhos(lista).subscribe((idTransacao) => {
      this.progressoService.show(idTransacao, async (retorno) => {
        this.resultados = retorno;
      });
    }, e => {
      this.funcaoService.acaoErro(e);
    });
  }

}
