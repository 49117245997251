// tslint:disable: variable-name
import { TipoOcorrenciaContrato } from '../../components/types';
import { BaseResourceModel } from '../../models/base-resource.model';
import { Usuario } from '../comum/usuario.model';
import { Contrato } from './contrato.model';

export class ContratoOcorrencia extends BaseResourceModel {
  constructor(
    public id?: number,
    public data_ocorrencia?: Date,
    public ocorrencia?: string,
    public tipo?: TipoOcorrenciaContrato,
    public contrato?: Contrato,
    public usuario?: Usuario,
  ) {
    super();
  }

  static converteJson(json: any): ContratoOcorrencia {
    return Object.assign(new ContratoOcorrencia(), json);
  }
}
