import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BaseResourceItemsComponent,
  Contrato,
  ContratoHistorico,
  FuncaoService,
  GlobalService,
  TipoContratacao
} from 'eddydata-lib';
import * as toastr from 'toastr';
import { ContratoHistoricoService } from '../service/contrato-historico.service';

declare var $: any;

@Component({
  selector: 'lib-contrato-historico',
  templateUrl: './contrato-historico.component.html'
})
export class ContratoHistoricoComponent extends BaseResourceItemsComponent<ContratoHistorico> {

  public ptBR: any;
  public itemAtual: ContratoHistorico;

  @Input() public listaContratacao: TipoContratacao[];
  @Input() public contrato: Contrato;
  @Output() alteracaoEvent: EventEmitter<Contrato> = new EventEmitter();
  @Input() visualizar: Boolean;

  imaskConfig = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: ','
  };

  constructor(
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    historicoService: ContratoHistoricoService) {
    super(new ContratoHistorico(), historicoService);
  }

  protected afterInit(): void {
    this.ptBR = this.globalService.obterDataBR();
  }

  protected validSave(item: ContratoHistorico): boolean {
    if (!item.data_historico) {
      toastr.warning('Informe a data de contabilização!');
      return false;
    }
    return true;
  }

  protected beforeSave(item: ContratoHistorico): void { }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public adicionar() {
    this.itemAtual = new ContratoHistorico();
    this.itemAtual.data_historico = this.funcaoService.obterDataUTC(new Date());
    this.itemAtual.contrato = this.contrato;
    this.itemAtual.data_inicio = this.contrato.data_inicio;
    this.itemAtual.data_termino = this.contrato.data_termino;
    this.itemAtual.tipo_contratacao = this.contrato.tipo_contratacao;
    this.itemAtual.processo = this.contrato.processo;
    this.itemAtual.valor_caucao = this.contrato.valor_caucao;
    this.itemAtual.valor_contrato = this.contrato.valor_contrato;
    this.itemAtual.editavel = true;
    $('#dialogHistorico').modal('show');
  }

  alteracaoHistoricoEvento(event: any){
    this.alteracaoEvent.emit(event);
  }

  public podeVisualizar() {
    if (this.visualizar === true) {
      return true;
    }
    if(this.visualizar === false){
      return false;
    }
  }
}