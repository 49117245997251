import * as extenso from 'extenso';
import { FuncaoService, Login, Relatorio, GlobalService, Empenho, OrgaoAssinaturaService, LicitacaoPipe } from 'eddydata-lib';
import JsBarcode from 'jsbarcode';

export class NotaEmpenho {

  protected funcaoService: FuncaoService;
  protected globalService: GlobalService;

  private log: Login;
  private ordenador_despesa: string;
  private cargo_ordenador_despesa: string;
  private ordenador_despesa_funcao_08: string;
  private cargo_ordenador_despesa_funcao_08: string;
  private ordenador_despesa_funcao_10: string;
  private cargo_ordenador_despesa_funcao_10: string;
  private ordenador_despesa_funcao_12: string;
  private cargo_ordenador_despesa_funcao_12: string;
  private ordenador_pagto: string;
  private cargo_ordenador_pagto: string;
  private contador: string;
  private cargo_contador: string;
  private tesoureiro: string;
  private cargo_tesoureiro: string;

  constructor(protected assinaturaService: OrgaoAssinaturaService) {
    this.funcaoService = new FuncaoService();
    this.globalService = new GlobalService();
  }

  private async preencheAssinatura() {
    const assinatura = await this.assinaturaService.obter({
      orgao_id: this.log.orgao.id,
      'data_limite$ge': String(this.funcaoService.converteDataSQL(new Date().toLocaleDateString()))
    }).toPromise();
    if (assinatura) {
      this.ordenador_despesa = assinatura.ordenador_despesa;
      this.cargo_ordenador_despesa = assinatura.cargo_ordenador_despesa;

      this.ordenador_despesa_funcao_08 = assinatura.ordenador_despesa_funcao_08;
      this.cargo_ordenador_despesa_funcao_08 = assinatura.cargo_ordenador_despesa_funcao_08;

      this.ordenador_despesa_funcao_10 = assinatura.ordenador_despesa_funcao_10;
      this.cargo_ordenador_despesa_funcao_10 = assinatura.cargo_ordenador_despesa_funcao_10;

      this.ordenador_despesa_funcao_12 = assinatura.ordenador_despesa_funcao_12;
      this.cargo_ordenador_despesa_funcao_12 = assinatura.cargo_ordenador_despesa_funcao_12;

      this.ordenador_pagto = assinatura.ordenador_pagto;
      this.cargo_ordenador_pagto = assinatura.cargo_ordenador_pagto;

      this.tesoureiro = assinatura.tesoureiro;
      this.cargo_tesoureiro = assinatura.cargo_tesoureiro;

      this.contador = assinatura.contador;
      this.cargo_contador = assinatura.cargo_contador;
    }
  }

  private retornaLayout() {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }

  public async criarPdf(model: Empenho[], login: Login): Promise<pdfMake.TCreatedPdf> {
    this.log = login;
    await this.preencheAssinatura()

    return await Relatorio.imprimirPersonalizado('NOTA DE EMPENHO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao, this.montarConteudo(model), 'portrait', `nota_empenho_${model[0].numero}`, this.retornaLayout(), true, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true)
  }

  public async imprimir(model: Empenho[], login: Login) {
    this.log = login;
    await this.preencheAssinatura()

    await Relatorio.imprimirPersonalizado('NOTA DE EMPENHO', login.usuario.nome, login.usuario.sobrenome,
      login.orgao.nome, login.brasao, this.montarConteudo(model), 'portrait', 'NOTA EMPENHO', this.retornaLayout(), true, true)
  }

  private montarConteudo(lista: Empenho[]) {
    const conteudo = [];

    for (const entidade of lista) {
      if (conteudo.length > 0) {
        conteudo.push([{ text: '', pageBreak: 'after' }]);
      }
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const barCode = `018170${this.funcaoService.strZero((+entidade.valor_empenho).toFixed(2), 12).split('.').join('')}` +
        `${this.log.orgao.codigo.substring(0, 4)}${this.funcaoService.converteDataSQL(new Date(entidade.data_empenho)).split('-').join('')}` +
        `${this.funcaoService.strZero(entidade.numero, 10)}0000090`;

      JsBarcode(canvas, String(barCode), { displayValue: false, height: 70, margin: 0 });
      conteudo.push(this.dadosCabecalho(this.log, entidade)
        .concat(this.dadosEmpenho(entidade))
        .concat(this.dadosClassificacao(entidade))
        .concat(this.dadosContrato(entidade))
        .concat(this.dadosFavorecido(entidade))
        .concat(this.dadosHistorico(entidade))
        .concat(this.dadosAutorizacao(entidade))
        .concat(this.dadosAssinatura(this.log))
        .concat(this.dadosRecibo()).concat([{
          image: canvas.toDataURL(),
          fit: [250, 70], margin: [10, 5]//, absolutePosition: { x: 280, y: -90 }
        }]));
    }
    return conteudo;
  }

  private dadosCabecalho(log: Login, entidade: Empenho): {}[] {
    const anulacao = entidade.especie === 'EOA'

    let brasaoImage: {};
    if (log.brasao) {
      brasaoImage = {
        image: log.brasao,
        width: 70,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [true, true, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, false, false] };
    }

    brasaoImage['rowSpan'] = 4;

    const conteudo = [
      [brasaoImage, { text: log.orgao.nome, bold: true, alignment: 'center', fontSize: 14, border: [false, true, true, false] }],
      [
        { text: '', border: [false, false, false, false], margin: [0, 0, 0, 0] },
        {
          text: `${log.orgao.endereco} ${log.cidade?.nome} ${log.cidade?.estado?.nome} CNPJ: ${log.orgao.cnpj}`,
          alignment: 'center', fontSize: 8, border: [false, false, true, false]
        }
      ], [
        { text: '', border: [true, false, false, false], margin: [0, 0, 0, 0] },
        {
          text: !anulacao ? `EMPENHO ORÇAMENTÁRIO` : `ANULAÇÃO EMPENHO ORÇAMENTÁRIO`,
          bold: true, alignment: 'center', fontSize: 14, border: [false, false, true, false]
        }
      ], [
        { text: '', border: [true, false, false, false], margin: [0, 0, 0, 0] },
        { text: `${log.exercicio.ano}`, bold: true, alignment: 'center', fontSize: 12, border: [false, false, true, false] }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        // dontBreakRows: true,
        widths: [50, '*'],
        body: conteudo
      }
    }];
  }

  private dadosEmpenho(dados: Empenho): {}[] {
    const tipoEmpenho = this.globalService.obterListaTiposEmpenhos().find(x => x.id === dados.tipo_empenho);
    const modalidade = dados.modalidade?.nome?.length > 21 ? dados.modalidade?.nome?.split(' ', 3).join(' ') : dados.modalidade?.nome;
    const conteudo = [
      [
        {
          text: `EMPENHO Nº ${this.funcaoService.strZero(dados.numero, 4)}`,
          rowSpan: 2, fontSize: 11, alignment: 'left', bold: true, margin: [0, 8, 8, 8]
        },
        { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
        { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Data', fontSize: 7, border: [true, true, false, false] },
        { text: 'Previsão', fontSize: 7, border: [true, true, false, false] },
        { text: 'Licitação', fontSize: 7, border: [true, true, false, false] },
        { text: 'Processo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Modalidade Licitação', fontSize: 7, border: [true, true, true, false] }
      ], [
        { text: '' },
        { text: dados.ficha?.numero, alignment: 'center', fontSize: 12, bold: true, border: [false, false, false, true] },
        {
          text: tipoEmpenho ? tipoEmpenho.nome : ' - ',
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: this.funcaoService.converteDataBR(dados.data_empenho),
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: this.funcaoService.converteDataBR(dados.data_vencimento),
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: dados.licitacao ? `${new LicitacaoPipe().transform(dados.licitacao?.numero)}` : ' - ',
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        { text: dados.processo, alignment: 'center', bold: true, border: [true, false, false, true] },
        { text: modalidade, bold: true, border: [true, false, true, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [115, 30, 50, 45, 45, 50, 50, '*'],
        body: conteudo
      }
    }];
  }

  private dadosClassificacao(dados: Empenho): {}[] {
    const dotacao = (+dados.total_dotacao_ficha + +dados.total_creditado_ficha) - +dados.total_reservado;
    const anterior = +dotacao - +dados.total_empenhado_ficha_anterior;
    let saldo = 0;
    if (dados.especie === 'EMO') {
      saldo = +anterior - +dados.valor_empenho;
    } else {
      saldo = +dados.total_empenho_sem_anulacao + +dados.valor_empenho;
    }

    const conteudo = [
      [
        { text: 'UNIDADE', border: [true, false, false, false] },
        {
          text: `${dados.ficha?.executora?.unidade?.codigo} ${dados.ficha?.executora?.unidade?.nome}`,
          border: [false, false, true, false]
        },
        { text: dados.especie === 'EMO' ? 'DOTAÇÃO' : '', border: [true, false, false, false] },
        {
          text: dados.especie === 'EMO' ? this.funcaoService.convertToBrNumber(dotacao) : '',
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'EXECUTORA', border: [true, false, false, false] },
        { text: `${dados.ficha?.executora?.codigo} ${dados.ficha?.executora?.nome} `, border: [false, false, true, false] },
        { text: dados.especie === 'EMO' ? 'ANTERIOR' : 'EMPENHADO', border: [true, false, false, false] },
        {
          text: dados.especie === 'EMO' ?
            this.funcaoService.convertToBrNumber(anterior) : this.funcaoService.convertToBrNumber(+dados.total_empenho_sem_anulacao),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'NATUREZA', border: [true, false, false, false] },
        { text: `${this.funcaoService.mascarar('#.#.##.##', dados.ficha?.despesa?.codigo)} ${dados.ficha?.despesa?.nome}`, border: [false, false, true, false] },
        // { text: `${dados.subelemento.codigo.substring(0, 8)}`, border: [false, false, true, false] },
        { text: dados.especie === 'EMO' ? 'EMPENHADO' : 'ANULADO', border: [true, false, false, false] },
        {
          text: this.funcaoService.convertToBrNumber(+dados.valor_empenho),
          border: [false, false, true, false], alignment: 'right'
        },
      ], [
        { text: 'SUBELEMENTO', border: [true, false, false, false] },
        { text: `${dados.subelemento?.codigo} ${dados.subelemento?.nome} `, border: [false, false, true, false] },
        { text: 'SALDO ATUAL', border: [true, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(saldo), border: [false, false, true, false], alignment: 'right' },
      ], [
        { text: 'FUNCIONAL', border: [true, false, false, false] },
        {
          // tslint:disable-next-line: max-line-length
          text: `${dados.ficha?.funcao?.codigo}.${dados.ficha?.subfuncao?.codigo}.${dados.ficha?.programa?.codigo} ${dados.ficha?.programa?.nome} `,
          border: [false, false, true, false]
        },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'AÇÃO', border: [true, false, false, false] },
        { text: `${dados.ficha?.acao?.codigo} ${dados.ficha?.acao?.nome} `, border: [false, false, true, false] },
        { text: '', border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'RECURSO', border: [true, false, false, true] },
        {
          text: `${dados.ficha?.aplicacao_variavel?.codigo ? dados.ficha?.aplicacao_variavel?.codigo : dados.ficha?.recurso?.codigo + ' ' + dados.ficha?.aplicacao?.codigo} ${dados.ficha?.aplicacao_variavel?.codigo ? dados.ficha?.aplicacao_variavel?.nome : dados.ficha?.aplicacao?.nome}`,
          border: [false, false, false, true]
        },
        { text: '', border: [true, false, false, true] },
        { text: '', border: [false, false, true, true] },
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*', 60, 50],
        body: conteudo
      }
    }];
  }

  private dadosFavorecido(dados: Empenho): {}[] {
    let bancoPrincipal = dados.favorecido.contas?.find((e) => e.principal);
    const conteudo = [
      [
        { text: 'DEVERÁ SER PAGO À:', colSpan: 3, border: [true, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        {
          text: `${dados.favorecido?.nome}`, colSpan: 3,
          fontSize: 11, bold: true, border: [true, false, false, false]
        },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, false, false] },
        { text: `CÓDIGO: ${dados.favorecido?.id}`, bold: true, border: [false, false, true, false] },
      ], [
        { text: 'CPF/CNPJ:', border: [true, false, false, false] },
        { text: `${dados.favorecido?.cpf_cnpj}`, border: [false, false, false, false] },
        { text: 'TIPO PESSOA:', border: [false, false, false, false] },
        {
          text: dados.favorecido?.tipo?.nome,
          border: [false, false, false, false]
        },
        { text: `BANCO: ${bancoPrincipal?.banco.nome ? bancoPrincipal?.banco.nome : ''}`, border: [false, false, true, false] },
      ], [
        { text: 'ENDEREÇO:', border: [true, false, false, false] },
        {
          text: `${dados.favorecido?.endereco}, ${dados.favorecido?.num_endereco} `,
          border: [false, false, false, false]
        },
        { text: 'BAIRRO:', border: [false, false, false, false] },
        { text: dados.favorecido?.bairro, border: [false, false, false, false] },
        { text: `AGÊNCIA: ${bancoPrincipal?.agencia ? bancoPrincipal?.agencia : ''}`, border: [false, false, true, false] },
      ], [
        { text: 'CIDADE:', border: [true, false, false, true] },
        { text: `${dados.favorecido?.municipio}, ${dados.favorecido?.uf} `, border: [false, false, false, true] },
        { text: 'TELEFONE:', border: [false, false, false, true] },
        { text: `(${dados.favorecido?.ddd_fone ? dados.favorecido?.ddd_fone : ''}) ${dados.favorecido?.telefone}     `, border: [false, false, false, true] },
        { text: `Nº CONTA: ${bancoPrincipal?.numero_conta ? bancoPrincipal?.numero_conta : ''}`, border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: [60, '*', 60, 150, '*'],
        body: conteudo
      }
    }];
  }

  private dadosContrato(dados: Empenho): {}[] {
    const conteudo = [
      [
        { text: 'Contrato No.', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Assinatura', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Início', fontSize: 7, border: [true, false, false, false] },
        { text: 'Data Término', fontSize: 7, border: [true, false, false, false] },
        { text: 'Convênio No.', fontSize: 7, border: [true, false, true, false] }
      ], [
        {
          text: dados.contrato?.numero ? dados.contrato?.numero : '-',
          alignment: 'center', bold: true, border: [true, false, false, true]
        },
        {
          text: dados.contrato?.data_assinatura ? this.funcaoService.converteDataBR(dados.contrato?.data_assinatura) : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {
          text: dados.contrato?.data_inicio ? this.funcaoService.converteDataBR(dados.contrato?.data_inicio) : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {
          text: dados.contrato?.data_termino ? this.funcaoService.converteDataBR(dados.contrato?.data_termino) : '-',
          alignment: 'center', border: [true, false, false, true]
        },
        {
          text: dados.convenio?.numero ? dados.convenio?.numero : '-',
          alignment: 'center', bold: true, border: [true, false, true, true]
        }
      ]
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [110, 85, 85, 85, '*'],
        body: conteudo
      }
    }];
  }

  private dadosHistorico(dados: Empenho): {}[] {
    // Abreviação da observação caso seja gigante.
    let partialObservation = '';
    if (dados.compra?.rcms?.observacao) {
      const { observacao } = dados.compra.rcms;
      partialObservation = observacao ? observacao.length > 220 ? `${observacao.substring(0, 220)}...` : observacao : '';
    }

    const conteudo = [
      [
        { text: 'Histórico:', border: [true, false, true, false] },
        { text: 'Observações', fontSize: 6, border: [true, false, true, false], colSpan: 2 },
        { text: ''/*, border: [false, false, true, false]*/ }
      ], [
        { text: `${(dados.historico.length > 500 ? dados.historico.substring(0, 450) + '...' : dados.historico)} `, border: [true, false, true, false] },
        { text: partialObservation, fontSize: 7, bold: true, border: [true, false, true, false], margin: [0, -5, 0, 0], colSpan: 2 },
        { text: ''/*, border: [false, false, true, false]*/ }
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'RCMS:  ' + `${dados?.compra?.rcms?.numero || ''}` + ' | ' + 'OF.:  ' + `${dados?.compra?.numero || ''}`, fontSize: 7, border: [true, false, true, false], colSpan: 2 },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'REQ.:  ' + `${dados?.compra?.rcms?.requerente?.nome || ''}`, fontSize: 7, border: [true, false, true, false], colSpan: 2 },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'Prazo:  ' + `${dados?.compra?.rcms?.prazo?.intervalo_qtd || ''} - ${dados?.compra?.rcms?.prazo?.intervalo || ''}`, fontSize: 7, border: [true, false, false, false] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: '', border: [true, false, true, false] },
        { text: 'VALOR....R$', fontSize: 9, bold: true, border: [true, true, false, false] },
        { text: `${this.funcaoService.convertToBrNumber(dados?.valor_empenho || 0)}`, fontSize: 9, bold: true, alignment: 'right', border: [false, true, true, false] },
      ], [
        { text: '', border: [true, false, true, true] },
        { text: 'LIQUIDO....R$', fontSize: 9, bold: true, border: [true, true, false, true] },
        { text: `${this.funcaoService.convertToBrNumber(dados?.valor_empenho || 0)}`, fontSize: 9, bold: true, alignment: 'right', border: [false, true, true, true] },
      ], [
        { text: 'Fica empenhado a importância de: ', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false] },
        { text: '', border: [false, false, true, false] }
      ], [
        {
          text: extenso(this.funcaoService.convertToBrNumber(dados.valor_empenho),
            { mode: 'currency', currency: { type: 'BRL' } }).toUpperCase(),
          border: [true, false, false, false], margin: [0, 0, 0, 0]
        },
        { text: 'VALOR R$:', bold: true, fontSize: 11, border: [false, false, false, false], alignment: 'right' },
        {
          text: this.funcaoService.convertToBrNumber(dados.valor_empenho),
          alignment: 'center', bold: true, fontSize: 13, border: [false, false, true, false], margin: [0, 0, 0, 5]
        },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [5, 5],
        widths: ['*', 60, 100],
        body: conteudo
      }
    }];
  }

  private dadosAutorizacao(dados: Empenho): {}[] {
    let ordenador = '';
    let cargoOrdenador = '';
    const funcao = dados.ficha?.funcao?.codigo;
    const data_liquidacao = dados.liquidacoes?.length == 1 ?
      dados.liquidacoes[0]?.data_liquidacao : dados.liquidacoes[dados.liquidacoes?.length - 1]?.data_liquidacao;

    switch (funcao) {
      case '12':
        ordenador = this.ordenador_despesa_funcao_12;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_12;
        break;
      case '10':
        ordenador = this.ordenador_despesa_funcao_10;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_10;
        break;
      case '08':
        ordenador = this.ordenador_despesa_funcao_08;
        cargoOrdenador = this.cargo_ordenador_despesa_funcao_08;
        break;
      default:
        ordenador = this.ordenador_despesa;
        cargoOrdenador = this.cargo_ordenador_despesa;
    }

    if (!ordenador || !cargoOrdenador) {
      ordenador = this.ordenador_despesa;
      cargoOrdenador = this.cargo_ordenador_despesa;
    }

    const conteudo = [
      [
        {
          text: 'AUTORIZAÇÃO EMPENHO',
          bold: true, border: [true, true, true, false], margin: [0, 0, 0, 10]
        },
        { text: 'CONTABILIZAÇÃO', border: [false, true, true, false], bold: true, },
        { text: 'LIQUIDAÇÃO', border: [false, true, true, false] }
      ], [
        { text: '_____________________________________', alignment: 'center', border: [true, false, true, false] },
        { text: '_____________________________________', alignment: 'center', border: [false, false, true, false] },
        { text: 'Documento:', border: [false, false, true, false] }
      ], [
        { text: ordenador, fontSize: 7, alignment: 'center', border: [true, false, true, false] },
        { text: this.contador ? this.contador : '', fontSize: 7, alignment: 'center', border: [false, false, true, false] },
        { text: dados.liquidacoes?.length == 1 ? dados.liquidacoes[0]?.documento : dados.liquidacoes[dados.liquidacoes?.length - 1]?.documento, border: [false, false, true, false], margin: [0, 5, 0, 5] }
      ], [
        { text: cargoOrdenador, fontSize: 7, alignment: 'center', border: [true, false, true, false], margin: [0, -7, 0, 0] },
        { text: this.cargo_contador ? this.cargo_contador : '', fontSize: 7, alignment: 'center', border: [false, false, true, false], margin: [0, -7, 0, 0] },
        { text: '', border: [false, false, true, false], margin: [0, 5, 0, 5] }
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, true, true], margin: [0, -5, 0, 0] },
        { text: 'Data: _____/_____/________', border: [false, false, true, true], margin: [0, -5, 0, 0] },
        { text: data_liquidacao ? `Data: ${this.funcaoService.converteDataBR(data_liquidacao)}` : 'Data: _____/_____/________', border: [false, false, true, true], margin: [0, -5, 0, 0] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: ['*', '*', '*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(log: Login): {}[] {
    const conteudo = [
      [
        { text: 'AUTORIZAÇÃO PAGAMENTO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [true, false, false, false], },
        { text: '______________________________________', border: [false, false, false, false], margin: [20, 30, -20, 0] },
        { text: '______________________________________', border: [false, false, false, false], margin: [40, 30, -30, 0] },
        { text: '', border: [false, false, true, false] },
      ], [
        { text: 'BANCO:_______________', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], },
        { text: this.ordenador_pagto ? this.ordenador_pagto : '', fontSize: 7, alignment: 'center', border: [false, false, false, false], margin: [10, 0, -20, 0] },
        { text: this.tesoureiro ? this.tesoureiro : '', fontSize: 7, alignment: 'center', border: [false, false, false, false], margin: [50, 0, -20, 0] },
        { text: '', border: [false, false, true, false], },
      ], [
        { text: 'RECURSO:_____________ CHEQUE:______________', border: [true, false, false, false] },
        { text: '', border: [false, false, false, false], },
        { text: this.cargo_ordenador_pagto ? this.cargo_ordenador_pagto : '', alignment: 'center', fontSize: 7, border: [false, false, false, false], margin: [15, 0, -15, 10] },
        { text: this.cargo_tesoureiro ? this.cargo_tesoureiro : '', alignment: 'center', fontSize: 7, border: [false, false, false, false], margin: [50, 0, -15, 10] },
        { text: '', border: [false, false, true, false], },
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: '', border: [false, false, false, true], margin: [0, 0, 0, 10] },
        { text: '', border: [false, false, true, true] },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: [100, 0, 150, 150, '*'],
        body: conteudo
      }
    }];
  }

  private dadosRecibo(): {}[] {
    const conteudo = [
      [
        { text: 'RECIBO E QUITAÇÃO', border: [true, false, false, false], colSpan: 2 },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, false, false], },
        { text: '', border: [false, false, true, false], },
      ], [
        {
          text: 'Recebemos o valor a que se refere este empenho, ao qual damos plena e geral quitação.',
          border: [true, false, false, false], colSpan: 2, margin: [0, 0, 20, 0]
        },
        { text: '', border: [false, false, false, false], },
        { text: 'Assinatura', border: [false, false, false, false], },
        { text: 'Documento', border: [false, false, true, false], },
      ], [
        { text: 'Data: _____/_____/________', border: [true, false, false, true], colSpan: 2 },
        { text: '', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, false, true], },
        { text: '__________________________', border: [false, false, true, true], },
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        heights: [10, 0],
        widths: ['*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }
}
