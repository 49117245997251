import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContratoItem, LoginContabil } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'compra-tabela-desconto-dlg',
  templateUrl: './compra-tabela-desconto-dlg.component.html'
})
export class CompraTabelasDescontoDlg implements OnInit {

  @Input() public login: LoginContabil;

  @Input() public lista: any[] = [];
  @Input() public compra: any
  @Input() public processo: string;
 

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Output() callback: EventEmitter<any[]> = new EventEmitter();

  @Input() static : boolean = false;

  public aviso: string = '';

  ngOnInit(): void { }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public todosSelecionados() {
    if (!this.lista)
        return
    const selecionados = []
    this.lista.filter((elemento) => {
        if(elemento['selecionado']) {
            selecionados.push(elemento)
        }
    })

    return selecionados.length === this.lista.length
  }

  public selecionarTodos() {
    if (this.todosSelecionados()) {
        this.lista.filter((elemento) => elemento['selecionado'] = false)
    } else {
        this.lista.filter((elemento) => elemento['selecionado'] = true)
    }
    
  }

  public validSelect(item: any) {
    return item['selecionado'] === true
  }

  public selecionar(item: any) { 
    if (item['selecionado'] === true) {
        item['selecionado'] = false
    } else {
        item['selecionado'] = true
    }
  }

  public bloqueio() {
    if (!this.lista || this.lista.length === 0)
      return false;
    
    for (const item of this.lista) {
      if (item['mensagem_bloqueio']) {
        this.aviso = item['mensagem_bloqueio'];
        return true;
      }
    }
  }

  public salvar() {
    
    const selecionados = this.lista.filter((elemento) => {
      return elemento['selecionado'] === true
    })
    
    this.callback.emit(selecionados);
  }
}
