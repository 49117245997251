import { BaseResourceModel } from '../../models/base-resource.model';
import { Exercicio } from '../comum/exercicio.model';
import { Recurso } from './recurso.model';
import { Despesa } from './despesa.model';
import { Acao } from './acao.model';
import { Programa } from './programa.model';
import { FuncaoGoverno } from './funcao-governo.model';
import { Executora } from './executora.model';
import { Orgao } from '../comum/orgao.model';
import { SubfuncaoSiope } from './subfuncao-siope.model';
import { RecursoSiconfi } from './recurso-siconfi.model';
import { RecursoSiconfiComplemento } from './recurso-siconfi-complemento.model';
import { CotaMensal, ReservaDotacaoItem } from 'eddydata-lib';
import { FaturaTipoImovel } from '../contabil/fatura-tipo-imovel.model';

export class FichaDespesa extends BaseResourceModel {
  constructor(
    public id?: number,
    public numero?: number,
    public valor_orcado?: number,
    public valor_cota?: number,
    public especie?: string,
    public ensino?: number,
    public saude?: number,
    public convenio?: boolean,
    public consorcio?: boolean,
    public publicidade?: number,
    public terceiro_setor?: boolean,
    public ativo?: boolean,
    public naoImportar?: boolean,
    public exercicio?: Exercicio,
    public recurso?: Recurso,
    public recurso_siconfi?: RecursoSiconfi,
    public complemento_siconfi?: RecursoSiconfiComplemento,
    public aplicacao?: Recurso,
    public aplicacao_variavel?: Recurso,
    public despesa?: Despesa,
    public acao?: Acao,
    public programa?: Programa,
    public funcao?: FuncaoGoverno,
    public subfuncao?: FuncaoGoverno,
    public executora?: Executora,
    public orgao?: Orgao,
    public data_cadastro?: Date,
    public data_alteracao?: Date,
    public previsoes?: [],
    public orcamento_crianca?: boolean,
    public publicidade_legal?: boolean,
    public publicidade_institucional?: boolean,
    public exclusivo_crianca?: 'S' | 'N',
    public valor_exclusivo?: number,
    public siope?: SubfuncaoSiope,
    public folha_pagamento?: boolean,
    public reservas_dotacao?: ReservaDotacaoItem,
    public tipo_cota?: 'A' | 'M',
    public data_cota?: Date,
    public cotas?: CotaMensal[],
    public fatura_tipo_imovel?: FaturaTipoImovel

  ) {
    super();
  }

  static converteJson(json: any): FichaDespesa {
    return Object.assign(new FichaDespesa(), json);
  }

}
