import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AssinaturaService, BaseResourceRptPersonalizadoComponent, Credito, CreditoItem, FormatoExportacao, Login, OrgaoAssinatura, OrgaoAssinaturaService } from 'eddydata-lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditoService } from '../../credito/service/credito.service';
import * as extenso from "extenso";
import * as toastr from 'toastr';


@Component({
  selector: 'app-transferencia-interna-rpt',
  templateUrl: './transferencia-interna-rpt.component.html'
})
export class TransferenciaInternaRpt extends BaseResourceRptPersonalizadoComponent {

  protected datepipe: DatePipe;
  public ptBR: any;

  public decretoSelected: string;
  public decretoList: { key: string, value: string }[] = [];
  public listFormato: FormatoExportacao[] = ['pdf', 'docx'];
  public formato: FormatoExportacao;

  private creditos: Credito[] = [];
  private assinaturaVigente: OrgaoAssinatura;

  private count: number = 0;
  private dataDecreto: Date;

  protected unsubscribe: Subject<void> = new Subject();

  @Input() login: Login;

  constructor(
    private creditoService: CreditoService,
    protected assinaturaService: AssinaturaService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.removerCabecalho = true;
    this.removerRodape = true;
  }

  protected afterInit(): void {
    this.creditoService.filtrar(1, -1, {
      'relations': 'itens.ficha.executora,itens.ficha.recurso,itens.ficha.aplicacao,itens.ficha.aplicacao_variavel,itens.ficha.programa,itens.ficha.acao,itens.ficha.despesa,itens.ficha.funcao,itens.ficha.subfuncao',
      'exercicio.id': this.login?.exercicio?.id,
      'orgao.id': this.login?.orgao?.id,
      'ativo': true
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.creditos = res.content;

        res.content.forEach((val: Credito) => {
          if (!this.decretoList.find(x => x.value === val.decreto_interno?.replace('/', '')) && val.decreto_interno) {
            this.decretoList.push({ key: val.decreto_interno, value: val.decreto_interno?.replace('/', '') });
          }
        });
        this.decretoList.sort((d1, d2) => +d1.value - +d2.value);
      });

    this.orgaoAssinaturaService.filtrar(1, -1, {
      'orgao.id': this.login?.orgao?.id,
      'data_limite$ge': this.funcaoService.converteDataSQL(new Date()),
      'orderBy': 'data_limite'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        if (res.content && res.content.length > 0) this.assinaturaVigente = res.content[0];
      });

    this.orientacao = 'portrait';
    this.formato = 'pdf';
  }

  private validar(): boolean {
    if (!this.assinaturaVigente) {
      toastr.warning(`Não foi encontrado assinatura vigente para emitir o relatório!`);
      return false;
    }
    if (!this.decretoSelected) {
      toastr.warning(`Selecione o decreto!`);
      return false;
    }
    return true;
  }

  public imprimir(): void {
    if (!this.validar()) return;

    super.imprimir();
  }

  protected modalRelatorio(): string {
    return null;
  }

  protected tituloRelatorio(): string {
    return '';
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo: {}[] = [];
    this.count = 0;

    let dados = this.creditos.filter((credito: Credito) => credito.decreto_interno === this.decretoSelected);

    if (!dados) {
      toastr.warning('Não foi localizado minutas com os filtros informados!');
      return;
    }

    this.dataDecreto = dados[0].data_cadastro ? new Date(dados[0].data_cadastro) : new Date();

    conteudo.push(this.dadosCabecalho(dados[0]));
    for (const credito of dados) {
      conteudo.push(this.dadosCreditos(credito, false));
    }
    this.count = 0;
    for (const credito of dados) {
      conteudo.push(this.dadosCreditos(credito, true));
    }
    conteudo.push(this.dadosAssinatura());

    return conteudo;
  }

  protected dadosCabecalho(credito: Credito): {}[] {
    return [
      {
        layout: 'linhas',
        unbreakable: true,
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [130, '*'],
          body: [
            [
              { text: '', border: [false] },
              {
                text: `DECRETO Nº ${credito?.decreto ? (credito?.decreto.replace('/', '').length === 8 ? '0' + this.funcaoService.mascarar('####/####', credito.decreto.replace('/', '')) : this.funcaoService.mascarar('#####/####', credito.decreto.replace('/', ''))) : ''}, DE ${this.funcaoService.formatarDataExtenso(this.dataDecreto).toUpperCase()}`,
                bold: true, decoration: 'underline', alignment: 'left', fontSize: 12, border: [false], margin: [0, 100, 0, 0]
              }
            ],
            [
              { text: '', border: [false] },
              {
                text: `Dispõe sobre alterações no Orçamento de ${this.dataDecreto.getFullYear()}, através de transferências de recursos entre dotações de classificações aprovadas na Lei nº. 9.099, de 24/11/2021 - Lei Orçamentária Anual, e dá outras providências`,
                alignment: 'justify', fontSize: 12, border: [false], margin: [0, 0, 0, 14]
              }
            ]
          ]
        }
      },  {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ['*'],
          body: [
            [
              {
                text: `\u200B\t\t\t\t\t\t\t\t\t\t\t\t${this.assinaturaVigente?.prefeito ? this.assinaturaVigente.prefeito.toUpperCase() : ''}, Prefeito Municipal de ${this.login?.cidade?.nome ? this.login?.cidade?.nome : ''}, Estado de ${this.login?.cidade?.estado?.nome ? this.login?.cidade?.estado?.nome : ''}, no exercício de suas atribuições legais`,
                alignment: 'left', fontSize: 12, border: [false], margin: [0, 0, 0, 24]
              }
            ]
          ]
        }
      }, {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [130, '*'],
          body: [
            [
              { text: '', border: [false] },
              {
                text: `D E C R E T A`,
                alignment: 'left', fontSize: 12, border: [false], margin: [0, 0, 0, 36]
              }
            ]
          ]
        }
      }
    ];
  }

  protected dadosCreditos(credito: Credito, anulacao: boolean): {}[] {
    let creditos: {}[] = [];
    let numArtigo: string = '';
    let artigo: string = '';
    let total: number = 0;
    this.count++;

    var aux = credito.itens.filter((item: CreditoItem) => {
      if (anulacao) return item.especie === 'A';

      return item.especie === 'N';
    });

    if (aux.length === 0) return;

    var grupoExecutora = this.funcaoService.agrupar(aux, ['ficha.executora.codigo', 'ficha.executora.nome'], ['valor_credito']);
    for (const executora of grupoExecutora) {
      total += executora.totalizadores['valor_credito'] ? +executora.totalizadores['valor_credito'] : 0;
      creditos.push([
        { text: '', border: [false], margin: [0, 0, 0, 0] },
        {
          text: `${executora.grupo['ficha.executora.codigo'] ? this.funcaoService.mascarar('##.##.##', executora.grupo['ficha.executora.codigo']) + ' ' : ''}${executora.grupo['ficha.executora.nome'] ? executora.grupo['ficha.executora.nome'] : ''}`,
          fontSize: 8, bold: true, border: [false], margin: [0, 0, 0, 0]
        },
        { text: '', border: [false], margin: [0, 0, 0, 0] }
      ]);

      const grupoPrograma = this.funcaoService.agrupar(executora.registros, ['ficha.funcao.codigo', 'ficha.subfuncao.codigo', 'ficha.programa.codigo', 'ficha.programa.nome']);
      for (const programa of grupoPrograma) {
        if (programa.grupo['ficha.funcao'] && programa.grupo['ficha.subfuncao'] && programa.grupo['ficha.programa']) {
          creditos.push([
            { text: '', border: [false] },
            {
              text:
              `${programa.grupo['ficha.funcao.codigo'] ? programa.grupo['ficha.funcao.codigo'] + '.' : ''}${programa.grupo['ficha.subfuncao.codigo'] ? programa.grupo['ficha.subfuncao.codigo'] + '.' : ''}${programa.grupo['ficha.programa.codigo'] ? programa.grupo['ficha.programa.codigo'] + ' ' : ''}${programa.grupo['ficha.programa.nome'] ? programa.grupo['ficha.programa.nome'] : ''}`,
              fontSize: 8, border: [false], margin: [0, 1, 0, 0]
            },
            { text: '', border: [false] }
          ]);
        }

        const grupoAcao = this.funcaoService.agrupar(programa.registros, ['ficha.acao.codigo', 'ficha.acao.nome']);
        for (const acao of grupoAcao) {
          if (acao.grupo['ficha.acao']) {
            creditos.push([
              { text: '', border: [false] },
              {
                text: `${acao.grupo['ficha.acao.codigo'] ? acao.grupo['ficha.acao.codigo'] + ' ' : ''}${acao.grupo['ficha.acao.nome'] ? acao.grupo['ficha.acao.nome'] : ''}`,
                fontSize: 8, border: [false], margin: [0, 0, 0, 0]
              },
              { text: '', border: [false] }
            ]);
          }
          acao.registros.forEach(registro => {
            creditos.push(
            registro['ficha']['despesa'] ? [
              { text: '', border: [false] },
              {
                text: `${registro['ficha']['despesa']['codigo'] + ' ' + registro['ficha']['despesa']['nome']}`,
                fontSize: 8, border: [false], margin: [0, 0, 0, 0]
              }, {
                text: '', border: [false]
              }
            ] : [],
            registro['ficha']['aplicacao_variavel'] ? [
              { text: '', border: [false] },
              {
                text: `Fonte / Aplicação: ${registro['ficha']['aplicacao_variavel']['codigo'] + ' ' + registro['ficha']['aplicacao_variavel']['nome']}`, bold: true,
                fontSize: 8, border: [false], margin: [0, 0, 0, 0]
              },
              { text: '', border: [false] }
            ] : [],
            [
              { text: '', border: [false] },
              {
                text: `Ficha      ${registro['ficha']['numero']}`, bold: true,
                fontSize: 8, border: [false], margin: [0, 0, 0, 0]
              }, {
                text: `${registro['valor_credito'] ? this.funcaoService.convertToBrNumber(registro['valor_credito']) : '0,00'}`,
                fontSize: 8, alignment: 'right', border: [false]
              }
            ]);
          });
        }
      }

      creditos.push([
        { text: '', border: [false], margin: [0, 0, 0, 0] },
        { text: 'TOTAL.....', fontSize: 8, bold: true, border: [false], margin: [0, 0, 0, 0] },
        {
          text: `${executora.totalizadores['valor_credito'] ? this.funcaoService.convertToBrNumber(executora.totalizadores['valor_credito']) : '0,00'}`,
          fontSize: 9, bold: true, alignment: 'right', border: [false], margin: [0, 0, 0, 0]
        }
      ]);
    }

    if (this.count === 1) {
      if (anulacao) {
        numArtigo = `Art 2º -`;
        artigo = `A origem dos recursos para cobertura das transferências referidas no artigo anterior provém das seguintes classificações:`;
      } else {
        numArtigo = `Art 1º -`;
        artigo = `Ficam efetuadas através da Secretaria Municipal de Finanças, as transferências de recursos orçamentários, respeitada a Lei n°. 9.099, de 24/11/2021 - Lei Orçamentária Anual, no valor de ${this.funcaoService.convertToBrNumber(total)} (${extenso(this.funcaoService.convertToBrNumber(total), { mode: 'currency', currency: { type: 'BRL' } })}), destinadas às seguintes classificações:`;
      }
    }

    return [
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: [85, '*'],
          body: [
            [
              {
                text: anulacao && this.count === 1 ? 'Parágrafo Único -' : '', alignment: 'right',
                fontSize: 11, border: [false], margin: [0, 0, 0, 0]
              },
              {
                text: anulacao && this.count === 1 ? 'As transferências referidas no caput não alteram as classificações funcional-programáticas, categorias e fontes de recursos aprovadas Lei Orçamentária Anual.' : '',
                alignment: 'justify', fontSize: 11, border: [false], margin: [0, 0, 0, 0]
              }
            ],
            [
              { text: `${numArtigo}`, alignment: 'right', fontSize: 11, border: [false], margin: [0, 0, 0, 0] },
              { text: `${artigo}`, alignment: 'left', fontSize: 11, border: [false], margin: [0, 0, 0, 10] }
            ]
          ]
        }
      },
      {
        layout: 'linhas',
        table: {
          dontBreakRows: true,
          headerRows: 0,
          widths: [85, '*', 100],
          body: creditos
        }
      }
    ];
  }

  protected dadosAssinatura(): {} {
    return {
      layout: 'linhas',
      unbreakable: true,
      table: {
        dontBreakRows: true,
        headerRows: 0,
        widths: ['*'],
        body: [
          [
            {
              text: `\u200B\t\t\t\t\t\t\t\t\tArt 3º Este Decreto entrará em vigor na data de sua publicação, surtindo seus efeitos a partir de ${this.funcaoService.formatarDataExtenso(new Date())}`,
              alignment: 'justify', fontSize: 10, border: [false], margin: [0, 20, 0, 8]
            }
          ], [
            {
              text: `\u200B\t\t\t\t\t\t\t\t\tArt 4º Revogam-se as disposições em contrário.`,
              alignment: 'justify', fontSize: 10, border: [false], margin: [0, 0, 0, 8]
            }
          ], [
            {
              text: `${this.funcaoService.formatarDataExtenso(new Date(), this.login?.cidade?.nome).toUpperCase()}`,
              alignment: 'center', fontSize: 9, border: [false], margin: [0, 15, 0, 10]
            }
          ], [
            {
              text: '_______________________________________________',
              alignment: 'center', fontSize: 10, bold: true, border: [false], margin: [0, 15, 0, 0]
            }
          ], [
            {
              text: `${this.assinaturaVigente.prefeito ? this.assinaturaVigente.prefeito.toUpperCase() : ''}`,
              alignment: 'center', fontSize: 9, border: [false]
            }
          ], [
            {
              text: this.assinaturaVigente.cargo_prefeito,
              alignment: 'center', fontSize: 9, border: [false]
            }
          ]
        ]
      }
    };
  }

  protected layoutRelatorio(): {} {
    return {
      table_eddy: {
        hLineWidth: function () {
          return 1;
        },
        vLineWidth: function () {
          return 0;
        },
        hLineColor: function () {
          return 'black';
        },
        paddingLeft: function () {
          return 3;
        },
        paddingRight: function () {
          return 3;
        },
        paddingTop: function () {
          return 8;
        },
        paddingBottom: function () {
          return 8;
        }
      }
    };
  }

  protected margemRelatorio(): number[] {
    return [50, 8, 30, 40];
  }

}
