import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AcaoGovernoService } from 'administrativo-lib';
import { AcaoGoverno, EddyAutoComplete, FuncaoService, GlobalService, LoginContabil, Ppa, PpaService, Relatorio } from 'eddydata-lib';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-totalizador-acao-dlg',
  templateUrl: './totalizador-acao-dlg.component.html'
})
export class TotalizadorAcaoDlgComponent implements OnInit, OnDestroy {
  @Input() login: LoginContabil;

  protected datepipe: DatePipe;
  protected unsubscribe: Subject<void> = new Subject();
  public ptBR: any;
  public ppa: Ppa;
  public somenteLeiProjeto: boolean = false;
  public ppaAutoComplete: EddyAutoComplete<Ppa>;

  constructor(
    protected messageService: MessageService,
    protected funcaoService: FuncaoService,
    private globalService: GlobalService,
    private acaoGovernoService: AcaoGovernoService,
    private ppaService: PpaService,
  ) { }

  ngOnInit() {
    this.ptBR = this.globalService.obterDataBR();
    this.datepipe = new DatePipe('pt');

    this.carregarAutocomplete();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private carregarAutocomplete() {
    this.ppaAutoComplete = new EddyAutoComplete(null, this.ppaService,
      'id', ['lei_numero'], { orderBy: 'lei_numero' }, { text: ['lei_numero'] }
    );
  }

  imprimir() {
    const parametros = {
      relations: 'acao,executora,meta,meta.programa,meta.funcao,meta.subfuncao,meta.indicadores,meta.ppa,indicador',
      'ppa.id': this.login.ppa.id,
      'orderBy': 'acao.codigo'
    };

    if (this.somenteLeiProjeto) {
      parametros['meta.indicadores.lei$like'] = `${this.ppa.lei_numero.split('/').join('')}%`;
    }

    this.acaoGovernoService.filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(lista => {
        Relatorio.imprimirPersonalizado('TOTALIZADOR DE AÇÕES DE GOVERNO', this.login.usuario.nome, this.login.usuario.sobrenome,
        this.login.orgao.nome, this.login.brasao, this.montarConteudo(lista.content),
        'portrait', 'TOTALIZADOR DE AÇÕES DE GOVERNO',
        {
          linhas: {
            hLineWidth() {
              return 1;
            },
            vLineWidth() {
              return 1;
            },
            hLineColor() {
              return 'black';
            },
            paddingLeft() {
              return 3;
            },
            paddingRight() {
              return 3;
            }
          }
        });
      }, () => alert('erro ao retornar lista'));
  }

  public montarConteudo(acoes: AcaoGoverno[]) {
    const conteudo = [];
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;

    conteudo.push([
      { text: 'Código', bold: true, alignment: 'center', border: [true, true, true, true] },
      { text: 'Descrição', bold: true, border: [true, true, true, true] },
      { text: this.login.ppa.ppaperiodo.ano1, bold: true, alignment: 'center', border: [true, true, true, true] },
      { text: this.login.ppa.ppaperiodo.ano2, bold: true, alignment: 'center', border: [true, true, true, true] },
      { text: this.login.ppa.ppaperiodo.ano3, bold: true, alignment: 'center', border: [true, true, true, true] },
      { text: this.login.ppa.ppaperiodo.ano4, bold: true, alignment: 'center', border: [true, true, true, true] },
    ]);

    const lista = this.funcaoService.agrupar(acoes, ['acao.codigo'], ['custo1', 'custo2', 'custo3', 'custo4'])

    for (const item of lista){
      total1 += +item.totalizadores['custo1'];
      total2 += +item.totalizadores['custo2'];
      total3 += +item.totalizadores['custo3'];
      total4 += +item.totalizadores['custo4'];
      
      conteudo.push([
        { text: item.grupo['acao.codigo'], border: [false, false, false, false], alignment: 'center' },
        { text: item.registros[0].acao.nome, border: [false, false, false, false] },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['custo1']), border: [false, false, false, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['custo2']), border: [false, false, false, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['custo3']), border: [false, false, false, false], alignment: 'right' },
        { text: this.funcaoService.convertToBrNumber(item.totalizadores['custo4']), border: [false, false, false, false], alignment: 'right' },
      ]);
    }

    conteudo.push([
      { text: 'Totais:', fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right', colSpan: 2 }, '',
      { text: this.funcaoService.convertToBrNumber(total1), fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(total2), fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(total3), fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right' },
      { text: this.funcaoService.convertToBrNumber(total4), fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right' },
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto'],
        dontBreakRows: true,
        body: conteudo
      }
    }];
  }

}
