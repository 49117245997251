import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FuncaoService, GlobalService, LoginContabil, ProgressoService, Recebimento } from 'eddydata-lib';
import { TreeNode } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { RecebimentoExtraService } from '../../recebimento-extra/service/recebimento-extra.service';
import { RecebimentoService } from '../service/recebimento.service';

declare var $: any;

@Component({
  selector: 'lib-importar-recebimento',
  templateUrl: './importar-recebimento.component.html'
})

export class ImportarRecebimentoComponent implements OnInit {
  @ViewChild('input') inputField: ElementRef;

  protected unsubscribe: Subject<void> = new Subject();
  public login: LoginContabil;
  public arquivos: any[] = [];
  public arquivoSelecionado: TreeNode;
  public inconsistencias: [];
  public recebimentosSalvo: Recebimento[] = []
  public mensagem = ""
  public visualizarMensagem = false;
  public valorTotal = 0;
  public listaRecebimentos;

  constructor(
    protected progressoService: ProgressoService,
    private recebimentoService: RecebimentoService,
    protected funcaoService: FuncaoService,
    private recebimentoExtraService: RecebimentoExtraService,
  ) {
    this.login = GlobalService.obterSessaoLogin();
  }

  ngOnInit(): void {
    this.loadRecebimentosLote()
  }

  public upload(input: any) {
    for (const file of input.files) {
      this.recebimentoService.leituraArquivo(file)
        .subscribe((data) => {
          this.listaRecebimentos = data;
          for (const recebimento of this.listaRecebimentos) {
            this.valorTotal += +recebimento.valor_recebido;
          }
        }, err => toastr.error(err.error.payload),
          (document.getElementById('my-file-selector') as HTMLInputElement).value = null)
    }
  }

  public async importar() {
    try {
      const foraDaData = this.listaRecebimentos.find(r => new Date(r.data_recebimento).getFullYear() !== this.login.exercicio.ano);
      if (foraDaData) {
        toastr.info('A importação deverá ser realizada no exercício vigente');
        throw new Error('A importação deverá ser realizada no exercício vigente');
      }

      const fichaInativa = this.listaRecebimentos.find(r => (r.ficha && !r.ficha.ativo) || (r.ficha_extra && !r.ficha_extra.ativo));
      if (fichaInativa) {
        toastr.info('A importação deverá ter apenas fichas ativas');
        throw new Error('A importação deverá ter apenas fichas ativas');
      }

      const recursoInativo = this.listaRecebimentos.find(r => (r.ficha && !r.ficha.recurso.ativo) || (r.ficha_extra && !r.ficha_extra.recurso.ativo));
      if (recursoInativo) {
        toastr.info('A importação deverá ter fichas vinculadas a recursos ativos');
        throw new Error('A importação deverá ter fichas vinculadas a recursos ativos');
      }

      const receitaRecurso = this.listaRecebimentos.find(r => r.ficha && +r.ficha.receita.n1 === 9);
      if (receitaRecurso) {
        toastr.info('A importação não pode ter fichas vinculadas a receitas iniciadas em 9');
        throw new Error('A importação não pode ter fichas vinculadas a receitas iniciadas em 9');
      }

      for (const lr of this.listaRecebimentos) {
        if (!this.funcaoService.podeAlterarAudesp(lr.data_recebimento, this.login)) {
          toastr.error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
          throw new Error('O mês do registro já foi armazenado no TCE. Não é possível salvar!');
        }
      }

      // const semFornecedor = this.listaRecebimentos.find(r => !r.favorecido?.id);
      // if (semFornecedor) {
      //   toastr.info('A importação deverá ter fornecedor informado');
      //   throw new Error('A importação deverá ter fornecedor informado');
      // }
    } catch (err) {
      throw err;
    }

    const reo = this.listaRecebimentos.filter(r => r.especie === 'REO');
    const ree = this.listaRecebimentos.filter(r => r.especie === 'REE');

    if (reo) await this.importarRecebimentos(reo, ree)
    else if (ree) await this.importarRecebimentosExtra(ree)

    this.listaRecebimentos = null;
    this.valorTotal = 0;
  }

  public async importarRecebimentos(reo, ree) {
    this.recebimentoService.importarRecebimentos(reo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, (retorno) => {
          if (retorno.inconsistencias) {
            this.inconsistencias = retorno.inconsistencias;
          } else {
            this.inconsistencias = [];
            if (ree) this.importarRecebimentosExtra(ree);
          }
          this.loadRecebimentosLote()
        });
      }, async error => {
        await this.loadRecebimentosLote()
        if (ree) this.importarRecebimentosExtra(ree);
        toastr.error(error.error.payload)
      });
  }

  public async importarRecebimentosExtra(ree) {
    this.recebimentoExtraService.importarRecebimentosExtras(ree).pipe(takeUntil(this.unsubscribe))
      .subscribe(async idTransacao => {
        this.progressoService.show(idTransacao, (retorno) => {
          if (retorno.inconsistencias) {
            this.inconsistencias = retorno.inconsistencias
          } else {
            this.inconsistencias = []
          }
          this.loadRecebimentosLote()
        });
      }, async error => {
        await this.loadRecebimentosLote()
        toastr.error(error.error.payload)
      });
  }

  public nodeSelectEvent(event) {
    if (this.arquivoSelecionado && this.arquivoSelecionado.data === -2)
      this.inputField.nativeElement.click();
  }

  public visualizarRetorno(item) {
    if (!item?.retorno?.mensagem)
      return;
    this.mensagem = item.retorno.mensagem;
    this.visualizarMensagem = true;
  }

  public async loadRecebimentosLote() {
    const parametro = {
      'nome_arquivo$not_null': true, 'exercicio.id': this.login.exercicio.id,
      'orgao.id': this.login.orgao.id, relations: 'exercicio,orgao', orderBy: 'id$DESC'
    }
    this.recebimentoService.filtrar(1, -1, parametro).pipe(takeUntil(this.unsubscribe)).subscribe((recebimentoData) => {
      this.recebimentoExtraService.filtrar(1, -1, parametro).pipe(takeUntil(this.unsubscribe)).subscribe((extraData) => {
        let recebimentos = [...recebimentoData.content, ...extraData.content]
        this.recebimentosSalvo = recebimentos.filter((v, i, a) => a.findIndex(t => (t.nome_arquivo === v.nome_arquivo)) === i)
      });
    });
  }

  public abrir() {
    $('#excluirRecebimento').modal('show')
  }
}
