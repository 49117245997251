
import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BaseResourceListComponent, FuncaoService, Coluna, Convenio, Filtro, FormatoExportacao, GlobalService, LoginPublico, UnidadeService, ExercicioService
} from 'eddydata-lib';




import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConvenioService } from '../service/convenio.service';
import { TipoConvenioService } from '../../tipo-convenio/service/tipo-convenio.service';
import { RecursoService } from '../../../contabil/recurso/recurso.service';

declare var $: any;

@Component({
  selector: 'lib-convenio-lst',
  templateUrl: './convenio-lst.component.html'
})
export class ConvenioLstComponent extends BaseResourceListComponent<Convenio, LoginPublico> {

  public ptBR: any;
  public colunaOrdenacao: string;
  private listaTiposConvenios: { valor: any, label: string }[] = [];
  private listaExercicios: { valor: any, label: string }[] = [];
  private listaRecursos: { valor: any, label: string }[] = [];
  private listaAplicacaoRecursos: { valor: any, label: string }[] = [];
  private listaAplicacaoVariavel: { valor: any, label: string }[] = [];


  private listaUnidadesAdministrativas: { valor: any, label: string }[] = [];

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    private route: ActivatedRoute,
    protected injector: Injector,
    private globalService: GlobalService,
    public funcaoService: FuncaoService,
    private tiposConvenioService: TipoConvenioService,
    private exerciciosService: ExercicioService,
    private recursoService: RecursoService,
    private unidadesService: UnidadeService,
    private convenioService: ConvenioService) {
    super(convenioService, injector);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected podeAlterar(_entidade: Convenio): boolean {
    return this.login.sistema != 'controle-interno'
  }

  protected relations(): string {
    return 'orgao,favorecido,tipo_convenio';
  }

  protected condicoesGrid(): {} {
    const parametros: {} = {};
    parametros['orgao.id'] = this.login.orgao.id;
    return parametros;
  }

  protected ordenacaoGrid(): string[] {
    // return []
    return ['ano$DESC', 'numero$DESC', 'data_assinatura$ASC'];
  }

  protected filtrosGrid(): Filtro {
    return {
      text: ['finalidade', 'numero', 'favorecido.nome', 'tipo_fornecedor'],
      date: ['data_assinatura'],
      number: ['valor_convenio']
    };

  }

  public beforeInit(): void {
    this.usarFiltroPersonalizado = true;

    this.login.contraste = sessionStorage.getItem('contraste') === 'true';

    this.tiposConvenioService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id }).subscribe(e => {

      e.content.forEach(e => {

        this.listaTiposConvenios.push({ valor: e.id, label: e.nome })

      });

    });

    this.exerciciosService.filtrar(1, -1, { 'orderBy': 'ano$DESC' }).subscribe(e => {

      e.content.forEach(e => {

        this.listaExercicios.push({ valor: (e.ano + '').slice(-2), label: e.ano })

      });

    })

    this.recursoService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, 'nivel': 0 }).subscribe(e => {

      e.content.forEach(e => {

        this.listaRecursos.push({ valor: e.codigo, label: e.codigo + ' ' + e.nome })

      });

    })

    this.recursoService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, 'nivel': 1, 'orderBy': 'codigo' }).subscribe(e => {

      e.content.forEach(e => {

        this.listaAplicacaoRecursos.push({ valor: e.codigo, label: e.codigo + ' ' + e.nome })

      });

    })

    this.recursoService.filtrar(1, -1, { 'cidade.id': this.login.cidade.id, 'nivel': 2, 'orderBy': 'codigo' }).subscribe(e => {

      e.content.forEach(e => {

        this.listaAplicacaoVariavel.push({ valor: e.codigo, label: e.codigo + ' ' + e.nome })

      });

    })

    this.unidadesService.filtrar(1, -1, { 'orgao.id': this.login.orgao.id, 'ppa.id': this.login.ppa.id }).subscribe(e => {

      e.content.forEach(e => {

        this.listaUnidadesAdministrativas.push({ valor: e.id, label: e.nome })

      });

    });




  }

  protected afterInit(): void {
    window.scrollTo(0, 0);
    this.ptBR = this.globalService.obterDataBR();
  }

  protected acaoRemover(model: Convenio): Observable<Convenio> {
    return null;
  }


  public obterColunasfiltroPersonalizado(): Coluna[] {
    const retorno: Coluna[] = [];
    retorno.push({ titulo: 'Número', coluna: 'numero', tipo: 'String', cols: 2, padrao: true });
    retorno.push({ titulo: 'Favorecido', coluna: 'favorecido.nome', cols: 3, tipo: 'String', padrao: true });
    retorno.push({ titulo: 'Data de Assinatura', coluna: 'data_assinatura', tipo: 'Date', padrao: true });
    retorno.push({ titulo: 'Tipo', coluna: 'tipo_convenio.id', tipo: 'Selection', opcoesSelecao: this.listaTiposConvenios, cols: 3, padrao: true });
    retorno.push({ titulo: 'Convênio Ativo', coluna: 'ativo', tipo: 'Selection', padrao: true, filtro1: 'true', opcoesSelecao: [{ valor: 'true', label: 'Ativo' }, { valor: 'false', label: 'Inativo' }] });

    retorno.push({ titulo: 'Favorecido CNPJ/CPF', coluna: 'favorecido.cpf_cnpj', cols: 2, tipo: 'String' });

    retorno.push({ titulo: 'Tipo de Fornecedor', coluna: 'tipo_fornecedor', tipo: 'Selection', opcoesSelecao: [{ valor: 'F', label: 'Fornecedor' }, { valor: 'C', label: 'Credor' }], padrao: true });

    retorno.push({ titulo: 'Ano do Convênio', coluna: 'ano', tipo: 'String', cols: 1, });
    retorno.push({ titulo: 'Data de Contabilização', coluna: 'data_contabilizacao', tipo: 'Date' });
    retorno.push({ titulo: 'N° Processo', coluna: 'processo', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Data de Início', coluna: 'data_inicio', tipo: 'Date' });
    retorno.push({ titulo: 'N° Convênio', coluna: 'numero_convenio', tipo: 'String', cols: 2 });
    retorno.push({ titulo: 'Origem', coluna: 'origem', tipo: 'Selection', opcoesSelecao: [{ valor: 'E', label: 'ESTADUAL' }, { valor: 'U', label: 'FEDERAL' }] });
    retorno.push({ titulo: 'Unidade Administrativa', coluna: 'unidade_id', tipo: 'Selection', opcoesSelecao: this.listaUnidadesAdministrativas, cols: 3 });
    retorno.push({
      titulo: 'Modalidade', coluna: 'especie', tipo: 'Selection',

      opcoesSelecao: [{ valor: 'X', label: '-' }, { valor: 'C', label: 'CHAMAMENTO' },
      { valor: 'D', label: 'DISPENSA' },
      { valor: 'I', label: 'INEXIGIBILIDADE' },
      { valor: 'O', label: 'OUTROS' },
      ]
    }

    );

    retorno.push({
      titulo: 'Legislação', coluna: 'legislacao', tipo: 'Selection',

      opcoesSelecao: [{ valor: '0', label: '-' }, { valor: '1', label: 'LEI FEDERAL' },
      { valor: '2', label: 'LEI ESTADUAL' },
      { valor: '3', label: 'LEI MUNICIPAL' },
      { valor: '4', label: 'DECRETO FEDERAL' },
      { valor: '5', label: 'DECRETO ESTADUAL' },
      { valor: '6', label: 'DECRETO MUNICIPAL' }
      ]
    }

    );

    retorno.push({ titulo: 'N° SICONV', coluna: 'siconv', tipo: 'String' });
    retorno.push({ titulo: 'Finalidade', coluna: 'finalidade', tipo: 'String' });
    retorno.push({ titulo: 'Autorização Lei N°', coluna: 'autorizacao', tipo: 'String' });
    retorno.push({ titulo: 'N° da Ficha', coluna: 'empenhos.ficha.numero', tipo: 'Number', cols: 2 });
    retorno.push({ titulo: 'Exercício da Ficha', coluna: 'empenhos.ficha.exercicio', tipo: 'Selection', opcoesSelecao: this.listaExercicios });
    retorno.push({ titulo: 'Fonte de Recurso', coluna: 'recurso.codigo', tipo: 'Selection', opcoesSelecao: this.listaRecursos });
    retorno.push({ titulo: 'Aplicação do Recurso', coluna: 'aplicacao.codigo', tipo: 'Selection', opcoesSelecao: this.listaAplicacaoRecursos });
    retorno.push({ titulo: 'Aplicação Variável', coluna: 'aplicacao_variavel.codigo', tipo: 'Selection', opcoesSelecao: this.listaAplicacaoVariavel });
    return retorno;
  }

  protected colunasRelatorio(): string[] | Coluna[] {
    return [
      { titulo: 'Número', coluna: 'numero' },
      { titulo: 'Fornecedor', coluna: 'favorecido.nome' },
      { titulo: 'Finalidade', coluna: 'finalidade' },
      { titulo: 'Objeto', coluna: 'objeto' },
      { titulo: 'Assinatura', coluna: 'data_inicio' },
      { titulo: 'Término', coluna: 'data_termino' },
      { titulo: 'Valor', coluna: 'valor_convenio', decimais: 2, alignment: 'right' },
    ];
  }

  public exportarListagem(formato: FormatoExportacao) {
    const parametros = this.obterParametros();
    this.convenioService
      .filtrar(1, -1,
        parametros
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        lista => {
          if (formato === 'pdf') {
            this.imprimir('LISTAGEM DE CONVÊNIOS',
              '', '', this.login.orgao.nome, this.login.brasao, 'landscape',
              'Listagem convenio', ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], lista.content, ['valor_convenio']);
          } else {
            this.exportar(formato, lista.content);
          }
        },
        () => alert('erro ao retornar lista')
      );
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public editar(id: number, visualizar: boolean) {
    if (visualizar) {
      if (!this.filtro || this.filtro === '') {
        this.router.navigate([`/convenios/${id}/visualiza`, this.paginaCorrente]);
      } else {
        this.router.navigate([`/convenios/${id}/visualiza`, this.paginaCorrente, this.filtro]);
      }
    } else {
      this.router.navigate([`/convenios/${id}/editar`]);
    }
  }

  public onSelectPeriodo() {
    this.preencherGrid();
  }

}
