import { Component, ElementRef, Injector, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { ContratoService, ConvenioRecursoService, ConvenioService, DespesaService, EmpenhoAnexoService, LicitacaoService, MemorialService, ProponenteService, PropostaService, TipoConvenioService } from "administrativo-lib";
import { ProdutoService } from "almoxarifado-lib";
import { FichaDespesaService } from "contabil-lib";
import {
  AssinaturaService, BaseResourceFormComponent, Compra, CompraItem, CompraStorage, Contrato, ContratoAditamento, ContratoItem, Convenio, DateFormatPipe,
  Despesa, EddyAutoComplete, EmailService, Executora, Exercicio, Favorecido, FavorecidoService, FichaDespesa, FuncaoService, GlobalService, Licitacao,
  LicitacaoPipe, LoginContabil, Modalidade, OrgaoAssinaturaService, Page, ParametroCompra, ParametroCompraService, Pessoa, PessoaService, Prazo, Produto,
  Proposta, Rcms, RcmsFavorecido, Recurso, Setor, SetorService
} from "eddydata-lib";
import { ConfirmationService } from "primeng/api";
import { AutoComplete } from "primeng/autocomplete";
import { takeUntil } from "rxjs/operators";
import * as toastr from "toastr";
import { ContratoItemService } from "../../contrato/service/contrato-item.service";
import { ModalidadeService } from "../../modalidade/service/modalidade.service";
import { PrazoService } from "../../prazo/service/prazo.service";
import { RcmsItemService } from "../../rcms/service/rcms-item.service";
import { RcmsService } from "../../rcms/service/rcms.service";
import { NotaCompra } from "../../relatorio-compra/nota-compra-rpt/nota-compra-rpt";
import { TipoContratacaoService } from "../../tipo-contratacao/service/tipo-contratacao.service";
import { CompraItemComponent } from "../compra-item/compra-item.component";
import { CompraItemService } from "../service/compra-item.service";
import { CompraStorageService } from "../service/compra-storage.service";
import { CompraTabelaDesconto } from "../service/compra-tabela-desconto.service";
import { CompraService } from "../service/compra.service";
import { ContratoAditamentoService } from "../../contrato/service/contrato-aditamento.service";
import { ActivatedRoute } from "@angular/router";
import { RecursoDisponibilidadeService } from "projects/contabil/src/app/planejamento/recurso-disponibilidade/service/recurso-disponibilidade.service";

type SaldoFixa = {
  dotacao?: number;
  reservado?: number;
  saldo?: number;
  empenhado?: number;
  compra?: number;
  rcms?: number;
};

// declare var $: any;

@Component({
  selector: "app-compra-form",
  templateUrl: "./compra-form.component.html",
})
export class CompraFormComponent extends BaseResourceFormComponent<Compra, LoginContabil> {

  @ViewChild("processo_") processoInput: ElementRef;
  @ViewChild("licitacao_") licitacaoInput: ElementRef;
  @ViewChild("contrato_") contratoInput: ElementRef;
  @ViewChild("favorecido_") favorecidoInput: AutoComplete;
  @ViewChild("codfavorecido_") codFavorecidoInput: ElementRef;
  @ViewChild("rcms_") rcmsInput: ElementRef;
  @ViewChild("compraitem_") compraItem: CompraItemComponent;

  /**
   * Auto completes
   */
  public pessoaAutoComplete: EddyAutoComplete<Pessoa>;
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public fichaAutoComplete: EddyAutoComplete<FichaDespesa>;
  public subElementoAutoComplete: EddyAutoComplete<Despesa>;
  public compraAutoComplete: EddyAutoComplete<Compra>;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public convenioAutoComplete: EddyAutoComplete<Convenio>;
  public modalidadeAutoComplete: EddyAutoComplete<Modalidade>;
  public setorAutoComplete: EddyAutoComplete<Setor>;
  public prazoEntregaAutoComplete: EddyAutoComplete<Prazo>;
  public prazoPagamentoAutoComplete: EddyAutoComplete<Prazo>;
  public aditamentoAutoComplete: EddyAutoComplete<ContratoAditamento>;

  /**
   * Declaração de variáveis
   */
  public listaItens: CompraItem[] = [];
  public saldoFixa: SaldoFixa;
  public listaTipos: Array<any>;
  public listaCompras: Array<any>;
  public listaLicitacoes: Array<any>;
  public listaContratos: Array<any>;
  public listaConvenios: Array<any>;
  private fichaOriginal: FichaDespesa;
  public selecionar: CompraItem[] = [];
  public favorecidosRcms: RcmsFavorecido[] = [];
  public listaArquivos: CompraStorage[];
  public visualizarRCMS: boolean = false;
  public visualizarFicha: boolean = false;
  public visualizarExclusao: boolean = false;
  public visualizarContrato: boolean = false;
  public visualizarSelecionar: boolean = false;
  public visualizarContratos: boolean = false;
  public visualizarFavorecidos: boolean = false;
  public visualizarLicitacoes: boolean = false;
  public licitacoesSelecionar: Licitacao[] = [];
  public possuiFavConvenio = false;
  public mostrarDescricao: boolean = false;
  public tipoContrato: 'REGISTRO_PRECO' | 'CONTRATO';
  public validSaldoDispensacao: boolean = true;
  public validAutorizado = false;
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public tab: number = 1;
  public naoExisteAditamento = false;
  public tipoEmail: string[] = ["R", "F"];
  public recurso: Recurso;
  public aplicacao: Recurso;
  public unidade: Executora;
  public selecionarItens: CompraItem[] = [];
  public contratoAtual: Contrato;
  public contratosSelecionar: Contrato[] = [];
  public processoAtual: string;
  public licitacaoAtual: Licitacao;
  public favorecidosSelecionar: Favorecido[] = [];
  public rcmsAtual: Rcms;
  public mes: string;
  public tipoAutocomplete: string = "text";
  public pedidoCompraDireta: boolean = false;
  public listaContratoss: Contrato[]
  public apertouProcesso: boolean = false;
  public apertouContrato: boolean = false;
  public visualizarTabelasDesconto: boolean = false;
  public tabelasDesconto: ContratoItem[] = [];
  public tabelasSelecionadas: any[] = []
  public numeroConvenio: string = ""
  public saldoConvenio: number;
  public alterarValorTotal: boolean = false;

  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    protected globalService: GlobalService,
    public funcaoService: FuncaoService,
    protected favorecidoService: FavorecidoService,
    protected anexoService: EmpenhoAnexoService,
    protected despesaService: DespesaService,
    protected fichaService: FichaDespesaService,
    protected licitacaoService: LicitacaoService,
    protected contratoService: ContratoService,
    protected convenioService: ConvenioService,
    protected modalidadeService: ModalidadeService,
    private setorService: SetorService,
    protected assinaturaService: AssinaturaService,
    protected produtoService: ProdutoService,
    private prazoService: PrazoService,
    protected pessoaService: PessoaService,
    protected compraItemService: CompraItemService,
    private contratoItemService: ContratoItemService,
    protected compraService: CompraService,
    protected orgaoAssinaturaService: OrgaoAssinaturaService,
    private emailService: EmailService,
    private confirmationService: ConfirmationService,
    private rcmsService: RcmsService,
    private rcmsItemService: RcmsItemService,
    private propostaService: PropostaService,
    private memorialService: MemorialService,
    private proponenteService: ProponenteService,
    private tipoContratacaoService: TipoContratacaoService,
    protected storageService: CompraStorageService,
    protected compraTabelaService: CompraTabelaDesconto,
    protected parametroCompraService: ParametroCompraService,
    protected convenioRecursoService: ConvenioRecursoService,
    private recursoDisponibilidadeService: RecursoDisponibilidadeService,
    private aditamentoService: ContratoAditamentoService,
    private tipoConvenioService: TipoConvenioService,
    private route: ActivatedRoute,
  ) {
    super(new Compra(), injector, Compra.converteJson, compraService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================


  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      tipo_compra: ["O", [Validators.required]],
      data_compra: [new Date(), [Validators.required]],
      data_entrega: [null],
      prazo_entrega: [null],
      prazo_pagamento: [null],
      adiantamento: [false, [Validators.required]],
      entregue_fornecedor: [false],
      autorizado: [true],
      documento: [null],
      operador: [this.login.usuario],
      modalidade: [null, Validators.required],
      observacao: [null],
      prazo: [null],
      ficha: [null, [Validators.required]],
      orgao: [this.login.orgao, [Validators.required]],
      exercicio: [this.login.exercicio, [Validators.required]],
      favorecido: [null, [Validators.required]],
      subelemento: [null, [Validators.required]],
      local_entrega: [null],
      numero_impressao: [0],
      estoque: [this.login.estoque],
      possui_tabela_desconto: [false],
      rcms: this.fb.group({
        id: [null],
        numero: [null],
      }),
      ata: [null],
      licitacao: this.fb.group({
        id: [null],
        numero: [null],
        tipo_licitacao: [null],
        tabela_desconto: [null],
        natureza: [null],
        modalidade: [null],
        situacao: [null]
      }),
      processo: [null],
      contrato: this.fb.group({
        id: [null],
        numero: [null],
        tipo_contratacao: [null],
        saldo_valor: [null],
      }),
      convenio: [null],
      setor: [null, [Validators.required]],
      itens: [null],
      requerente: [null, [Validators.required]],
      tabelas_desconto: [null],
      total_compra: [0],
      contrato_aditamento: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: [
        "operador", "modalidade", "licitacao", "subelemento.vpd", "convenio.favorecido.tipo", "favorecido.tipo", "contrato.tipo_contratacao", "ficha.despesa",
        "ficha.recurso", "ficha.executora.unidade", "ficha.aplicacao", "exercicio", "orgao", "requerente", "rcms.veiculo", "prazo", "empenho.exercicio",
        "setor.unidade", "prazo_entrega", "prazo_pagamento", "contrato_aditamento", "convenio.tipo_convenio"
      ],
    };
  }

  protected afterInit(): void {
    setInterval(() => {
      this.verificarPedidoCompraDireta();
    }, 1000);

    this.tipoAutocomplete = this.login.parametro?.["compras"]
      ?.bloqueio_razaosocial
      ? "number"
      : "text";
    this.mes = this.globalService.obterMes(this.login.mesReferencia + 1);
    this.listaTipos = this.globalService.obterListaTiposEmpenhos();
    this.carregarAutoCompletes();
    this.carregarAutoCompleteSubElemento();
    if (this.currentActionRoute === "novo") {
      this.compraService
        .proximoNumeroOF(this.login.exercicio.id, this.login.orgao.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get("numero").setValue(res);
        });
      this.compraService
        .obterDatasPosteriores(this.login.orgao.id, this.login.exercicio.id, { excluido: true })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm
            .get("data_compra")
            .setValue(new DateFormatPipe().transform(res.ultima, []));
        });
    }
  }

  protected campoFoco(): ElementRef {
    return null;
  }

  protected datePipe(): string[] {
    return ['data_compra'];
  }

  protected async afterLoad() {
    if (this.entidade.id) {
      this.carregarAutoCompleteFicha()
      this.carregarAutoCompleteSubElemento()

      if (this.entidade?.subelemento?.vpd) {
        this.entidadeForm.get("adiantamento").setValue(true)
      } else {
        this.entidadeForm.get("adiantamento").setValue(false)
      }
    }
    this.pessoaAutoComplete.id = this.entidade.requerente
      ? this.entidade.requerente.id
      : null;
    this.favorecidoAutoComplete.id = this.entidade.favorecido
      ? this.entidade.favorecido.id
      : null;
    this.fichaAutoComplete.id = this.entidade.ficha
      ? this.entidade.ficha.numero
      : null;
    if (this.entidade.operador) {
      this.entidadeForm.get("operador").setValue(this.entidade.operador);
    }
    this.subElementoAutoComplete.id = this.entidade.subelemento
      ? this.entidade.subelemento.codigo
      : null;
    this.licitacaoAutoComplete.id = this.entidade.licitacao
      ? this.entidade.licitacao.id
      : null;
    this.convenioAutoComplete.id = this.entidade.convenio
      ? this.entidade.convenio.id
      : null;
    this.fichaOriginal = this.entidade.ficha;
    this.contratoAtual = this.entidade.contrato;
    this.rcmsAtual = this.entidade.rcms;

    this.buscarSaldoFixa(true);

    this.compraItemService
      .filtrar(1, -1, {
        relations: [
          "produto_unidade",
          "produto_unidade.produto",
          "produto_unidade.produto.aliquota",
          "produto_unidade.produto.material.aliquota",
          "produto_unidade.unidade",
          'compra.rcms'
        ],
        "compra.id": this.entidade.id,
        orderBy: "ordem$ASC",
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.listaItens = res ? res.content : new Array<CompraItem>();
        },
        (error) => this.funcaoService.acaoErro(error)
      );

    this.contratoAtual = this.entidade.contrato;
    this.processoAtual = this.entidade.processo;
    this.licitacaoAtual = this.entidade.licitacao;

    this.carregarLicitacao();
    this.carregarContrato(this.entidade.contrato, true);
    this.carregarDadosFicha();
    this.preencherTabelas();
    this.numeroConvenio = this.entidade?.convenio ? `${this.entidade?.convenio?.numero}/${this.entidade?.convenio?.ano}` : ''
    if (this.entidade.convenio?.id) {
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        this.entidade.convenio.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_compra').value),
        this?.login?.orgao?.id

      ).toPromise();
    }
    this.possuiFavConvenio = !!this.entidade?.convenio;

    this.carregarAditamentoAutoComplete();

    const adiantamentos = await this.findAditamentos();

    if (adiantamentos.content.length < 2) {
      this.naoExisteAditamento = true;
    }
  }

  protected async podeAlterar(item: Compra): Promise<boolean> {
    return !item.excluido && !item?.empenho?.id && this.login.sistema !== 'licitacao' && this.login.sistema != 'controle-interno';
  }

  public preencherTabelas() {
    this.storageService
      .filtrar(1, -1, {
        "compra.id": this.entidade.id,
        orderBy: "data_cadastro$DESC",
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });
  }

  protected async beforeSubmit() {
    if (!!this.entidadeForm.get("contrato").value.id) {
      this.listaItens.map(item => item.cota = item.cota === 'PRINCIPAL' || item.cota === 'P' ? 'P' : 'R')
    }
    this.entidadeForm.get("itens").setValue(this.listaItens);
    this.entidadeForm.get('total_compra').setValue(this.valorTotal())

    const editando = this.listaItens.find((c) => c['editavel']);

    if (editando) {
      if (!editando.id && !editando.produto_unidade.id)
        this.listaItens.splice(this.listaItens.findIndex((c) => c.id === editando.id), 1);
      else if (!(await this.compraItem.salvar(editando))) {
        toastr.warning(`Verifique os processos pendentes no cadastro de itens da ordem de fornecimento`);
        throw new Error();
      }
    }

    if (!this.validarItensAjuste()) {
      this.ajusteSemSubmit = false;
      throw new Error();
    }

    let autorizado = this.entidadeForm.get("autorizado").value;
    try {
      if ((autorizado && !this.entidade.autorizado) || !this.entidade.id)
        this.validAutorizado = true;

      if (
        new Date(this.entidadeForm.get("data_compra").value).getFullYear() !==
        this.login.exercicio.ano
      ) {
        throw new Error(
          `Atenção: O exercício da compra deve corresponder ao ano do exercício (${this.login.exercicio.ano})`
        );
      }
    } catch (err) {
      this.funcaoService.acaoErro(err);
      throw err;
    }
    if (autorizado && (!this.listaItens || this.listaItens.length === 0)) {
      this.confirmationService.confirm({
        message: `Não há itens na ordem de fornecimento, é preciso inserí-los para que a OF seja autorizada para Empenho. Deseja desmarcar opção: Autorizada para Empenho?`,
        header: `Autorizado para empenho`,
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        key: "compra",
        accept: async () => {
          this.validAutorizado = false;
          this.entidadeForm.get("autorizado").setValue(false);
          this.submitForm(this.limparTela);
        },
      });

      throw Error("Validar Autorização");
    }

    let parametros: ParametroCompra = this.login.parametro["compras"];

    let licitacao = this.entidadeForm.get("licitacao").value;
    let contrato = this.entidadeForm.get("contrato").value;
    let processo = this.entidadeForm.get("processo").value;
    let modalidade: Modalidade = this.entidadeForm.get("modalidade").value;

    //validar saldo de dispensa
    if (
      this.validSaldoDispensacao &&
      modalidade != null &&
      modalidade.codigo === 5 &&
      // this.entidadeForm.get("autorizado").value &&
      (!licitacao || !licitacao.id) &&
      (!contrato || !contrato.id) &&
      !processo
    ) {
      const valid = await this.validarDispensa();
      if (valid) {
        this.validSaldoDispensacao = false;
      } else {
        throw new Error("validar saldo dispensa");
      }
    }

    let rcms = this.entidadeForm.get('rcms').value ? this.entidadeForm.get('rcms').value : this.entidade.rcms

    if (!rcms.id && (this.valorTotal() > this.saldoFixa.saldo)) {
      toastr.warning(`O valor total da OF é maior que o saldo da Ficha ${this.entidadeForm.get("ficha").value.id}`)
      throw new Error(`O valor total da OF é maior que o saldo da Ficha ${this.entidadeForm.get("ficha").value.id}`)
    }

    if (this.entidadeForm.get('subelemento').value.codigo.substring(2, 4) === '91' && !this.entidadeForm.get("favorecido").value.autarquia) {
      toastr.warning('Elemento da despesa é 91 e o favorecido precisa ser uma autarquia!')
      throw new Error(
        'Elemento da despesa é 91 e o favorecido precisa ser uma autarquia!'
      );
    }
  }

  protected afterSubmit(ent: Compra) {
    this.entidadeForm.get('tabelas_desconto').setValue(null);

    this.validSaldoDispensacao = true;
    this.entidade.autorizado = ent.autorizado;
    if (this.validAutorizado) {
      let compra = this.entidadeForm.value;
      compra.itens = this.listaItens;
      this.validAutorizado = false;

      let mensagem = "";
      if (this.login.parametro["compras"].email_requerente)
        mensagem += `<br />Requerente ${compra.requerente.nome}(${compra.requerente.email})`;
      if (this.login.parametro["compras"].email_favorecido)
        mensagem += `<br />Fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`;

      if (mensagem) {
        this.confirmationService.confirm({
          message: `Encaminhar por e-mail a ordem de fornecimento para: ${mensagem}?`,
          header: `Envio de Ordem de Fornecimento - Nº: ${compra.numero}`,
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Sim",
          rejectLabel: "Não",
          key: "compra",
          accept: async () => {
            await this.enviarEmail(ent);
            this.acaoSucesso(ent);
          },
          reject: () => {
            this.acaoSucesso(ent);
          },
        });
        throw Error("Validar envio de e-mail");
      }
    }
    if (!this.limparTela) this.loadResource();
  }

  private async validarDispensa(): Promise<boolean> {
    let parametros: ParametroCompra = this.login.parametro["compras"];
    let subelemento: Despesa = this.entidadeForm.get("subelemento").value;
    let favorecido: Despesa = this.entidadeForm.get("favorecido").value;
    let data_compra = this.entidadeForm.get("data_compra").value;

    if (!parametros.dispensacao_favorecido && !parametros.dispensacao_subelemento) {
      return true;
    }

    let saldo;
    let valor_limite_dispensacao;
    if (parametros.dispensacao_subelemento) {
      valor_limite_dispensacao = subelemento.obras_engenharia
        ? +parametros.valor_limite_dispensacao_engenharia_subelemento
        : +parametros.valor_limite_dispensacao_subelemento;

      if (!valor_limite_dispensacao) return true;

      saldo = await this.compraService
        .saldoDispensacaoSubElemento(
          this.login.exercicio.id,
          this.login.orgao.id,
          subelemento.codigo,
          new FuncaoService().converteDataSQL(data_compra),
          this.entidade.id
            ? {
              compra: this.entidade.id,
            }
            : null
        )
        .toPromise();
    } else {
      valor_limite_dispensacao = subelemento.obras_engenharia
        ? +parametros.valor_limite_dispensacao_engenharia_favorecido
        : +parametros.valor_limite_dispensacao_favorecido;

      if (!valor_limite_dispensacao) return true;

      saldo = await this.compraService
        .saldoDispensacaoFavorecido(
          this.login.exercicio.id,
          this.login.orgao.id,
          favorecido.id,
          new FuncaoService().converteDataSQL(data_compra),
          this.entidade.id
            ? {
              compra: this.entidade.id,
            }
            : null
        )
        .toPromise();
    }
    if (
      (saldo &&
        +saldo.saldo_compra +
        +saldo.saldo_rcms_nao_importado +
        this.valorTotal()) > valor_limite_dispensacao
    ) {
      let mensagem = `Valor da ordem de fornecimento para o subelemento ${subelemento.nome
        } excede o limite definido para dispensa${subelemento.obras_engenharia ? " para obras e engenharia" : ""
        } - ${new FuncaoService().convertToBrNumber(
          valor_limite_dispensacao
        )}. Total já requisitado/comprado ${new FuncaoService().convertToBrNumber(
          +saldo.saldo_compra + +saldo.saldo_rcms_nao_importado
        )},`;
      if (parametros.dispensacao_favorecido)
        mensagem = `Valor da ordem de fornecimento para o fornecedor ${favorecido.nome
          } excede o limite definido para dispensa ${subelemento.obras_engenharia ? " para obras e engenharia" : ""
          } - ${new FuncaoService().convertToBrNumber(
            valor_limite_dispensacao
          )}. Total já requisitado/comprado ${new FuncaoService().convertToBrNumber(
            +saldo.saldo_compra + +saldo.saldo_rcms_nao_importado
          )},`;
      if (parametros.autorizar_dispensa) {
        return await this.confirm(
          `${mensagem} deseja continuar?`,
          "Limite de Dispensa"
        );
      } else {
        toastr.warning(mensagem);
        return false;
      }
    } else {
      return true;
    }
  }

  public verificarPedidoCompraDireta() {
    const licitacao = this.entidadeForm.get("licitacao").value;
    const processo = this.entidadeForm.get("processo").value;
    const contrato = this.entidadeForm.get("contrato").value;
    const modalidade: Modalidade = this.entidadeForm.get("modalidade").value;

    if (
      (!licitacao || !licitacao.id) &&
      (!contrato || !contrato.id) &&
      !processo &&
      modalidade?.codigo === 5
    )
      this.pedidoCompraDireta = true;
    else this.pedidoCompraDireta = false;
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  private buscarSaldoFixa(init?: boolean) {
    const exercicio: Exercicio = this.entidade.exercicio
      ? this.entidade.exercicio
      : this.entidadeForm.get("exercicio").value;
    const ficha: FichaDespesa = this.entidadeForm.get("ficha").value
      ? this.entidadeForm.get("ficha").value
      : this.entidade.ficha;
    const dataCompra: Date = this.entidadeForm.get("data_compra").value;

    if (!ficha || !dataCompra || !exercicio) return;

    const desconsiderar = {};

    if (this.entidade.id) desconsiderar["compraId"] = this.entidade.id;

    const rcms = this.entidadeForm.get("rcms").value;
    if (rcms && this.listaItens && this.listaItens.length > 0) {
      desconsiderar["rcmsId"] = rcms.id;
      desconsiderar["rcmsProdutoUnId"] = this.listaItens.map(
        (i) => i.produto_unidade.id
      );
    }

    this.fichaService
      .saldoAtualFicha(
        ficha.id,
        this.login.orgao.id,
        this.login.exercicio.id,
        String(
          this.funcaoService.converteDataSQL(
            new DateFormatPipe().transform(dataCompra, ["local"])
          )
        ),
        desconsiderar ? desconsiderar : null,
        true
      )
      .subscribe((data) => {
        if (data) this.carregarSaldoFixa(data, init);
      });
  }

  private carregarSaldoFixa(totalizadores: any, init?: boolean) {
    if (!totalizadores) return;

    if (totalizadores.saldo_atual <= 0 && !init) {
      toastr.warning(
        `Ficha de '${this.entidadeForm.get("ficha").value.despesa.nome
        }' não possui saldo para estar realizando a requisição`
      );
    }

    this.saldoFixa = {};
    this.saldoFixa.empenhado = +totalizadores.total_empenhado;
    this.saldoFixa.rcms = +totalizadores.total_rcms_pendente;
    this.saldoFixa.compra = +totalizadores.total_compra_pendente;

    this.saldoFixa.dotacao =
      +totalizadores.total_dotacao + +totalizadores.total_creditado;
    this.saldoFixa.reservado = +totalizadores.total_reservado;
    this.saldoFixa.saldo = +totalizadores.saldo_atual - (totalizadores.cota_reservada ? +totalizadores.cota_reservada : 0);
  }

  private carregarAutoCompletes() {
    // autocomplete para favorecido
    this.carregarAutoCompleteFavorecido();
    this.pessoaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("pessoa"),
      this.pessoaService,
      "id",
      ["nome"],
      { cidade_id: this.login.cidade.id, 'cpf_cnpj$not_null': true, 'cpf_cnpj$ne': '', orderBy: "nome" },
      { number: ["id", "cpf_cnpj"], text: ["nome"] }
    );
    this.compraAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("compra"),
      this.compraService,
      "numero",
      ["numero"],
      {
        exercicio_id: this.login.exercicio.id,
        orgao_id: this.login.orgao.id,
        relations: "exercicio",
        orderBy: "numero",
      },
      { number: ["id", "numero"] }
    );
    this.licitacaoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("licitacao"),
      this.licitacaoService,
      "id",
      ["numero"],
      { orgao_id: this.login.orgao.id, orderBy: "numero" },
      { number: ["numero"] }
    );
    this.convenioAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("convenio"),
      this.convenioService,
      "id",
      ["numero", "ano"],
      { orgao_id: this.login.orgao.id, ativo: true, orderBy: "numero", relations: 'tipo_convenio' },
      { number: ["id", "numero"] }
    );
    this.modalidadeAutoComplete = new EddyAutoComplete(
      null,
      this.modalidadeService,
      "id",
      ["codigo", "nome"],
      { orderBy: "codigo" },
      { number: ["codigo"], text: ["nome"] },
      null,
      -1
    );
    this.prazoEntregaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("prazo_entrega"),
      this.prazoService,
      "id",
      ["nome"],
      { "orgao.id": this.login.orgao.id, entrega: true },
      { text: ["nome"] }
    );
    this.prazoPagamentoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("prazo_pagamento"),
      this.prazoService,
      "id",
      ["nome"],
      { "orgao.id": this.login.orgao.id, pagamento: true },
      { text: ["nome"] }
    );

    this.carregarAutoCompleteSetor();
    this.carregarAutoCompleteFicha();

    // Autocomplete Aditamento
    this.carregarAditamentoAutoComplete();
  }

  private carregarAutoCompleteSetor() {
    const parametros = {
      "estoques.estoque.id": this.login.estoque.id,
      "orgao.id": this.login.orgao.id,
      ativo: true,
      relations: "unidade,executoras.executora",
    };

    const ficha = this.entidadeForm.get("ficha").value;
    if (ficha) parametros["unidade.id"] = ficha.executora?.unidade?.id;

    this.setorAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("setor"),
      this.setorService,
      "id",
      ["codigo", "nome"],
      parametros,
      { text: ["nome", "codigo"] },
      () => {
        this.carregarAutoCompleteFicha();
      }
    );
  }

  public favorecidoAutorizado() {
    if (!this.entidadeForm.get("favorecido").value.autorizado) {
      this.favorecidoAutoComplete.id = null;
      this.entidadeForm.get("favorecido").setValue(null);
      toastr.error("Favorecido não esta autorizado, entre em contato com setor responsável");
    } else {
      this.favorecidoAutoComplete.atualizar(true);
    }
  }

  public async carregarAutoCompleteFicha() {
    let parametros = {
      "exercicio.id": this.login.exercicio.id,
      "orgao.id": this.login.orgao.id,
      relations: ["despesa", "recurso", "aplicacao", "executora.unidade", "aplicacao_variavel"],
      orderBy: "despesa.nome",
    };

    const setor: Setor = this.entidadeForm.get("setor").value;
    if (setor?.executoras?.length > 0)
      parametros["executora.id$in"] = setor.executoras.map(e => e.executora.id).join(',');
    else
      if (setor?.unidade?.id) {
        parametros["executora.unidade.id"] = setor?.unidade?.id;
      }

    this.fichaAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("ficha"),
      this.fichaService,
      "numero",
      ["ficha.despesa.codigo", "ficha.despesa.nome"],
      parametros,
      { number: ["numero"], text: ["despesa.codigo", "despesa.nome"] },
      async () => {
        const ficha: FichaDespesa = this.entidadeForm.get("ficha").value;
        if (!this.fichaAutoComplete.id) {
          this.entidadeForm.get("ficha").setValue(null);
          this.entidadeForm.get("convenio").setValue(null)
          this.numeroConvenio = null
        }

        if (ficha && !(await this.validarFicha(ficha))) {
          this.entidadeForm.get("ficha").setValue(null);
          this.fichaAutoComplete.id = null;
          this.entidadeForm.get("convenio").setValue(null)
          this.numeroConvenio = null
        }
        if (
          ficha &&
          (!this.entidade.ficha || this.entidade.ficha.id !== ficha.id)
        ) {
          this.carregarAutoCompleteSubElemento();
        }

        this.carregarAutoCompleteSubElemento()
        this.alterarSubElemento();
        this.buscarSaldoFixa();
        this.carregarDadosFicha();
        this.carregarAutoCompleteFavorecido();
        this.carregarAutoCompleteSetor();
        if (this.login.parametro.contabil.nao_requisitar_01_100) {
          if (ficha && ficha?.aplicacao_variavel?.codigo?.startsWith('01100')) {
            await this.validarDisponibilidadeRecurso(ficha);
          }
        }
        if (ficha?.aplicacao_variavel) {
          await this.carregarConvenio()
        } else {
          this.possuiFavConvenio = false;
        }
      }
    );
  }

  public onInputtDataCompra() {
    const data_compra = this.entidadeForm.get("data_compra").value;
    this.carregarProcesso(false, true);
    if (data_compra) this.fichaAutoComplete.funcaoOnSelect();
  }

  public async validarFicha(ficha: FichaDespesa) {
    if (this.listaItens && this.listaItens.length > 0) {
      let pagina = await this.produtoService
        .filtrar(1, -1, {
          orgao_id: this.login.orgao.id,
          "material.sub_grupo.sub_elementos.sub_elemento.codigo$like": `${ficha.despesa.codigo.substring(
            0,
            6
          )}%`,
          id$in: this.listaItens
            .map((i) => i.produto_unidade.produto.id)
            .join(","),
        })
        .toPromise();
      if (!pagina || !pagina.content || pagina.content.length === 0) {
        toastr.warning(
          `Produtos vinculados não possuem vínculo com ficha ${ficha.numero} - ${ficha.despesa.nome}`
        );
        return false;
      }
      let produtos: Produto[] = pagina.content;
      for (let item of this.listaItens) {
        let valid = produtos.find(
          (p) => p.id === item.produto_unidade.produto.id
        );
        if (!valid) {
          toastr.warning(
            `Produtos vinculados não possuem vínculo com ficha ${ficha.numero} - ${ficha.despesa.nome}`
          );
          return false;
        }
      }
    }

    let favorecido = this.entidadeForm.get("favorecido").value;

    if (favorecido) {
      if (ficha.despesa.n4 === "39" || ficha.despesa.n4 === "36") {
        let tipo = ficha.despesa.n4 === "39" ? "01" : "02";
        if (favorecido.tipo?.tce?.toString() !== tipo) {
          toastr.warning(
            `Favorecido vinculado à OF é ${tipo === "01" ? "Pessoa Física" : "Pessoa Jurídica"
            }, portanto não é possível inserir Ficha de ${tipo === "01" ? "Pessoa Jurídica" : "Pessoa Física"
            }`
          );
          return false;
        }
      }
    }
    return true;
  }

  private async carregarAutoCompleteSubElemento() {
    let parametros = {
      exercicio_id: this.login.exercicio.id,
      nivel: 6,
      relations: 'vpd',
      orderBy: "codigo",
      ativo: true
    };
    let ficha = this.entidadeForm.get("ficha").value;
    if (ficha) {
      parametros["codigo$like"] = ficha.despesa.codigo.substring(0, 6) + "%";
    }

    this.subElementoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("subelemento"),
      this.despesaService,
      "codigo",
      ["nome"],
      parametros,
      { number: ["id", "codigo"], text: ["nome"] },
      async () => {
        let subelemento = this.entidadeForm.get("subelemento").value;
        if (!this.subElementoAutoComplete.id)
          this.entidadeForm.get("subelemento").setValue(null);
        if (subelemento && !(await this.validarSubElemento(subelemento))) {
          this.entidadeForm.get("subelemento").setValue(null);
        }
        this.alterarSubElemento(false);
      }
    );

    if (this.entidadeForm.get("subelemento").value?.vpd) {
      this.entidadeForm.get("adiantamento").setValue(true)
    } else {
      this.entidadeForm.get("adiantamento").setValue(false)
    }

    if (!ficha) this.subElementoAutoComplete.disabled = true;
    else this.subElementoAutoComplete.disabled = false;
    this.alterarSubElemento();
  }

  public async validarSubElemento(subelemento: Despesa) {
    if (this.listaItens && this.listaItens.length > 0) {
      let pagina = await this.produtoService
        .filtrar(1, -1, {
          orgao_id: this.login.orgao.id,
          "material.sub_grupo.sub_elementos.sub_elemento.id": subelemento.id,
          id$in: this.listaItens
            .map((i) => i.produto_unidade.produto.id)
            .join(","),
        })
        .toPromise();

      if (!pagina || !pagina.content || pagina.content.length === 0) {
        toastr.warning(
          `Produtos vinculados não possuem vínculo com sub-elemento ${subelemento.codigo} - ${subelemento.nome}`
        );
        return false;
      }

      let produtos: Produto[] = pagina.content;
      for (let item of this.listaItens) {
        let valid = produtos.find(
          (p) => p.id === item.produto_unidade.produto.id
        );
        if (!valid) {
          toastr.warning(
            `Produtos vinculados não possuem vínculo com sub-elemento ${subelemento.codigo} - ${subelemento.nome}`
          );
          return false;
        }
      }
    }

    if (!(await this.validarTipoContratacao())) {
      toastr.warning(
        `A ficha informada não é compatível com a contratação informada no contrato. Verifique na divisão de compras se a contratação informada é válida.`
      );
      return false;
    }

    if (!(await this.validarTipoConvenio())) {
      toastr.warning(
        `A ficha informada não é compatível com o tipo de convênio informado. Verifique na divisão de compras se o convênio informado é válido.`
      );
      return false;
    }

    return true;
  }

  private async validarTipoContratacao(): Promise<boolean> {
    let subelemento = this.entidadeForm.get("subelemento").value;
    let contrato = this.entidadeForm.get("contrato").value;
    if (!subelemento || !contrato?.tipo_contratacao?.id)
      //Se o contrato for Outros não valida
      return true;

    const validar = await this.tipoContratacaoService
      .validarDespasa(contrato.tipo_contratacao.id, subelemento.id)
      .toPromise();
    return validar.valido;
  }

  private async validarTipoConvenio(): Promise<boolean> {
    let subelemento = this.entidadeForm.get('subelemento').value;
    let convenio = this.entidadeForm.get('convenio').value;
    if (!subelemento || !convenio?.tipo_convenio?.id) {
      return true;
    }

    const validar = await this.tipoConvenioService.validarDespesa(convenio.tipo_convenio.id, subelemento.id).toPromise();
    return validar.valido;
  }

  private alterarSubElemento(atualizar: boolean = true) {
    const ficha: FichaDespesa = this.entidadeForm.get("ficha").value;
    if (ficha) {
      this.fichaAutoComplete.id = ficha.numero;
      const fichaSubElemento: string = ficha.despesa.codigo.substring(0, 6);
      const subElemento: Despesa = this.entidadeForm.get("subelemento").value;
      if (
        !subElemento ||
        fichaSubElemento !== subElemento.codigo.substring(0, 6)
      ) {
        this.subElementoAutoComplete.id = fichaSubElemento;
        this.entidadeForm.get("subelemento").setValue(null);
      } else {
        this.subElementoAutoComplete.id = subElemento.codigo;
      }
    } else if (atualizar) {
      this.subElementoAutoComplete.id = null;
      this.subElementoAutoComplete.atualizar(false);
    }

    if (this.entidadeForm.get("subelemento").value?.vpd) {
      this.entidadeForm.get("adiantamento").setValue(true)
    } else {
      this.entidadeForm.get("adiantamento").setValue(false)
    }
  }

  private carregarAutoCompleteFavorecido() {
    let processo = this.entidadeForm.get("processo").value;
    let ficha = this.entidadeForm.get("ficha").value;
    let parametros = {
      cidade_id: this.login.cidade.id,
      orderBy: "nome",
      relations: "tipo",
    };
    if (ficha?.despesa?.n4 === "39") parametros["tipo.tce"] = "01";
    if (ficha?.despesa?.n4 === "36") parametros["tipo.tce"] = "02";
    if (processo) {
      parametros["proponentes.licitacao.processo"] = this.funcaoService.removerPontos(processo).trim();
      parametros["proponentes.propostas.situacao"] = "VENCEDOR";
    }

    this.favorecidoAutoComplete = new EddyAutoComplete(
      this.entidadeForm.get("favorecido"),
      this.favorecidoService,
      "id",
      ["cpf_cnpj", "nome"],
      parametros,
      { number: ["id", "cpf_cnpj"], text: ["nome"] }, () => {
        if (+this.entidadeForm.get('favorecido')?.value?.tipo?.tce !== 1 && +this.entidadeForm.get('favorecido')?.value?.tipo?.tce !== 2 && +this.entidadeForm.get('favorecido')?.value?.tipo?.tce !== 10 && +this.entidadeForm.get('favorecido')?.value?.id) {
          toastr.warning('Verifique a espécie no cadastro de fornecedor');
          this.entidadeForm.get('favorecido').setValue(null); this.favorecidoAutoComplete.id = null;
          return;
        }
      }
    );
    this.favorecidoAutoComplete.atualizar(true);
  }

  public buscarContrato() {
    let contrato = this.entidadeForm.get("contrato").value;
    if (!contrato.numero) {
      this.contratoAtual = null;
      this.entidadeForm.get("contrato").patchValue({ id: null, numero: null });
      this.carregarProcesso(true);
      return;
    }

    if (this.contratoAtual && this.contratoAtual.numero === contrato.numero)
      return;

    this.entidadeForm.get("modalidade").enable();

    this.carregarLicitacao(true);

    this.contratoService
      .obter({
        orgao_id: this.login.orgao.id,
        numero: contrato.numero,
        excluido: false,
        orderBy: ["itens.memorial.ordem"],
        relations: [
          "licitacao",
          "modalidade",
          "favorecido.tipo",
          "itens",
          "itens.produto_unidade",
          "tipo_contratacao",
          "itens.produto_unidade.produto",
          "itens.produto_unidade.produto.aliquota",
          "itens.produto_unidade.produto.material.aliquota",
          "itens.produto_unidade.unidade",
          "itens.produto_unidade.produto.material.sub_grupo",
          "itens.memorial",
          "prazo",
        ],
      })
      .subscribe(
        (data) => {
          if (data) this.carregarContrato(data, false);
          else {
            toastr.warning("Contrato não encontrado!");
            if (this.contratoAtual)
              this.entidadeForm.get("contrato").patchValue(this.contratoAtual);
            else {
              this.entidadeForm
                .get("contrato")
                .patchValue({ id: null, numero: null });
              this.carregarProcesso(true);
            }
            this.funcaoService.focarCampo(this.contratoInput, true);
          }
        },
        (error) => toastr.error(error.error.payload)
      );
    this.visualizarContratos = false
  }

  private async carregarContrato(contrato: Contrato, init?: boolean) {
    this.visualizarContratos = false;
    if (!contrato) {
      this.entidadeForm.get("contrato").patchValue({ id: null, numero: null });
      return;
    }
    if (contrato.licitacao?.tipo_licitacao !== 5 && !contrato.licitacao?.tabela_desconto) {
      if (!(await this.validarSaldoContrato(contrato)) && !init) {
        toastr.warning(`Contrato não possui saldo para ser importado.`);
        if (this.contratoAtual)
          this.entidadeForm.get("contrato").patchValue(this.contratoAtual);
        else {
          this.entidadeForm
            .get("contrato")
            .patchValue({ id: null, numero: null });
          this.carregarProcesso(true);
        }
        this.funcaoService.focarCampo(this.contratoInput, true);
        return;
      }
    }

    if (contrato.excluido) {
      toastr.warning(`O contrato indicado está removido`);
      this.entidadeForm
        .get("contrato")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("licitacao")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("processo")
        .patchValue(null);
      this.carregarProcesso(true);
      this.funcaoService.focarCampo(this.contratoInput, true);
      return;
    }

    if (new Date(this.entidadeForm.get('data_compra').value) > new Date(contrato.data_termino) && contrato.aditamentos?.length === 0) {
      toastr.warning(`O contrato indicado está vencido`);
      this.entidadeForm
        .get("contrato")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("licitacao")
        .patchValue({ id: null, numero: null });
      this.entidadeForm
        .get("processo")
        .patchValue(null);
      this.entidadeForm
        .get("rcms")
        .patchValue(null);
      this.carregarProcesso(true);
      this.funcaoService.focarCampo(this.contratoInput, true);
      return;
    }
    const aditamentosOrdenados = contrato?.aditamentos?.sort((a, b) => {
      return a.data_termino < b.data_termino ? 1 : -1;
    })
    if (aditamentosOrdenados?.length > 0) {
      if (new Date(this.entidadeForm.get('data_compra').value) > new Date(aditamentosOrdenados[0]?.data_termino)) {
        toastr.warning(`O contrato/aditivo indicado está vencido`);
        this.entidadeForm
          .get("contrato")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("processo")
          .patchValue(null);
        this.entidadeForm
          .get("rcms")
          .patchValue({ id: null, numero: null, });
        this.carregarProcesso(true);
        this.funcaoService.focarCampo(this.contratoInput, true);
        return;
      }
    }

    if (
      !this.contratoAtual ||
      (this.contratoAtual && this.contratoAtual.id !== contrato.id)
    )
      this.listaItens = [];
    this.contratoAtual = contrato;

    this.entidadeForm.get("contrato").patchValue(contrato);
    this.entidadeForm.get("processo").setValue(contrato.processo);
    this.processoAtual = contrato.processo;
    this.tipoContrato = contrato.tipo === 'REGISTRO_PRECO' ? 'REGISTRO_PRECO' : 'CONTRATO'
    this.entidadeForm.get("prazo").setValue(contrato.prazo);

    if (contrato.licitacao) {
      this.entidadeForm.get("licitacao").patchValue(contrato.licitacao);
      this.licitacaoAtual = contrato.licitacao;
    }
    if (contrato.modalidade)
      this.entidadeForm.get("modalidade").setValue(contrato.modalidade);
    if (contrato.prazo)
      this.entidadeForm.get("prazo_pagamento").setValue(contrato.prazo);
    if (contrato.entrega)
      this.entidadeForm.get("prazo_entrega").setValue(contrato.entrega);

    if (!init) {
      if (+contrato.favorecido.tipo.tce !== 1 && +contrato.favorecido.tipo.tce !== 2 && +contrato.favorecido.tipo.tce !== 10) {
        toastr.warning('Verifique a espécie no cadastro de fornecedor');
        this.entidadeForm
          .get("contrato")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
        this.entidadeForm
          .get("processo")
          .patchValue(null);
        this.carregarProcesso(true);
        this.funcaoService.focarCampo(this.contratoInput, true);
        return;
      }
      this.entidadeForm.get("favorecido").setValue(contrato.favorecido);
      this.favorecidoAutoComplete.id = contrato.favorecido.id;
    }

    this.entidadeForm.get("modalidade").disable();
    this.contratoAtual = contrato;
    this.carregarAutoCompleteFicha();
    this.carregarAutoCompleteSubElemento();
  }

  public retornaCota(itemAtual: ContratoItem, lista: ContratoItem[]) {
    let cota: 'P' | 'R'
    if (itemAtual.memorial) {
      cota = itemAtual.memorial.cota === 'PRINCIPAL' ? 'P' : itemAtual.memorial.cota === 'NAO_DEFINIDO' ? 'P' : 'R'
    } else {
      let itemIgual = lista.find(it => it.produto_unidade.id === itemAtual.produto_unidade.id && it.id !== itemAtual.id)
      cota = !itemIgual ? 'P' : +itemAtual.quantidade > +itemIgual.quantidade ? 'P' : 'R'
    }
    return cota
  }

  public carregarItensContrato() {
    const dataReferencia = this.funcaoService.converteDataSQL(this.entidadeForm.get('data_compra').value);

    let contrato = this.entidadeForm.get("contrato").value;
    if (!contrato.numero) return;
    this.contratoItemService
      .filtrar(1, -1, {
        "contrato.inativo": false,
        "contrato.excluido": false,
        "contrato.orgao.id": this.login.orgao.id,
        "contrato.numero": contrato.numero,
        "OR": `produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento.exercicio_id=${this.login.exercicio.id};!;!;produto_unidade.id$null=true`,
        orderBy: ["memorial.ordem"],
        relations: [
          "produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento",
          "produto_unidade.unidade", "produto_unidade.produto.material.aliquota",
          "produto_unidade.produto.aliquota", "memorial",
        ],
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (!data || !data.content || data.content.length === 0) return;
        data.content = data.content.filter(c => c.contrato.tipo === this.tipoContrato)
        this.selecionarItens = data.content.map((c) => {
          let item = new CompraItem();
          item.produto_unidade = c.produto_unidade;
          item.produto = c.descricao;
          item.unidade = c.unidade;
          item.quantidade = c.quantidade;
          item["bloqueado"] = c.bloqueado;
          item["codigo_item"] = c.memorial ? c.memorial.ordem : "";
          item["cota"] = this.retornaCota(c, data.content) === 'P' ? 'PRINCIPAL' : 'RESERVADO'
          let rcms = this.entidadeForm.get("rcms").value;
          let pararametros = {};
          pararametros['contrato_id'] = contrato.id
          pararametros['cota'] = this.retornaCota(c, data.content)
          if (this.entidade.id)
            pararametros["desconCompraId"] = this.entidade.id;
          if (rcms?.id) pararametros["desconRcmsId"] = rcms.id;
          pararametros['dataReferencia'] = dataReferencia;
          this.contratoItemService
            .obterSaldo(this.login.orgao.id, c.id, pararametros)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              item["saldo_vl"] = +res.saldo_vl;
              item["saldo_qtd"] = +res.saldo_qtd;
              item["quantidade_import"] = +res.saldo_qtd;
              // item["saldo_qtd"] = +res.quantidade_cota > 0 ? (+res.quantidade_cota === +item.quantidade ? +res.saldo_qtd : 0.0) : +res.saldo_qtd;
              // item["quantidade_import"] = +res.quantidade_cota > 0 ? (+res.quantidade_cota === +item.quantidade ? +res.saldo_qtd : 0.0) : +res.saldo_qtd;
              item["valor_unitario"] = +res.valor_unitario;
              //Travado temporariamente para testar apenas em cotas principais, futuramente será estudado testar o inverso como informação.
              if (
                this.login.parametro?.compras?.priorizar_reservado &&
                c.memorial?.cota === "PRINCIPAL"
              ) {
                let licitacao = this.entidadeForm.get("licitacao").value;
                //Quando principal, testa se a reserva é mais favoravel e vice-versa para exibir aviso/trava.
                this.propostaService
                  .obterVencedoresItem(
                    licitacao.id,
                    item.produto_unidade.id,
                    c.memorial.cota == "PRINCIPAL" ? "RESERVADO" : "PRINCIPAL",
                    this.contratoAtual.favorecido.id
                  )
                  .subscribe((res) => {
                    res["content"].forEach((i) => {
                      let p: Proposta = i;
                      if (
                        p.memorial.id != c.memorial.id &&
                        p.proponente.favorecido.id !=
                        this.contratoAtual.favorecido.id
                      ) {
                        //Onde estiver principal, deve se jogar 5%, pois EPP/MEI pode ser até 5% mais caro segundo lei e ainda ser válido como prioridade.
                        let va = c.memorial.cota == "PRINCIPAL" ? 1.05 : 1; //Caso o contrato atual é o principal, testa pelo C.
                        let vb = p.memorial.cota == "PRINCIPAL" ? 1.05 : 1; //Caso o contrato atual é o reserva, testa pelo P.
                        if (item["valor_unitario"] * va > p.valor_final * vb) {
                          //Se for desvantajoso, antes de bloquear verificar se o saldo do outro contrato ainda está disponível.
                          //Busca o contrato atual do fornecedor reservado, para a mesma licitação do Principal:
                          this.contratoService
                            .obter({
                              inativo: false,
                              excluido: false,
                              "orgao.id": this.login.orgao.id,
                              "licitacao.id": licitacao.id,
                              "favorecido.id": p.proponente.favorecido.id,
                            })
                            .pipe(takeUntil(this.unsubscribe))
                            .subscribe((contratoReserva) => {
                              let parametros = {
                                "contrato.orgao.id": this.login.orgao.id,
                                "contrato.numero": contratoReserva.numero,
                                "contrato.inativo": false,
                                "contrato.excluido": false,
                              };

                              if (p.memorial?.produto_unidade?.id)
                                parametros["produto_unidade.id"] =
                                  p.memorial.produto_unidade.id;
                              else {
                                parametros["descricao"] = p.memorial.descricao;
                                parametros["unidade"] = p.memorial.unidade;
                              }

                              //Busca o item atual no contrato:
                              this.contratoItemService
                                .filtrar(1, -1, parametros)
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe((propReserva) => {
                                  //Com o item, se obtem o saldo atual dele:
                                  this.contratoItemService
                                    .obterSaldo(
                                      this.login.orgao.id,
                                      propReserva.content[0].id,
                                      this.entidade.id
                                        ? { desconRcmsId: this.entidade.id, dataReferencia: dataReferencia }
                                        : { dataReferencia: dataReferencia }
                                    )
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe((saldo) => {
                                      if (saldo.saldo_qtd > 0) {
                                        item["vantagem_bloqueio"] =
                                          c.memorial.cota == "PRINCIPAL";
                                        item[
                                          "vantagem"
                                        ] = `Item disponível no Contrato: ${contratoReserva.numero.substring(
                                          0,
                                          4
                                        )}/${contratoReserva.numero.substring(
                                          4
                                        )} - ${p.proponente.favorecido.nome}. ${c.memorial.cota == "PRINCIPAL"
                                          ? "\nDeve ser utilizado por se tratar de um item de COTA RESERVADA com saldo disponível "
                                          : "\nPode ser mais vantajoso na COTA PRINCIPAL com saldo disponível"
                                          } e valor mais vantajoso (Vl. unitário ${Number(
                                            p.valor_final
                                          ).toFixed(2)}).`;
                                      }
                                    });
                                });
                            });
                        } else {
                          item["vantagem_bloqueio"] = false;
                        }
                      }
                    });
                  });
              }
              let select;
              if (this.listaItens)
                select = this.listaItens.find(
                  (i) =>
                    i.produto_unidade?.id &&
                    i.produto_unidade.id === item.produto_unidade.id && i.cota === (item.cota === 'PRINCIPAL' ? 'P' : 'R')
                );
              if (select) {
                item.id = select.id;
                item["selecionado"] = true;
                item["quantidade_import"] = +select.quantidade;
              }

              //validar bloqueio de seleção de item
              let mensagem_bloqueio = this.bloquearItem(item);
              if (mensagem_bloqueio) {
                item["bloqueado"] = true;
                item["mensagem_bloqueio"] = mensagem_bloqueio;
              }
            });

          return item;
        });
        this.visualizarSelecionar = true;
      });
  }

  public bloquearItem(item: CompraItem, propostas?: Proposta[]): string {

    if (!item.produto_unidade?.id) return null;

    let subelemento = this.entidadeForm.get("subelemento").value;

    if (
      !subelemento ||
      !item.produto_unidade?.produto?.material?.sub_grupo?.sub_elementos ||
      item.produto_unidade.produto.material.sub_grupo.sub_elementos.length === 0
    ) {
      return `Produto ${item.produto_unidade.produto.nome} não possui vinculo com sub-elemento informado.`;
    }

    let valid = false;
    let subelementoInativo = false;
    for (let subelemItem of item.produto_unidade.produto.material.sub_grupo.sub_elementos) {
      if (subelemItem.sub_elemento.id === subelemento.id) {
        valid = true;
        if (subelemItem.inativo === true) {
          subelementoInativo = true;
        }
      }
    }

    if (!valid) {
      return `Produto ${item.produto_unidade.produto.nome} não possui vinculo com sub-elemento informado.`;
    }

    if (subelementoInativo) {
      return `Produto ${item.produto_unidade.produto.nome} possui vinculo com o sub-elemento informado, porém o mesmo se encontra inativo no sub-grupo vinculado ao produto!`;
    }

    if (item['bloqueado']) {
      return `Produto ${item.produto_unidade.produto.nome} está bloqueado.`
    }

    return null;
  }

  private async validarSaldoContrato(contrato: Contrato): Promise<boolean> {
    let rcms = this.entidadeForm.get("rcms").value ? this.entidadeForm.get("rcms").value : this.entidade?.rcms;
    let saldo = await this.contratoService
      .obterSaldo(
        this.login.orgao.id,
        contrato.id,
        this.entidade?.id
          ? { compraId: this.entidade.id}
          : null,
        contrato.saldo_quantidade
      ) 
      .toPromise();
    if (!saldo || !saldo.saldo || saldo.saldo <= 0) {
      return false;
    }
    return true;
  }

  public posSelecionarItens(itens: CompraItem[]) {
    this.listaItens = [];
    this.listaItens = itens.map((a, i) => {
      a.ordem = 1 + i;
      a.quantidade = a["quantidade_import"];
      a.valor_unitario = a.valor_unitario;
      a.valor_desconto = 0;
      a.valor_icmsipi = 0;
      return a;
    });
    if (!this.validarItensAjuste()) this.ajusteSemSubmit = true;
  }

  public carregarLicitacao(naoBuscarContrato?: boolean) {
    this.entidadeForm.get("modalidade").enable();
    this.entidadeForm.get("processo").enable();
    let licitacao = this.entidadeForm.get("licitacao").value;
    if (!licitacao.numero) {
      this.entidadeForm.get("licitacao").patchValue({ id: null, numero: null });
      return;
    }

    const parametros = {
      numero: licitacao.numero,
      "orgao.id": this.login.orgao.id,
      "itens.propostas.situacao": "VENCEDOR",
      relations: "modalidade"
    }

    if (licitacao?.modalidade?.id || this.entidadeForm.get("modalidade").value?.id) {
      parametros['modalidade_id'] = licitacao?.modalidade?.id ?? this.entidadeForm.get("modalidade").value.id
      parametros['situacao'] = licitacao?.situacao
    }

    this.licitacaoService
      .filtrar(1, -1, parametros)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data) => {
          if (data.content.length > 1) {
            this.licitacoesSelecionar = data.content;
            this.visualizarLicitacoes = true;
          } else {
            this.selecionarLicitacao(data.content[0], naoBuscarContrato);
          }
        },
        (error) => toastr.error(error.error.payload)
      );
  }

  public async selecionarLicitacao(data: Licitacao, naoBuscarContrato?: boolean) {
    if (data) {
      let processo = this.entidadeForm.get("processo").value;
      if (
        processo &&
        this.funcaoService.removerPontos(data.processo).trim() !==
        this.funcaoService.removerPontos(processo).trim()
      ) {
        toastr.warning(
          `Licitação de número '${this.funcaoService.mascarar(
            "#####/####",
            data.numero
          )}' não pertencente ao processo '${processo}'`
        );
        if (this.licitacaoAtual)
          this.entidadeForm
            .get("licitacao")
            .patchValue(this.licitacaoAtual);
        else {
        }
        // this.funcaoService.focarCampo(this.licitacaoInput, true);
        return;
      }
      this.entidadeForm.get("licitacao").patchValue(data);
      this.entidadeForm.get("processo").setValue(data.processo);
      if (data.modalidade)
        this.entidadeForm.get("modalidade").setValue(data.modalidade);
      this.entidadeForm.get("modalidade").disable();
      // this.processoInput.nativeElement.disabled = true;
      let contrato = this.entidadeForm.get("contrato").value;
      if (!naoBuscarContrato && !contrato.id) this.carregarProcesso();
      this.licitacaoAtual = data;
    } else {
      if (this.licitacaoAtual)
        this.entidadeForm
          .get("licitacao")
          .patchValue(this.licitacaoAtual);
      else
        this.entidadeForm
          .get("licitacao")
          .patchValue({ id: null, numero: null });
      // this.funcaoService.focarCampo(this.licitacaoInput, true);
      toastr.warning("Licitação não encontrada!");
    }
  }


  public async carregarProcesso(ignorar?: boolean, botaoContrato?: boolean, botaoProcesso?: boolean) {
    if (botaoContrato) {
      this.contratoService.filtrar(1, -1, {
        excluido: false,
        "orgao.id": this.login.orgao.id,
        numero: this.entidadeForm.get('contrato.numero').value,
        relations: 'favorecido.tipo,licitacao,modalidade,prazo,entrega,aditamentos'
      })
        .subscribe(
          async (lista) => {
            if (!lista || !lista.content || lista.content.length === 0) {
              if (contrato.numero) {
                toastr.warning(`Contrato '${contrato.numero ? contrato.numero : " "}' não encontrado!`);
                this.entidadeForm.get('contrato').patchValue({ id: null, numero: null });
                return
              }
            } else {
              if (lista.content.length > 1) {
                this.contratosSelecionar = lista.content;
                this.apertouContrato = true;
                this.visualizarContratos = true;
              } else {
                if (lista.content[0].inativo) {
                  toastr.warning('Contrato inativado. Entre em contato com o administrador do sistema de licitação.');
                  this.entidadeForm.get('contrato').patchValue({ id: null, numero: null });
                  return
                }
                if (lista.content[0].excluido) {
                  toastr.warning(`O contrato indicado está removido`);
                  return
                }
                await this.selecionarContrato(lista.content[0])
              }
            }
          });
    }
    let processo = this.entidadeForm.get("processo").value;
    let contrato = this.entidadeForm.get("contrato").value;
    // let licitacao = this.entidadeForm.get('licitacao').value;
    if (!processo) {
      if (contrato.numero) {
        processo = this.processoAtual;
        this.entidadeForm.get("processo").setValue(processo);
      } else {
        this.processoAtual = null;
        this.entidadeForm.get("licitacao").get("numero").setValue(null);
        this.carregarLicitacao();
      }
    }
    if (
      (!processo || (this.processoAtual && this.funcaoService.removerPontos(this.processoAtual).trim() === this.funcaoService.removerPontos(processo).trim())) &&
      (!ignorar || !processo)
    )
      return;
    if (botaoProcesso) {
      this.apertouProcesso = true;
    }
    this.licitacaoService
      .filtrar(1, -1, {
        "OR": `processo=${this.funcaoService.removerPontos(processo).trim()};!;!;processo=${processo}`,
        "orgao.id": this.login.orgao.id,
        "itens.propostas.situacao": "VENCEDOR",
        "relations": 'modalidade'
      })
      .subscribe(
        async (data) => {
          if (!data || !data.content || data.content.length === 0) {
            toastr.warning(`Processo '${processo}' não encontrado!`);
            this.entidadeForm.get("processo").setValue(null);
            if (this.processoAtual)
              this.entidadeForm.get("processo").setValue(this.processoAtual);
            this.funcaoService.focarCampo(this.processoInput, true);
            return;
          }
          let licitacao = data.content[0];
          this.entidadeForm.get("licitacao").patchValue(licitacao);
          this.carregarLicitacao(true);
          if (!(await this.carregarContratosProcesso(processo))) {
            this.entidadeForm
              .get("contrato")
              .patchValue({ id: null, numero: null });
            await this.carregarFavorecidosProcesso(processo);
          }
          this.processoAtual = processo;
        },
        (error) => toastr.error(error.error.payload)
      );
  }

  private async carregarContratosProcesso(processo: string): Promise<boolean> {
    let pagina = await this.contratoService
      .filtrar(1, -1, {
        "OR": `processo=${this.funcaoService.removerPontos(processo).trim()};!;!;processo=${processo}`,
        orgao_id: this.login.orgao.id,
        inativo: false,
        excluido: false,
        orderBy: ["numero", "itens.memorial.ordem"],
        relations: [
          "licitacao",
          "modalidade",
          "favorecido.tipo",
          "itens",
          "itens.produto_unidade",
          "itens.produto_unidade.produto",
          "itens.produto_unidade.unidade",
          "itens.produto_unidade.produto.material.sub_grupo",
          "itens.produto_unidade.produto.material.aliquota",
          "itens.produto_unidade.produto.aliquota",
          "itens.memorial",
          "prazo",
          "tipo_contratacao",
        ],
      })
      .toPromise();
    if (!pagina || !pagina.content || pagina.content.length === 0) {
      return false;
    }
    let contratos: Contrato[] = [];
    for (let contrato of pagina.content) {
      if ((await this.validarSaldoContrato(contrato)).valueOf()) {
        contratos.push(contrato);
      }
    }
    if (contratos.length === 0) return false;
    this.contratosSelecionar = contratos;
    this.visualizarContratos = true;
    this.apertouProcesso = true;
    return true;
  }

  private async carregarFavorecidosProcesso(processo: string) {
    let pagina = await this.proponenteService
      .filtrar(1, -1, {
        OR: `licitacao.processo=${this.funcaoService
          .removerPontos(processo)
          .trim()};!;!;licitacao.processo=${processo}`,
        "propostas.situacao": "VENCEDOR",
        relations: "favorecido.tipo",
      })
      .toPromise();
    if (!pagina || !pagina.content || pagina.content.length === 0) {
      return;
    }
    this.favorecidosSelecionar = pagina.content.map((p) => p.favorecido);
    this.visualizarFavorecidos = true;
    return true;
  }

  public carregarItensProcesso() {
    let processo = this.entidadeForm.get("processo").value;
    let contrato = this.entidadeForm.get("contrato").value;
    let favorecido = this.entidadeForm.get("favorecido").value;
    if (contrato.numero || !processo || !favorecido?.id) return;
    this.memorialService
      .filtrar(1, -1, {
        OR: `licitacao.processo=${this.funcaoService
          .removerPontos(processo)
          .trim()};!;!;licitacao.processo=${processo}`,
        "propostas.situacao": "VENCEDOR",
        "propostas.proponente.favorecido.id": favorecido.id,
        "produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento.exercicio_id": this.login.exercicio.id,
        relations:
          "produto_unidade.produto.material.sub_grupo.sub_elementos.sub_elemento,produto_unidade.unidade,produto_unidade.produto.material.aliquota,produto_unidade.produto.aliquota,propostas.proponente.favorecido",
      })
      .subscribe((data) => {
        if (!data || !data.content || data.content.length === 0) return;
        this.selecionarItens = data.content.map((i) => {
          let item = new CompraItem();
          item.produto_unidade = i.produto_unidade;
          item.produto = i.descricao;
          item.unidade = i.unidade;
          item.quantidade = +i.quantidade;
          item.valor_unitario = +i.propostas[0].valor_unitario;
          item["saldo_qtd"] = +i.quantidade;
          item["saldo_vl"] = +i.quantidade * +i.propostas[0].valor_unitario;
          item["quantidade_import"] = +i.quantidade;
          item["codigo_item"] = i.ordem;
          item["cota"] = i.cota;

          let select;
          if (this.listaItens)
            select = this.listaItens.find(
              (it) => it.produto_unidade.id === i.produto_unidade.id
            );
          if (select) {
            item.id = select.id;
            item["selecionado"] = true;
            item["quantidade_import"] = +select.quantidade;
          }

          //validar bloqueio de seleção de item
          let mensagem_bloqueio = this.bloquearItem(item, i.propostas);
          if (mensagem_bloqueio) {
            item["bloqueado"] = true;
            item["mensagem_bloqueio"] = mensagem_bloqueio;
          }
          return item;
        });
        this.visualizarSelecionar = true;
      });
  }

  public async selecionarContrato(contrato: Contrato) {
    this.entidadeForm.get("contrato").patchValue(contrato);
    this.carregarContrato(contrato);
    await this.setAditamento()
  }

  public selecionarFavorecido(favorecido: Favorecido) {
    let fav = this.entidadeForm.get("favorecido").value;
    if (fav && fav.id && fav.id !== favorecido.id) this.listaItens = [];
    this.entidadeForm.get("favorecido").setValue(favorecido);
    this.favorecidoAutoComplete.id = favorecido.id;
    this.carregarAutoCompleteFicha();
    this.carregarAutoCompleteSubElemento();
  }

  public async selecionarRCMS(favorecido: RcmsFavorecido) {
    this.entidadeForm.get('favorecido').setValue(favorecido.favorecido);
    this.entidadeForm.get('rcms').patchValue(favorecido.rcms);
    this.entidadeForm.get('ficha').patchValue(favorecido.rcms.ficha);
    this.entidadeForm.get('setor').patchValue(favorecido.rcms.setor);
    this.entidadeForm.get('prazo_entrega').patchValue(favorecido.rcms.prazo_entrega);
    this.entidadeForm.get('prazo_pagamento').patchValue(favorecido.rcms.prazo_pagamento);
    this.entidadeForm.get('subelemento').patchValue(favorecido.rcms.subelemento);
    this.entidadeForm.get('modalidade').patchValue(favorecido.rcms.modalidade);
    this.entidadeForm.get('requerente').setValue(favorecido.rcms.requerente);
    this.entidadeForm.get('observacao').setValue(favorecido.rcms.observacao);
    this.entidadeForm.get('local_entrega').setValue(favorecido.rcms.local_entrega);
    this.entidadeForm.get('estoque').setValue(favorecido.rcms.estoque);

    if (favorecido?.rcms?.contrato_aditamento)
      this.entidadeForm.get('contrato_aditamento').setValue(favorecido?.rcms?.contrato_aditamento);

    if (favorecido.rcms.tabelas_desconto) {
      this.entidadeForm.get('tabelas_desconto').setValue(favorecido.rcms.tabelas_desconto);
      this.entidadeForm.get('possui_tabela_desconto').setValue(true);
    }
    if (favorecido?.rcms?.convenio) {
      this.entidadeForm.get('convenio').setValue(favorecido.rcms.convenio);
      let convenio = this.entidadeForm.get('convenio').value;
      this.numeroConvenio = `${convenio.numero}/${convenio.ano}`;
      this.entidadeForm.get('favorecido').setValue(favorecido.favorecido);
      this.possuiFavConvenio = true;
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        convenio?.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_compra').value),
        this?.login?.orgao?.id

      ).toPromise();
    } else {
      this.numeroConvenio = "";
      this.entidadeForm.get('convenio').setValue(null);
      this.possuiFavConvenio = false;
    }

    // this.entidadeForm.get('licitacao').setValue(favorecido.rcms.licitacao)
    this.fichaAutoComplete.id = favorecido.rcms.ficha?.numero;
    this.subElementoAutoComplete.id = favorecido.rcms.subelemento?.codigo;
    this.favorecidoAutoComplete.id = favorecido.favorecido.id;

    if (favorecido.rcms.licitacao) {
      this.entidadeForm.get("licitacao").patchValue(favorecido.rcms.licitacao);
      this.carregarLicitacao();
    }
    if (favorecido.rcms.contrato) {
      this.entidadeForm.get("contrato").patchValue(favorecido.rcms.contrato);
      this.entidadeForm
        .get("processo")
        .setValue(favorecido.rcms.contrato.processo);
      // this.entidadeForm.get("licitacao").disable();
      this.processoInput.nativeElement.disabled = true;
      this.entidadeForm.get("modalidade").disable();
      this.favorecidoInput.disabled = true;
      this.codFavorecidoInput.nativeElement.disabled = true;
    }

    this.listaItens = [];
    this.listaItens = favorecido.cotacoes.map((c, i) => {
      let item = new CompraItem();
      item.produto_unidade = c.rcmsItem.produto_unidade;
      item.cota = c.rcmsItem.cota
      item.produto = c.rcmsItem.produto;
      item.unidade = c.rcmsItem.unidade;
      item.valor_unitario = c.valor_unitario;
      item.quantidade = c.rcmsItem.quantidade;
      item.valor_desconto = 0.0;
      item.valor_icmsipi = 0.0;
      item.ordem = i + 1;
      item.valor_referencia = c.valor_referencia;
      item['importado_rcms'] = true;
      return item;
    });

    this.carregarAutoCompleteSetor();
    this.carregarAutoCompleteFicha();

    if (!this.validarItensAjuste()) this.ajusteSemSubmit = true;
    else this.buscarSaldoFixa();
  }

  public carregarRCMS() {
    let numero = this.entidadeForm.get("rcms").value.numero;
    if (
      !numero ||
      (this.rcmsAtual && +this.rcmsAtual.numero === numero) ||
      !this.login
    )
      return;
    this.entidadeForm.get("estoque").setValue(this.login.estoque);
    this.rcmsService
      .importarRcms(numero, this.login.exercicio.id, this.login.orgao.id, "N", true)
      .subscribe(
        (data) => {
          this.favorecidosRcms = data;
          this.visualizarRCMS = true;
          this.rcmsAtual = { numero: numero };
        },
        (error) => {
          this.funcaoService.acaoErro(error);
          this.entidadeForm.get("rcms").patchValue(new Rcms());
          this.funcaoService.focarCampo(this.rcmsInput);
        }
      );
  }

  public aposAjustarProdutos(produtos: any[]) {
    const contrato = this.entidadeForm.get("contrato").value;
    const licitacao = this.entidadeForm.get("licitacao").value;
    const rcms = this.entidadeForm.get("rcms").value;
    const exercicio = this.entidadeForm.get("exercicio").value;

    if (!produtos) return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }
    for (let item of this.listaItens) {
      if (item.produto_unidade?.id) continue;
      let produto_unidade = produtos.find(
        (p) =>
          p.produto === item.produto_unidade.produto.nome &&
          p.unidade === item.produto_unidade.unidade.nome
      );
      if (produto_unidade) {
        item.produto_unidade = produto_unidade.produto_unidade;

        if (contrato?.id) {
          this.contratoItemService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              contrato_id: contrato.id,
              produto: item.produto,
              unidade: item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${item.produto} - ${item.unidade
                } do contrato ${new LicitacaoPipe().transform(contrato.numero)}`
              );
            });
        } else if (licitacao?.id) {
          this.memorialService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              licitacao_id: licitacao.id,
              produto: item.produto,
              unidade: item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${item.produto} - ${item.unidade
                } da licitação ${new LicitacaoPipe().transform(
                  licitacao.numero
                )}`
              );
            });
        } else if (rcms?.id) {
          this.rcmsItemService
            .atualizarProdutoUnidade(produto_unidade.produto_unidade, {
              rcms_id: rcms.id,
              produto: item.produto,
              unidade: item.unidade,
            })
            .subscribe((ci2) => {
              toastr.success(
                `Ajustado item ${item.produto} - ${item.unidade
                } da requisição de compra ${("00000" + rcms.numero).slice(
                  -5
                )}/${exercicio.ano}`
              );
            });
        }
      }
    }

    this.buscarSaldoFixa();

    if (!this.ajusteSemSubmit) this.submitForm(this.limparTela);
    this.ajusteSemSubmit = false;
  }

  private validarItensAjuste() {
    if (this.listaItens.filter((i) => !i.produto_unidade?.id).length > 0) {
      toastr.warning(`Foi identificado itens com serviços não cadastrados`);
      this.produtosAjuste = this.listaItens
        .filter((i) => !i.produto_unidade?.id)
        .map((i) => {
          return { produto: i.produto, unidade: i.unidade };
        });
      this.visulizarAjusteServico = true;
      return false;
    }
    return true;
  }

  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.listaItens = [];
    }
    this.visulizarAjusteServico = false;
    this.buscarSaldoFixa();
  }

  public parametrosProdutos(): {} {
    let subelemento = this.entidadeForm.get("subelemento").value;
    let processo = this.entidadeForm.get("processo").value;

    let parametros = {};
    if (subelemento && subelemento.codigo) {
      parametros["valid_sub_elemento$like"] = `%${subelemento.id}%`;
    }
    // if (processo)
    //   parametros['unidades.memoriais.licitacao.processo'] = processo;
    return parametros;
  }

  public valorTotal() {
    let valorTotal = 0.0;
    if (!this.listaItens || this.listaItens.length === 0) return valorTotal;
    for (let item of this.listaItens) {
      valorTotal +=
        item.quantidade * item.valor_unitario +
        +item.valor_icmsipi -
        +item.valor_desconto;
    }
    return valorTotal;
  }

  public selecionarTab(tab: number) {
    this.tab = tab;
  }

  public tabSelecionado(tab: number) {
    return this.tab === tab;
  }

  public email() {
    this.entidade.itens = this.listaItens;

    let mensagem = "";
    if (this.tipoEmail.includes("R"))
      mensagem += `<br />Requerente ${this.entidade.requerente.nome}(${this.entidade.requerente.email})`;
    if (this.tipoEmail.includes("F"))
      mensagem += `<br />Fornecedor ${this.entidade.favorecido.nome}(${this.entidade.favorecido.email})`;

    if (mensagem.length === 0) return;

    this.confirmationService.confirm({
      message: `Encaminhar por e-mail a ordem de fornecimento para: ${mensagem}?`,
      header: `Envio de Ordem de Fornecimento - Nº: ${this.entidade.numero}`,
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      key: "compra",
      accept: () => {
        this.enviarEmail(this.entidade, true);
      },
    });
  }

  public async enviarEmail(comp: Compra, validar?: boolean) {
    return new Promise<void>((resolve) => {
      this.compraService
        .obter({
          id: comp.id,
          "orgao.id": comp.orgao.id,
          "exercicio.id": comp.exercicio.id,
          relations: [
            "ficha",
            "modalidade",
            "licitacao",
            "subelemento",
            "convenio",
            "favorecido.tipo",
            "contrato",
            "ficha.despesa",
            "ficha.acao",
            "ficha.recurso",
            "ficha.executora.unidade",
            "ficha.aplicacao",
            "exercicio",
            "orgao",
            "contrato",
            "requerente",
            "rcms.setor",
            "rcms.veiculo",
            "prazo",
            "empenho",
            "empenho.exercicio",
            "estoque",
          ].join(","),
        })
        .subscribe((compra) => {
          this.compraItemService
            .filtrar(1, -1, {
              relations: [
                "produto_unidade",
                "produto_unidade.produto",
                "produto_unidade.unidade",
              ],
              "compra.id": compra.id,
              orderBy: "ordem$ASC",
            })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(async (res) => {
              compra.itens = res.content;
              let favorecido = true;
              let requisicao = true;

              const anexo = await new NotaCompra(
                this.orgaoAssinaturaService,
                this.compraService,
                this.login,
                null,
                null,
                this.propostaService
              ).exportar([compra]);

              let mensagem = "";
              if (this.login.parametro["compras"].mensagem_of)
                mensagem += this.login.parametro["compras"].mensagem_of;
              if (
                ((validar && this.tipoEmail.includes("R")) ||
                  (!validar &&
                    this.login.parametro["compras"].email_requerente)) &&
                compra.requerente.email
              ) {
                requisicao = false;
                let subject = `Envio de Ordem de Fornecimento - Nº OF: ${compra.numero}`;
                let message =
                  "<p><b>Ordem de Fornecimento enviado para o requerente:  </b>" +
                  compra.requerente.nome +
                  "<br><b>E-mail do requerente: </b>" +
                  compra.requerente.email +
                  "<br><b>Número da OF: </b>" +
                  compra.numero +
                  "<br><b>Data: </b>" +
                  this.funcaoService.converteDataBR(new Date()) +
                  "</p>";
                message += mensagem ? "<br />" + mensagem : "";
                this.emailService
                  .enviar({
                    titulo: subject,
                    corpo: mensagem,
                    destinos: [
                      {
                        nome: compra.requerente.nome,
                        email: compra.requerente.email,
                      },
                    ],
                    anexos: [
                      {
                        arquivo: anexo,
                        nome: `OF ${compra.numero} - ${compra.exercicio.ano}.pdf`,
                      },
                    ],
                  })
                  .subscribe(
                    (data) => {
                      toastr.success(
                        `E-mail encaminhado para o requerente ${compra.requerente.nome}(${compra.requerente.email})`
                      );
                      requisicao = true;
                      if (requisicao && favorecido) resolve();
                    },
                    (error) => {
                      toastr.error(
                        `Não foi possivel encaminhar o e-mail para requerende ${compra.requerente.nome}(${compra.requerente.email})`
                      );
                      requisicao = true;
                      if (requisicao && favorecido) resolve();
                    }
                  );
              }
              if (
                ((validar && this.tipoEmail.includes("F")) ||
                  (!validar &&
                    this.login.parametro["compras"].email_favorecido)) &&
                compra.favorecido.email
              ) {
                favorecido = false;
                let subject = `Envio de Ordem de Fornecimento - Nº OF: ${compra.numero}`;
                let subjectRetorno = `Envio de Pré-empenho - Nº OF: ${compra.numero}`
                let message =
                  "<p><b>Ordem de Fornecimento enviado para o fornecedor:  </b>" +
                  compra.favorecido.nome +
                  "<br><b>E-mail do fornecedor: </b>" +
                  compra.favorecido.email +
                  "<br><b>Número da OF: </b>" +
                  compra.numero +
                  "<br><b>Data: </b>" +
                  this.funcaoService.converteDataBR(new Date()) +
                  "</p>";
                // message += mensagem ? "<br />" + mensagem : "";
                this.emailService
                  .enviar({
                    titulo: subject,
                    corpo: mensagem,
                    destinos: [
                      {
                        nome: compra.favorecido.nome,
                        email: compra.favorecido.email,
                      },
                    ],
                    anexos: [
                      {
                        arquivo: anexo,
                        nome: `OF ${compra.numero} - ${compra.exercicio.ano}.pdf`,
                      },
                    ],
                  })
                  .subscribe(
                    (data) => {
                      toastr.success(
                        `E-mail encaminhado para o fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`
                      );
                      favorecido = true;
                      this.parametroCompraService.obter({
                        'orgao.id': compra.orgao.id
                      }).subscribe((parametroCompra) => {
                        if (parametroCompra?.email_retorno) {
                          this.emailService.enviar({ titulo: subjectRetorno, corpo: message, destinos: [{ nome: '', email: parametroCompra?.email_retorno }] })
                            .subscribe(() => {
                              toastr.success(`E-mail de retorno encaminhado para o e-mail ${parametroCompra.email_retorno}`)
                            });
                        }
                      });

                      if (favorecido && requisicao) resolve();
                    },
                    (error) => {
                      toastr.error(
                        `Não foi possivel encaminhar o e-mail para fornecedor ${compra.favorecido.nome}(${compra.favorecido.email})`
                      );
                      favorecido = true;
                      if (favorecido && requisicao) resolve();
                    }
                  );
              }
            });
        });
    });
  }

  public carregarDadosFicha(): void {
    this.recurso = (
      (this.entidadeForm.get("ficha").value ||
        this.entidade.ficha) as FichaDespesa
    )?.recurso;
    this.aplicacao = (
      (this.entidadeForm.get("ficha").value ||
        this.entidade.ficha) as FichaDespesa
    )?.aplicacao;
    this.unidade = (
      (this.entidadeForm.get("ficha").value ||
        this.entidade.ficha) as FichaDespesa
    )?.executora;
  }

  public async confirm(mensagem: string, titulo: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: mensagem,
        header: titulo,
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        key: "compra",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
      });
    });
  }

  public onKeyFavorecido(event) {
    if (
      event.code !== "Enter" &&
      this.login.parametro["compras"].bloqueio_razaosocial
    ) {
      if (!this.funcaoService.isNumerico(event.key)) {
        let text: string = this.favorecidoInput.inputEL.nativeElement.value;
        this.favorecidoInput.inputEL.nativeElement.value = text.replace(
          /[a-z]/i,
          ""
        );
      }
    }
  }

  permitirEditar(): boolean {
    const contrato: Contrato = this.entidadeForm.get('contrato').value;
    const licitacao: Licitacao = this.entidadeForm.get('licitacao').value;
    const processo: string = this.entidadeForm.get('processo').value;
    const subelemento: Despesa = this.entidadeForm.get('subelemento').value;

    if (licitacao?.tipo_licitacao === 5) return false // licitação julgada por taxa permite edição
    else this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(params => params.find(p => { if (p.path === 'editar' || p.path === 'novo') { this.alterarValorTotal = true } }));
    if (contrato?.id) return true
    if (processo) return true
    if (!subelemento) return true

    return false
  }

  public salvar() {
    const licitacao = this.entidadeForm.get('licitacao').value;
    const contrato = this.entidadeForm.get('contrato').value
    if (licitacao && licitacao.tabela_desconto && (licitacao.tipo_licitacao === 5 || licitacao.natureza === 3) && !this.entidadeForm.get('tabelas_desconto').value) {
      let tabelas = []
      if (this.entidade?.id) {
        this.compraTabelaService.filtrar(1, -1, { 'compra.id': this.entidade?.id, relations: 'compra.itens,memorial' })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((response) => {
            tabelas = tabelas.concat(response.content)
            if (tabelas.length > 0) {
              this.submitForm()
            } else {
              this.contratoItemService.filtrar(1, -1, { 'contrato.id': contrato.id, relations: 'memorial,memorial.produto_unidade,memorial.produto_unidade.produto,memorial.produto_unidade.produto.material', orderBy: 'memorial.ordem' })
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((itens) => {
                  this.tabelasDesconto = itens.content.map((c) => {
                    let item = new ContratoItem();
                    item.memorial = c.memorial;
                    item.descricao = c.descricao;
                    item.produto_unidade = c.produto_unidade;

                    //validar bloqueio de seleção de item
                    let mensagem_bloqueio = c.bloqueado ? `Produto ${item.produto_unidade.produto.nome} está bloqueado.` : null;
                    if (mensagem_bloqueio) {
                      item.bloqueado = true;
                      item["mensagem_bloqueio"] = mensagem_bloqueio;
                    }
                    return item;
                  });
                });
              this.visualizarTabelasDesconto = true
            }
          });
      } else {
        this.contratoItemService.filtrar(1, -1, { 'contrato.id': contrato.id, relations: 'memorial,memorial.produto_unidade,memorial.produto_unidade.produto,memorial.produto_unidade.produto.material', orderBy: 'memorial.ordem' })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((itens) => {
            this.tabelasDesconto = itens.content.map((c) => {
              let item = new ContratoItem();
              item.memorial = c.memorial;
              item.descricao = c.descricao;
              item.produto_unidade = c.produto_unidade;

              //validar bloqueio de seleção de item
              let mensagem_bloqueio = c.bloqueado ? `Produto ${item.produto_unidade.produto.nome} está bloqueado.` : null;
              if (mensagem_bloqueio) {
                item["bloqueado"] = true;
                item["mensagem_bloqueio"] = mensagem_bloqueio;
              }
              return item;
            });
          });
        this.visualizarTabelasDesconto = true
      }
    } else {
      this.submitForm();
    }
  }

  public salvarTabelas(tabelasSelecionadas?: any[]) {
    this.visualizarTabelasDesconto = false;
    this.tabelasSelecionadas = tabelasSelecionadas;
    this.entidadeForm.get('tabelas_desconto').setValue(this.tabelasSelecionadas);
    this.submitForm();
  }

  public async carregarConvenio() {
    if (!this.entidadeForm.get('ficha').value)
      throw new Error(`sem ficha`)
    const ficha: FichaDespesa = this.entidadeForm.get('ficha').value;

    const parametros = {
      'OR_1': `recurso_id=${ficha?.recurso?.id};!;!;recurso_contra_id=${ficha?.recurso?.id}`,
      'OR_2': `aplicacao_id=${ficha?.aplicacao?.id};!;!;aplicacao_contra_id=${ficha?.aplicacao?.id}`,
      // 'convenio.ano': this.login.exercicio.ano,
      'convenio.orgao_id': this.login.orgao.id,
      relations: 'convenio.favorecido.tipo,convenio.aditamento,convenio.tipo_convenio'
    };

    if (ficha?.aplicacao_variavel?.id) {
      parametros['OR_3'] = `aplicacao_variavel_id=${ficha?.aplicacao_variavel?.id};!;!;aplicacao_variavel_contra_id=${ficha?.aplicacao_variavel?.id}`;
    }

    const convenio = await this.convenioRecursoService.filtrar(1, -1, parametros).toPromise();
    if (convenio.content.length > 0) {
      this.entidadeForm.get('convenio').setValue(convenio.content[0].convenio);
      this.numeroConvenio = `${this.entidadeForm.get('convenio')?.value?.numero}/${this.entidadeForm.get('convenio')?.value?.ano}`;
      this.entidadeForm.get('favorecido').setValue(convenio.content[0].convenio.favorecido);
      this.possuiFavConvenio = true;
      this.saldoConvenio = await this.convenioService.buscarSaldoConvenio(
        this.entidadeForm.get('convenio').value?.id,
        this.funcaoService.converteDataSQL(this.entidadeForm.get('data_compra').value),
        this?.login?.orgao?.id
      ).toPromise();
    } else {
      this.entidadeForm.get('convenio').setValue(null);
      this.numeroConvenio = ""
      this.possuiFavConvenio = false;
    }
  }

  // Autocomplete do aditamento
  private carregarAditamentoAutoComplete(): void {
    let contrato_id = null;
    if (this.entidadeForm.get('contrato').value.id) {
      contrato_id = +this.entidadeForm.get('contrato').value.id
    } else {
      contrato_id = 0;
    }

    this.aditamentoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('contrato_aditamento'), this.aditamentoService,
      'id', ['numero'],
      {
        contrato_id,
        data_termino$ge: this.funcaoService.converteDataSQL(this.entidadeForm.get('data_compra').value),
      },
      {
        text: ['numero']
      });
  }

  private async findAditamentos(): Promise<Page> {
    let contrato_id = null;
    if (this.entidadeForm.get('contrato').value) {
      contrato_id = +this.entidadeForm.get('contrato').value.id
    } else {
      contrato_id = 0;
    }

    const data = this.entidadeForm.get('data_compra').value;

    if (!data) {
      return { content: [] } as Page;
    }

    return await this.aditamentoService.filtrar(1, 0, {
      contrato_id,
      data_termino$ge: this.funcaoService.converteDataSQL(data),
    }).toPromise();
  }

  public async setAditamento(): Promise<void> {
    try {
      this.entidadeForm.get('contrato_aditamento').setValue(null);
      this.aditamentoAutoComplete.id = null;

      const aditamentos = await this.findAditamentos();

      if (!aditamentos.content.length) {
        this.naoExisteAditamento = true;
      } else if (aditamentos.content.length === 1) {
        this.naoExisteAditamento = true;
        this.carregarAditamentoAutoComplete();
        this.entidadeForm.get('contrato_aditamento').setValue(aditamentos.content[0]);
      } else {
        this.naoExisteAditamento = false;
        this.carregarAditamentoAutoComplete();
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
        toastr.error(err.message, 'Erro ao buscar aditamentos')
      }
    }
  }

  private async validarDisponibilidadeRecurso(ficha: FichaDespesa) {
    let recursoDisponibilidade = await this.recursoDisponibilidadeService.obter({ 'recurso_despesa.codigo': ficha.recurso.codigo, 'aplicacao_despesa.codigo': ficha?.aplicacao?.codigo, 'aplicacao_variavel_despesa.codigo': ficha.aplicacao_variavel.codigo, 'exercicio.id': this.login.exercicio?.id }).toPromise();
    if (!recursoDisponibilidade?.id) {
      this.entidadeForm.get("ficha").setValue(null);
      this.fichaAutoComplete.id = null;
      this.entidadeForm.get("subelemento").setValue(null);
      this.subElementoAutoComplete.id = null;
      this.entidadeForm.get("convenio").setValue(null);
      this.numeroConvenio = null;
      toastr.warning(`Por gentileza cadastre um de-para de disponibilidade de recursos \n ou entre em contato com contador responsável.`)
      return;
    } else if (!recursoDisponibilidade?.ativo && recursoDisponibilidade?.id) {
      this.entidadeForm.get("ficha").setValue(null);
      this.fichaAutoComplete.id = null;
      this.entidadeForm.get("subelemento").setValue(null);
      this.subElementoAutoComplete.id = null;
      this.entidadeForm.get("convenio").setValue(null);
      this.numeroConvenio = null;
      toastr.warning(`Por gentileza verifique o de-para de disponibilidade de recursos \n ou entre em contato com contador responsável.`)
      return;
    }
  }
}
