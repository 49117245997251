import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginContabil } from 'eddydata-lib';

declare var $: any;

@Component({
  selector: 'rcms-tabela-desconto-dlg',
  templateUrl: './rcms-tabela-desconto-dlg.component.html'
})
export class RcmsTabelasDescontoDlg implements OnInit {

  @Input() public login: LoginContabil;

  @Input() public tabelasDesconto: any[] = [];
  @Input() public compra: any
  @Input() public processo: string;
 

  @Input() visualizar: boolean = false;
  @Output() visualizarChange: EventEmitter<boolean> = new EventEmitter();

  @Output() callback: EventEmitter<any[]> = new EventEmitter();

  @Input() static : boolean = false;

  public aviso: string = '';

  ngOnInit(): void { }

  public onHide() {
    this.hide();
  }

  public show() {
    this.visualizar = true;
    this.visualizarChange.emit(this.visualizar);
  }

  public hide() {
    this.visualizar = false;
    this.visualizarChange.emit(this.visualizar);
  }

  public todosSelecionados() {
    if (!this.tabelasDesconto)
        return
    const selecionados = []
    this.tabelasDesconto.filter((elemento) => {
        if(elemento['selecionado']) {
            selecionados.push(elemento)
        }
    })

    return selecionados.length === this.tabelasDesconto.length
  }

  public selecionarTodos() {
    if (this.todosSelecionados()) {
        this.tabelasDesconto.filter((elemento) => elemento['selecionado'] = false)
    } else {
        this.tabelasDesconto.filter((elemento) => elemento['selecionado'] = true)
    }
    
  }

  public validSelect(item: any) {
    return item['selecionado'] === true
  }

  public selecionar(item: any) { 
    if (item['selecionado'] === true) {
        item['selecionado'] = false
    } else {
        item['selecionado'] = true
    }
  }

  public salvar() {
    
    const selecionados = this.tabelasDesconto.filter((elemento) => {
      return elemento['selecionado'] === true
    })
    
    this.callback.emit(selecionados);
  }

  public print() {
    console.log(this.tabelasDesconto);
    console.log(this.compra);
  }

  public bloqueio() {
    if (!this.tabelasDesconto || this.tabelasDesconto.length === 0)
      return false;
    
    for (const item of this.tabelasDesconto) {
      if (item['mensagem_bloqueio']) {
        this.aviso = item['mensagem_bloqueio'];
        return true;
      }
    }
  }
}
