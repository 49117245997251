import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { BaseResourceService, ContaBancaria, ContaBancariaRecurso, Pagamento, Retencao, Usuario } from 'eddydata-lib';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService extends BaseResourceService<Pagamento> {

  constructor(
    protected injector: Injector
  ) {
    super(`pagamentos`, injector);
  }

  public obterPorNumero(numero: number, ano: number, orgaoId: number): Observable<Pagamento> {
    return this.http.get<Pagamento>(
      `${this.login.cidade.id}/${this.api}?numero=${numero}&exercicio_ano=${ano}&orgao_id=${orgaoId}`).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public atualizarDataAnulacao(id: number, data: string, orgao_id: number): Observable<any> {
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/data-anulacao/${id}/${data}/${orgao_id}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err)))
  }

  public totalPagoAno(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(`${this.login.cidade.id}/${this.api}/total-pago-ano/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalPagoMes(exercicio: number, orgaoId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${this.login.cidade.id}/${this.api}/total-pago-mes/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public totalFavorecidoMes(exercicio: number, favorecido: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${this.login.cidade.id}/${this.api}/total-favorecido-mes/${exercicio}/${favorecido}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalPagoPorEmpenho(numero: number, exercicioId: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-pago-por-empenho/${numero}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalPagoPorLiquidacaoId(liquidacao: number): Observable<any> {
    return this.http.get(
      `${this.login.cidade.id}/${this.api}/total-pago-por-liquidacao/${liquidacao}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public obterTotalPagoAnuladoPorEmpenho(numero: number, exercicioId: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-pago-anulado-por-empenho/${numero}/${exercicioId}/${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public totalPagoPorConvenio(convenioId: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/total-pago-convenio?convenio=${convenioId}&orgao=${orgaoId}`,
      this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public anular(id: number, data: string, valor: number, historico: string, usuario: Usuario, conta: ContaBancaria, recurso: ContaBancariaRecurso, retencoes, desconsiderarAdiantamento: Boolean): Observable<Pagamento> {
    const anulacao = { id, data, valor, historico, usuario, conta, recurso, retencoes, desconsiderarAdiantamento };
    return this.http.post<Pagamento>(
      `${this.login.cidade.id}/${this.api}/anular`, JSON.stringify(anulacao), {
      headers: new HttpHeaders(this.adicionarCriptografia({
        'Content-Type': 'application/json',
        Authorization: this.login.token
      }))
    });
  }

  public ultimaDataPagamento(exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/ultima-data-pagamento/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public acertarReceitaRetencoes(exercicio: number, orgao: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/acertar-receitas-retencoes/${exercicio}/${orgao}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }


  public numeroBoletim(data: string, exercicio: number, orgaoId: number): Observable<number> {
    return this.http.get<number>(
      `${this.login.cidade.id}/${this.api}/numero-boletim-pagamento/${data}/${exercicio}/${orgaoId}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public boletimPagamento(filtros: {}): Observable<Array<any>> {
    let parametros = '';
    if (filtros) {
      for (const key in filtros) {
        if (parametros === '') {
          parametros += '?';
        } else {
          parametros += '&';
        }
        parametros += key + '=' + filtros[key];
      }
    }
    return this.http.get<any>(`${this.login.cidade.id}/${this.api}/boletim-pagamento${parametros}`).pipe(
      map(res => res),
      catchError(err => this.handleError(err))
    );
  }

  public pagamentoEmLote(parametros: {
    numero_inicial: number, numero_final: number, data_pagamento: string, documento: string,
    exercicio: number, orgao: number, conta: number, favorecido: number, ignorar?: { numero: number, parcela: number }[]
  }): Observable<{ numero: number, parcela: number, erro?: string }[]> {
    return this.http.post<{ numero: number, parcela: number, erro?: string }[]>(`${this.login.cidade.id}/${this.api}/pagamento-lote`,
      parametros, this.httpOptions());
  }

  public ordemCronologica(exercicio: number, orgao: number, dataInicial: string, dataFinal: string): Observable<{}[]> {
    let params = `?dataInicial=${dataInicial}&dataFinal=${dataFinal}`;
    return this.http.get<{}[]>(
      `${this.login.cidade.id}/${this.api}/ordem-cronologica/${exercicio}/${orgao}${params}`, this.httpOptions()).pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      );
  }

  public validarParcelas(numero_inicial: number, numero_final: number, exercicio: number, orgao: number, favorecido?: number): Observable<{ numero: number, parcela: number, saldo_pagar: number }[]> {
    return this.http.get<{ numero: number, parcela: number, saldo_pagar: number }[]>(`${this.login.cidade.id}/${this.api}/duplicidade-pagamento-lote/${exercicio}/${orgao}/${numero_inicial}/${numero_final}/${favorecido}`, this.httpOptions());
  }

  public conferenciaPagamentoDiario(filtros: any) {
    let parametros = ''
    for (const key in filtros) {
      if (parametros === '') {
        parametros += '?';
      } else {
        parametros += '&';
      }
      parametros += key + '=' + encodeURIComponent(filtros[key]);
    }

    return this.http.get<any[]>(`${this.login.cidade.id}/${this.api}/conferencia-pagamento-diario${parametros}`, this.httpOptions())
      .pipe(
        map(res => res),
        catchError(err => this.handleError(err))
      )
  }

  public atualizarSituacaoReinf(pagamento: Pagamento): Observable<void> {
    return this.http.post<void>(`${this.login.cidade.id}/${this.api}/atualizar-situacao-reinf/${pagamento.id}/${pagamento.situacao}`, {}, this.httpOptions()).pipe(
      catchError(err => this.handleError(err))
    );
  }
}
