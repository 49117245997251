import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MemorialService } from "administrativo-lib";
import {
  Assinatura,
  AssinaturaService,
  BaseResourceRptPersonalizadoComponent,
  FuncaoService,
  GlobalService,
  LicitacaoPipe,
  Rcms,
  RcmsCotacao,
  RcmsFavorecido,
  RcmsItem,
} from "eddydata-lib";
import { RcmsFavorecidorService } from "../../service/rcms-favorecido.service";
import * as toastr from 'toastr';

@Component({
  selector: "lib-imprimir-nota-rcms-rpt",
  templateUrl: "./imprimir-nota-rcms-rpt.component.html",
})
export class ImprimirNotaRcmsRpt extends BaseResourceRptPersonalizadoComponent {
  @Input() rcms: Rcms[];
  @Input() mostrarDescricao: boolean = false;

  @Output() eventoPreencheAssinaturas: EventEmitter<{
    assinatura: Assinatura;
    listaAssinaturas: Assinatura[];
  }> = new EventEmitter();

  private qtdLinhaFavorecidos = 0;

  public favorecidos: RcmsFavorecido[];

  private qtdLinhaObservacao: number = 0;

  constructor(
    public memorialService: MemorialService,
    public assinaturaService: AssinaturaService,
    private rcmsFavorecidoService: RcmsFavorecidorService
  ) {
    super(assinaturaService);
    this.assinaturaObrigatoria = false;
    this.removerCabecalho = true;
    this.orientacao = "portrait";
  }

  ngOnInit(): void {
    this.funcaoService = new FuncaoService();
    this.assinaturaService
      .filtrar(1, -1, {
        orgao_id: this.login.orgao.id,
        ativo: true,
        sistema: this.login.sistema,
        relations: "pessoas.pessoa",
        orderBy: "nome",
      })
      .subscribe(
        (res) => {
          this.listAssinatura = res ? res.content : [];
          this.eventoPreencheAssinaturas.emit({
            assinatura: this.assinatura,
            listaAssinaturas: this.listAssinatura,
          });
        },
        (error) => toastr.error(error.message ? error.message : error)
      );
  }

  protected afterInit(): void { }

  protected modalRelatorio(): string {
    return "";
  }

  public tituloRelatorio(): string {
    if (!this.rcms || this.rcms.length === 0)
      return '';
    return 'Requisição ' + (this.rcms.length > 1 ?
      `(${this.rcms.map((r) => r.numero).join(',')})` :
      this.rcms[0].numero);
  }

  protected ocultarCabecalho(): boolean {
    return true;
  }

  protected ocultarRodape(): boolean {
    return true;
  }

  protected layoutRelatorio(): {} {
    return {
      linhas: {
        hLineWidth() {
          return 1;
        },
        vLineWidth() {
          return 1;
        },
        hLineColor() {
          return 'black';
        },
        paddingLeft() {
          return 3;
        },
        paddingRight() {
          return 3;
        }
      }
    };
  }

  protected async montarConteudo(): Promise<{}[]> {
    const conteudo: {}[] = [];
    return await new Promise(async (resolve) => {
      for (const entidade of this.rcms) {
        if (entidade?.favorecidos?.length > 3) {
          this.orientacao = 'landscape'
          if (conteudo.length > 0) {
            conteudo.push([{ text: '', pageBreak: 'after' }]);
          }
          conteudo.push(this.dadosCabecalho2()
            .concat(this.dadosRcms2(entidade))
            .concat(this.dadosDestino2(entidade))
            .concat(entidade.ficha ? this.dadosClassificacao2(entidade) : [])
            .concat(this.dadosItens2(entidade))
            .concat(this.dadosTotalizacao2(entidade))
            .concat(this.dadosAssinatura2(entidade)));
        } else {
          this.orientacao = 'portrait'
          if (conteudo.length > 0) {
            conteudo.push([{ text: '', pageBreak: 'after' }]);
          }
          conteudo.push(this.dadosCabecalho()
            .concat(this.dadosRcms(entidade))
            .concat(this.dadosDestino(entidade))
            .concat(entidade.ficha ? this.dadosClassificacao(entidade) : [])
            .concat(this.dadosItens(entidade))
            .concat(this.dadosTotalizacao(entidade))
            .concat(this.dadosAssinatura(entidade)));
        }
      }
      resolve(conteudo);
    });
  }

  private dadosCabecalho(): {}[] {
    let brasaoImage: {};
    if (this.login.brasao) {
      brasaoImage = {
        image: this.login.brasao,
        width: 70,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [true, true, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, false, false] };
    }

    brasaoImage['rowSpan'] = 5;

    const conteudo = [];

    conteudo.push([brasaoImage, { text: this.login.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, true, true, false] }]);
    conteudo.push([
      { text: '' },
      {
        text: `${this.login.orgao.endereco}, ${this.login.orgao.cidade ? this.login.orgao.cidade : ''} - CEP: ${this.login.orgao.cep} - PABX: ${this.login.orgao.telefone} - FAX: ${this.login.orgao.telefone}`,
        fontSize: 8, alignment: 'center', border: [false, false, true, false]
      }
    ]);
    conteudo.push([
      { text: '' },
      { text: `C.N.P.J.: ${this.login.orgao.cnpj}     -    Site: ${this.login.orgao.site}`, fontSize: 8, alignment: 'center', border: [false, false, true, false] }
    ]);
    conteudo.push([
      { text: '' },
      { text: 'REQUISIÇÃO DE COMPRAS PRODUTOS E SERVIÇOS', bold: true, alignment: 'center', fontSize: 13, border: [false, false, true, false] }
    ]);
    conteudo.push([
      { text: '' },
      { text: this.login.exercicio.ano, bold: true, alignment: 'center', fontSize: 12, border: [false, false, true, false] }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: [50, '*'],
        body: conteudo
      }
    }];
  }

  private dadosRcms(rcms: Rcms): {}[] {
    const tipo_rcms = new GlobalService().obterListaTiposEmpenhos().find(x => x.id === rcms.tipo_rcms);
    const conteudo = [
      [
        {
          text: 'Requisição Nº ' + this.funcaoService.strZero(rcms.numero, 4),
          rowSpan: 2, fontSize: 8, alignment: 'left', bold: true, margin: [0, 13, 10, 10]
        },
        { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
        { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Data', fontSize: 7, border: [true, true, false, false] },
        { text: 'Licitação', fontSize: 7, border: [true, true, false, false] },
        { text: 'Processo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Contrato', fontSize: 7, border: [true, true, false, false] },
        { text: 'Modalidade', fontSize: 7, border: [true, true, true, false] }
      ],
      [
        { text: '' },
        { text: rcms.ficha ? rcms.ficha?.numero : '-', alignment: 'center', bold: true, border: [false, false, false, true] },
        {
          text: tipo_rcms ? tipo_rcms.nome : '-',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: this.funcaoService.converteDataBR(rcms.data_rcms),
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.licitacao ? new LicitacaoPipe().transform(rcms.licitacao?.numero) : '',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.processo ? this.funcaoService.mascarar('00000/0000', rcms.processo?.replace('/', '')) : '',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.contrato ? new LicitacaoPipe().transform(rcms.contrato?.numero) : '',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.modalidade?.nome,
          alignment: 'center', fontSize: 5, bold: true, border: [true, false, true, true]
        },
      ]];

    return [{
      layout: 'linhas',
      table: {
        widths: [90, 25, 50, 40, 40, 40, 40, '*'],
        body: conteudo
      }
    }];
  }

  private dadosDestino(rcms: Rcms): {}[] {
    let favorecidos = this.getFavorecidos3(rcms);
    let caracteres = 0;
    //Calculo para saber quantas linhas tem o favorecido.
    for (const f of favorecidos) {
      caracteres += f.favorecido.nome.length + f.favorecido.cpf_cnpj.length + 27;
    }
    this.qtdLinhaFavorecidos = Math.ceil(caracteres / 70);

    const conteudo = [
      [
        { text: 'ESTOQUE:', border: [true, false, false, false], bold: true },
        { text: `${rcms.estoque ? rcms.estoque.nome : ''}`, border: [false, false, false, false], bold: true },
        {
          text: favorecidos.map((f, i) => `Fornecedor ${i > 8 ? i + 1 : `0${i + 1}`}: ${f.favorecido.nome} - CNPJ/CPF: ${f.favorecido.cpf_cnpj}`).join('\n'),
          border: [false, false, true, true], rowSpan: 3, fontSize: 8
        },
      ],
      [
        { text: 'SETOR:', border: [true, false, false, false] },
        { text: `${rcms.setor ? rcms.setor.nome : ''}`, border: [false, false, false, false] },
        { text: '' }
      ],
      [
        { text: 'REQUERENTE:', border: [true, false, false, true] },
        { text: `${rcms.requerente ? rcms.requerente.nome.toUpperCase() : ''} `, border: [false, false, false, true] },
        { text: '' }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        widths: [60, 150, '*'],
        body: conteudo
      }
    }];
  }

  private dadosClassificacao(rcms: Rcms): {}[] {
    const conteudo = [
      [
        { text: 'UNIDADE:', border: [true, false, false, false] },
        {
          text: `${rcms.ficha?.executora?.unidade?.codigo} ${rcms.ficha?.executora?.unidade?.nome}`,
          border: [false, false, true, false]
        },
      ],
      [
        { text: 'EXECUTORA:', border: [true, false, false, false] },
        { text: `${rcms.ficha?.executora?.codigo} ${rcms.ficha?.executora?.nome} `, border: [false, false, true, false] },
      ],
      [
        { text: 'DESPESA:', border: [true, false, false, false] },
        { text: `${rcms.ficha?.despesa?.codigo} ${rcms.ficha?.despesa?.nome} `, border: [false, false, true, false] },
      ],
      [
        { text: 'SUBELEMENTO:', border: [true, false, false, false] },
        { text: `${rcms.subelemento?.codigo} ${rcms.subelemento?.nome} `, border: [false, false, true, false], }
      ],
      [
        { text: 'RECURSO:', border: [true, false, false, false] },
        {
          text: `${rcms.ficha?.recurso?.codigo} ${rcms.ficha?.aplicacao?.codigo} ${rcms.ficha?.aplicacao?.nome}`,
          border: [false, false, true, false]
        }
      ],
      [
        { text: 'PROJETO:', border: [true, false, false, false] },
        { text: `${rcms.ficha?.acao?.codigo} ${rcms.ficha?.acao?.nome} `, border: [false, false, true, false] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        headerRows: 2,
        widths: [60, '*'],
        body: conteudo
      }
    }];
  }

  private getFavorecidos3(rcms: Rcms) {
    return rcms.favorecidos ? rcms.favorecidos
      .sort((a, b) => {
        let cotacoesA = this.cotacaoFavorecido(rcms.itens, a).length > 0;
        let cotacoesB = this.cotacaoFavorecido(rcms.itens, b).length > 0;
        if (cotacoesA && !cotacoesB)
          return -1;
        else if (!cotacoesA && cotacoesB)
          return 1;
        else
          return a.id - b.id;
      })
      .slice(0, 3) : [];
  }

  private cotacaoFavorecido(itens: RcmsItem[], favorecido: RcmsFavorecido): RcmsCotacao[] {
    let cotacoes = [];
    if (itens)
      for (let item of itens) {
        let cotacao = item.cotacoes ? item.cotacoes.find((c) => c.rcmsFavorecido.id === favorecido.id) : null;
        if (cotacao)
          cotacoes.push(cotacao);
      }
    return cotacoes;
  }

  private dadosItens(rcms: Rcms): {}[] {

    let conteudo = [];

    let favorecidos = this.getFavorecidos3(rcms);

    const titulos: any[] = [
      { text: 'QUANTIDADE', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
      { text: 'CÓDIGO', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
      { text: 'UN', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
      { text: 'DESCRIÇÃO DO ITEM', alignment: 'center', bold: true, margin: [0, 5, 0, 5] }
    ];

    conteudo.push(titulos.concat(favorecidos.map((f, i) => {
      return { text: `FORNECEDOR ${i > 8 ? i + 1 : `0${i + 1}`}`, alignment: 'center', bold: true };
    })));

    favorecidos = favorecidos.map((f) => {
      f['valor_total'] = 0;
      return f;
    });

    for (let item of rcms.itens) {
      const linha = [
        { text: this.funcaoService.convertToBrNumber(item.quantidade), alignment: 'right', border: [true, false, true, false] },
        { text: item.produto_unidade?.produto?.codigo, alignment: 'center', border: [true, false, true, false] },
        { text: item.produto_unidade?.unidade?.nome, alignment: 'center', border: [true, false, true, false] },
        { text: this.mostrarDescricao ? (item.produto ?? item.produto_unidade?.produto?.descricao) : item.produto_unidade?.produto?.nome, alignment: 'left', border: [true, false, true, false] },
      ];

      for (let favorecido of favorecidos) {
        let cotacao = item.cotacoes.find((c) => c.rcmsFavorecido.id === favorecido.id);
        if (cotacao) {
          // cotacao.valor_unitario = +(+cotacao.valor_unitario).toFixed(4);
          favorecido['valor_total'] += +(item.quantidade * +cotacao.valor_unitario).toFixed(6);
        }
        linha.push({
          text: cotacao ?
            this.funcaoService.convertToBrNumber(+cotacao.valor_unitario, 6)
            : '', alignment: 'right', border: [true, false, true, false]
        });
      }

      conteudo.push(linha);
    }

    this.qtdLinhaObservacao = Math.ceil(rcms.observacao ? rcms.observacao.length / 120 : 0);
    let linhas = 55 - (3 * conteudo.length) - this.qtdLinhaFavorecidos;
    if (linhas > 0) {
      conteudo = conteudo.concat(Array.from(Array(linhas).keys()).map(_ =>
        Array.from(Array(4 + favorecidos.length).keys()).map((_) => { return { text: '', border: [true, false, true, false] }; })
      ));
    }

    const total: any[] = [
      { text: 'TOTAL**', colSpan: 4, border: [true, true, true, true] }, { text: '' }, { text: '' }, { text: '' }
    ];


    conteudo.push(total.concat(favorecidos.map((f) => {
      return { text: this.funcaoService.arredondar(+f['valor_total'], 2).toFixed(2), alignment: 'right', border: [true, true, true, true] };
    })));


    return [{
      layout: 'linhas',
      table: {
        dontBreakRows: true,
        widths: (<any[]>['auto', 'auto', 'auto', '*']).concat(favorecidos.map(_ => 60)),
        body: conteudo
      }
    }];
  }

  private dadosTotalizacao(rcms: Rcms): {}[] {
    let valor_total = 0;
    // let qtd_total = 0;
    let menor_global = 0;

    for (let item of rcms.itens) {
      let menor = 0
      let qntCotaValidas = 0
      let valorSomadoCotaValidas = 0

      if (item.cotacoes && item.cotacoes.length > 0) {
        let cotacao_ordenada = item.cotacoes
          .sort((a, b) => a.valor_unitario <= 0 ? -1 : (a.valor_unitario - b.valor_unitario))
        menor = cotacao_ordenada.find(a => a.valor_unitario > 0).valor_unitario
      }
      menor_global += +(menor * item.quantidade).toFixed(6);

      item.cotacoes.map(cotacao => {
        if (cotacao.valor_unitario && +cotacao.valor_unitario > 0) {
          qntCotaValidas++
          valorSomadoCotaValidas += +(cotacao.valor_unitario * item.quantidade).toFixed(6);
        }
      })

      valor_total += +valorSomadoCotaValidas / +qntCotaValidas
      // let menor = item.cotacoes && item.cotacoes.length > 0 ? item.cotacoes
      //   .sort((a, b) => a.valor_unitario <= 0 ? -1 : (a.valor_unitario - b.valor_unitario)).find(a => a.valor_unitario > 0) : 0

      // for (let cotacao of item.cotacoes) {
      //   if (cotacao.valor_unitario && cotacao.valor_unitario > 0) {
      //     valor_total += +cotacao.valor_unitario * +item.quantidade;
      //     qtd_total++;
      //   }
      // }
    }

    const conteudo: any[] = [
      [{
        text: `APURAÇÃO TOTAL DE MENOR CUSTO:  R$        ${this.funcaoService.arredondar(menor_global, 2).toFixed(2)}`,
        border: [true, false, true, false], bold: true
      },],
      [{
        // text: `APURAÇÃO TOTAL DE CUSTO MÉDIO:  R$        ${this.funcaoService.convertToBrNumber(qtd_total > 0 ? (valor_total / qtd_total) : 0)}`,
        // border: [true, false, true, false], bold: true
        text: `APURAÇÃO TOTAL DE CUSTO MÉDIO:  R$        ${this.funcaoService.arredondar(valor_total, 2).toFixed(2)}`,
        border: [true, false, true, false], bold: true
      },],
      [{ text: `Observação:`, border: [true, false, true, false], margin: [0, 0, 0, !rcms.observacao ? 8 : 0] }],
      [{ text: rcms.observacao, border: [true, false, true, false] }]
    ];

    return [{
      layout: 'linhas',
      table: {
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  private dadosAssinatura(rcms: Rcms): {}[] {
    let assinaturas = [];
    // if (this.assinatura) {
    //if (assinaturas?.length === 0) return [{}];
    // }
    if (this.assinatura)
      assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
    if (this.assinatura && assinaturas?.length > 0) {

      const conteudo = [
        [{ text: 'Esta requisição não autoriza o fornecedor a entregar produto/serviço', margin: [0, 0, 0, 0], border: [true, true, true, false] }],
        [{ text: rcms.excluido === false && rcms.bloqueado ? 'REQUISIÇÃO BLOQUEADA PARA ANÁLISE DA DIVISÃO DE COMPRAS' : "", fontSize: 13, alignment: 'center', bold: true, margin: [0, 0, 0, 30], border: [true, true, true, false] }],
        [{ columns: [this.imprimirAssinaturas(assinaturas, 10)], border: [true, false, true, false] }],
        [{ text: [{ text: 'Operador:   ', bold: true }, { text: rcms.operador?.nome }], margin: [0, 0, 0, 0], border: [true, false, true, true] }]
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: false,
          widths: ['*'],
          body: conteudo
        }
      }];
    } else {
      const assinaturaPadrao = [
        [{ text: 'Esta requisição não autoriza o fornecedor a entregar produto/serviço', margin: [0, 0, 0, 0], border: [true, true, true, false] }],
        [{ text: rcms.excluido === false && rcms.bloqueado ? 'REQUISIÇÃO BLOQUEADA PARA ANÁLISE DA DIVISÃO DE COMPRAS' : "", fontSize: 13, bold: true, alignment: 'center', margin: [0, 0, 0, 30], border: [true, true, true, false] }],
        [{ canvas: [{ type: 'line', x1: this.orientacao === 'portrait' ? 7 : 53, y1: 0, x2: this.orientacao === 'portrait' ? 167 : 213, y2: 0, lineWidth: 0.5 }, { type: 'line', x1: this.orientacao === 'portrait' ? 187 : 312, y1: 0, x2: this.orientacao === 'portrait' ? 347 : 472, y2: 0, lineWidth: 0.5 }, { type: 'line', x1: this.orientacao === 'portrait' ? 365 : 570, y1: 0, x2: this.orientacao === 'portrait' ? 525 : 730, y2: 0, lineWidth: 0.5 }], border: [true, false, true, false] }],
        [{
          columns: [
            { text: 'Requerente', alignment: 'center', margin: [0, 3, 0, 16], border: [true, false, true, false] },
            { text: 'Secretário Requisitante', alignment: 'center', margin: [0, 3, 0, 16], border: [true, false, true, false] },
            { text: 'Secretário de Finanças', alignment: 'center', margin: [0, 3, 0, 16], border: [true, false, true, false] },
          ], border: [true, false, true, false]
        },],
        [{ text: [{ text: 'Operador:   ', bold: true }, { text: rcms.operador?.nome }], margin: [0, 0, 0, 0], border: [true, false, true, true] }]
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: false,
          widths: ['*'],
          body: assinaturaPadrao
        }
      }];
    }

  }

  /////////////////////////////////////////// Relatorio com mais de 4 favorecidos //////////////////////////////////

  //Não mexer
  private dadosCabecalho2(): {}[] {
    let brasaoImage: {};
    if (this.login.brasao) {
      brasaoImage = {
        image: this.login.brasao,
        width: 70,
        alignment: 'left',
        margin: [0, 0, 0, 0],
        border: [true, true, false, false]
      };
    } else {
      brasaoImage = { margin: [0, 10, 0, 0], text: '', border: [true, true, false, false] };
    }

    brasaoImage['rowSpan'] = 5;

    const conteudo = [];

    conteudo.push([brasaoImage, { text: this.login.orgao.nome, bold: true, alignment: 'center', fontSize: 13, border: [false, true, true, false] }]);
    conteudo.push([
      { text: '' },
      {
        text: `${this.login.orgao.endereco}, ${this.login.orgao.cidade ? this.login.orgao.cidade : ''} - CEP: ${this.login.orgao.cep} - PABX: ${this.login.orgao.telefone} - FAX: ${this.login.orgao.telefone}`,
        fontSize: 8, alignment: 'center', border: [false, false, true, false]
      }
    ]);
    conteudo.push([
      { text: '' },
      { text: `C.N.P.J.: ${this.login.orgao.cnpj}     -    Site: ${this.login.orgao.site}`, fontSize: 8, alignment: 'center', border: [false, false, true, false] }
    ]);
    conteudo.push([
      { text: '' },
      { text: 'REQUISIÇÃO DE COMPRAS PRODUTOS E SERVIÇOS', bold: true, alignment: 'center', fontSize: 13, border: [false, false, true, false] }
    ]);
    conteudo.push([
      { text: '' },
      { text: this.login.exercicio.ano, bold: true, alignment: 'center', fontSize: 12, border: [false, false, true, false] }
    ]);

    return [{
      layout: 'linhas',
      table: {
        widths: [50, '*'],
        body: conteudo
      }
    }];
  }

  // ver como ficou
  private dadosRcms2(rcms: Rcms): {}[] {
    const tipo_rcms = new GlobalService().obterListaTiposEmpenhos().find(x => x.id === rcms.tipo_rcms);
    const conteudo = [
      [
        {
          text: 'Requisição Nº ' + this.funcaoService.strZero(rcms.numero, 4),
          rowSpan: 2, fontSize: 8, alignment: 'left', bold: true, margin: [0, 13, 10, 10]
        },
        { text: 'Ficha', fontSize: 7, border: [true, true, false, false] },
        { text: 'Tipo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Data', fontSize: 7, border: [true, true, false, false] },
        { text: 'Licitação', fontSize: 7, border: [true, true, false, false] },
        { text: 'Processo', fontSize: 7, border: [true, true, false, false] },
        { text: 'Contrato', fontSize: 7, border: [true, true, false, false] },
        { text: 'Modalidade', fontSize: 7, border: [true, true, true, false] }
      ],
      [
        { text: '' },
        { text: rcms.ficha ? rcms.ficha?.numero : '-', alignment: 'center', bold: true, border: [false, false, false, true] },
        {
          text: tipo_rcms ? tipo_rcms.nome : '-',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: this.funcaoService.converteDataBR(rcms.data_rcms),
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.licitacao ? new LicitacaoPipe().transform(rcms.licitacao?.numero) : '',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.processo ? this.funcaoService.mascarar('00000/0000', rcms.processo?.replace('/', '')) : '',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.contrato ? new LicitacaoPipe().transform(rcms.contrato?.numero) : '',
          alignment: 'center', fontSize: 6, bold: true, border: [true, false, false, true]
        },
        {
          text: rcms.modalidade?.nome,
          alignment: 'center', fontSize: 5, bold: true, border: [true, false, true, true]
        },
      ]];

    return [{
      layout: 'linhas',
      table: {
        widths: [150, '*', '*', '*', '*', '*', '*', '*'],
        body: conteudo
      }
    }];
  }


  //ele pega os favorecidos aqui, retirar os favorecidos
  private dadosDestino2(rcms: Rcms): {}[] {
    let favorecidos = this.getFavorecidos32(rcms);
    let caracteres = 0;
    //Calculo para saber quantas linhas tem o favorecido.
    for (const f of favorecidos) {
      caracteres += f.favorecido.nome.length + f.favorecido.cpf_cnpj.length + 27;
    }
    this.qtdLinhaFavorecidos = Math.ceil(caracteres / 70);

    const conteudo = [
      [
        { text: 'ESTOQUE:', border: [true, false, false, false], bold: true },
        { text: `${rcms.estoque ? rcms.estoque.nome : ''}`, border: [false, false, false, false], bold: true },
        {
          text: favorecidos.map((f, i) => `Fornecedor ${i > 8 ? i + 1 : `0${i + 1}`}: ${f.favorecido.nome} - CNPJ/CPF: ${f.favorecido.cpf_cnpj}`).join('\n'),
          border: [false, false, true, true], rowSpan: 3, fontSize: 8, alignment: 'right'
        },
      ],
      [
        { text: 'SETOR:', border: [true, false, false, false] },
        { text: `${rcms.setor ? rcms.setor.nome : ''}`, border: [false, false, false, false] },
        { text: '' }
      ],
      [
        { text: 'REQUERENTE:', border: [true, false, false, true] },
        { text: `${rcms.requerente ? rcms.requerente.nome.toUpperCase() : ''} `, border: [false, false, false, true] },
        { text: '' }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        widths: [60, 150, '*'],
        body: conteudo
      }
    }];
  }

  //Não mexer
  private dadosClassificacao2(rcms: Rcms): {}[] {
    const conteudo = [
      [
        { text: 'UNIDADE:', border: [true, false, false, false] },
        {
          text: `${rcms.ficha?.executora?.unidade?.codigo} ${rcms.ficha?.executora?.unidade?.nome}`,
          border: [false, false, true, false]
        },
      ],
      [
        { text: 'EXECUTORA:', border: [true, false, false, false] },
        { text: `${rcms.ficha?.executora?.codigo} ${rcms.ficha?.executora?.nome} `, border: [false, false, true, false] },
      ],
      [
        { text: 'DESPESA:', border: [true, false, false, false] },
        { text: `${rcms.ficha?.despesa?.codigo} ${rcms.ficha?.despesa?.nome} `, border: [false, false, true, false] },
      ],
      [
        { text: 'SUBELEMENTO:', border: [true, false, false, false] },
        { text: `${rcms.subelemento?.codigo} ${rcms.subelemento?.nome} `, border: [false, false, true, false], }
      ],
      [
        { text: 'RECURSO:', border: [true, false, false, false] },
        {
          text: `${rcms.ficha?.recurso?.codigo} ${rcms.ficha?.aplicacao?.codigo} ${rcms.ficha?.aplicacao?.nome}`,
          border: [false, false, true, false]
        }
      ],
      [
        { text: 'PROJETO:', border: [true, false, false, false] },
        { text: `${rcms.ficha?.acao?.codigo} ${rcms.ficha?.acao?.nome} `, border: [false, false, true, false] }
      ],
    ];

    return [{
      layout: 'linhas',
      table: {
        widths: [60, '*'],
        body: conteudo
      }
    }];
  }

  //Ver se vai precisar
  private getFavorecidos32(rcms: Rcms) {
    return rcms.favorecidos ? rcms.favorecidos
      .sort((a, b) => {
        let cotacoesA = this.cotacaoFavorecido2(rcms.itens, a).length > 0;
        let cotacoesB = this.cotacaoFavorecido2(rcms.itens, b).length > 0;
        if (cotacoesA && !cotacoesB)
          return -1;
        else if (!cotacoesA && cotacoesB)
          return 1;
        else
          return a.id - b.id;
      })
      .slice(0, 8) : [];
  }

  //Ver se precisa
  private cotacaoFavorecido2(itens: RcmsItem[], favorecido: RcmsFavorecido): RcmsCotacao[] {
    let cotacoes = [];
    if (itens)
      for (let item of itens) {
        let cotacao = item.cotacoes ? item.cotacoes.find((c) => c.rcmsFavorecido.id === favorecido.id) : null;
        if (cotacao)
          cotacoes.push(cotacao);
      }
    return cotacoes;
  }

  //precisa mexer nas colunas
  private dadosItens2(rcms: Rcms): {}[] {
    let conteudo = [];
    let favorecidos = this.getFavorecidos32(rcms);

    const titulos: any[] = [
      { text: 'QUANTIDADE', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
      { text: 'CÓDIGO', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
      { text: 'UN', alignment: 'center', bold: true, margin: [0, 5, 0, 5] },
      { text: 'DESCRIÇÃO DO ITEM', alignment: 'center', bold: true, margin: [0, 5, 0, 5] }
    ];

    conteudo.push(titulos.concat(favorecidos.map((f, i) => {
      return { text: `FORNECEDOR ${i > 8 ? i + 1 : `0${i + 1}`}`, alignment: 'center', bold: true };
    })));

    favorecidos = favorecidos.map((f) => {
      f['valor_total'] = 0;
      return f;
    });

    for (let item of rcms.itens) {
      const linha = [
        { text: this.funcaoService.convertToBrNumber(item.quantidade), alignment: 'right', border: [true, false, true, false] },
        { text: item.produto_unidade?.produto?.codigo, alignment: 'center', border: [true, false, true, false] },
        { text: item.produto_unidade?.unidade?.nome, alignment: 'center', border: [true, false, true, false] },
        { text: this.mostrarDescricao ? (item.produto ?? item.produto_unidade?.produto?.descricao) : item.produto_unidade?.produto?.nome, alignment: 'left', border: [true, false, true, false] },
      ];

      for (let favorecido of favorecidos) {
        let cotacao = item.cotacoes.find((c) => c.rcmsFavorecido.id === favorecido.id);
        if (cotacao) {
          cotacao.valor_unitario = +(+cotacao.valor_unitario).toFixed(4);
          favorecido['valor_total'] += +(item.quantidade * cotacao.valor_unitario).toFixed(6);
        }
        linha.push({
          text: cotacao ?
            this.funcaoService.convertToBrNumber(+cotacao.valor_unitario, 5)
            : '', alignment: 'right', border: [true, false, true, false]
        });
      }
      conteudo.push(linha);
    }

    this.qtdLinhaObservacao = Math.ceil(rcms.observacao ? rcms.observacao.length / 120 : 0);
    let linhas = 55 - (3 * conteudo.length) - this.qtdLinhaFavorecidos;
    if (linhas > 0) {
      conteudo = conteudo.concat(Array.from(Array(linhas).keys()).map(_ =>
        Array.from(Array(4 + favorecidos.length).keys()).map((_) => { return { text: '', border: [true, false, true, false] }; })
      ));
    }

    const total: any[] = [
      { text: 'TOTAL', colSpan: 4, border: [true, true, true, true] }, { text: '' }, { text: '' }, { text: '' }
    ];

    conteudo.push(total.concat(favorecidos.map((f) => {
      return { text: this.funcaoService.convertToBrNumber(+f['valor_total']), alignment: 'right', border: [true, true, true, true] };
    })));

    return [{
      layout: 'linhas',
      table: {
        widths: (<any[]>['auto', 'auto', 'auto', '*']).concat(favorecidos.map(_ => 60)),
        body: conteudo
      }
    }];
  }

  //Não mexer
  private dadosTotalizacao2(rcms: Rcms): {}[] {
    let valor_total = 0;
    let menor_global = 0;

    for (let item of rcms.itens) {
      let menor = 0
      let qntCotaValidas = 0
      let valorSomadoCotaValidas = 0

      if (item.cotacoes && item.cotacoes.length > 0) {
        let cotacao_ordenada = item.cotacoes
          .sort((a, b) => a.valor_unitario <= 0 ? -1 : (a.valor_unitario - b.valor_unitario))
        menor = cotacao_ordenada.find(a => a.valor_unitario > 0).valor_unitario
      }
      menor_global += +menor * +item.quantidade;

      item.cotacoes.map(cotacao => {
        if (cotacao.valor_unitario && +cotacao.valor_unitario > 0) {
          qntCotaValidas++
          valorSomadoCotaValidas += +cotacao.valor_unitario * +item.quantidade
        }
      });

      valor_total += +valorSomadoCotaValidas / +qntCotaValidas
    }

    const conteudo: any[] = [
      [{
        text: `APURAÇÃO TOTAL DE MENOR CUSTO:  R$        ${this.funcaoService.convertToBrNumber(menor_global)}`,
        border: [true, false, true, false], bold: true
      },],
      [{
        text: `APURAÇÃO TOTAL DE CUSTO MÉDIO:  R$        ${this.funcaoService.convertToBrNumber(valor_total)}`,
        border: [true, false, true, false], bold: true
      },],
      [{ text: `Observação:`, border: [true, false, true, false], margin: [0, 0, 0, !rcms.observacao ? 8 : 0] }],
      [{ text: rcms.observacao, border: [true, false, true, false] }]
    ];

    return [{
      layout: 'linhas',
      table: {
        widths: ['*'],
        body: conteudo
      }
    }];
  }

  //Verificar tamanho das assinaturas
  private dadosAssinatura2(rcms: Rcms): {}[] {
    let assinaturas = [];
    if (this.assinatura) {
      assinaturas = this.assinatura.pessoas.filter(p => p.selecionado);
    }

    if (this.assinatura && assinaturas?.length > 0) {
      const conteudo = [
        [{ text: 'Esta requisição não autoriza o fornecedor a entregar produto/serviço', margin: [0, 0, 0, 0], border: [true, true, true, false] }],
        [{ text: rcms.excluido === false && rcms.bloqueado ? 'REQUISIÇÃO BLOQUEADA PARA ANÁLISE DA DIVISÃO DE COMPRAS' : "", fontSize: 13, alignment: 'center', bold: true, margin: [0, 0, 0, 30], border: [true, true, true, false] }],
        [{ columns: [this.imprimirAssinaturas(assinaturas, 10)], border: [true, false, true, false] }],
        [{ text: [{ text: 'Operador:   ', bold: true }, { text: rcms.operador?.nome }], margin: [0, 0, 0, 0], border: [true, false, true, true] }]
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: false,
          widths: ['*'],
          body: conteudo
        }
      }];
    } else {
      // landscape
      const assinaturaPadrao = [
        [{ text: 'Esta requisição não autoriza o fornecedor a entregar produto/serviço', margin: [0, 0, 0, 0], border: [true, true, true, false] }],
        [{ text: rcms.excluido === false && rcms.bloqueado ? 'REQUISIÇÃO BLOQUEADA PARA ANÁLISE DA DIVISÃO DE COMPRAS' : "", fontSize: 13, bold: true, alignment: 'center', margin: [0, 0, 0, 30], border: [true, true, true, false] }],
        [{ canvas: [{ type: 'line', x1: 53, y1: 0, x2: 213, y2: 0, lineWidth: 0.5 }, { type: 'line', x1: 312, y1: 0, x2: 472, y2: 0, lineWidth: 0.5 }, { type: 'line', x1: 570, y1: 0, x2: 730, y2: 0, lineWidth: 0.5 }], border: [true, false, true, false] }],
        [{
          columns: [
            { text: 'Requerente', alignment: 'center', margin: [0, 3, 0, 16], border: [true, false, true, false] },
            { text: 'Secretário Requisitante', alignment: 'center', margin: [0, 3, 0, 16], border: [true, false, true, false] },
            { text: 'Secretário de Finanças', alignment: 'center', margin: [0, 3, 0, 16], border: [true, false, true, false] },
          ], border: [true, false, true, false]
        },],
        [{ text: [{ text: 'Operador:   ', bold: true }, { text: rcms.operador?.nome }], margin: [0, 0, 0, 0], border: [true, false, true, true] }]
      ];

      return [{
        layout: 'linhas',
        table: {
          dontBreakRows: false,
          widths: ['*'],
          body: assinaturaPadrao
        }
      }];
    }
  }
}
