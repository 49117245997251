import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContratoService, EmpenhoService, FavorecidoSocioService, LicitacaoService } from 'administrativo-lib';
import {
  AudespService,
  BaseResourceFormComponent, Compra, Contrato, ContratoAditamento, ContratoHistorico,
  ContratoItem,
  ContratoOcorrencia,
  ContratoReajuste, ContratoRescisao, ContratoResponsavel, ContratoStorage, DateFormatPipe, EddyAutoComplete,
  Empenho,
  Favorecido, FavorecidoService, FavorecidoSocio, FuncaoService, GlobalService, Licitacao, LicitacaoPipe, LoginContabil,
  Modalidade,
  PNCPService,
  Prazo, Rcms, TipoContratacao, TipoContrato
} from 'eddydata-lib';
import { ConfirmationService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ModalidadeService } from '../../modalidade/service/modalidade.service';
import { PrazoService } from '../../prazo/service/prazo.service';
import { TipoContratacaoService } from '../../tipo-contratacao/service/tipo-contratacao.service';
import { ContratoAditamentoService } from '../service/contrato-aditamento.service';
import { ContratoHistoricoService } from '../service/contrato-historico.service';
import { ContratoItemService } from '../service/contrato-item.service';
import { ContratoOcorrenciaService } from '../service/contrato-ocorrencia.service';
import { ContratoReajusteService } from '../service/contrato-reajuste.service';
import { ContratoRescisaoService } from '../service/contrato-rescisao.service';
import { ContratoResponsavelService } from '../service/contrato-responsavel.service';
import { ContratoStorageService } from '../service/contrato-storage.service';
import { RcmsService } from '../../rcms/service/rcms.service';
import { CompraService } from '../../compra/service/compra.service';

declare var $: any;
@Component({
  selector: 'app-contrato-form',
  templateUrl: './contrato-form.component.html'
})
export class ContratoFormComponent extends BaseResourceFormComponent<Contrato, LoginContabil> implements OnInit {
  /**
   * Declaração de variáveis
   */
  @ViewChild('numero_') inputField: ElementRef;

  public listaArquivos: ContratoStorage[];
  public listaModalidades: Modalidade[];
  public dataContabil: Date = null;
  public displayHistorico: boolean = false;
  public cleanScreen: boolean = false;
  public buttonBlocked = true;
  public tipos: { id: 'CONTRATO' | 'REGISTRO_PRECO' | 'ATA_REGISTRO', nome: string }[];
  public listaTipos: { id: TipoContrato, nome: string }[];
  public listaAtosPotenciaisPassivos: { id: string, nome: string }[];
  public listaContratacao: TipoContratacao[];
  public listaPrazos: Prazo[];
  public listaResponsaveis: ContratoResponsavel[] = [];
  public listaRescisoes: ContratoRescisao[] = [];
  public favorecidoAutoComplete: EddyAutoComplete<Favorecido>;
  public licitacaoAutoComplete: EddyAutoComplete<Licitacao>;
  public listaAditamentos: Array<ContratoAditamento> = new Array<any>();
  public listaHistoricos: Array<ContratoHistorico> = new Array<any>();
  public listaReajustes: Array<ContratoReajuste> = new Array<any>();
  public desabilitarProcesso = false;

  // ajuste de conversão de serviços
  public visulizarAjusteServico: boolean = false;
  public ajusteSemSubmit: boolean = false;
  public produtosAjuste: any[] = [];
  public confirmCadastroItens = false;
  public totalMaterial = 0;
  public totalServico = 0;
  public totalLocacao = 0;
  public tipoDeContratacao: TipoContratacao;
  public atualizandoItens: boolean = false;

  //Atualização CNO
  public tipoObra: number = 0;
  public cno: string = '';
  private entidadeOriginal = null;

  public tiposLei: { id: boolean, nome: string }[] = [
    { id: false, nome: 'Lei 8666/1993' },
    { id: true, nome: 'Lei 14.133/2021' },
  ];
  /**
   * Construtor com as injeções de dependencias
   */
  constructor(
    protected injector: Injector,
    public funcaoService: FuncaoService,
    private favResponsavelService: FavorecidoSocioService,
    protected storageService: ContratoStorageService,
    protected aditamentoService: ContratoAditamentoService,
    protected historicoService: ContratoHistoricoService,
    protected reajusteService: ContratoReajusteService,
    protected globalService: GlobalService,
    protected prazoService: PrazoService,
    protected contratacaoService: TipoContratacaoService,
    protected contratoService: ContratoService,
    protected responsavelService: ContratoResponsavelService,
    protected rescisaoService: ContratoRescisaoService,
    protected modalidadeService: ModalidadeService,
    protected licitacaoService: LicitacaoService,
    protected itemService: ContratoItemService,
    protected ocorrenciaService: ContratoOcorrenciaService,
    protected contratoItenService: ContratoItemService,
    protected favorecidoService: FavorecidoService,
    protected contratoItemService: ContratoItemService,
    protected rcmsService: RcmsService,
    protected compraService: CompraService,
    protected empenhoService: EmpenhoService,
    private arquivoXmlService: AudespService,
    private pncpService: PNCPService,
    protected confirmationService: ConfirmationService,
  ) {
    super(new Contrato(), injector, Contrato.converteJson, contratoService);
  }

  // ========================================================================
  //                        MÉTODOS ABSTRAÍDOS
  // ========================================================================

  protected criarCamposForm(): void {
    this.entidadeForm = this.fb.group({
      id: [null],
      numero: [null, [Validators.required]],
      ano: [new Date().getFullYear(), [Validators.required]],
      data_assinatura: [null, [Validators.required]],
      data_inicio: [null, [Validators.required]],
      data_termino: [null, [Validators.required]],
      data_contabilizacao: [null],
      processo: [null],
      tipo_contrato: [null, [Validators.required]],
      tipo: ['CONTRATO', [Validators.required]],
      objeto: [null, [Validators.required]],
      gestor_cpf: [null],
      gestor_exige: [false],
      gestor: [null],
      gestor_cargo: [null],
      valor_contrato: [0, [Validators.required]],
      valor_garantia: [0, [Validators.required]],
      valor_caucao: [0, [Validators.required]],
      valor_material: [0, [Validators.required]],
      valor_servico: [0, [Validators.required]],
      valor_locacao: [0, [Validators.required]],
      enviar_email: [false, [Validators.required]],
      parente: [null],
      licitacao: [null],
      modalidade: [null],
      orgao: [this.login.orgao, [Validators.required]],
      favorecido: [null, [Validators.required]],
      entrega: [null],
      prazo: [null, [Validators.required]],
      tipo_contratacao: [null, [Validators.required]],
      inativo: [this.login.parametro['licitacao']?.contratos_inativos, [Validators.required]],
      saldo_quantidade: [false, [Validators.required]],
      saldo_valor: [false, [Validators.required]],
      itens: [null],
      contratos_ppp: [null],
      responsaveis: [null],
      ocorrencias: [null],
      fiscais: [],
      testemunhas: [],
      data_historico: [null],
      cno: [null],
      ind_obra: [null],
      data_prorrogacao: [null],
      prazo_resposta: [null],
      indice_reajuste: [null],
      multa_moratoria: [0.5],
      permite_subcontratacao: [false],
      porcentagem_subcontratacao: [0],
      valor_mensal: [0],
      excluido: [false],
      justificativa_exclusao: [null],
      usuario_exclusao: [null],
      data_exclusao: [null],
      lei_14133_2021: [false],
      atos_potenciais_passivos: [null]
    });
  }

  protected parametrosExtras(): {} {
    return {
      relations: 'favorecido,favorecido.tipo,orgao,orgao.cidade,prazo,entrega,tipo_contratacao,licitacao,'
        + 'itens.memorial,itens.produto_unidade.produto.material,'
        + 'modalidade,licitacao_audesp,fiscais.pessoa,testemunhas.pessoa,'
        + 'contrato_pncp,licitacao.exercicio,responsaveis.contrato,responsaveis.responsavel,usuario_exclusao,'
        + 'contratos_ppp'
    };
  }

  protected afterInit(): void {
    this.tipos = [{ id: 'CONTRATO', nome: 'Contrato' }, { id: 'REGISTRO_PRECO', nome: 'Registro de Preço' }];
    this.listaTipos = this.globalService.obterTiposContrato();
    this.listaAtosPotenciaisPassivos = this.globalService.obterAtosPotenciaisPassivos();
    this.contratacaoService.filtrar(0, 0, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaContratacao = res ? res.content : [];
      }, error => toastr.error(error.message ? error.message : error));
    this.prazoService.filtrar(0, 0, { orgao_id: this.login.orgao.id }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaPrazos = res ? res.content : [];
      }, error => toastr.error(error.message ? error.message : error));
    this.modalidadeService.filtrar(1, -1, {
      'cidade.id': this.login.cidade.id, orderBy: 'codigo'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaModalidades = res ? res.content : new Array<Modalidade>();
      }, error => this.funcaoService.acaoErro(error));

    // autocomplete para favorecido
    this.favorecidoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('favorecido'), this.favorecidoService,
      'id', ['cpf_cnpj', 'nome'], { cidade_id: this.login.cidade.id, orderBy: 'nome', relations: 'tipo' }, { number: ['id', 'cpf_cnpj'], text: ['nome'] },
      () => {
        if (this.currentActionRoute === 'novo') {
          this.favResponsavelService.filtrar(0, 0, { favorecido_id: this.entidadeForm.get('favorecido').value.id, orgao_id: this.login.orgao.id, relations: 'pessoa' })
          if (+this.entidadeForm.get('favorecido').value.tipo.tce !== 1 && +this.entidadeForm.get('favorecido').value.tipo.tce !== 2 && +this.entidadeForm.get('favorecido').value.tipo.tce !== 10) {
            toastr.warning('Verifique a espécie no cadastro de fornecedor');
            this.entidadeForm.get('favorecido').setValue(null);
            return;
          }
          this.favResponsavelService.filtrar(0, 0, { favorecido_id: this.entidadeForm.get('favorecido').value.id, relations: 'pessoa' })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
              const responsaveis: FavorecidoSocio[] = res.content;
              if (responsaveis?.length === 0) return;

              responsaveis.forEach(f => {
                if (!this.listaResponsaveis.find(c => c.responsavel.id === f.pessoa.id)) {
                  this.listaResponsaveis.push({
                    responsavel: f.pessoa,
                    ativo: true,
                    contratante: false
                  });
                }
              });
            });
        }
      }
    );
    this.licitacaoAutoComplete = new EddyAutoComplete(this.entidadeForm.get('licitacao'), this.licitacaoService,
      'numero', ['numero', 'modalidade.nome'], { orgao_id: this.login.orgao.id, 'situacao$ne': 'REMOVIDO', orderBy: 'numero', relations: 'modalidade' }, { text: ['numero'] }, undefined, undefined, (ent) => {
        return new LicitacaoPipe().transform(ent.numero)
      }
    );

    if (this.currentActionRoute === 'novo') {
      this.contratoService.proximoNumero(this.login.exercicio.ano)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          this.entidadeForm.get('numero').setValue(res);
        });
    }
  }

  protected campoFoco(): ElementRef {
    return this.inputField;
  }

  protected afterLoad() {
    if (this.entidade.excluido) {
      this.router.navigate(['/contratos']);
      toastr.warning('Não é possível editar o contrato. Contrato Excluído!');
      return;
    }
    this.entidade.data_assinatura = new DateFormatPipe().transform(this.entidade.data_assinatura, []);
    this.entidade.data_inicio = new DateFormatPipe().transform(this.entidade.data_inicio, []);
    this.entidade.data_termino = new DateFormatPipe().transform(this.entidade.data_termino, []);
    this.entidade.data_contabilizacao = new DateFormatPipe().transform(this.entidade.data_contabilizacao, []);
    this.entidade.prazo_resposta = new DateFormatPipe().transform(this.entidade.prazo_resposta, []);
    this.entidade.data_prorrogacao = new DateFormatPipe().transform(this.entidade.data_prorrogacao, []);
    this.entidade.lei_14133_2021 = this.entidade?.licitacao?.lei_14133_2021 ? this.entidade?.licitacao?.lei_14133_2021 : this.entidade.lei_14133_2021;
    this.favorecidoAutoComplete.id = this.entidade.favorecido ? this.entidade.favorecido.id : null;
    this.licitacaoAutoComplete.id = this.entidade.licitacao ? this.entidade.licitacao.id : null;
    this.entidadeOriginal = Object.assign({}, this.entidade);
    this.preencherTabelas();
    this.verificarItens();
    this.calcularTotalLSM();
  }

  protected async podeAlterar(item: Contrato): Promise<boolean> {
    return !item.excluido && this.login.sistema != 'controle-interno';
  }

  public alteracaoHistorico(event) {
    this.entidade = event;
    this.loadResource();
  }

  public atualizarTabelasContrato() {
    this.preencherTabelas();
    this.atualizandoItens = !this.atualizandoItens;
  }

  public retornaCota(itemAtual: ContratoItem, lista: ContratoItem[]) {
    let cota: 'P' | 'R';
    if (itemAtual.memorial) {
      cota = itemAtual.memorial.cota === 'PRINCIPAL' ? 'P' : itemAtual.memorial.cota === 'NAO_DEFINIDO' ? 'P' : 'R'
    } else {
      let itemIgual = lista.find(it => it.produto_unidade.id === itemAtual.produto_unidade.id && it.id !== itemAtual.id)
      cota = !itemIgual ? 'P' : +itemAtual.quantidade > +itemIgual.quantidade ? 'P' : 'R'
    }
    return cota;
  }

  public preencherTabelaItens() {
    this.itemService.itensContratoSaldo(this.entidade.id, this.login.orgao.id).pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.entidade.itens = res ? res : [];
        for (let i = 0; i < this.entidade?.itens?.length; i++) {
          if (this.entidade.itens[i]?.memorial?.id && !this.entidade.itens[i]?.ordem) {
            this.entidade.itens[i].ordem = this.entidade.itens[i].memorial?.ordem;
          }
          if (!this.entidade.itens[i]?.memorial?.id && !this.entidade.itens[i]?.ordem) {
            +i == 0 ? this.entidade.itens[+i].ordem = 1 : this.entidade.itens[+i].ordem = 1 + i;
          }
        }

        this.entidade.itens.forEach(item => {
          item['cota'] = item['cota'] === 'P' ? 'PRINCIPAL' : 'RESERVADO'
        })

      });
  }

  public preencherTabelas() {
    this.storageService.filtrar(1, -1, { 'contrato.id': this.entidade.id, orderBy: 'data_cadastro$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaArquivos = res.content;
      });

    this.aditamentoService.filtrar(0, -1, {
      'contrato.id': this.entidade.id,
      relations:
        'contrato.tipo_contratacao,exercicio,tipo,itens.contrato_item.memorial,'
        + 'licitacao_audesp,contrato.orgao,contrato.orgao.cidade,contrato.favorecido,'
        + 'contrato.favorecido.tipo,itens.contrato_item.produto_unidade.produto,'
        + 'itens.contrato_item.produto_unidade.unidade,contrato.licitacao'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaAditamentos = data ? data.content : new Array<ContratoAditamento>();
        }, error => this.funcaoService.acaoErro(error));

    this.responsavelService.filtrar(1, -1,
      { relations: 'responsavel', 'contrato.id': this.entidade.id, orderBy: 'id$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaResponsaveis = res ? res.content : new Array<ContratoResponsavel>();
      }, error => this.funcaoService.acaoErro(error));

    this.rescisaoService.filtrar(1, -1,
      { relations: 'usuario', 'contrato.id': this.entidade.id, orderBy: 'id$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.listaRescisoes = res ? res.content : new Array<ContratoRescisao>();
      }, error => this.funcaoService.acaoErro(error));

    this.historicoService.filtrar(0, -1, {
      'contrato.id': this.entidade.id,
      relations: 'contrato,tipo_contratacao,licitacao,usuario',
      'orderBy': 'id$DESC'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaHistoricos = data ? data.content : new Array<ContratoHistorico>();
        }, error => this.funcaoService.acaoErro(error));

    this.reajusteService.filtrar(0, -1, {
      'contrato_item.contrato.id': this.entidade.id, relations: 'contrato_item.produto_unidade.produto.material'
    }).pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: any) => {
          this.listaReajustes = data ? data.content : new Array<ContratoReajuste>();
        }, error => this.funcaoService.acaoErro(error));

    this.ocorrenciaService.filtrar(1, -1, { 'contrato.id': this.entidade.id, relations: 'usuario', orderBy: 'data_ocorrencia$DESC' })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.entidade.ocorrencias = res.content;
      });
  }

  public mudancaStatus(numero) {
    if (!this.entidade.id) {
      return;
    }
    if (numero === 3) {
      if (!this.entidade.data_contabilizacao) {
        this.confirmationService.confirm({
          header: 'Ativação de contrato',
          message: `<pre>Confirma a ativação do contrato? Ele receberá data de contabilização para <b>hoje</b>, essa ação não poderá ser revertida.</pre>.`,
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Sim, confirmo ativação do contrato.',
          rejectLabel: 'Não',
          accept: async () => {
            this.entidade.data_contabilizacao = new Date();
            this.efetivarAtivacaoInativacao();
          }
        });
      } else {
        this.entidade['ignoreContabilizacao'] = true;
        this.efetivarAtivacaoInativacao();
      }
    } else {

      if (numero === 6) {
        this.entidade['ignoreContabilizacao'] = true;
        if (this.entidade.saldo_valor === true) {
          this.entidade.saldo_valor = false;
        } else {
          if (this.entidade.itens.find(c => c.produto_unidade.produto.material.servico === true)) {
            this.entidade.saldo_valor = true;
            this.entidade.saldo_quantidade = false;
            this.entidadeForm.get('saldo_quantidade').setValue(false);
          } else {
            toastr.error('Não foram localizados serviços no contrato!');
            this.entidadeForm.get('saldo_valor').setValue(false);
            return;
          }
        }
        this.contratoService.atualizar(this.entidade).subscribe(res => {
          toastr.success('Registro salvo com sucesso!');
        }, error => this.funcaoService.acaoErro(error));
      } else {

        if (this.entidade.id && new DateFormatPipe().transform(this.entidade.data_contabilizacao, []) < new DateFormatPipe().transform(new Date, [])) {
          toastr.error('Data de contabilização inválida, período na contabilidade já está fechado, entre em contato com o contador.');
        }
        if (this.entidade.id && new DateFormatPipe().transform(this.entidade.data_contabilizacao, []) >= new DateFormatPipe().transform(new Date, [])) {

          if (this.entidadeForm.get('tipo').value === this.entidade.tipo && numero === 1) {
            toastr.error('Tipo selecionado já encontra salvo!');
            return;
          } else {
            this.entidade.tipo = this.entidadeForm.get('tipo').value;
          }

          if (numero === 2) {
            if (this.entidade.enviar_email === true) {
              this.entidade.enviar_email = false;
            } else {
              this.entidade.enviar_email = true;
            }
          }
          if (numero === 4) {
            if (this.entidade.saldo_quantidade === true) {
              this.entidade.saldo_quantidade = false;
            } else {
              this.entidade.saldo_quantidade = true;
              this.entidade.saldo_valor = false;
              this.entidadeForm.get('saldo_valor').setValue(false);
            }
          }

          if (numero === 5) {
            this.entidade.permite_subcontratacao = !this.entidade.permite_subcontratacao;
          }
          this.contratoService.atualizar(this.entidade).subscribe(res => {
            toastr.success('Registro salvo com sucesso!');

          }, error => this.funcaoService.acaoErro(error));
        }
      }
    }
  }

  private efetivarAtivacaoInativacao() {
    this.entidade.inativo = !this.entidade.inativo;
    this.contratoService.atualizar(this.entidade).subscribe(res => {
      if (this.entidade.licitacao_audesp?.enviado) {
        toastr.error(`Registro já foi enviado para a FASE IV (${this.entidade.licitacao_audesp.codigo})`);
      }
      if (this.entidade.licitacao_audesp_obra?.enviado) {
        toastr.error(`Registro já foi enviado para a FASE IV (${this.entidade.licitacao_audesp_obra.codigo})`);
      }

      this.entidadeForm.get('inativo').setValue(this.entidade.inativo);
      this.entidadeForm.get('data_contabilizacao').setValue(this.entidade.data_contabilizacao);

      toastr.success('Registro salvo com sucesso!');
      this.entidade['ignoreContabilizacao'] = false;

    }, error => this.funcaoService.acaoErro(error));
  }

  protected async beforeSubmit() {
    if (this.entidade.itens.filter((i) => !i.produto_unidade?.id).length > 0) {
      toastr.warning(`Foi identificado itens com serviços não cadastrados`);
      this.produtosAjuste = this.entidade.itens.filter((i) => !i.produto_unidade?.id)
        .map((i) => { return { produto: i.descricao, unidade: i.unidade }; });
      this.visulizarAjusteServico = true;
      throw new Error();
    }

    try {
      if (this.entidadeForm.get('favorecido').value === null) {
        throw new Error('É necessário informar o nome do favorecido');
      }
      if (+this.entidadeForm.get('favorecido').value.tipo.tce === 3 || +this.entidadeForm.get('favorecido').value.tipo.tce === 9) {
        toastr.warning('Verifique a espécie no cadastro de fornecedor');
        this.entidadeForm.get('favorecido').setValue(null);
        return;
      }
      if (this.entidadeForm.get('data_assinatura').value === null) {
        throw new Error('É necessário informar a data de assinatura do contrato');
      }
      if (this.entidadeForm.get('data_inicio').value === null) {
        throw new Error('É necessário informar a data de início do contrato');
      }
      if (this.entidadeForm.get('data_contabilizacao').value === null && !this.entidadeForm.get('inativo').value) {
        throw new Error('É necessário informar a data de contabilização do contrato');
      }
      if (this.entidadeForm.get('data_termino').value === null) {
        throw new Error('É necessário informar a data de término do contrato');
      }
      if (this.entidadeForm.get('ano').value === null) {
        throw new Error('É necessário informar o ano do contrato');
      }
      if (this.entidadeForm.get('modalidade').value === null) {
        throw new Error('É necessário informar a modalidade');
      }
      if (this.entidadeForm.get('tipo_contratacao').value === null) {
        throw new Error('É necessário informar o tipo de contratação');
      }
      if (this.entidadeForm.get('tipo_contrato').value === null) {
        throw new Error('É necessário informar o tipo de contrato');
      }
      if (this.entidadeForm.get('objeto').value === null) {
        throw new Error('É necessário informar o objeto do contrato');
      }
      const dtContabilizacao: Date = this.entidadeForm.get('data_contabilizacao').value;
      if (dtContabilizacao && dtContabilizacao.getFullYear() !== this.login.exercicio.ano) {
        throw new Error('O ano da data de contabilização está diferente do exercício logado');
      }
      if (this.entidade.permite_subcontratacao
        && (+this.entidadeForm.get('porcentagem_subcontratacao').value > 100 || +this.entidadeForm.get('porcentagem_subcontratacao').value <= 0)) {
        throw new Error('Porcentagem de sub contratação inválida');
      }
      if (+this.entidadeForm.get('multa_moratoria').value < 0.5 || this.entidadeForm.get('multa_moratoria').value > 30) {
        throw new Error('A porcentagem de multa moratória deve ser entre 0.5% e 30%');
      }

      this.entidadeForm.get('valor_contrato').setValue(this.obterTotal());
      this.entidadeForm.get('responsaveis').setValue(this.listaResponsaveis);
      this.entidadeForm.get('ocorrencias').setValue(this.entidade.ocorrencias);
      this.entidadeForm.get('fiscais').setValue(this.entidade.fiscais);
      this.entidadeForm.get('testemunhas').setValue(this.entidade.testemunhas);
      this.entidadeForm.get('itens').setValue(this.entidade.itens);
      if (!this.entidadeForm.get('tipo_contratacao').value?.exige_cno) {
        this.entidadeForm.get('cno').setValue(null);
        this.entidadeForm.get('ind_obra').patchValue(0);
        this.entidade.cno = null;
        this.entidade.ind_obra = 0;
      }
    } catch (e) {
      this.funcaoService.acaoErro(e);
      throw e;
    }
  }

  /*
  * Faz a verificação de campos que fazem parte do envio para o AUDESP.
  * Se houve alteração desses campos ele grava no histórico para posterior envio.
  */
  public async verificarMudancaAudesp(limpa?: boolean) {
    if (this.entidade.id && (this.entidade.processo !== this.entidadeForm.get('processo').value ||
      this.entidade.data_inicio !== this.entidadeForm.get('data_inicio').value ||
      this.entidade.data_termino !== this.entidadeForm.get('data_termino').value || this.entidade.tipo_contratacao?.id !== this.entidadeForm.get('tipo_contratacao').value.id ||
      +this.entidade.valor_caucao !== +this.entidadeForm.get('valor_caucao').value || +this.entidade.valor_contrato !== +this.entidadeForm.get('valor_contrato').value
      || this.entidade.licitacao !== this.entidadeForm.get('licitacao').value)) {
      this.displayHistorico = true;
      this.cleanScreen = limpa;
    } else {
      this.submitForm(limpa);
    }
  }

  public confirmaHistorico() {
    if (!this.dataContabil) {
      toastr.error('Informe a data de envio para alterações de contrato ao TCE');
      return;
    }
    const dtEnvioHistorico: Date = this.dataContabil;
    if (dtEnvioHistorico.getFullYear() !== this.login.exercicio.ano) {
      toastr.error('O ano da data de envio do histórico de alterações está diferente do exercício logado');
      return;
    }
    if (!this.podeAlterarAudesp(dtEnvioHistorico)) {
      toastr.error('Data de envio inválida, período na contabilidade já encerrado!');
      return;
    }
    this.entidadeForm.get('data_historico').setValue(this.dataContabil);
    this.displayHistorico = false;
    this.submitForm(this.cleanScreen);
    this.cleanScreen = false;
  }

  protected afterSubmit(ent: Contrato) {
    if (this.currentActionRoute === 'novo' && this.limparTela) {
      this.entidadeForm.reset();
      this.entidadeForm.get('orgao').setValue(this.login.orgao);
      this.router.navigate(['/contratos', 'novo']);
    } else if (this.currentActionRoute === 'novo' && !this.limparTela) {
      this.router.navigate(['/contratos', ent.id, 'editar']);
    } else {
      this.afterLoad();
    }
  }

  // ========================================================================
  //                            MÉTODOS DA CLASSE
  // ========================================================================

  public tipoContratacao() {
    if (this.entidadeForm.get('tipo_contratacao').value.servico && this.entidadeForm.get('tipo_contratacao').value.material) {
    }
  }

  public carregarModalidade() {
    const licitacao: Licitacao = this.entidadeForm.get('licitacao').value;
    if (!licitacao) return;
    if (licitacao.modalidade) {
      this.entidadeForm.get('modalidade').setValue(licitacao.modalidade);
    }

    this.entidadeForm.get('processo').setValue(licitacao.processo)
    this.desabilitarProcesso = true;
  }

  public obterTotal(): number {
    if (!this.entidade.itens || this.entidade.itens.length === 0) return this.entidade.valor_contrato;

    let total = 0;
    for (const item of this.entidade.itens) total += +(+item.quantidade * +item.valor_unitario).toFixed(2);

    if (this.entidadeForm.get('data_inicio').value && this.entidadeForm.get('data_termino').value) {
      const meses = this.funcaoService.diferencaEmMeses(new Date(this.entidadeForm.get('data_inicio').value), new Date(this.entidadeForm.get('data_termino').value));
      this.entidadeForm.get('valor_mensal').setValue(+(total / (meses < 1 ? 1 : meses + 1)).toFixed(2));
    }
    this.calcularTotalLSM();

    return total;
  }

  public aposAjustarProdutos(produtos: any[]) {
    if (!produtos)
      return;
    if (produtos.filter((p) => !p.produto_unidade?.id).length > 0) {
      toastr.warning(`Alguns serviços não foram ajustados`);
      return;
    }
    for (let item of this.entidade.itens) {
      if (item.produto_unidade?.id)
        continue;
      let produto_unidade = produtos
        .find((p) => p.produto === item.produto_unidade.produto.nome
          && p.unidade === item.produto_unidade.unidade.nome);
      if (produto_unidade) {
        item.produto_unidade = produto_unidade.produto_unidade;
      }
    }
    if (!this.ajusteSemSubmit)
      this.submitForm(this.limparTela);
    this.ajusteSemSubmit = false;
  }

  public cancelarAjuste() {
    if (this.ajusteSemSubmit) {
      this.entidade.itens = [];
    }
    this.visulizarAjusteServico = false;
  }

  public verificarItens(): void {
    const licitacao = this.entidadeForm.get('licitacao').value;
    const processo = this.entidadeForm.get('processo').value;
    if (this.entidade.id && !licitacao && !processo && !this.entidade.itens?.length) {
      $('#modalCadastroItens').modal('show');
    }
  }

  public verificacaoItens(): void {
    this.confirmCadastroItens = true;
  }

  public salvarCadastroItens(): void {
    const contrato = { ...this.entidade };
    delete contrato.itens;

    this.entidade.itens = this.entidade.itens?.map(contratoItem => ({ ...contratoItem, contrato }));
    this.contratoItemService.inserirLista(this.entidade.itens)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        toastr.success('Itens cadastrados com sucesso');
        $('#modalCadastroItens').modal('hide');
        this.confirmCadastroItens = false;
        const cont = Object.assign({}, this.entidade)
        delete cont.itens;
        cont.valor_contrato = this.entidade.itens.reduce((acc, cur) => acc + cur.valor_unitario * cur.quantidade, 0)
        cont['ignoreContabilizacao'] = true;
        this.contratoService.atualizar(cont).pipe(takeUntil(this.unsubscribe))
          .subscribe(() => { },
            error => this.acaoErro(error))
      },
        error => this.acaoErro(error));
  }

  public setButtonBlocked(v: boolean): void {
    this.buttonBlocked = v;
  }

  atualizarCNO() {
    this.entidadeOriginal.ind_obra = this.tipoObra;
    this.entidadeOriginal.cno = this.cno;
    this.entidadeOriginal['atualizacao_reinf'] = true;
    this.contratoService.atualizar(this.entidadeOriginal).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      toastr.success('CNO atualizado com sucesso!');
      this.entidadeForm.get('ind_obra').setValue(this.tipoObra);
      this.entidadeForm.get('cno').setValue(this.cno);
    }, (e) => {
      toastr.error('Erro ao processar informações: ' + e.message ? e.message : e);
    });
  }

  public calcularTotalLSM(): void {
    this.totalMaterial = this.entidade.itens.reduce((acc, cur) => {
      if (!cur.produto_unidade?.produto?.material?.servico) {
        return acc + +(cur.valor_unitario * cur.quantidade).toFixed(2);
      }

      return acc;
    }, 0);
    this.entidadeForm.get('valor_material').setValue(this.totalMaterial);

    this.totalServico = this.entidade.itens.reduce((acc, cur) => {
      if (cur.produto_unidade?.produto?.material?.servico && !cur.produto_unidade?.produto?.material?.locacao) {
        return acc + +(cur.valor_unitario * cur.quantidade).toFixed(2);
      }

      return acc;
    }, 0);
    this.entidadeForm.get('valor_servico').setValue(this.totalServico);

    this.totalLocacao = this.entidade.itens.reduce((acc, cur) => {
      if (cur.produto_unidade?.produto?.material?.locacao) {
        return acc + +(cur.valor_unitario * cur.quantidade).toFixed(2);
      }

      return acc;
    }, 0);
    this.entidadeForm.get('valor_locacao').setValue(this.totalLocacao);
  }

  public abrirPncp() {
    window.open(this.pncpService.abrirPortalPNCP(this.entidade.orgao.cnpj, this.entidade.licitacao?.exercicio.ano, this.entidade.sequencial_pncp, 'C'), '_blank');
  }

  async excluir() {
    this.entidade.excluido = true;
    this.entidade.data_exclusao = new Date();
    this.entidade.usuario_exclusao = this.login.usuario;

    const ultimoAudesp = await this.arquivoXmlService.obter({
      armazenado: true, descricao: 'BALANCETE-ISOLADO-CONTA-CORRENTE', 'orgao.id': this.entidade.orgao.id,
      'orderBy': 'ano$desc,mes$DESC', fields: 'mes,ano', onlyFields: true
    }).toPromise();
    const dataAudesp = (ultimoAudesp?.ano ?? '1900') + '-' + (ultimoAudesp?.mes ?? '1');
    if (dataAudesp) {
      const mes = +dataAudesp.split('-')[1];
      const ano = +dataAudesp.split('-')[0];
      if (this.entidade.data_contabilizacao && (+this.funcaoService.converteDataSQL(this.entidade.data_contabilizacao).split('-')[0] < ano || (+this.funcaoService.converteDataSQL(this.entidade.data_contabilizacao).split('-')[0] === ano && +this.funcaoService.converteDataSQL(this.entidade.data_contabilizacao).split('-')[1] <= mes))) {
        toastr.warning('Este contrato está armazenado, será necessário realizar variação patrimonial. Contate o contador!');
      }
    }

    const contrato: Contrato[] = (await this.contratoService.extendido(1, 1, { id: this.entidade.id, 'orgao.id': this.entidade.orgao.id }).toPromise()).content
    if (+contrato[0].ano < new Date().getFullYear()) {
      toastr.error(`Existem movimentações em exercicios passados`);
      throw new Error(`Existem movimentações em exercicios passados`);
    }
    const empenho: Empenho[] = (await this.empenhoService.extendido(1, -1, { 'contrato.id': this.entidade.id, 'exercicio.ano': this.entidade.ano, 'orgao.id': this.entidade.orgao.id, ignoreCondObrig: true }).toPromise()).content

    if (empenho?.length > 0 && (+contrato[0]['total_empenho'] > 0 || +contrato[0]['total_compra_qtd'] > 0)) {
      toastr.error(`Existe empenho vinculado, anule para prosseguir`);
      throw new Error(`Existe empenho vinculado, anule para prosseguir`);
    }

    const compra: Compra[] = (await this.compraService.filtrar(1, -1, { 'contrato.id': this.entidade.id, excluido: false, ignoreCondObrig: true }).toPromise()).content
    if (compra?.length > 0 && +contrato[0]['total_compra_pendente'] > 0) {
      toastr.error(`Existe OF vinculada, exclua para prosseguir`);
      throw new Error(`Existe OF vinculada, exclua para prosseguir`);
    }

    const rcms: Rcms[] = (await this.rcmsService.filtrar(1, -1, { 'contrato.id': this.entidade.id, excluido: false, ignoreCondObrig: true }).toPromise()).content
    if (rcms?.length > 0 && +contrato[0]['total_rcms_pendente'] > 0) {
      toastr.error(`Existe requisição vinculada, exclua para prosseguir`);
      throw new Error(`Existe requisição vinculada, exclua para prosseguir`);
    }
    this.entidade['ignoreEstrituracao'] = true;

    this.contratoService
      .atualizar(this.entidade)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        $('#dlgExclusao').modal('hide');
        this.router.navigate([`/contratos/${this.entidade.id}/visualizar`]);
        toastr.success('Contrato excluído realizado com sucesso!');
      },
        error => {
          if (error.error && error.error.payload) {
            toastr.error(error.error.payload);
          } else {
            toastr.error('Não foi possível fazer o excluir o contrato!');
          }
        });
  }

  public async alterarPrazos() {
    const prazo = this.entidadeForm.get('prazo').value?.id !== this.entidade.prazo?.id ? this.entidadeForm.get('prazo').value : null;
    const entrega = this.entidadeForm.get('entrega').value?.id !== this.entidade.entrega?.id ? this.entidadeForm.get('entrega').value : null;
    this.contratoService.alterarPrazos(this.entidade.id, prazo?.id, entrega?.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.entidade.prazo = prazo;
        this.entidade.entrega = entrega;
        toastr.success('Prazo(s) alterado(s) com sucesso!');
      }, error => {
        if (error.error && error.error.payload) {
          toastr.error(error.error.payload);
        } else {
          toastr.error('Não foi possível alterar o(s) prazo(s) do contrato!');
        }
      });
  }

  public salvarDataContabilizacao() {
    if (!this.entidade.id) return
    if (this.podeIncluir() && this.login.usuario.administrador &&
      this.podeAlterarAudesp(this.entidade.data_contabilizacao) &&
      (this.funcaoService.converteDataBR(this.entidade.data_contabilizacao) !==
        this.funcaoService.converteDataBR(this.entidadeForm.get('data_contabilizacao').value)) &&
      this.entidade.data_assinatura <= this.entidadeForm.get('data_contabilizacao').value
    ) {
      this.confirmationService.confirm({
        header: 'Alterar data de contabilização?',
        message: `Alterar a data de contabilização de ${this.funcaoService.converteDataBR(this.entidade.data_contabilizacao)} para ${this.funcaoService.converteDataBR(this.entidadeForm.get('data_contabilizacao').value)}.`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim, confirmo alteração da data.',
        rejectLabel: 'Não',
        accept: async () => {
          this.entidade.data_contabilizacao = this.entidadeForm.get('data_contabilizacao').value;
          this.contratoService.atualizar(this.entidade)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              toastr.success('Data de contabilização alterada com sucesso!')
            }, error => {
              this.entidadeForm.get('data_contabilizacao').setValue(this.entidade.data_contabilizacao);
              if (error.error && error.error.payload) {
                toastr.error(error.error.payload);
              } else {
                toastr.error('Não foi possível alterar a data de contabilização do contrato!');
              }
            })
        }, reject: () => {
          this.entidadeForm.get('data_contabilizacao').setValue(this.entidade.data_contabilizacao);
        },
      });
    } else {
      toastr.error('Data de contabilização já contabilizada!');
      this.entidadeForm.get('data_contabilizacao').setValue(this.entidade.data_contabilizacao);
      return;
    }
  }
}
